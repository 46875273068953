var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MessageBox", {
    staticClass: "global-message",
    attrs: { transition: "t-fade", type: _vm.type, message: _vm.message },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var content = ref.content
          return [
            _c("div", { staticClass: "global-message-inner-wrapper" }, [
              _c("div", { staticClass: "message-content" }, [
                _c("span", { domProps: { innerHTML: _vm._s(content) } }),
                _vm._v(" "),
                _vm.url && _vm.url !== _vm.currentLocation.href
                  ? _c("a", { attrs: { href: _vm.url } }, [
                      _vm._v(_vm._s(_vm.linkText))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.showLoader
                ? _c("div", { staticClass: "loader-wrapper" }, [
                    _c("span", { staticClass: "loader" })
                  ])
                : _c(
                    "div",
                    { staticClass: "close-icon", on: { click: _vm.close } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.getAssetPath("cross--light.svg", "icons")
                        }
                      })
                    ]
                  )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
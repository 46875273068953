// imports
import _get from 'lodash/get';

import MeasuringStripModel from '$models/monitoring/MeasuringStripModel';
import MMCollection from '../MMCollection';

// class definition
export default class MeasuringStripCollection extends MMCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || MeasuringStripModel;
		
		// call super constructor
		super(args);
	}
}

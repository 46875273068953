// imports
import Model from '$dependencies/Model';
import { formatDateForUi } from '$utils/dateFormat';
import Config from '../../Config';
import format from 'date-fns/format'
import TaxonomyModel from '$models/TaxonomyModel';
import MeasuringStripModel from './MeasuringStripModel';

const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	title: { type: String, default: '' },
	location: { type: Object, default: {lat: null, lon: null} },
	projectPath: {type: Array, default: () => []},
	measurementDate: { type: String, default: '' },
	status: {type: Object, default: {}},
	locationType: {type: TaxonomyModel, default: () => new TaxonomyModel()},
};

// class definition
export default class ProjectMapModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	parse(data) {
		if(!data) return {};

		data.data['projectPath'] = geoToCoords(data.data.location);

		return super.parse(data.data);
	}

	// getters & setters
	get active() {
		return this.uuid ? true : false;
	}

	get id() {
		return this.uuid;
	}

	get formattedDate() {
		return formatDateForUi(this.startDate * 1000);
	}

	get fractionList() {
		const fractions = [];
		this.fractions.forEach(f => {
			fractions.push(f.title);
		});
		return fractions;
	}

	get partiesList() {
		const parties = [];
		this.involvedParties.forEach(f => {
			parties.push(f.title);
		});
		return parties;
	}

	get coordinates() {
		return { latitude: this.location.lat, longitude: this.location.lon };
	}

	get gmapCoordinates() {
		return { lat: this.location.lat, lng: this.location.lon };
	}

	get latitude() {
		return this.location.lat;
	}

	get longitude() {
		return this.location.lon;
	}

	set latitude(val) {
		return(this.location.lat = val);
	}

	set longitude(val) {
		return(this.location.lon = val);
	}

	get isMeasured() {
		return this.measurementCount > 0;
	}

	get formattedLastMeasurementDate() {
		return this.measurementDate ? formatDateForUi(this.measurementDate * 1000) : '-';
	}
}

function geoToCoords(geo) {
	const type = geo.indexOf('LINESTRING') === 0 ? 'polyline' : 'polygon';

	const regExp = /\((.*)\)/;
	const matches = regExp.exec(geo);

	let coords = [];

	switch (type) {
		case 'polygon':
			coords = regExp.exec(`${matches[1]}`)[1].split(', ')
			break;
		case 'polyline':
			coords = matches[1].split(', ')
			break;
		default:
			coords = regExp.exec(`${matches[1]}`)[1].split(', ')
			break;
	}

	coords = coords.map(coord => {
		return {
			lng: Number(coord.split(' ')[0]),
			lat: Number(coord.split(' ')[1]),
		}
	});

	return coords;
}


// imports
import _get from 'lodash/get';

import ActivityMarkerModel from '$models/volunteers/ActivityMarkerModel';
import Config from '$root/Config';
import MMCollection from '../MMCollection';

// class definition
export default class ActivityMarkerCollections extends MMCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || ActivityMarkerModel;
		args.config = {
			url: `${Config.getUrl('activities')}?format=map`,
		};
		super(args);
	}

	fetch() {
		this.setConfig('params', Object.assign(this.config.params, {format: 'map'}));
		this.setConfig('url', `${Config.getUrl('activities')}`);

		return super.fetch();
	}

	parse(data) {
		return super.parse(data.data);
	}
}

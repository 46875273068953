// imports
import Model from '$dependencies/Model';

// private static properties
export const FIELDS = {
	id: { type: String, default: '', identifier: true },
	category: { type: String, default: '' },
	postcode: { type: String, default: '' },
	permission: {
		type: Object,
		default: () => {
			return { read: false, create: false, edit: false };
		},
	},
};

// class definition
export default class PermissionModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	// methods
	destroy() {}

	// utility methods

	// getters & setters
	get read() {
		return this.permission.read || false;
	}

	set read(value) {
		return (this.permission.read = value);
	}

	get create() {
		return this.permission.create || false;
	}

	set create(value) {
		return (this.permission.create = value);
	}

	get edit() {
		return this.permission.edit || false;
	}

	set edit(value) {
		return (this.permission.edit = value);
	}
}

import _includes from 'lodash/includes';

const types = ['info', 'warning', 'error', 'success', 'info-blue'];

const iconClassMap = {
	info: 'icon--shakespeare icon--alertInfo',
	warning: 'icon--primroseYellow icon--alert',
	error: 'icon--carnation icon--alert',
	success: 'icon--done icon--alertInfo',
};

export default {
	props: {
		type: {
			type: String,
			default: 'info',
			validator: (type) => {
				return _includes(types, type);
			},
		},
		withIcon: {
			type: Boolean,
			default: false,
		},
	},

	mixins: [],

	data() {
		return {};
	},

	computed: {
		iconClass() {
			return iconClassMap[this.type] || iconClassMap.info;
		},
	},

	methods: {},

	watch: {},
};

import _get from 'lodash/get';
import _map from 'lodash/map';

import Config from '$root/Config';
import BinAnalyseModel from '$models/bin/BinAnalyseModel';
import MMCollection from './MMCollection';

const filterDictionary = {
	bin_post_code: 'field_bin_post_code',
	bin_location: 'field_bin_location',
	bin_surface: 'field_bin_surface',
	bin_material: 'field_bin_material',
	color: 'field_bin_color',
	bin_volume: 'field_bin_volume',
	bin_lid_size: 'field_bin_lid_size',
	bin_damage: 'field_bin_damage',
	bin_emptying_frequency: 'field_bin_emptying_frequency',
	bin_last_measurement: 'field_bin_last_measurement',
	bin_first_measurement: 'field_bin_first_measurement',	
	bin_active: 'field_bin_status',
	title: 'title',
	cigarettes: 'field_measurement_cigarettes',
	addition: 'field_measurement_addition.id',
	fill_degree: 'field_measurement_fill_degree',
	illegal_dump: 'field_measurement_illegal_dump',
	'field_bin_remark.value': 'field_bin_remark',
};

export default class BinAnalyseCollection extends MMCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || BinAnalyseModel;
		args.filterDictionary = filterDictionary;
		super(args);

		this.info = {};
		this.currentUrl = Config.getUrl('binAnalyse');
	}

	parse(data) {
		const markers = _get(data, 'features');
		const total = _get(data, 'count_bins');
		const countPerPage = _get(data, 'count_per_page');

		const pagination = {
			total,
			countPerPage,
			totalPages: Math.ceil(total / countPerPage),
			currentPage: _get(data, 'page'),
		};

		this.info = {
			countBins: total,
			countBinsInAnalyse: _get(data, 'count_bins_in_analyse'),
		};

		data = _map(markers, (item) => {
			const coordinates = {
				lat: parseFloat(_get(item, 'geometry.coordinates[1]')),
				lng: parseFloat(_get(item, 'geometry.coordinates[0]')),
			};

			const preparedItem = {
				..._get(item, 'properties'),
				coordinates,
				active: Boolean(Number(_get(item, 'properties.field_bin_status'))),
				measurements: _get(item, 'measurments[0]'),
				in_analyse: _get(item, 'in_analyse', false),
				id: _get(item, 'properties.uuid'),
				parentModel: {},
			};

			const newModel = new this.Model().set(preparedItem);
			return newModel;
		});

		return super.parse(data, pagination);
	}

	fetch() {
		const communityID = localStorage.getItem('communityID') || window.vueStore.state.session.user.community.id;
		this.setConfig('url', `${this.currentUrl}/${communityID}`);

		return super.fetch();
	}

	setUrl(url) {
		this.currentUrl = url;
	}
}

// imports
import JsonApiModel from '$dependencies/JsonApiModel';

// private static properties
const API_DICTIONARY = {
	uuid: 'id',
	field_post_code: 'code',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	title: { type: String, default: '' },
	code: { type: Number, default: 0 },
};

// class definition
export default class PostcodeModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.entityType = 'node--post_code';
		args.fields = FIELDS;

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);
	}

	// methods
	destroy() {}

	// utility methods

	// getters & setters
	get urlKey() {
		return `${this.code}`;
	}

	get fullPostcode() {
		return `${this.code} ${this.title}`;
	}
}

import _isObject from 'lodash/isObject';

export default {
	namespaced: true,

	state() {
		return {
			message: '',
			type: '',
			sticky: false,
			showLoader: false,
			url: '',
			linkText: ''
		};
	},

	getters: {
		message: (state) => state.message,
		type: (state) => state.type,
		showLoader: (state) => state.showLoader,
		url: (state) => state.url,
		linkText: (state) => state.linkText,
		sticky: (state) => state.sticky,
	},

	mutations: {
		SET(state, payload) {
			this.commit('globalMessage/CLEAR', {}, { root: true });
			state.url = payload.url || '';
			state.linkText = payload.linkText || '';
			state.message = payload.message || '';
			state.type = payload.type;
			state.showLoader = !!payload.showLoader;
			state.sticky = !!payload.sticky;
		},

		SUCCESS(state, content) {
			this.commit('globalMessage/CLEAR', {}, { root: true });
			state.message = content;
			state.type = 'success';
			state.showLoader = false;
		},

		ERROR(state, content) {
			this.commit('globalMessage/CLEAR', {}, { root: true });
			state.message = content;
			state.type = 'error';
			state.showLoader = false;
		},

		WARNING(state, content) {
			this.commit('globalMessage/CLEAR', {}, { root: true });
			state.message = content;
			state.type = 'warning';
			state.showLoader = false;
		},

		INFO(state, payload) {
			this.commit('globalMessage/CLEAR', {}, { root: true });
			state.message = _isObject(payload) ? payload.message : payload;
			state.type = 'info';
			state.showLoader = _isObject(payload) ? payload.showLoader : false;
		},

		CLEAR(state) {
			state.message = null;
			state.showLoader = false;
			state.url = ''
			state.linkText = ''
			state.sticky = false;
		},

		SET_LINK(state, payload) {
			state.url = payload.url;
			state.linkText = payload.title;
		},

		STICK(state) {
			state.sticky = true;
		}
	},

	actions: {},
};

import Model from '$dependencies/Model';

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	title: { type: String, default: '', identifier: true },
	gId: { type: String, default: '', identifier: true },
	parentModel: { type: Object, default: () => {} },
};

export default class BinSmallModel extends Model {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.fields = FIELDS;
		super(args);
	}

	destroy() {}
}

// imports
import JsonApiModel from '$dependencies/JsonApiModel';
import Config from '$root/Config';

// private static properties
const API_DICTIONARY = {
	uuid: 'id',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	name: { type: String, default: '' },
};

// class definition
export default class BinRequiredActionModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;
		args.entityType = 'taxonomy_term--bin_action';
		args.config = {
			url: Config.getUrl('binActions'),
		};

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);
	}

	get urlKey() {
		return this.name.toLowerCase().replace(' ', '-');
	}
}


export default {
    data() {
        return {
            showIf: 'pro-beta',
            host: window.location.host,
        }
    },
    computed: {
        showTopBar: {
            cache: false,
            get() {
                return this.host.includes(this.showIf);
            }
        }
    }
}
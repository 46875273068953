import OverlayChildMixin from '$mixins/OverlayChildMixin';
import TermsOfUseModel from '../../models/session/TermsOfUseModel';
import Loader from '$components/Loader/Loader.vue';


export default {
	props: {
		hasCheckbox: {
			type: Boolean,
			default: true
		},
		buttonText: {
			type: String,
			default: 'Aanvaarden'
		},
	},

	components: {
		Loader,
	},

	mixins: [OverlayChildMixin],

	mounted() {
		this.termsOfUse.setConfig('headers', {
				Authorization: this.$store.getters['session/jwt_authorization'],
			})	
			this.termsOfUse
				.fetch()
				.then(response => {  this.termsOfUse = response.data; this.loading = false} )
				.catch( e => { console.log(e) })

	},

	data() {
		return {
			approved: false,
			endScroll: false,
			termsOfUse: new TermsOfUseModel(),
			loading: true,
		};
	},

	computed: {
		isLoading() {
			return this.loading;
		},
	},

	methods: {
		onBeforeHide(e) {
			if(!this.approved) {
				this.$emit('denyTerms');
			}
		},

		onAfterHide() {
			this.$emit('afterhide');
		},

		onScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
			if ( scrollTop + clientHeight >= scrollHeight ) {
				this.endScroll = true
			}
		},

		onDenyTerms () {
			this.$emit('denyTerms');
		},
		updateGdprTerms () {
			this.$emit('approveGdpr');
		},
	},

	watch: {
		isLoading() {
			if (this.termsOfUse.text === '') {
				if (this.$refs.termsLoader === undefined) return
				if (this.loading) {
					//show loader
					this.$refs.termsLoader.show();
				} else {
					//hide loader
					this.$refs.termsLoader.hide();
				}
			} 
		}
	}
};

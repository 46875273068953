// imports
import JsonApiCollection from '$dependencies/JsonApiCollection';

import CategoryModel from '$models/CategoryModel';

// private static properties

// class definition
export default class CategoryCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || CategoryModel;

		// args.jsonApi = {
		// 	translationTargets: false
		// }

		// call super constructor
		super(args);

		// ask itself to be sorted on weight by default
		this.setCallOrder({ weight: 'asc' });
	}

	// methods
	getList(parent = undefined) {
		return this.where((model) => model.parent === parent);
	}

	getCrumb(model, prop = null) {
		// model could also be a model, which is also allowed
		// if model is string => get model by string
		// if model is model => get model by id of that model to make sure model has all the data required, could be a search query
		model = this.get(typeof model === 'string' ? model : model.id);

		// render crumb & return
		return renderCrumb(model, this, prop);
	}

	destroy() {}

	// utility methods
	toAVSList() {
		// convert this collection to array for AdvancedSelectComponent
		return collectionToAvSelectOptionList(this);
	}

	toAVTList() {
		return collectionToAvTreeSelectList(this);
	}

	// getters & setters
}

function renderCrumb(model, collection, prop) {
	// block if model is not defined
	if (!model) return [];

	// render path by swimming upstream
	let parentModel;
	let id = model.parent;
	const p = [prop ? model.get(prop) : model];

	while ((parentModel = collection.findWhere((item) => item.id == id))) {
		// keep model or prop of model if prop was provided
		p.unshift(prop ? parentModel.get(prop) : parentModel);

		// break out if current parent has no parent
		if (!parentModel.parent) break;

		// replace id before looping again
		id = parentModel.parent.id;
	}

	return p;
}

function collectionToAvSelectOptionList(collection, parentId = undefined) {
	const options = collection
		.where((item) => item.parent === parentId)
		._models.map((model) => ({
			id: model.id,
			title: model.name,
			options: collectionToAvSelectOptionList(collection, model.id),
			categoriesLegal: model.categoriesLegal,
			categoriesVolume: model.categoriesVolume,
			model,
		}));

	return options.length ? options : null;
}

function collectionToAvTreeSelectList(collection, parentId = undefined) {
	const options = collection
		.where((item) => item.parent === parentId)
		._models.map((model) => ({
			id: model.id,
			label: model.name,
			children: collectionToAvTreeSelectList(collection, model.id),
			model,
		}));

	if (options.length) {
		return options;
	}
}

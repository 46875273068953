import Model from '$dependencies/Model'
import Config from '../../Config'
import { formatDateForUi } from '$utils/dateFormat';

const FIELDS = {
    uuid: { type: String, default: '', identifier: true },
    projectId: { type: String, default: '' },
    author: { type: Object, default: () => ({}) },
    project: { type: Object, default: () => ({}) },
    date: { type: String, default: '' },
    questions: { type: Object, default: () => ({}) },
    remark: { type: String, default: '' },
    resultsByPillar: { type: Array, default: () => [] },
}

export default class ProjectExperienceScanModel extends Model {

    constructor(args = {}) {
        args = Object.assign({}, args)
        args.fields = FIELDS

        super(args)
    }

    fetch(data) {
        this.setConfig('url', `${Config.getUrl('projectExperienceScans')}/${this.uuid}`)

        return super.fetch(data)
    }

    post(data) {
        data = data || this.toObject(true);

        this.setConfig('url', `${Config.getUrl('projects')}/${this.projectId}/experience-scans`)

        return super.post(data)
    }

    patch(scanId, data) {
        this.setConfig('url', `${Config.getUrl('projectExperienceScans')}/${scanId}`)
        return super.patch(data)
    }

    delete(scanId) {
        this.setConfig('url', `${Config.getUrl('projectExperienceScans')}/${scanId}`)
        return super.delete()
    }

    parse(data) {
		if(!data) return {};
        return super.parse(data);
    }

    get id() {
		return this.uuid;
	}

    get formattedDate() {
		return formatDateForUi(this.date * 1000);
	}

    get modelDate() {
        return new Date(this.date * 1000)
    }

    set modelDate(val) {
        this.date = new Date(val).getTime() / 1000
    }
}
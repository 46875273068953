import _delay from 'lodash/delay';

import vSelect from "vue-select";
import LibMGR from '$dependencies/LibMGR';

export default {
	props: {
		context: {
			type: Object,
			required: true
		},

		bounds: {
			type: Object,
			default: () => {}
		}
	},

	components: {
		"v-select": vSelect,
	},

	mounted() {
		// load & init gmaps
		LibMGR.load('gmap').then(() => {
			this.init();
		});
	},

	data() {
		return {
			autoCompleteMaps: null,
			sessionToken: null,
			options: [],
			selectedAddress: null
		};
	},

	computed: {

	},

	methods: {
		init() {
			window.vueGoogleMapsInit();
			this.autoCompleteMaps = new google.maps.places.AutocompleteService();
			this.sessionToken = new google.maps.places.AutocompleteSessionToken();
			this.placesService = new google.maps.places.PlacesService(this.$refs.addressfield);
		},

		onClose: function () {
			this.context.blurHandler();
		},

		onSearch: function(val, loading) {
			if(val.length) {
				loading(true);
				if(this.searchDelay) clearTimeout(this.searchDelay);
				this.searchDelay = _delay(() => {
					if (val.length) {
						const searchValues = {
							input: val,
							sessionToken: this.sessionToken,
							componentRestrictions: { country: 'be' },
							types: ['address'],
							bound: this.bounds,
						};
		
						this.autoCompleteMaps.getPlacePredictions(searchValues, (e) => {
							this.options = [];
							if (e) {
								for (let i = 0; i < e.length; i++) {
									const resultItem = makeResultItem(e[i], val);
									this.options.push(resultItem);
								}
							}
							loading(false);
						});
					} else {
						this.options = [];
					}
				}, 200);
			}
		}
	},

	watch: {
		selectedAddress(val) {
			if(val) {
				this.placesService.getDetails({ placeId: val }, (place) => {
					this.$emit('placeSelected', place);
				});
			}
		}
	}
};

function makeResultItem(resultItem, inputValue) {
	const newItem = {
		label: highlightMatchesInString(resultItem, inputValue),
		id: resultItem.id,
		value: resultItem.place_id,
	};

	return newItem;
}

function highlightMatchesInString(result, originalString) {
	const stringRegExp = new RegExp(originalString, 'ig');
	return result.description.replace(stringRegExp, `<mark>${originalString}</mark>`);
}

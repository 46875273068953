import _get from 'lodash/get';

import Model from '$dependencies/Model';
import JsonApiCollection from '$dependencies/JsonApiCollection';

import IncidentCandidateModel from '$models/IncidentCandidateModel';

export default class IncidentCandidateCollection extends JsonApiCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || IncidentCandidateModel;

		super(args);
	}

	set(model) {
		super.set(model);

		if (model instanceof this.Model) {
			model.setConfig('headers', this._config.headers);
		}
	}

	setConfig(prop, value) {
		super.setConfig(prop, value);

		if (prop === 'headers' && this._models) {
			this._models.forEach((model) => {
				model.setConfig('headers', value);
			});
		}

		return this;
	}

	unsetConfig(prop, value) {
		super.unsetConfig(prop, value);

		if (prop === 'headers' && this._models) {
			this._models.forEach((model) => {
				model.unsetConfig('headers', value);
			});
		}

		return this;
	}

	resetConfig() {
		super.resetConfig();

		if (this._models) {
			this._models.forEach((model) => {
				model.resetConfig('headers');
			});
		}

		return this;
	}

	bulkSet(model) {
		if (model instanceof Model) {
			model = model.toObject(true);
		}

		this.each((_, item) => {
			item.set(model);
		});

		return this;
	}

	destroy() {}

	get checkedOnly() {
		return this.where((model) => model.checked);
	}

	get hasChecked() {
		return this.findWhere((model) => model.checked);
	}

	get numChecked() {
		const { checkedOnly } = this;
		return checkedOnly ? checkedOnly.lenght : 0;
	}

	get allChecked() {
		return this.length ? this.length === _get(this, 'checkedOnly.length', 0) : false;
	}

	set allChecked(value) {
		this.each((_, model) => (model.checked = value));
	}
}

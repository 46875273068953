import JsonApiModel from '$dependencies/JsonApiModel';

const API_DICTIONARY = {
	uuid: 'id',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	name: { type: String, default: '' },
};

export default class MeasurementDamageTypeModel extends JsonApiModel {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.fields = FIELDS;
		args.entityType = 'taxonomy_term--measurement_damage_type';

		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		super(args);
	}
}

// imports
import Config from '$root/Config';
import JsonApiModel from '$dependencies/JsonApiModel';

import CommunityModel from '$models/CommunityModel';

import { checkDeepProp } from '$utils/MC_Utils';

// private static properties
const API_DICTIONARY = {
	uuid: 'id',
	drupal_internal__uid: 'uid',
	mail: 'email',
	field_user_first_name: 'first_name',
	field_user_last_name: 'last_name',
	field_user_phone: 'phone',
	field_user_community: 'community',
	field_user_bin_management: 'bin_management',
	field_user_status_management: 'incident_status_management',
	field_user_incident_permissions: 'incidentPermissions',
	field_user_delete_permission: 'delete_permission',
	field_user_volunteers_permission: 'volunteer_permission',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	uid: { type: Number, default: '' },
	name: { type: String, default: '' },
	first_name: { type: String, default: '' },
	last_name: { type: String, default: '' },
	phone: { type: String, default: '' },
	email: { type: String, default: '' },
	created: { type: Date, default: () => new Date() },
	changed: { type: Date, default: () => new Date(), isLastModified: true },
	community: { type: Object, default: () => new CommunityModel(), referenceType: 'node--community' },
	bin_management: { type: Boolean, default: false },
	incident_status_management: { type: Boolean, default: false },
	incidentPermissions: { type: Array, default: [] },
	delete_permission: { type: Boolean, default: false },
	volunteer_permission: { type: Boolean, default: false },
};

// class definition
export default class UserModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.entityType = 'user--user';
		args.fields = FIELDS;
		args.config = Object.assign({}, args.config, {
			url: Config.getUrl('user'),
		});

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);

		// set extra properties
		this.deepLoad = typeof args.deepLoad === 'boolean' ? args.deepLoad : true;
	}

	// methods
	fetch(data) {
		// remove any parameters set by fetchByUid
		this.unsetConfig('params');
		this.setConfig('url', `${Config.getUrl('user')}/${this.id}`);

		// run super fetch (make it an empty fetch when no data was passed)
		super.fetch(data || {});
	}

	fetchByUid(uid = null) {
		this.setConfig('url', Config.getUrl('user'));

		// set uid param
		this.setCallFilter({
			userId: {
				value: uid || this.uid,
				field: 'uid',
			},
		});

		// fetch
		return super.fetch({});
	}

	fetchMe() {
		// remove any parameters set by fetchByUid
		this.unsetConfig('params');
		this.setConfig('url', Config.getUrl('me'));

		// fetch
		super.fetch({});
	}

	setConfig(prop, value) {
		// super config
		super.setConfig(prop, value);

		// set config headers of children
		if (prop === 'headers') {
			this.community.setConfig(prop, value);
		}

		return this;
	}

	parse(data) {
		// fetch by nid will return an array of results with 1 item => use first item
		if (Array.isArray(data.data)) data.data = data.data[0];

		// check if it contains "me" data in meta => transform it to data.attributes
		if (checkDeepProp(data, 'meta.links.me.meta.id')) {
			data.data = {
				attributes: {
					uuid: data.meta.links.me.meta.id,
				},
			};
		}

		return super.parse(data);
	}

	response(xhr) {
		// hijack response, do 2 fetches before considering it to be a full response when fetchMe was executed
		switch (xhr.config.url) {
			case Config.getUrl('me'):
				this.set({
					id: xhr.data.id,
				});

				super.response(xhr);

				this.fetch();
				break;

			default:
				super.response(xhr);
		}
	}

	getPermissionOf(category, postCode, permission) {
		return this.incidentPermissions.indexOf(`${permission} ${postCode} ${category}`) >= 0;
	}

	permissionsByPermission(permission) {
		return this.incidentPermissions.filter((p) => {
			return p.indexOf(permission) >= 0;
		});
	}

	permissionsByPostcode(postCode, permission) {
		return this.incidentPermissions.filter((p) => {
			return p.indexOf(postCode) >= 0 && p.indexOf(permission) >= 0;
		});
	}

	permissionsByCategory(permission, category) {
		return this.incidentPermissions.filter((p) => {
			return p.indexOf(permission) >= 0 && p.indexOf(category) >= 0;
		});
	}

	destroy() {}
	// utility methods

	// getters & setters
	get full_name() {
		return `${this.first_name || ''} ${this.last_name || ''}`.trim();
	}

	// permissions
	get canRead() {
		return this.incidentPermissions.length ? this.incidentPermissions.filter((p) => p.indexOf('read') >= 0).length > 0 : false;
	}

	get canCreate() {
		return this.incidentPermissions.length ? this.incidentPermissions.filter((p) => p.indexOf('create') >= 0).length > 0 : false;
	}
	
	get incidentsApiActive() {
		return this.community.incidents_api_active;
	}

	get email() {
		return this.email
	}
}
 
// https://stackoverflow.com/a/42389266 (modified for autoinstall)

const vClickOutside = {
	listener: null,

	directive: {
		bind(el, binding, vnode) {
			// set up listener and keep it in element
			el._vClickOutsideListener = (e) => {
				if (!(el == event.target || el.contains(event.target))) {
					vnode.context[binding.expression](e);
				}
			};

			// bind
			document.body.addEventListener('click', el._vClickOutsideListener);
		},

		unbind(el) {
			// unbind & clear listener
			document.body.addEventListener('click', el._vClickOutsideListener);
			el._vClickOutsideListener = null;
		},
	},

	plugin: {
		install(Vue) {
			Vue.directive('click-outside', vClickOutside.directive);
		},
	},
};

export default vClickOutside;

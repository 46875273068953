var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formulate-async-select" },
    [
      _c(
        "v-select",
        _vm._b(
          {
            ref: "select",
            class:
              "formulate-input-element formulate-input-element--" +
              _vm.context.type,
            attrs: {
              "data-type": _vm.context.type,
              options: _vm.context.options,
              reduce: function(x) {
                return x.value
              },
              filterable: false,
              label: "label"
            },
            on: { close: _vm.onClose, search: _vm.onSearch },
            model: {
              value: _vm.context.model,
              callback: function($$v) {
                _vm.$set(_vm.context, "model", $$v)
              },
              expression: "context.model"
            }
          },
          "v-select",
          _vm.context.attributes,
          false
        )
      ),
      _vm._v(" "),
      _vm._t("after")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import _forEach from 'lodash/forEach';
import Config from '$root/Config';

import BinTypeCollection from '$collections/bin/BinTypeCollection';
import BinLocationTypeCollection from '$collections/bin/BinLocationTypeCollection';
import BinColorCollection from '$collections/bin/BinColorCollection';
import BinSurfaceCollection from '$collections/bin/BinSurfaceCollection';
import BinMaterialCollection from '$collections/bin/BinMaterialCollection';
import BinEmptyingFrequencyCollection from '$collections/bin/BinEmptyingFrequencyCollection';
import BinActionCollection from '$collections/bin/BinActionCollection';
import BinRemarkStatusCollection from '$collections/bin/BinRemarkStatusCollection';
import GarbageBinCollection from '$collections/GarbageBinCollection';
import GarbageBinMarkerCollection from '$collections/GarbageBinMarkerCollection';
import BinMarkerSmallCollection from '$collections/BinMarkerSmallCollection';
import BinAnalyseCollection from '$collections/BinAnalyseCollection';
import GarbageBinSmallCollection from '$collections/GarbageBinSmallCollection';
import ReportBinsCollection from '$collections/ReportBinsCollection';
import ReportBinsMeasurementsCollection from '$collections/ReportBinsMeasurementsCollection';
import ReportBinsMeasurementsLocationCollection from '$collections/ReportBinsMeasurementsLocationCollection';
import MeasuringPeriodCollection from '../../collections/bin/MeasuringPeriodCollection';

import MeasurementAdditionCollection from '$collections/measurement/MeasurementAdditionCollection';
import MeasurementDamageCollection from '$collections/measurement/MeasurementDamageCollection';
import MeasurementDamageTypeCollection from '$collections/measurement/MeasurementDamageTypeCollection';
import MeasurementCollection from '$collections/measurement/MeasurementCollection';
import MeasurementCollectionJSON_API from '$collections/measurement/MeasurementCollectionJSON_API';
import TaxonomyCollection from '../../collections/TaxonomyCollection';

export default {
	namespaced: true,

	state() {
		return {
			colors: new BinColorCollection({
				config: { url: Config.getUrl('binColors') },
			}),

			binList: new GarbageBinCollection({
				config: { url: Config.getUrl('bin') },
			}),

			binMarkers: new GarbageBinMarkerCollection({
				config: { url: Config.getUrl('binMarkers') },
			}),

			binMarkersSmall: new BinMarkerSmallCollection({
				config: { url: Config.getUrl('binMarkersSmall') },
			}),

			binsSmall: new GarbageBinSmallCollection({
				config: { url: Config.getUrl('binsSmall') },
			}),

			simpleBins: new GarbageBinMarkerCollection({
				config: { url: Config.getUrl('binMarkers') },
			}),

			emptyingFrequencies: new BinEmptyingFrequencyCollection({
				config: { url: Config.getUrl('binEmptyingFrequencies') },
			}),

			binTypes: new BinTypeCollection({
				config: { url: Config.getUrl('binTypes') },
			}),

			binActions: new BinActionCollection({
				config: { url: Config.getUrl('binActions') },
			}),

			binRemarkStatuses: new BinRemarkStatusCollection({
				config: { url: Config.getUrl('binRemarkStatuses') },
			}),

			locationTypes: new BinLocationTypeCollection({
				config: { url: Config.getUrl('binLocationTypes') },
			}),

			measurementDamageOptions: new MeasurementDamageCollection({
				config: { url: Config.getUrl('measurementDamage') },
			}),

			measurementDamageTypeOptions: new MeasurementDamageTypeCollection({
				config: { url: Config.getUrl('measurementDamageType') },
			}),

			surfaces: new BinSurfaceCollection({
				config: { url: Config.getUrl('binSurfaces') },
			}),

			materials: new BinMaterialCollection({
				config: { url: Config.getUrl('binMaterials') },
			}),

			measurementJSON_API: new MeasurementCollectionJSON_API({
				config: { url: Config.getUrl('measurementJSON_API') },
			}),

			measurements: new MeasurementCollection({
				config: { url: Config.getUrl('measurements') },
			}),

			measurementAdditionOptions: new MeasurementAdditionCollection({
				config: { url: Config.getUrl('measurementAddition') },
			}),

			analyse: new BinAnalyseCollection({
				config: { url: Config.getUrl('binAnalyse') },
			}),

			reportBins: new ReportBinsCollection({
				config: { url: Config.getUrl('reportBins') },
			}),

			reportMeasurementBins: new ReportBinsMeasurementsCollection({
				config: { url: Config.getUrl('dataBins') },
			}),

			reportMeasurementLocationBins: new ReportBinsMeasurementsLocationCollection({   
				config: { url: Config.getUrl('dataBinsLocation') },
			}),

			//new custom stuff
			c_binLocationTypes: new TaxonomyCollection({
				type: 'c_binLocationTypes'
			}),

			c_binMaterials: new TaxonomyCollection({
				type: 'c_binMaterials'
			}),

			c_binSurfaces: new TaxonomyCollection({
				type: 'c_binSurfaces'
			}),

			c_binTypes: new TaxonomyCollection({
				type: 'c_binTypes'
			}),

			c_binDamageAmounts: new TaxonomyCollection({
				type: 'c_binDamageAmounts'
			}),

			c_binRemarkStatuses: new TaxonomyCollection({
				type: 'c_binRemarkStatuses'
			}),

			c_binActions: new TaxonomyCollection({
				type: 'c_binActions'
			}),

			c_binColors: new TaxonomyCollection({
				type: 'c_binColors'
			}),

			c_binEmptyingFrequencies: new TaxonomyCollection({
				type: 'c_binEmptyingFrequencies'
			}),

			additionalDumpStatuses: new TaxonomyCollection({
				type: 'additionalDumpStatuses'
			}),

			dumpStatuses: new TaxonomyCollection({
				type: 'dumpStatuses'
			}),

			fillDegrees: new TaxonomyCollection({
				type: 'fillDegrees'
			}),
			
			litterStatuses: new TaxonomyCollection({
				type: 'litterStatuses'
			}),
			
			groups: new TaxonomyCollection({
				type: 'binGroups'
			}),

			measuringPeriods: new MeasuringPeriodCollection({   
				config: { url: Config.getUrl('measuringPeriods') },
			}),
		};
	},

	mutations: {
		UPDATE_AUTH(state, payload) {
			const authorizationToken = { Authorization: payload.jwt_authorization };

			// update jwt
			_forEach(state, (item) => {
				item.setConfig('headers', authorizationToken);
			});
		},

		ON_LOGOUT(state) {
			_forEach(state, (item) => {
				item.unsetConfig('headers').clear();
			});
		},
	},
};

import vSelect from "vue-select";

export default {
	props: {
		context: {
			type: Object,
			required: true
		},
	},

	components: {
		"v-select": vSelect,
	},

	mounted() {

	},

	data() {
		return {

		};
	},

	computed: {
		selectedOptions: {
			cache: false,
			get() {
				return this.$refs.select ? this.$refs.select.selectedValue : [];
			}
		}
	},

	methods: {
		onClose: function () {
			this.context.blurHandler();
		},

		onOptionClick(option) {
			this.context.model.splice(this.context.model.indexOf(option.uuid), 1);
		},

		onSearch: function(val, loading) {
			if(val.length) {
				loading(true)
				this.$emit('search', {loading, val});
			} else {
				loading(false)
				let emptyArray = []
				this.$emit('search', {loading, emptyArray})
			}
		}
	},
};

// imports
import _get from 'lodash/get';

import ActivityHeatMapDataModel from '$models/volunteers/ActivityHeatMapDataModel';
import Config from '$root/Config';
import Convert from 'xml-js';
import MinskyCollection from '../MinskyCollection';

// class definition
export default class ActivityHeatMapCollection extends MinskyCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || ActivityHeatMapDataModel;
		args.config = {
			url: Config.getUrl('activityHeatMapData'),
			responseType: 'xml'
		};
		args.filterConversions = [
			{
				key: 'created',
				action: 'timestampArrayToSeconds'
			}
		];
		// call super constructor
		super(args);
	}

    fetch(preventClear) {
		this.setConfig('url', `${Config.getUrl('activityHeatMapData')}`);

		return super.fetch(preventClear);
	}

    parse(data) {
		data = JSON.parse(Convert.xml2json(data, { compact: true }));
		var count = 0
		if (data.gpx && data.gpx.trk && data.gpx.trk.length) {
			count = data.gpx.trk.length
		} else if (data.gpx && data.gpx.trk) {
			count = 1
		}
		
		data = {
			data: [
				{
					count: count,
					data: data.gpx && data.gpx.trk ? data.gpx.trk : null,
				}
			],
		}
        return super.parse(data);
    }

	response(xhr) {
		if (xhr.status === 202) {
			window.vueStore.state.activitiesDM.loadingMessage = 'De heatmap wordt aangemaakt. Dit kan even duren.'			
			super.response(xhr);
		} else {
			window.vueStore.state.activitiesDM.loadingMessage = ''
			super.response(xhr);
		}
	}
}

// Imports
import { dictionaryTranslate } from '$utils/dictionaryUtils.js';

// Methods
export function urlQueryToObject(url = null, args = {}) {
	// check if url isn't an object, if so => consider it args
	if (typeof url === 'object') {
		args = url || {};
		url = null;
	}

	// get url from address bar if none is given
	url = url || document.location.toString();

	// extract query
	const index = url.indexOf('?');
	if (index === -1) return {};
	const query = url.substring(index + 1);

	// convert to object
	const params = {};
	const matches = query.match(/([^=&]+)=([^&]*)/g);

	if (matches && matches.length) {
		matches.forEach((v) => {
			const split = v.split('=');
			let param = decodeURIComponent(split[0]);
			let value = decodeURIComponent(split[1]);

			if (!args.selection || (Array.isArray(args.selection) && args.selection.indexOf(dictionaryTranslate(param, args.dictionary)) > -1)) {
				// translate if necessary
				if (args.dictionary) {
					param = dictionaryTranslate(param, args.dictionary);
					value = dictionaryTranslate(value.indexOf(',') > -1 ? value.split(',') : value, args.dictionary);
				}

				// translate if necessary
				params[param] = value;
			}
		});
	}

	// return params
	return params;
}

export function processUrlAndQuery(url, query) {
	let u;
	let q;
	let args;

	if (typeof url === 'object') {
		// check for full config object, or just query
		if (typeof url.url === 'string' || typeof url.query === 'object') {
			u = url.url;
			q = url.query;
			args = url.args;
		} else if (url.query.query && url.query.args) {
			q = url.query.query;
			args = url.query.args;
		} else {
			// use url as query object and use document location as base
			u = document.location.origin + document.location.href;
			q = url;
		}
	} else {
		u = url;
		q = query;
	}

	return {
		url: u || document.location.origin + document.location.pathname,
		query: q || {},
		args: args || {},
	};
}

export function generateUrl(url, query) {
	// process params for flexibility
	const cfg = processUrlAndQuery(url, query);

	// extract dictionary if present
	const dictionary = cfg.args.dictionaryReversed;

	// convert query object to string
	const queryParts = [];

	for (let key of Object.keys(cfg.query)) {
		let value = cfg.query[key];

		// translate if dictionary is given
		if (dictionary) {
			key = dictionary[key] || key;

			// treat differently if value is array
			if (Array.isArray(value)) {
				// loop over values to translate
				const translatedValues = [];
				for (const v of value) {
					translatedValues.push(dictionary[v] || v);
				}
				value = translatedValues;
			} else {
				value = dictionary[value] || value;
			}
		}

		// join if array
		if (Array.isArray(value)) value = value.join(',');

		// push to parts
		queryParts.push(`${key}=${value}`);
	}
	// render result

	const result = `${cfg.url}?${queryParts.join('&')}`;

	return result;
}

export function pushUrl(url, query, replaceState = true, router) {
	// process params for flexibility
	const cfg = processUrlAndQuery(url, query);

	// generate url
	const result = generateUrl(cfg);

	// replace state (can be turned off to only support logic)
	if (replaceState) {
		if (router) {
			router._entries.pop();
			router.replace({ query: cfg.query }, () => {}, () => {});
		} else {
			window.history.replaceState({}, 'filter', result);
		}
	}
	return result;
}

export function mergeValuesIntoUrlQuery(url, query, replaceState = true) {
	// process params for flexibility
	const cfg = processUrlAndQuery(url, query);

	// get current query
	const currentQuery = urlQueryToObject({
		selection: cfg.args.urlParams,
		dictionary: cfg.args.dictionary,
	});

	// loop over cfg query keys & override those in current query
	for (const key of Object.keys(cfg.query)) {
		if (cfg.query[key] !== undefined) {
			currentQuery[key] = cfg.query[key];
		} else {
			delete currentQuery[key];
		}
	}

	// update cfg
	cfg.query = currentQuery;

	// push new url
	return pushUrl(cfg);
}

export function objectToUrlQuery(obj) {
	let str = '?';
	for (const key in obj) {
		if (str != '?') {
			str += '&';
		}
		str += `${key}=${encodeURIComponent(obj[key])}`;
	}
	return str;
}

// imports
import '$root/Config';

// private static
const logKey = 'Messagebox';

// class definition
export default {
	props: {
		type: {
			type: String,
			default: 'info',
			// types: info, warning, error, success
		},

		message: {
			type: String,
			default: '',
		},
		transition: {
			type: String,
			default: '',
		},
	},

	mounted() {},

	data() {
		return {
			messageType: this.type || 'info',
			messageContent: this.message || '',
		};
	},

	computed: {
		hasMessage() {
			return !!this.messageContent;
		},
		imgSrc() {
			const icons = {
				error: 'alert--carnation',
				warning: 'alert--primroseYellow',
				info: 'alertInfo--shakespeare',
				success: 'check--atlantis--circled',
			};
			return `/images/icons/${icons[this.type]}.svg`;
		},
	},

	methods: {
		set(type, info) {
			this.messageType = type;
			this.messageContent = info;
		},
		click() {
			this.$emit('click');
		},
	},

	watch: {
		message() {
			this.messageContent = this.message;
		},
		type() {
			this.messageType = this.type;
		},
	},
};

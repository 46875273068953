import _isEmpty from 'lodash/isEmpty';

const fields = ['color', 'opacity'];

export default class CommunityColorModel {
	constructor(args = {}) {
		if (!_isEmpty(args)) {
			fields.forEach((field) => {
				this[field] = args[field];
			});
		}
	}
}

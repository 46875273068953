var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Overlay",
    {
      ref: "overlay",
      staticClass: "overlay--loader",
      class: {
        "overlay--nested": _vm.nested,
        "overlay--opaque": _vm.opaque
      },
      attrs: {
        title: "Loader",
        isShown: _vm.isShown,
        "show-header": false,
        onCloseFromBg: _vm.noClose
      }
    },
    [_c("template", { slot: "body" }, [_vm._v("\n\t\tLoading\n\t")])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentList && _vm.currentList.length
    ? _c(
        "div",
        {
          staticClass: "advancedSelect",
          class: {
            "advancedSelect--open": _vm.isOpen,
            "align-right": _vm.alignRight,
            "bottom-space": _vm.bottomSpace,
            "without-min-width": _vm.withoutMinWidth
          },
          on: {
            keydown: function($event) {
              $event.preventDefault()
              return _vm.onInput.apply(null, arguments)
            },
            keyup: function($event) {
              $event.preventDefault()
              return _vm.onKeyUp.apply(null, arguments)
            }
          }
        },
        [
          _c("div", { staticClass: "advancedSelect-component" }, [
            _vm.showLabel
              ? _c(
                  "span",
                  { staticClass: "advancedSelect-label" },
                  [
                    _vm._t(
                      "label",
                      function() {
                        return [_vm._v(_vm._s(_vm.label))]
                      },
                      { label: _vm.label }
                    )
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.customHeader
              ? _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.onHeaderClickOutside,
                        expression: "onHeaderClickOutside"
                      }
                    ],
                    staticClass: "advancedSelect-header",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onHeaderClick.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "advancedSelect-inner advancedSelect-inner--header"
                      },
                      [
                        !_vm.currentItem
                          ? _c(
                              "span",
                              { staticClass: "advancedSelect-headerValue" },
                              [
                                _vm._v("\n\t\t\t\t\t- "),
                                _vm._t(
                                  "placeholder",
                                  function() {
                                    return [_vm._v(_vm._s(_vm.placeholder))]
                                  },
                                  { placeholder: _vm.placeholder }
                                ),
                                _vm._v(" -\n\t\t\t\t")
                              ],
                              2
                            )
                          : _c(
                              "span",
                              { staticClass: "advancedSelect-headerValue" },
                              [
                                _vm._t(
                                  "header",
                                  function() {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.customDateFilter &&
                                            _vm.currentItem.key === "custom"
                                            ? _vm.customDateFilter
                                            : _vm.currentItem.title
                                        )
                                      )
                                    ]
                                  },
                                  { option: _vm.currentItem }
                                )
                              ],
                              2
                            ),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "icon icon--bostonBlue icon--arrowDown advancedSelect-headerArrow"
                        })
                      ]
                    )
                  ]
                )
              : _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.onHeaderClickOutside,
                        expression: "onHeaderClickOutside"
                      }
                    ],
                    staticClass: "advancedSelect-custom-header",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onHeaderClick.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _vm._t("customHeader", null, {
                      isOpen: _vm.isOpen,
                      currentItem: _vm.currentItem
                    })
                  ],
                  2
                ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.onListClickOutside,
                    expression: "onListClickOutside"
                  },
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOpen,
                    expression: "isOpen"
                  }
                ],
                ref: "optionlist",
                staticClass: "advancedSelect-options",
                class: {
                  empty: !_vm.currentList || !_vm.currentList.length,
                  fluid: _vm.fluidOnMobile,
                  injectInRoot: _vm.injectInRoot
                }
              },
              [
                _vm._t("topLogo"),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    ref: "optionlistInner",
                    staticClass:
                      "advancedSelect-inner advancedSelect-inner--options"
                  },
                  _vm._l(_vm.currentList, function(option, index) {
                    return _c(
                      "a",
                      {
                        key: option.id || index,
                        staticClass: "advancedSelect-item",
                        class: [
                          option.options ? "advancedSelect-item--hasSubs" : "",
                          _vm.focusedItem
                            ? option.id === _vm.focusedItem.id
                              ? "advancedSelect-item-focused"
                              : ""
                            : false,
                          !_vm.preventIconDisplay &&
                          (_vm.largeOptions || option.icon)
                            ? "advancedSelect-item--largeOption"
                            : ""
                        ].concat(option.modifiers),
                        attrs: {
                          "data-option-value": option.value || option.id,
                          "data-option-index": index,
                          "data-option-id": option.id,
                          href: "#"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onItemClick.apply(null, arguments)
                          },
                          mouseover: function($event) {
                            $event.preventDefault()
                            return _vm.focusItemById(option.id, true)
                          }
                        }
                      },
                      [
                        _vm._t(
                          "option--" + option.key,
                          function() {
                            return [
                              _vm._t(
                                "option",
                                function() {
                                  return [
                                    _c("span", [_vm._v(_vm._s(option.title))]),
                                    _vm._v(" "),
                                    option.icon
                                      ? _c("span", {
                                          staticClass: "icon",
                                          class: [
                                            "icon--" + option.color,
                                            "icon--" + option.icon
                                          ]
                                        })
                                      : _vm._e()
                                  ]
                                },
                                { option: option, currentItem: _vm.currentItem }
                              )
                            ]
                          },
                          { option: option }
                        )
                      ],
                      2
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm._t("bottomNote"),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.overFlow,
                        expression: "overFlow"
                      }
                    ],
                    ref: "scrollBar",
                    staticClass: "advancedSelect-scrollBar"
                  },
                  [
                    _c("span", {
                      ref: "scrollBarIndicator",
                      staticClass: "advancedSelect-scrollBar--indicator"
                    })
                  ]
                )
              ],
              2
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
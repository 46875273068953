var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mainFooter", class: { overlapping: _vm.large } },
    [
      _c(
        "div",
        { staticClass: "mainFooter-legal", class: { large: _vm.large } },
        [
          _c(
            "div",
            {
              staticClass: "mainFooter-legal-side mainFooter-legal-side--left"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("strong", [
                _vm._v(
                  "\n\t\t\t\t© Mijn Mooie Straat " +
                    _vm._s(new Date().getFullYear()) +
                    "\n\t\t\t\t"
                ),
                _c("i", { staticClass: "version" }, [
                  _vm._v(_vm._s(_vm.appVersion))
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _vm._m(1)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mainFooter-logo" }, [
      _c("img", {
        attrs: { src: "/images/logos/logo-patch-white-border.png", alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mainFooter-legal-side mainFooter-legal-side--right" },
      [
        _c(
          "a",
          {
            staticClass: "footerLink footerLink--disclaimer mainFooter-link",
            attrs: {
              href: "https://mijnmooiestraat.be/algemene-voorwaarden.php"
            }
          },
          [_vm._v("disclaimer")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
// filter object definition
export default {
	install(Vue) {
		if (Vue.filter('sortAlphabetical')) {
			console.warn('[filter duplication]: A filter named sortAlphabetical has already been installed.');
		} else {
			Vue.filter('sortAlphabetical', function(arrayToFilter, filterValue = 'name', exception = 'Andere') {
				// transform list to array
				let sortedArray = arrayToFilter;
				// sort array alphabetically
				arrayToFilter.sort((obj1, obj2) => {
					return obj1[filterValue] > obj2[filterValue];
				});
				// push specific option to back
				const otherOption = sortedArray.filter((el) => {
					return el.name == exception;
				});
				// Filter list on specific option
				sortedArray = sortedArray.filter((el) => {
					return el.name != exception;
				});

				if (otherOption.length) sortedArray.push(otherOption[0]);

				return sortedArray;
			});
		}
	},
};

// imports
import JsonApiCollection from '$dependencies/JsonApiCollection';
import BinEmptyingFrequencyModel from '$models/bin/BinEmptyingFrequencyModel';
import Config from '$root/Config';

// class definition
export default class BinEmptyingFrequencyCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || BinEmptyingFrequencyModel;
		args.config = {
			url: Config.getUrl('binEmptyingFrequencies'),
		};

		args.jsonApi = { order: { weight: 'asc' } };

		// call super constructor
		super(args);
	}
}

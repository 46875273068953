// imports
import JsonApiCollection from '$dependencies/JsonApiCollection';
import BinTypeModel from '$models/bin/BinTypeModel';
import Config from '$root/Config';

// class definition
export default class BinTypeCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || BinTypeModel;
		args.config = {
			url: Config.getUrl('binTypes'),
		};
		args.jsonApi = { order: { weight: 'asc' } };
		// call super constructor
		super(args);
	}

	// utility methods
	toAVSList() {
		// convert this collection to array for AdvancedSelectComponent
		return collectionToAvSelectOptionList(this);
	}
	
	toAVTList() {
		return collectionToAvTreeSelectList(this);
	}
}

function collectionToAvSelectOptionList(collection, parentId = undefined) {
	const options = collection
		.where((item) => item.parent === parentId)
		._models.map((model) => ({
			id: model.id,
			name: model.name,
			options: collectionToAvSelectOptionList(collection, model.id),
			parentId: parentId,
			model,
		}));
	return options.length ? options : null;
}

function collectionToAvTreeSelectList(collection, parentId = undefined) {
	const options = collection
		.where((item) => item.parent === parentId)
		._models.map((model) => ({
			id: model.id,
			label: model.name,
			children: collectionToAvTreeSelectList(collection, model.id),
			model,
		}));

	if (options.length) {
		return options;
	}
}
// imports
import Config from '$root/Config';
import Model from '$dependencies/Model';
import { formatDateForUi, minutesToHours } from '$utils/dateFormat';

const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	startTime: { type: String, default: '' },
	endTime: { type: String, default: '' },
	name: { type: String, default: '' },
	startAddress: { type: String, default: '' },
	endAddress: { type: String, default: '' },
	totalVolume: { type: String, default: '' },
	bags: { type: Number, default: 0},
	totalIncidents: { type: Number, default: 0},
	date: { type: Number, default: 0 },
	duration: {type: Number, default: 0},
	distance: {type: Number, default: 0},
	cleanliness: {type: String, default: ''},
	routes: {type: Array, default: () => []},
	reports: {type: Array, default: () => []},
	collectedTrash: {type: Array, default: () => []},
	events: {type: Array, default: () => []},
	volunteerUuid: { type: String, default: '' },
	user: {type: Object, default: () => {}}
};

// class definition
export default class ActivityModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	fetch(data) {
		this.setConfig('url', `${Config.getUrl('activity')}/${this.uuid}`);
		return super.fetch(data);
	}

	get id() {
		return this.uuid;
	}

	get startDate() {
		return formatDateForUi(this.startTime * 1000);
	}

	get startTimeFormatted() {
		return formatDateForUi(parseFloat(this.startTime) * 1000);
	}

	get endTimeFormatted() {
		return formatDateForUi(parseFloat(this.endTime) * 1000);
	}

	get activeSince() {
		return formatDateForUi(this.created * 1000);
	}

	get durationFormatted() {
		return minutesToHours(this.duration);
	}

	get totalBags () {
		let bags = 0;
		if(this.collectedTrash) {
			this.collectedTrash.forEach(trash => {
				bags += trash.count;
			});
		}
		return bags;
	}

	get route() {
		return this.routes.map(point => {
			return {
				lat: parseFloat(point.lat),
				lng: parseFloat(point.lng)
			}
		})
	}
}

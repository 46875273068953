import _pick from 'lodash/pick';
import _forEach from 'lodash/forEach';
import _keys from 'lodash/keys';
import _isArray from 'lodash/isArray';
import _invert from 'lodash/invert';
import _isPlainObject from 'lodash/isPlainObject';
import _cloneDeep from 'lodash/cloneDeep';

import { pushUrl, urlQueryToObject } from '$utils/urlUtils';

import { getField, updateField } from 'vuex-map-fields';

import qs from 'qs';

export default {
	namespaced: true,

	state () {
		return {
			collection: null,
			bindedCollections: {},
			fixed: [],
			filters: {},
			emptyFilters: {},
			activeFilters: {},
			sort: 'created|desc',
			ignoreUrl: false,
			ignoreSort: false,
			skip: ['analysis'],
			loadingMessage: ''
		}
	},

	// getters
	getters: {
		collection: ({ collection }) => collection,
		bindedCollections: ({ bindedCollections }) => bindedCollections,
		getFilters(state) {
			return getField(state.filters);
		},
		hasActiveFilters: (state) => {
			let hasFilters = false;
			_keys(state.activeFilters).forEach(key => {
				//except for title / q filters (search filters)
				if(_isPlainObject(state.activeFilters[key]) && (state.fixed.indexOf(key) < 0) && key !== 'analysis') {
					if(_keys(state.activeFilters[key]).length) {
						hasFilters = true;
					}
				} else if(state.activeFilters[key] && state.activeFilters[key].length && key !== 'title' && key !== 'q' && (state.fixed.indexOf(key) < 0)) {
					hasFilters = true;
				}
			})
			return hasFilters;
		},
		analysisActive: (state) => {
			let hasAnalysis = false;
			if(state.activeFilters.analysis) {
				_keys(state.activeFilters.analysis).forEach(key => {
					if(state.activeFilters.analysis[key].value.length) {
						hasAnalysis = true;
					}
				})
			}
			return hasAnalysis;
		},
		nAnalyseFilters: (state) => {
			let nFilters = 0;
			if(state.activeFilters.analysis) {
				_keys(state.activeFilters.analysis).forEach(key => {
					if(state.activeFilters.analysis[key].value.length) {
						nFilters++;
					}
				})
			}
			return nFilters;
		},
		sort: ({sort}) => sort,
		loadingMessage: ({loadingMessage}) => loadingMessage
	},
	
	actions: {
		fetchData({ commit, state  }, initial) {

			//url reading
			if(initial && !state.ignoreUrl) {
				commit('readUrl');
			}
			//copy filters to active
			state.activeFilters = _cloneDeep(state.filters);

			//apply filters
			commit('applyFilters');

			//apply analysis if exisiting
			if(state.filters.analysis) {
				commit('applyAnalysis');
			}
			
			//apply sorting
			if(!state.ignoreSort) {
				commit('applySort');
			}

			//update url
			if(!state.ignoreUrl) {
				commit('updateUrl');
			}

			if (state.collection.config.params.filterSummary) {
				state.collection.config.params.redirectUrl = `${window.location.origin}${window.location.pathname}${window.location.search}${window.location.hash}`
			}
	
			//clear paging
			commit('clearPageParams');

			//copy params to bindedCollections
			if(_keys(state.bindedCollections).length) {
				commit('setBindedCollectionsConfig');
			}

			//fetch
			const promises = [state.collection.fetch()];

			
			if(_keys(state.bindedCollections).length) {
				_keys(state.bindedCollections).forEach((key => {
					promises.push(state.bindedCollections[key].fetch());
				}));
			}
			
			return Promise.all(promises);
		},
	},

	mutations: {
		initFilters(state, filters) {
			state.filters = _cloneDeep(filters);
			state.activeFilters = _cloneDeep(filters);
			state.emptyFilters = _cloneDeep(filters);
		},

		updateFilters(state, field) {
			updateField(state.filters, field);
		},

		setCollection(state, payload) {
			state.collection = payload;
		},
		unsetCollection(state) {
			state.collection = null
			state.bindedCollections = []
		},

		addBindedCollection(state, {key, collection}) {
			state.bindedCollections[key] = collection;
		},

		clearFilters(state) {
			//keep fixed filters in emptyFilters
			state.fixed.forEach(f => {
				state.emptyFilters[f] = state.filters[f];
			});

			state.filters = _cloneDeep(state.emptyFilters);
			state.activeFilters = _cloneDeep(state.emptyFilters);
		},

		setSort(state, sort) {
			state.sort = sort;
		},

		applyFilters(state) {
			for (const key of _keys(state.activeFilters)) {
				if(state.skip.indexOf(key) < 0) {
					if(_isPlainObject(state.activeFilters[key])) {
						if(_keys(state.activeFilters[key]).length) {
							for (const subKey of _keys(state.activeFilters[key])) {
								const filter = {}
								filter[`${key}[${subKey}]`] = state.activeFilters[key][subKey];
								state.collection.setConfig('params', filter);
							}
						} else {
							for (const filterKey of _keys(state.collection.config.params)) {
								if(filterKey.startsWith(key)) {
									state.collection.unsetConfig('params', filterKey);
								}
							}
						}
					} else if(state.activeFilters[key] && state.activeFilters[key].length) {
						const filter = {}
						filter[key] = state.activeFilters[key];
						state.collection.setConfig('params', filter);
					} else {
						state.collection.unsetConfig('params', key);
					}
				}
			}
		},

		applyAnalysis(state) {		
			//remove analysis
			for (const key of _keys(state.collection.config.params)) {
				if(key.startsWith('analysis')) {
					state.collection.unsetConfig('params', key);
				}
			}

			//set analysis
			let analyseCounter = 0;
			for (const key of _keys(state.activeFilters.analysis)) {
				const filter = state.activeFilters.analysis[key];
				if(filter.value.length) {
					for (const subKey of _keys(filter)) {
						const params = {}
						params[`analysisFilters[${analyseCounter}][${subKey}]`] = filter[subKey];
						state.collection.setConfig('params', params);
					}
					analyseCounter++;
				}
			}

			//apply range
			if(analyseCounter > 0 && state.activeFilters.analysisRange) {
				for (const key of _keys(state.activeFilters.analysisRange)) {
					const filter = {}
					filter[`analysisRange[${key}]`] = state.activeFilters.analysisRange[key];
					state.collection.setConfig('params', filter);
				}
			}
		},

		applySort(state) {
			if(state.sort) {
				const field = state.sort.split('|')[0];
				const direction = state.sort.split('|')[1];
				state.collection.setConfig('params', {sort: field});
				state.collection.setConfig('params', {direction: direction});
			}
		},

		addFixed(state, key) {
			state.fixed.push(key);
		},

		addSkip(state, key) {
			state.skip.push(key);
		},

		ignoreUrl(state) {
			state.ignoreUrl = true;
		},

		ignoreSorting(state) {
			state.ignoreSort = true;
		},

		clearPageParams(state) {
			state.collection.resetPageParams();
		},

		setBindedCollectionsConfig(state) {
			_keys(state.bindedCollections).forEach((key => {
				state.bindedCollections[key].config.params = state.collection.config.params;
			}));
		},

		updateUrl(state) {
			const urlParams = {}		

			//reduce object te keep only the filters with values
			const reducedFilters = reduceFilters(state.activeFilters, state.collection);
			
			//add sort
			if(state.sort) {
				const field = state.sort.split('|')[0];
				const direction = state.sort.split('|')[1];
				Object.assign(reducedFilters, { sorteren: field, richting: direction});
			}

			//convert to queryString
			const queryString = qs.stringify(reducedFilters, {skipNulls: true, encode: false});

			if(queryString.length) {
				window.history.replaceState({}, 'filter', `${document.location.origin + document.location.pathname}?${queryString}${window.location.hash ? `${window.location.hash}` : ''}`);
			} else {
				window.history.replaceState({}, 'filter', `${document.location.origin + document.location.pathname}${window.location.hash ? `${window.location.hash}` : ''}`);
			}
		},

		readUrl(state) {
			const urlQuery = window.location.search;
			const queryObject = qs.parse(window.location.search, {ignoreQueryPrefix: true});
			
			//read sort first
			if(queryObject.richting && queryObject.sorteren) {
				state.sort = `${queryObject.sorteren}|${queryObject.richting}`
				delete queryObject.sorteren;
				delete queryObject.richting;
			}

			for (const key of _keys(queryObject)) {
				const label = _invert(state.collection.urlDictionary)[key] ? _invert(state.collection.urlDictionary)[key] : key;
				if(_isPlainObject(state.filters[label])) {
					state.filters[label] = Object.assign(state.filters[label], queryObject[key]);
				} else {
					state.filters[label] = queryObject[key];
				}
			}
		},

		UPDATE_AUTH(state, payload) {
			const authorizationToken = { Authorization: payload.jwt_authorization };
			const stateValuesToUpdateJWT = _pick(state, [
				'collection',
			]);

			// update jwt
			_forEach(stateValuesToUpdateJWT, (item) => {
				item.setConfig('headers', authorizationToken);
	
			});
			_keys(state.bindedCollections).forEach((key => {
				state.bindedCollections[key].setConfig('headers', authorizationToken);
			}));
		},
		setLoadingMessage(state, payload) {
			state.loadingMessage = payload
		}
	},

};

function reduceFilters(object, collection) {
	const newObject = _cloneDeep(object);
	for (const key of _keys(newObject)) {
		if(key === 'analysis') {
			_keys(newObject[key]).forEach(aKey => {
				if(!newObject[key][aKey].value.length) {
					delete newObject[key][aKey];
				}
			});

			if(!_keys(newObject[key]).length) {
				newObject[key] = null;
			}
		} else if(key === 'analysisRange') {
			if(!newObject['analysis']) {
				delete newObject[key];
			}
		} else if(_isPlainObject(newObject[key])) {
			if(!_keys(newObject[key]).length) {
				newObject[key] = null;
			} else {
				const urlKey = collection.urlDictionary[key] ? collection.urlDictionary[key] : key;
				if(urlKey !== key) {
					newObject[urlKey] = newObject[key];
					delete newObject[key];
				}
			}
		} else {
			if(!newObject[key].length) {
				newObject[key] = null;
			} else {
				const urlKey = collection.urlDictionary[key] ? collection.urlDictionary[key] : key;
				if(urlKey !== key) {
					newObject[urlKey] = newObject[key];
					delete newObject[key];
				}
			}
		}
	}
	return newObject;
}

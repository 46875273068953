import OverlayChildMixin from '$mixins/OverlayChildMixin';
// import FormItem from '$components/FormItem/FormItem.vue';
import { set } from 'lodash';

export default {
	props: {
		buttonText: {
			type: String,
			default: 'Weigeren'
		},
	},

	components: {
	},

	mixins: [OverlayChildMixin],

	mounted() {},

	data() {
		return {
			endScroll: false,
			denied: false,
		};
	},

	computed: {},

	methods: {
		onBeforeHide() {
			if(this.denied) {
				this.$emit('denyTerms')
			} else {
				this.$emit('showTerms');
			}
		},

		onLogoutClick(e) {
			this.denied = true;
			this.hide();
		},

		onBackToTerms() {
			this.denied = false;
			this.hide();
		},
	},

	watch: {},
};

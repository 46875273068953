var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._b(
      {
        class:
          "formulate-input-element formulate-input-element--" +
          _vm.context.type,
        attrs: {
          "data-type": _vm.context.type,
          options: _vm.context.options,
          reduce: function(x) {
            return x.value
          },
          label: "label"
        },
        on: { close: _vm.onClose },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function(option) {
              return [
                _c("span", {
                  staticClass: "option-label",
                  domProps: { innerHTML: _vm._s(_vm.labelFormatter(option)) }
                })
              ]
            }
          },
          {
            key: "selected-option",
            fn: function(option) {
              return [
                _c("span", {
                  staticClass: "option-label",
                  domProps: { innerHTML: _vm._s(_vm.labelFormatter(option)) }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.context.model,
          callback: function($$v) {
            _vm.$set(_vm.context, "model", $$v)
          },
          expression: "context.model"
        }
      },
      "v-select",
      _vm.context.attributes,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
const logKey = 'BL Manager';

class BLManager {
	// constructor
	constructor() {
		this.listeners = {};
	}

	// methods
	add(name, scope, listener) {
		// APP_GLOBAL.log(logKey, 'Add binded listener:', name)
		this.listeners[name] = listener.bind(scope || this);
		return this.listeners[name];
	}

	get(name) {
		// APP_GLOBAL.log(logKey, 'Get binded listener:', name)
		return this.listeners[name];
	}

	remove(name) {
		// APP_GLOBAL.log(logKey, 'Remove binded listener:', name)
		let listener = null;
		if (this.listeners[name]) {
			listener = this.listeners[name];
			this.listeners[name] = null;
		}
		return listener;
	}

	destroy() {
		// APP_GLOBAL.log(logKey, 'Destroy BL Manager')
		for (p in this.listeners) {
			this.listeners[p] = null;
		}

		this.listeners = null;
	}

	// getters & setters
}

export default BLManager;

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: _vm.transition, mode: "out-in", duration: 300 } },
    [
      _vm.messageContent
        ? _c(
            "div",
            {
              staticClass: "messageBox",
              class: ["messageBox--" + _vm.messageType],
              on: { click: _vm.click }
            },
            [
              _c("div", { staticClass: "messageBox-icon" }, [
                _c("img", { attrs: { src: _vm.imgSrc } })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "messageBox-message" },
                [
                  _vm._t(
                    "default",
                    function() {
                      return [
                        _vm._v(
                          "\n\t\t\t\t" + _vm._s(_vm.messageContent) + "\n\t\t\t"
                        )
                      ]
                    },
                    { content: _vm.messageContent }
                  )
                ],
                2
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
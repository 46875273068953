var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _vm.authenticated && !_vm.showLogo && !_vm.isFakeOverlay
        ? _c(
            "div",
            {
              staticClass: "appHeader",
              class: [_vm.typeClass].concat(_vm.modifierClasses)
            },
            [
              _c("div", { staticClass: "mainHeader-inner" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "top-navbar" }, [
                  _c(
                    "div",
                    {
                      staticClass: "mainHeader-floater mainHeader-floater--left"
                    },
                    [
                      _c(
                        "Logo",
                        {
                          staticClass: "mainHeader-siteNameLink",
                          attrs: {
                            modifiers: [
                              "mooieStraat",
                              "landscape",
                              "whiteBg",
                              "wide"
                            ],
                            href: "/home",
                            title: "Mijn Mooie Straat"
                          }
                        },
                        [_vm._v("\n            Mijn Mooie Straat\n          ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mainHeader-floater mainHeader-floater--right top-navbar-actions"
                    },
                    [
                      _c("div", { staticClass: "top-navbar-item user-item" }, [
                        _c(
                          "span",
                          { staticClass: "dropdown-wrapper" },
                          [
                            _c("AdvancedSelect", {
                              attrs: {
                                options: _vm.userProfileActions,
                                disableOuterScroll: _vm.isMobile,
                                showLabel: false,
                                customHeader: true,
                                alignRight: true,
                                bottomSpace: true,
                                fluidOnMobile: true,
                                largeOptions: true
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "customHeader",
                                    fn: function(slotProps) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "user-item" },
                                          [
                                            _c("img", {
                                              staticClass: "user-avatar",
                                              attrs: {
                                                src:
                                                  "/images/icons/avatar-placeholder.svg"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "user-name" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.loggedUser.full_name
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass:
                                                "icon icon--arrowDown"
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "hamburger-item" },
                                          [
                                            _c("span", {
                                              staticClass: "icon",
                                              class: [
                                                "icon--" + _vm.icon.color,
                                                slotProps.isOpen
                                                  ? "icon--cross"
                                                  : "icon--hamburger"
                                              ]
                                            })
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "option",
                                    fn: function(slotProps) {
                                      return [
                                        _vm._t(
                                          "selectOption",
                                          function() {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "link-item" },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "link-item-icon",
                                                    attrs: {
                                                      src:
                                                        slotProps.option.image
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          slotProps.option
                                                            .isDynamic
                                                            ? slotProps.option.title()
                                                            : slotProps.option
                                                                .title
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              slotProps.option.icon
                                                ? _c("span", {
                                                    staticClass: "icon",
                                                    class: [
                                                      "icon--" +
                                                        (slotProps.option
                                                          .color ||
                                                          _vm.icon.color),
                                                      "icon--" +
                                                        slotProps.option.icon,
                                                      slotProps.option.class
                                                    ]
                                                  })
                                                : _vm._e()
                                            ]
                                          },
                                          { option: slotProps.option }
                                        )
                                      ]
                                    }
                                  },
                                  _vm.isMobile
                                    ? {
                                        key: "bottomNote",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "bottom-note" },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to:
                                                        "https://mijnmooiestraat.be/algemene-voorwaarden.php"
                                                    }
                                                  },
                                                  [_vm._v("Disclaimer")]
                                                ),
                                                _vm._v(
                                                  "\n                    © Mijn Mooie Straat " +
                                                    _vm._s(
                                                      new Date().getFullYear()
                                                    ) +
                                                    "\n                  "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    : null
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mainHeader",
          class: [_vm.typeClass].concat(_vm.modifierClasses, [
            { "mainHeader--overlay": _vm.isFakeOverlay }
          ])
        },
        [
          _c("div", { staticClass: "mainHeader-inner" }, [
            !_vm.showLogo
              ? _c("div", { staticClass: "page-navbar" }, [
                  _c(
                    "div",
                    {
                      staticClass: "mainHeader-floater mainHeader-floater--left"
                    },
                    [
                      _c("h1", { staticClass: "mainHeader-pageTitle" }, [
                        _vm.showHamburger
                          ? _c(
                              "a",
                              {
                                staticClass: "mainHeader-hamburger",
                                attrs: { href: "#" },
                                on: { click: _vm.onBackClick }
                              },
                              [
                                _c("span", { staticClass: "icon-wrapper" }, [
                                  _c("span", {
                                    staticClass: "icon",
                                    class: [
                                      "icon--" + _vm.icon.color,
                                      "icon--" + _vm.icon.type
                                    ]
                                  })
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "page-title" }, [
                          _vm._v(_vm._s(_vm.cfg.title))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("CloseFormOverlay", {
                        ref: "dirtyOverlay",
                        attrs: { type: _vm.cfg.dirtyType },
                        on: { confirm: _vm.onBack }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isFakeOverlay
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mainHeader-floater mainHeader-floater--right"
                        },
                        [
                          _vm._t("headerActions", function() {
                            return [
                              _vm.authenticated
                                ? _c(
                                    "span",
                                    { staticClass: "mainHeader-action" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          key: _vm.avSelectKey,
                                          staticClass: "dropdown-wrapper"
                                        },
                                        [
                                          _c("AdvancedSelect", {
                                            ref: "userActions",
                                            attrs: {
                                              options: _vm.userDropdownActions,
                                              showLabel: false,
                                              customHeader: true,
                                              alignRight: true,
                                              bottomSpace: true,
                                              dropdownWidth: 300,
                                              stickyOptions: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "customHeader",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "header-text"
                                                        },
                                                        [_vm._v("Opties")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass: "icon",
                                                        class: [
                                                          slotProps.isOpen
                                                            ? "icon--arrowUp"
                                                            : "icon--arrowDown",
                                                          _vm.showLogo
                                                            ? "icon--light"
                                                            : ""
                                                        ]
                                                      })
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "option",
                                                  fn: function(slotProps) {
                                                    return [
                                                      _vm._t(
                                                        "selectOption",
                                                        function() {
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                key:
                                                                  _vm.avSelectKey
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    slotProps
                                                                      .option
                                                                      .title
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            slotProps.option
                                                              .icon
                                                              ? _c("span", {
                                                                  staticClass:
                                                                    "icon",
                                                                  class: [
                                                                    "icon--" +
                                                                      (slotProps
                                                                        .option
                                                                        .color ||
                                                                        _vm.icon
                                                                          .color),
                                                                    "icon--" +
                                                                      slotProps
                                                                        .option
                                                                        .icon
                                                                  ]
                                                                })
                                                              : _vm._e()
                                                          ]
                                                        },
                                                        {
                                                          option:
                                                            slotProps.option
                                                        }
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c("DeleteOverlay", {
                                            ref: "deleteOverlay",
                                            attrs: {
                                              itemType: _vm.cfg.itemType || "",
                                              root: true
                                            },
                                            on: { apply: _vm.onDeleteClick },
                                            scopedSlots: _vm._u(
                                              [
                                                _vm.cfg.deleteOverlayBody
                                                  ? {
                                                      key: "body",
                                                      fn: function() {
                                                        return [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.cfg
                                                                  .deleteOverlayBody
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  : null
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isHome
              ? _c("div", { staticClass: "mainHeader-inner" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mainHeader-floater mainHeader-floater--right"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "top-navbar-item user-item user-item--white d-inline-block user-item-spacing"
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "user-item-faq",
                              attrs: { target: "_blank", href: _vm.faqLink }
                            },
                            [_vm._m(1)]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "top-navbar-item user-item user-item--white d-inline-block"
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "dropdown-wrapper" },
                            [
                              _c("AdvancedSelect", {
                                attrs: {
                                  options: _vm.userProfileActions,
                                  disableOuterScroll: _vm.isMobile,
                                  showLabel: false,
                                  customHeader: true,
                                  alignRight: true,
                                  bottomSpace: true,
                                  fluidOnMobile: true,
                                  largeOptions: true
                                },
                                scopedSlots: _vm._u(
                                  [
                                    _vm.isMobile
                                      ? {
                                          key: "topLogo",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "top-logo" },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "Logo",
                                                        {
                                                          staticClass:
                                                            "top-logo-image",
                                                          attrs: {
                                                            modifiers: [
                                                              "mooieStraat",
                                                              "landscape",
                                                              "whiteBg",
                                                              "wide"
                                                            ],
                                                            title:
                                                              "Mijn Mooie Straat"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Mijn Mooie Straat\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "top-logo-close"
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "icon icon--shakespeare icon--cross",
                                                          attrs: { href: "#" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Sluiten\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      : null,
                                    {
                                      key: "customHeader",
                                      fn: function(slotProps) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "user-item" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "user-name" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.loggedUser.full_name
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass:
                                                  "icon icon--arrowDown icon--light"
                                              })
                                            ]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "option",
                                      fn: function(slotProps) {
                                        return [
                                          _vm._t(
                                            "selectOption",
                                            function() {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "link-item" },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "link-item-icon",
                                                      attrs: {
                                                        src:
                                                          slotProps.option.image
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            slotProps.option
                                                              .isDynamic
                                                              ? slotProps.option.title()
                                                              : slotProps.option
                                                                  .title
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                slotProps.option.icon
                                                  ? _c("span", {
                                                      staticClass: "icon",
                                                      class: [
                                                        "icon--" +
                                                          (slotProps.option
                                                            .color ||
                                                            _vm.icon.color),
                                                        "icon--" +
                                                          slotProps.option.icon,
                                                        slotProps.option.class
                                                      ]
                                                    })
                                                  : _vm._e()
                                              ]
                                            },
                                            { option: slotProps.option }
                                          )
                                        ]
                                      }
                                    },
                                    _vm.isMobile
                                      ? {
                                          key: "bottomNote",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "bottom-note" },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to:
                                                          "https://mijnmooiestraat.be/algemene-voorwaarden.php"
                                                      }
                                                    },
                                                    [_vm._v("Disclaimer")]
                                                  ),
                                                  _vm._v(
                                                    "\n                      © Mijn Mooie Straat " +
                                                      _vm._s(
                                                        new Date().getFullYear()
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      : null
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showLogo
              ? _c(
                  "h1",
                  { staticClass: "mainHeader-siteName" },
                  [
                    _c(
                      "Logo",
                      {
                        staticClass: "mainHeader-siteNameLink",
                        attrs: {
                          modifiers: _vm.logoModifiers,
                          href: "/home",
                          title: "Mijn Mooie Straat"
                        }
                      },
                      [_vm._v("\n          Mijn Mooie Straat\n        ")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _c("GdprOverlay", {
        ref: "gdprOverlay",
        on: { approveClose: _vm.onGdprClose }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "breadcrumb" }, [
      _c("div", { staticClass: "crumb main" }, [_vm._v("Vlaanderen")]),
      _vm._v(" "),
      _c("div", { staticClass: "separator" }),
      _vm._v(" "),
      _c("div", { staticClass: "crumb" }, [_vm._v("Mijnmooiestraat.be")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("\n                Help\n                "),
      _c("span", {
        staticClass: "icon icon--help icon--light user-item-spacing"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import _pull from 'lodash/pull';

export default {
	props: {
		context: {
			type: Object,
			required: true
		},
		icons: {
			type: Object,
		},
		colors: {
			type: Boolean,
			default: false
		}
	},

	components: {

	},

	mounted() {
		this.selected = [...this.context.model];
	},

	data() {
		return {
			selected: []
		};
	},

	computed: {
		vModel: {
			cache: false,
			get() {
				return this.context.model;
			}
		}
	},

	methods: {
		onOptionClick(val) {
			if(this.selected.indexOf(val) > -1) {
				_pull(this.selected, val);
			} else {
				this.selected.push(val);
			}
			this.context.rootEmit("input", this.selected);
			this.context.model = [...this.selected];
		}
	},

	watch: {
		vModel(val) {
			if(!val.length) {
				this.selected = [];
			} else {
				this.selected = [...val];
			}
		}
	}
};

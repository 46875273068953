import getAssetPath from '$utils/getAssetPath';

export default {
    props: {

    },
    components: {},

    data() {
        return {

        }
    },

    computed: {
		currentLocation() {
			const { pathname, href, search } = window.location;
			
			return { pathname, href, search };
		},
    },

    methods: {
        getAssetPath,
        switchTab(close) {
            window.location.hash = '#heatmap'
            close()
        }
    }
}
import _forEach from 'lodash/forEach';
import TaxonomyCollection from "../../collections/TaxonomyCollection"

export default {
    namespaced: true,
    state() {
        return {
            provinces: new TaxonomyCollection({
                type: 'provinces'
            }),
            intermunicipal: new TaxonomyCollection({
                type: 'intermunicipal'
            }),
            referenceRegions: new TaxonomyCollection({
                type: 'referenceRegions'
            }),
            belfiusClusters: new TaxonomyCollection({
                type: 'belfiusClusters'
            }),

        }
    },

    mutations: {
		UPDATE_AUTH(state, payload) {
			const authorizationToken = { Authorization: payload.jwt_authorization };

			// update jwt
			_forEach(state, (item) => {
				item.setConfig('headers', authorizationToken);
			});
		},

		ON_LOGOUT(state) {
			_forEach(state, (item) => {
				item.unsetConfig('headers').clear();
			});
		},
    }
}
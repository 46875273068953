// imports
import _get from 'lodash/get';
import _map from 'lodash/map';

import Config from '$root/Config';
import ReportIncidentsDetailCollectionModel from '$models/ReportIncidentsDetailCollectionModel';
import IncidentModel from '$models/IncidentModel';
import MMCollection from './MMCollection';

const filterDictionary = {
	post_code: 'field_incident_post_code',
	status: 'field_incident_status',
	volume: 'field_incident_volume',
	team: 'field_incident_team',
	bin_hasremarks: 'field_incident_bin_hasremarks',
	category: 'field_incident_category',
};

// class definition
export default class ReportIncidentsDetailCollection extends MMCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || ReportIncidentsDetailCollectionModel;
		args.parentModel = new IncidentModel();
		args.dictionary = filterDictionary;
		args.filterDictionary = filterDictionary;
		// call super constructor
		super(args);
	}

	parse(data) {
		return super.parse(data);
	}

	fetch() {
		// add community id to url
		this.setConfig('url', `${Config.getUrl('reportIncidentsDetail')}/${window.vueStore.state.session.user.community.id}`);
		// super fetch
		return super.fetch();
	}
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formulate-tree-select" },
    [
      _c(
        "Treeselect",
        _vm._b(
          {
            ref: "select",
            class: { showPlaceholder: !_vm.hideTreeSelectPlaceholderOnSearch },
            attrs: {
              options: _vm.context.options,
              "value-consists-of": "LEAF_PRIORITY"
            },
            scopedSlots: _vm._u([
              {
                key: "value-label",
                fn: function() {
                  return undefined
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.context.model,
              callback: function($$v) {
                _vm.$set(_vm.context, "model", $$v)
              },
              expression: "context.model"
            }
          },
          "Treeselect",
          _vm.context.attributes,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "formulate-tree-select__options" },
        _vm._l(_vm.selectedOptions, function(option) {
          return _c(
            "div",
            {
              key: option.id,
              staticClass: "button--option button",
              on: {
                click: function($event) {
                  return _vm.onOptionClick(option)
                }
              }
            },
            [
              _c("span", { staticClass: "icon icon--cross icon--dark" }, [
                _vm._v("remove")
              ]),
              _vm._v("\n\t\t\t" + _vm._s(option.label) + "\n\t\t")
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
	data() {
		return {
			_windowResizeMixin: {
				bindedListener: null,
				waiting: false,
			},
		};
	},

	mounted() {
		this.$data._windowResizeMixin.bindedListener = this._windowResizeMixin_onWindowResize.bind(this);
		window.addEventListener('resize', this.$data._windowResizeMixin.bindedListener);
		// Event Listener on 'scroll' can cause some performance issues. So we are attaching it only while opening a select in AdvancedSelect.js
		this._windowResizeMixin_onWindowResize();
		this.$on('windowresize', this.onWindowResize);
		this.$on('windowscroll', this.onWindowScroll);
	},

	methods: {
		_windowResizeMixin_onWindowResize(e) {
			if (!this.$data._windowResizeMixin.waiting) {
				this.$data._windowResizeMixin.waiting = true;

				this.$nextTick(() => {
					if (e == undefined || e.type == 'resize') {
						this.$emit('windowresize', {
							width: window.innerWidth,
							height: window.innerHeight,
						});
					} else if (e.type == 'scroll') {
						this.$emit('windowscroll');
					}

					this.$data._windowResizeMixin.waiting = false;
				});
			}
		},

		onWindowResize() {},
		onWindowScroll() {},		

		beforeDestroy() {
			window.removeEventListener('resize', this.$data._windowResizeMixin.bindedListener);
			window.removeEventListener('scroll', this.$data._windowResizeMixin.bindedListener);
		},
	},
};

// imports
import Model from '$dependencies/Model';
import Config from '../../Config';

const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	acceptProTermsOfUse: { type: Boolean, default: false },
};

// class definition
export default class GdprUserModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	fetch(data) {
		this.setConfig('url', `${Config.getUrl('c_user')}`);
		return super.fetch(data);
	}

	parse(data) {
		return super.parse(data.data);
	}

	// getters & setters
	get id() {
		return this.uuid;
	}
}

// imports
import JsonApiCollection from '$dependencies/JsonApiCollection';
import PartnerApiModel from '$models/PartnerApiModel';

// private static properties

// class definition
export default class PartnerApiCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || PartnerApiModel;
		args.entityType = 'taxonomy_term--api_partner';

		// call super constructor
		super(args);
	}

	get list() {
		const list = [];
		this._models.forEach(m => {
			list.push(m.name);
		});
		return list.join(', ');
	}
}

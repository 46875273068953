import Model from '$dependencies/Model';

const FIELDS = {
	data: { type: Array, default: () => [] },
	count: { type: Number, default: 0 },
};
export default class ActivityHeatMapDataModel extends Model {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.fields = FIELDS;
		super(args);
	}

	destroy() {}
}

// imports
import Model from '$dependencies/Model';

const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	type: { type: String, default: '' },
    id: { type: String, default: '' },
    location: { type: String, default: '' },
    characteristics: { type: String, default: '' },
    description: { type: String, default: '' },
};

// class definition
export default class BinPointModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	// getters & setters
	get active() {
		return this.uuid ? true : false;
	}

	get id() {
		return this.uuid;
	}

    get formattedDate() {
		return formatDateForUi(this.date * 1000);
	}
}

// imports
import Config from '$root/Config';
import Model from '$dependencies/Model';
import { formatDateForUi, minutesToHours } from '$utils/dateFormat';

const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	name: { type: String, default: '' },
	email: { type: String, default: '' },
	field_user_postcode: { type: Number, default: 0 },
	created: {type: Number, default: 0},
	field_app_meta_last_active: {type: Number, default: 0},
	field_app_meta_total_activities: {type: Number, default: 0},
	latestActivityDate: {type: Number, default: 0},
	field_app_meta_total_distance: {type: Number, default: 0},
	field_app_meta_avg_activity_dur: {type: Number, default: 0},
	field_app_meta_total_trash: {type: Number, default: 0},
	field_app_meta_total_incidents: {type: Number, default: 0},
	field_app_meta_total_trash_pmd: {type: Number, default: 0},
	field_app_meta_total_trash_rest: {type: Number, default: 0},
};

// class definition
export default class VolunteerOverviewModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	fetch(data) {
		this.setConfig('url', `${Config.getUrl('volunteer')}/${this.uuid}`);
		return super.fetch(data);
	}

	get id() {
		return this.uuid;
	}

	get cleanUpLatest() {
		return formatDateForUi(this.latestActivityDate * 1000);
	}

	get activeSince() {
		return formatDateForUi(this.created * 1000);
	}

	get activeLatest() {
		return formatDateForUi(this.field_app_meta_last_active * 1000);
	}

	get activityLatest() {
		return formatDateForUi(this.latestActivityDate * 1000);
	}

	get avgDurationFormatted() {
		return this.field_app_meta_avg_activity_dur ? minutesToHours(this.field_app_meta_avg_activity_dur) : minutesToHours(0);
	}
}

import _isObject from 'lodash/isObject';
import _get from 'lodash/get';

// imports
import Config from '$root/Config';
import JsonApiModel from '$dependencies/JsonApiModel';

import CommunityModel from '$models/CommunityModel';
import UserModel from '$models/UserModel';

import MeasurementAdditionModel from '$models/measurement/MeasurementAdditionModel';
import MeasurementDamageModel from '$models/measurement/MeasurementDamageModel';
import MeasurementDamageRemarkModel from '$models/measurement/MeasurementDamageRemarkModel';
import GarbageBinModel from '../GarbageBinModel';
import BinModel from '../bin/BinModel';

// private static properties
export const API_DICTIONARY = {
	uuid: 'id',
	uid: 'user',
	field_measurement_addition: 'addition',
	field_measurement_addition_bags: 'addition_bags',
	field_measurement_bin: 'old_bin',
	field_measurement_id: 'measurement_id',
	field_measurement_cigarettes: 'cigarettes',
	field_measurement_community: 'community',
	field_measurement_damage: 'damage_options',
	field_measurement_fill_degree: 'fill_degree',
	field_measurement_illegal_dump: 'illegal_dump',
	field_measurement_damage_remark: 'damage_remark',
	'field_measurement_damage_remark.field_m_b_damage_remark_type': 'damage_remark.type',
	field_measurement_remark: 'remark',
	field_measurement_date: 'date',
};

export const FIELDS = {
	id: { type: String, default: '', identifier: true },
	title: { type: String, default: '' },
	addition: { type: MeasurementAdditionModel, default: () => new MeasurementAdditionModel(), referenceType: 'taxonomy_term--measurement_additional_dump' },
	addition_bags: { type: Number, default: 0 },
	measurement_id: { type: String, default: '- - - -', isComputed: true, isEditable: false },
	bin: { type: BinModel, default: () => new BinModel(), referenceType: 'node--bin' },
	old_bin: { type: GarbageBinModel, default: () => new GarbageBinModel(), referenceType: 'node--bin' },
	cigarettes: { type: Number, default: 0 },
	community: { type: CommunityModel, default: () => new CommunityModel(), referenceType: 'node--community' },
	damage_options: { type: MeasurementDamageModel, default: () => new MeasurementDamageModel(), referenceType: 'taxonomy_term--measurement_damage_amounts' },
	damage_remark: {
		type: MeasurementDamageRemarkModel,
		default: () => new MeasurementDamageRemarkModel(),
		referenceType: 'measurement_remark--m_b_damage_remark',
	},
	fill_degree: { type: Number, default: 0 },
	illegal_dump: { type: Number, default: 0 },
	remark: { type: String, default: '' },
	changed: { type: Date, default: () => new Date() },
	created: { type: Date, default: () => new Date() },
	date: { type: Date, default: () => new Date() },
	permissions: { type: Object, default: () => {} },
	checked: { type: Boolean, default: false },
	deletePermission: { type: Boolean, default: false },
	user: { type: Object, default: () => new UserModel(), referenceType: 'user--user' },
};

let id = 0;

// class definition
export default class MeasurementModelJSON_API extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.entityType = 'node--measurement';
		args.fields = args.fields || FIELDS;

		// apply default config values
		args.config = Object.assign(
			{
				url: '/jsonapi/node/measurement',
			},
			args.config || {},
		);

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);

		this.instance_id = id++;

		// set extra properties
		this._subFetch = args._subFetch || false;
	}

	// methods
	async setDeletePermission() {
		return fetch(`${Config.getUrl('measurementDeletePermission')}/${this.id}`, {'headers': this._config.headers}).then(r => r.json()).then((permission) => {
			this.deletePermission = permission.allowed;
		});
	}


	fetch(data) {
		// clear sub collections & models

		this.set('community', { reset: true });
		this.set('bin', { reset: true });
		// this.set('addition', { reset: true });
		this.set('damage_options', { reset: true });
		this.set('user', { reset: true });

		// set their headers again
		// if (this.addition) this.addition.setConfig('headers', this.config.headers);
		if (this.damage_options) this.damage_options.setConfig('headers', this.config.headers);

		this.setDeletePermission();

		return super.fetch(data);
	}

	post(data) {
		// run super post
		data = cleanUpdataForPayload(data || this.toObject(true), 'post');

		// remove id as well
		delete data.id;

		// reset config url to base url
		this.setConfig('url', Config.getUrl('measurementJSON_API'));

		// post
		return super.post(data);
	}

	patch(data, options = {}) {
		// run super patch
		return super.patch(cleanUpPatchFields(cleanUpdataForPayload(data || this.toObject(true), 'patch'), options.fieldsToPatch));
	}

	setConfig(prop, value) {
		super.setConfig(prop, value);

		// update child headers
		if (prop === 'headers') {
			// update child headers
			// if (this.addition) this.addition.setConfig(prop, value);
			if (this.damage_options) this.damage_options.setConfig(prop, value);
		}

		return this;
	}

	parse(data) {
		data = super.parse(data);

		// catch textareas and normalize them
		const remark = _get(data, 'remark');
		if (_isObject(remark)) {
			data.remark = remark.value;
		}

		return data;
	}

	checkforChangedFields(model) {
		// Loop through cloned model to check for changes
		const changedFields = [];
		for (const key of Object.keys(model._values)) {
			let thisHasChanged = false;

			// Check if value is object & compare ID's in object
			if (typeof model._values[key] === 'object' && !Array.isArray(model._values[key])) {
				if (key !== 'date') {
					thisHasChanged = model._values[key].id !== this._values[key].id;
				} else {
					thisHasChanged = model._values[key] !== this._values[key];
				}
			} else if (Array.isArray(model._values[key])) {
				if (model._values[key].length !== this._values[key].length) {
					thisHasChanged = true;
				} else {
					for (let i = 0; i < model._values[key].length; i++) {
						thisHasChanged = model._values[key][i] !== this._values[key][i];
					}
				}
			}

			// Compare value if number or string
			else {
				thisHasChanged = model._values[key] !== this._values[key];
			}

			if (thisHasChanged) changedFields.push(key);
		}
		return changedFields;
	}

	destroy() {}

	// extra getters
	get computedTitle() {
		let title = '';

		if (this.bin.custom_id) title += this.bin.custom_id;

		return title;
	}
}

function cleanUpPatchFields(data, fieldsToPatch) {
	if (!fieldsToPatch) return data;
	if (data && fieldsToPatch) {
		for (const field in data) {
			if (!fieldsToPatch.includes(field)) {
				delete data[field];
			}
		}
	}
	return data;
}

function cleanUpdataForPayload(data, type) {
	// clean up some properties before posting
	if (data.changed !== undefined) delete data.changed;
	if (data.checked !== undefined) delete data.checked;
	if (data.created !== undefined) delete data.created;
	if (data.permissions !== undefined) delete data.permissions;
	if (data.deletePermission !== undefined) delete data.deletePermission;
	if (data.user) delete data.user;
	if (type === 'post') {
		data.old_bin.id = data.bin.uuid;
		delete data.bin;
	}

	if (type === 'post' && !data.damage_remark.id) delete data.damage_remark;
	// if (type == 'post' && data.images && !data.images.length) delete data.images;
	// if (type == 'post' && data.remarks && !data.remarks.length) delete data.remarks;

	return data;
}

import _get from 'lodash/get';
import _delay from "lodash/delay";
import _has from "lodash/has";
import _isString from "lodash/isString";

import Config from '$root/Config';
import ReportIncidentsStartModel from '$models/ReportIncidentsStartModel';

import MMCollection from './MMCollection';


const filterDictionary = {
	post_code: 'field_incident_post_code',
	category: 'field_incident_category.id',
	volume: 'field_incident_volume',
	status: 'field_incident_status',
	team: 'field_incident_team',
	bin_hasremarks: 'field_incident_bin_hasremarks',
};

export default class ReportIncidentsStartCollection extends MMCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || ReportIncidentsStartModel;
		args.filterDictionary = filterDictionary;
		super(args);
	}

	parse(response) {
		return super.parse([new this.Model().set(response)]);
	}


	fetchSuper() {
		const communityID = localStorage.getItem('communityID') || window.vueStore.state.session.user.community.id;
		this.setConfig('url', `${Config.getUrl('reportIncidentsStart')}/${communityID}`);
		// super.fetch can be only called in method.
		return super.fetch().then((response) => { 
			return response });
	}

	async fetch() {
		let correctResult = false;
		do {
			var p = await new Promise((resolve, reject) => {
				const result = this.fetchSuper();
				if(result) {resolve(result)}
			});
			//check if respond is correct data or "We are building your response"
			// if is correct we will return, if not we will wait 500 ms and refetch. 
			if (this.isBuilding(p)) {
				// console.warn('refetching');
				const waitPromise = await new Promise((resolve, reject) => {
					setTimeout(() => {
					  resolve('foo');
					}, 500);
				  });	
			} else {
				correctResult = true;
				return p;
			}
		} while (!correctResult);
			
	}

	//OLD FETCH - It Did refetch - but only first result was returned
	// fetch() {
	// 	const communityID = localStorage.getItem('communityID') || window.vueStore.state.session.user.community.id;
	// 	this.setConfig('url', `${Config.getUrl('reportIncidentsStart')}/${communityID}`);

	// 	return super.fetch().then((response) => {
	// 		if (this.isBuilding) {
	// 			_delay(() => this.fetch(), 3000);
	// 		}
	// 		if(!this.isBuilding) return response;
	// 	});
	// }

	// get isBuilding() {
	// 	return !_has(this.models, '[0].data') || _isString(_get(this.models, '[0].data'));
	// }

	isBuilding(models) {
		return !_has(models.data, '[0].data') || _isString(_get(models.data, '[0].data'));
	}
}

import _isString from 'lodash/isString';
import _merge from 'lodash/merge';
import InfoIcon from '$components/InfoIcon/InfoIcon.vue';

export default {
	props: {
		context: {
			type: Object,
			required: true
		},
		tooltip: {
			type: [String, Boolean],
			default: false
		}
	},

	components: {
		InfoIcon,
	},

	computed: {
		tooltipObject: {
			get() {
				const customConfig = _isString(this.tooltip) ? { content: this.tooltip } : this.tooltip;

				return _merge(
					{
						content: '',
						placement: 'right',
						classes: ['light'],
					},
					customConfig,
				);
			}
		}
	},
};

// filter object definition
export default {
	install(Vue) {
		if (Vue.filter('illegalDump')) {
			console.warn('[filter duplication]: A filter named illegalDump has already been installed.');
		} else {
			Vue.filter('illegalDump', function(value) {
				if (value === 0) return 'Nee';
				if (value === 1) return 'Ja, minder dan de helft';
				if (value === 2) return 'Ja, meer dan de helft';
				if (value < 0) return 'Niet gemeten';
			});
		}
	},
};

/* eslint no-unused-vars: 0 */
// first so it would come first in css compiled file
import '../sass/main.scss';

// Config
import Vue from 'vue';
import VueGeolocation from 'vue-browser-geolocation';
import * as VueGoogleMaps from 'vue2-google-maps';
import VScrollLock from 'v-scroll-lock';
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import VTooltip from 'v-tooltip';
import Multiselect from 'vue-multiselect';
import FormulateVSelectPlugin from '@cone2875/vue-formulate-select'
import VueBrowserUpdate from '@sum.cumo/vue-browserupdate';
import VueGtag from 'vue-gtag';
import Chart from 'chart.js';
import Config from '$root/Config';
import VueFormulate from '@braid/vue-formulate'
import PortalVue from 'portal-vue';
import browserDetect from "vue-browser-detect-plugin";
import Notifications from '@gmirmand/vue-notification';
import * as Sentry from "@sentry/vue";

// import store
import MainStore from '$stores/MainStore';

// core
import MainRouter from './router/MainRouter';

// general
import App from './App.vue';
import BLM from '$plugins/blm-plugin';
import GlobalError from '$plugins/globalMessage-plugin';

// directives
import vDropZone from '$directives/dropzone';
import vAutoGrow from '$directives/textarea-autogrow';
import vClickOutside from '$directives/click-outside';

// filter
import nl2brFilter from '$filters/nl2br';
import dateFilter from '$filters/date';
import fillDegreeFilter from '$filters/fillDegree';
import illegalDumpFilter from '$filters/illegalDump';
import sortAlphabetical from '$filters/sortAlphabetical';
import cigarettes from '$filters/cigarettes';
import cigarettesShort from '$filters/cigarettesShort';
import volume from '$filters/volume';

//global components
import Icon from '$components/Icon/Icon.vue';

//import formulate componentes
import FormulateDateSelector from './components/Formulate/FormulateDateSelector/FormulateDateSelector.vue';
import FormulateLabel from './components/Formulate/FormulateLabel/FormulateLabel.vue';
import FormulateOptionSelector from './components/Formulate/FormulateOptionSelector/FormulateOptionSelector.vue';
import FormulateStatusSelector from './components/Formulate/FormulateStatusSelector/FormulateStatusSelector.vue';
import FormulateAsyncSelect from './components/Formulate/FormulateAsyncSelect/FormulateAsyncSelect.vue';
import FormulateAddress from './components/Formulate/FormulateAddress/FormulateAddress.vue';
import FormulateBoolFilter from './components/Formulate/FormulateBoolFilter/FormulateBoolFilter.vue';
import FormulateSimpleSelect from './components/Formulate/FormulateSimpleSelect/FormulateSimpleSelect.vue';
import FormulateBoxSelect from './components/Formulate/FormulateBoxSelect/FormulateBoxSelect.vue';
import FormulateTreeSelect from './components/Formulate/FormulateTreeSelect/FormulateTreeSelect.vue';
import FormulateAvSelect from './components/Formulate/FormulateAvSelect/FormulateAvSelect.vue';
import FormulateAsyncOptionSelector from './components/Formulate/FormulateAsyncOptionSelector/FormulateAsyncOptionSelector.vue';

// utils
import { urlPattern } from '$utils/Utils';
import chartBackgroundColorPlugin from '$utils/chartBackgroundColorPlugin';
import VueTextareaAutogrowDirective from 'vue-textarea-autogrow-directive'

// components

if (!urlPattern(Config.urlsToExclude, window.location.toString())) {
	Chart.defaults.global.defaultFontColor = 'rgb(77, 77, 77)';
	Chart.defaults.global.defaultFontFamily = `'Flanders Art Sans', sans-serif`;
	Chart.defaults.global.tooltips.custom = function(tooltipModel) {
		// Tooltip Element
		let tooltipEl = document.getElementById('chartjs-tooltip');

		// Create element on first render
		if (!tooltipEl) {
			tooltipEl = document.createElement('div');
			tooltipEl.id = 'chartjs-tooltip';
			tooltipEl.classList.add('chartTooltip');
			tooltipEl.innerHTML = '<span></span>';
			document.body.appendChild(tooltipEl);
		}

		// set velly big z index
		tooltipEl.style.zIndex = 1000;

		// Hide if no tooltip
		if (tooltipModel.opacity === 0) {
			tooltipEl.style.display = 'none';
			tooltipEl.style.opacity = 0;
			return;
		}

		// Set caret Position
		tooltipEl.classList.remove('above', 'below', 'no-transform');
		if (tooltipModel.yAlign) {
			tooltipEl.classList.add(tooltipModel.yAlign);
		} else {
			tooltipEl.classList.add('no-transform');
		}

		function getBody(bodyItem) {
			return bodyItem.lines;
		}

		function getBodyBefore(bodyItem) {
			return bodyItem.after;
		}

		// Set Text
		if (tooltipModel.body) {
			const titleLines = tooltipModel.title || [];
			const bodyLines = tooltipModel.body.map(getBody);
			const afterBody = tooltipModel.body.map(getBodyBefore);

			let innerHtml = '<div></span>';

			titleLines.forEach((title) => {
				innerHtml += `${title}`;
			});
			afterBody.forEach((body) => {
				innerHtml += `${body}`;
			});
			bodyLines.forEach((body) => {
				innerHtml += `<span class="tooltip-content">${body}<span>`;
			});

			innerHtml += '</div>';

			const tableRoot = tooltipEl.querySelector('span');
			tableRoot.innerHTML = innerHtml;
		}

		// `this` will be the overall tooltip
		const position = this._chart.canvas.getBoundingClientRect();
		// Display, position, and set styles for font
		tooltipEl.style.opacity = 1;
		tooltipEl.style.display = 'block';
		tooltipEl.style.position = 'absolute';
		tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
		tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY - 20}px`; // additional 20px to not overlap chart piece data label
		tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
		tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
		tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
		tooltipEl.style.pointerEvents = 'none';
		if (tooltipEl.offsetWidth >= parseInt(position.left + window.pageXOffset + tooltipModel.caretX)) {
			tooltipEl.classList.remove('chartTooltip--right');
			tooltipEl.classList.add('chartTooltip--left');
		} else {
			tooltipEl.classList.remove('chartTooltip--left');
			tooltipEl.classList.add('chartTooltip--right');
		}
	};

	// Setup defaults; this will draw one fully transparent rectangle for each axis.
	// So, if the user only includes the plugin, but sets no configuration,
	// (s)he won't see any difference.
	Chart.defaults.global.plugins.gridBgColor = {
		backgroundColorRepeat: false,
		backgroundColor: 'rgba(127,127,127,0.0)',
	};

	Chart.plugins.register(chartBackgroundColorPlugin);

	const router = new MainRouter();

	if (BUILD_ENV !== 'local') {
		Sentry.init({
			Vue,
			dsn: 'https://318f8260837fb585e72209509b8ac50e@o146464.ingest.us.sentry.io/4506671083880448',
			integrations: [
				Sentry.browserTracingIntegration({ router }),
				Sentry.replayIntegration(),
			],
			// Performance Monitoring
			tracesSampleRate: 1.0, //  Capture 100% of the transactions
			// Session Replay
			tracePropagationTargets: ["localhost", /^https:\/\/mijnmooiestraat\.vlaanderen/],
			replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		})
	}

	// init all directives and filters
	Vue.use(BLM);
	Vue.use(GlobalError);
	Vue.use(MainRouter);

	Vue.use(vAutoGrow.plugin);
	Vue.use(vDropZone.plugin);
	Vue.use(vClickOutside.plugin);
	Vue.use(VScrollLock, {
		bodyScrollOptions: {
			allowTouchMove: () => true,
		},
	});
	Vue.use(PortalVue);
	Vue.use(browserDetect);
	Vue.use(Notifications)

	// components
	Vue.use(AirbnbStyleDatepicker, Config.datepickerOptions);

	Vue.use(nl2brFilter);
	Vue.use(dateFilter);
	Vue.use(illegalDumpFilter);
	Vue.use(fillDegreeFilter);
	Vue.use(sortAlphabetical);
	Vue.use(cigarettes);
	Vue.use(cigarettesShort);
	Vue.use(volume);
	Vue.use(VueGeolocation);
	Vue.use(VTooltip);
	Vue.use(
		VueGtag,
		{
			config: { id: Config.gtag.id },
			appName: 'Mijn Mooie Straat',
			pageTrackerScreenviewEnabled: true,
		},
		router,
	);
	Vue.component('Multiselect', Multiselect);
	Vue.component('Icon', Icon);

	Vue.use(VueGoogleMaps, {
		load: false,
	});

	Vue.use(VueBrowserUpdate, {
		options: {
			required: { e: -12, c: -16, f: -13, o: -12, s: -2 },
			container: document.body,
		},
	});

	//formulate config
	Vue.component('CustomFormLabel', FormulateLabel);
	Vue.component('FormulateDateSelector', FormulateDateSelector);
	Vue.component('FormulateOptionSelector', FormulateOptionSelector);
	Vue.component('FormulateStatusSelector', FormulateStatusSelector);
	Vue.component('FormulateAsyncSelect', FormulateAsyncSelect);
	Vue.component('FormulateAddress', FormulateAddress);
	Vue.component('FormulateBoolFilter', FormulateBoolFilter);
	Vue.component('FormulateSimpleSelect', FormulateSimpleSelect);
	Vue.component('FormulateBoxSelect', FormulateBoxSelect);
	Vue.component('FormulateTreeSelect', FormulateTreeSelect);
	Vue.component('FormulateAvSelect', FormulateAvSelect);
	Vue.component('FormulateAsyncOptionSelector', FormulateAsyncOptionSelector);
	Vue.use(VueFormulate,{
		plugins: [ FormulateVSelectPlugin ],
		slotComponents: {
			label: 'CustomFormLabel'
		},
		slotProps: {
			label: ['tooltip']
		},
		classes: {
			outer: (context, classes) => {
				return classes.concat([
					`formulate-input--errors-${context.hasErrors}`
				]);
			},
		},
		library: {
			dateSelector: {
			  classification: 'text',
			  component: 'FormulateDateSelector',
			  slotProps: {
				component: ['startDate']
			  }
			},
			'option-select': {
			  classification: 'search',
			  component: 'FormulateOptionSelector',
			},
			'status-select': {
			  classification: 'search',
			  component: 'FormulateStatusSelector',
			  slotProps: {
				component: ['colors']
			  }
			},
			'async-select': {
				classification: 'search',
				component: 'FormulateAsyncSelect',
			},
			'async-option-select': {
				classification: 'search',
				component: 'FormulateAsyncOptionSelector',
			},
			'bool-filter': {
				classification: 'text',
				component: 'FormulateBoolFilter',
			},
			'address-select': {
				classification: 'search',
				component: 'FormulateAddress',
				slotProps: {
					component: ['bounds']
				}
			},
			'box-select': {
				classification: 'search',
				component: 'FormulateBoxSelect',
				slotProps: {
					component: ['colors', 'icons']
				}
			},
			'simple-select': {
				classification: 'search',
				component: 'FormulateSimpleSelect',
			},
			'tree-select': {
				classification: 'search',
				component: 'FormulateTreeSelect',
			},
			'av-select': {
				classification: 'search',
				component: 'FormulateAvSelect',
				slotProps: {
					component: ['labelFormatter']
				}
			},
		},
		locales: {
			en: {
				required({ name }) {
					return `${name} is een verplicht veld`
				},
				max({ args }) {
					return `Dit veld mag maximum ${args[0]} karakters bevatten.`;
				}
			}
		}
	});
	Vue.use(VueTextareaAutogrowDirective)

	// window leave listener
	const allowPageLeave = true;
	const pageLeaveMessage = '';
	window.addEventListener('beforeunload', (e) => {
		if (!allowPageLeave) {
			e.preventDefault();
			return pageLeaveMessage;
		}
	});

	// init app
	const app = new Vue({
		el: '#app',
		router,
		store: MainStore,

		data() {
			return {};
		},

		computed: {},

		watch: {},

		created() {},

		methods: {},

		render: (h) => h(App),
	});

	window.app = app;
} else {
	document.body.classList.add('page--noVue');
}

// imports
import JsonApiCollection from '$dependencies/JsonApiCollection';
import RemarkModel from '$models/RemarkModel';
import Config from '$root/Config';

// private static properties

// class definition
export default class RemarkCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = RemarkModel;
		args.entityType = 'remark--remark';
		args.config = {
			url: Config.getUrl('remark'),
		};
		args.jsonApi = { order: { created: 'asc' } };
		// call super constructor
		super(args);
	}

	// methods

	destroy() {}

	// utility methods

	// getters & setters
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formulate-option-selector" },
    [
      _c(
        "v-select",
        _vm._b(
          {
            ref: "select",
            class:
              "formulate-input-element formulate-input-element--" +
              _vm.context.type,
            attrs: {
              "data-type": _vm.context.type,
              options: _vm.context.options,
              reduce: function(x) {
                return x.value
              },
              label: "label"
            },
            on: { close: _vm.onClose, open: _vm.onOpen },
            scopedSlots: _vm._u([
              {
                key: "selected-option-container",
                fn: function(ref) {
                  var label = ref.label
                  return [
                    _c("span", { staticClass: "noRender" }),
                    _vm._v(" "),
                    !_vm.context.multiple
                      ? _c(
                          "span",
                          {
                            class:
                              "formulate-input-element--" +
                              _vm.context.type +
                              "__selected"
                          },
                          [_vm._v(_vm._s(label))]
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "option",
                fn: function(ref) {
                  var label = ref.label
                  return [
                    _c(
                      "div",
                      { staticClass: "formulate-option-selector__option" },
                      [
                        _c("span", {
                          staticClass: "option-label",
                          domProps: { innerHTML: _vm._s(label) }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "icon icon--check--filled--inverted icon--shakespeare option-check"
                        })
                      ]
                    )
                  ]
                }
              },
              {
                key: "search",
                fn: function(ref) {
                  var attributes = ref.attributes
                  var events = ref.events
                  return [
                    _c(
                      "input",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "vs__search",
                            attrs: { placeholder: "- selecteer -" }
                          },
                          "input",
                          attributes,
                          false
                        ),
                        events
                      )
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.context.model,
              callback: function($$v) {
                _vm.$set(_vm.context, "model", $$v)
              },
              expression: "context.model"
            }
          },
          "v-select",
          _vm.context.attributes,
          false
        ),
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("template", { slot: "no-options" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.emptyMessage) + "\n\t\t")
          ])
        ],
        2
      ),
      _vm._v(" "),
      _vm.context.attributes.multiple
        ? _c(
            "div",
            { staticClass: "formulate-option-selector__options" },
            _vm._l(_vm.selectedOptions, function(option) {
              return _c(
                "div",
                {
                  key: option.id,
                  staticClass: "button--option button",
                  on: {
                    click: function($event) {
                      return _vm.onOptionClick(option)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "icon icon--cross icon--dark" }, [
                    _vm._v("remove")
                  ]),
                  _vm._v(" "),
                  _c("span", { domProps: { innerHTML: _vm._s(option.label) } })
                ]
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import OverlayChildMixin from '$mixins/OverlayChildMixin';
import FormItem from '$components/FormItem/FormItem.vue';
import { set } from 'lodash';

export default {
	props: {

	},

	components: {
		FormItem
	},

	mixins: [OverlayChildMixin],

	mounted() {},

	data() {
		return {
			checked: false
		};
	},

	computed: {

	},

	methods: {
		onClose(e) {
			this.hide();
		},

		onAfterHide() {
			this.$emit('afterhide');
			this.$store.commit('browserNoticeHidden');
			if(this.checked) {
				this.$store.commit('hideBrowserNotice');
			}
		}
	},

	watch: {},
};

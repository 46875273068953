var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bool-filter" }, [
    _c(
      "div",
      { staticClass: "bool-filter__options" },
      [
        _c("FormulateInput", {
          attrs: { type: "checkbox", label: "Ja" },
          on: { input: _vm.onYesInput },
          model: {
            value: _vm.yes,
            callback: function($$v) {
              _vm.yes = $$v
            },
            expression: "yes"
          }
        }),
        _vm._v(" "),
        _c("FormulateInput", {
          attrs: { type: "checkbox", label: "Nee" },
          on: { input: _vm.onNoInput },
          model: {
            value: _vm.no,
            callback: function($$v) {
              _vm.no = $$v
            },
            expression: "no"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { duration: 500, name: "page-transition", mode: "out-in" },
      on: {
        "before-enter": _vm.beforeEnter,
        enter: _vm.enter,
        "after-enter": _vm.afterEnter,
        "before-leave": _vm.beforeLeave,
        leave: _vm.leave,
        "after-leave": _vm.afterLeave
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// imports
import _get from 'lodash/get';

import ProjectMapModel from '$models/monitoring/ProjectMapModel';
import MinskyCollection from '../MinskyCollection';
import Config from '$root/Config';

// class definition
export default class ProjectMapCollection extends MinskyCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || ProjectMapModel;
		args.config = {
			url: Config.getUrl('mapProjects'),
		};
		
		// call super constructor
		super(args);

		this.urlDictionary = {
			title: 'titel'
		}
	}

	parse(data) {
		data.data.map(item => {
			const newModel = new this.Model().parse({data: item});
			return newModel;
		});

		return super.parse(data);
	}
}

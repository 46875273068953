// imports
import MessageBox from '$components/MessageBox/MessageBox.vue';

import getAssetPath from '$utils/getAssetPath';

// class definition
export default {
	props: {},

	components: {
		MessageBox,
	},

	mounted() {},

	data() {
		return {};
	},

	computed: {
		message() {
			return this.$store.getters['globalMessage/message'];
		},
		type() {
			return this.$store.getters['globalMessage/type'];
		},
		showLoader() {
			return this.$store.getters['globalMessage/showLoader'];
		},
		url() {
			return this.$store.getters['globalMessage/url'];
		},
		linkText() {
			return this.$store.getters['globalMessage/linkText'];
		},
		currentLocation() {
			const { pathname, href, search } = window.location;
			
			return { pathname, href, search };
		},
	},

	methods: {
		getAssetPath,
		close() {
			this.$store.commit('globalMessage/SET', {});
		},
	},

	watch: {},
};

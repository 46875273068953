// very unconventional way to deal with changed times in 1 call
// breaks with lots of rules in MC model

// imports
import Config from '$root/Config';
import Model from '$dependencies/Model';

// private static properties
const FIELDS = {
	id: { type: String, default: '', identifier: true },
	userChanged: { type: Date, default: () => new Date() },
	communityChanged: { type: Date, default: () => new Date() },
};

// class definition
export default class SessionCheckModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	// methods
	fetch() {
		const url = [Config.getUrl('user'), this.id, '?include=field_user_community&fields[user--user]=id,changed&fields[node--community]=changed'];
		this.setConfig('url', url.join('/'));
		return super.fetch();
	}

	parse(data) {
		return super.parse({
			userChanged: data.data.attributes.changed,
			communityChanged: data.included[0].attributes.changed,
		});
	}

	destroy() {}

	// utility methods

	// getters & setters
}

// imports
import Config from '$root/Config';

// private statics
import PermissionCollection from '$collections/PermissionCollection';
import PostcodeCollection from '$collections/PostcodeCollection';
import TeamCollection from '$collections/TeamCollection';

// module definition
export default {
	namespaced: true,

	// state
	state() {
		return {
			permissions: new PermissionCollection({
				config: { url: Config.getUrl('permissions') },
			}),

			postcodes: new PostcodeCollection(),
			teams: new TeamCollection(),
		};
	},

	// getters
	getters: {},

	// mutations
	mutations: {
		UPDATE_AUTH(state, payload) {
			// update jwt
			state.permissions.setConfig('headers', { Authorization: payload.jwt_authorization });
			state.postcodes.setConfig(
				'url',
				payload.user.community.postcodes
					.getConfig('url')
					.split('?')
					.shift(),
			);
			state.teams.setConfig(
				'url',
				payload.user.community.teams
					.getConfig('url')
					.split('?')
					.shift(),
			);

			// update permission url (unconventional but hey... so is it's endpoint)
			state.permissions.setConfig('url', `${Config.getUrl('permissions')}/${payload.user.id}?fields[user--user]=field_user_incident_permissions`);
		},
	},

	// actions
	actions: {},
};

// imports
import Model from '$dependencies/Model';
// private static properties
export const FIELDS = {
    uuid: { type: String, default: '', identifier: true },
    name: { type: String, default: '' },
    title: { type: String, default: '' },
};

// class definition
export default class FilterOptionModel extends Model {
    // constructor
    constructor(args = {}) {
        // clone args to do some modifications
        args = Object.assign({}, args);
        args.fields = FIELDS;
        // call super constructor
        super(args);
    }

    // methods
    destroy() {}
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "formulate-date-selector formulate-input-element formulate-input-element--" +
        _vm.context.type
    },
    [
      _c("input", {
        attrs: { type: "text" },
        domProps: { value: _vm.uiDate },
        on: { click: _vm.onDateClick }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "date-selector__icon", on: { click: _vm.onDateClick } },
        [
          _c("img", {
            attrs: {
              src: _vm.getAssetPath("date--loblolly.svg", "icons"),
              alt: "date-picker"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("EditDateOverlay", {
        ref: "editDateOverlay",
        attrs: {
          overlayTitle: "Datum",
          submitName: "Selecteren",
          resetValue: false,
          root: true,
          startDate: _vm.date
        },
        on: { apply: _vm.onDateApply }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
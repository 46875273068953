var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.pageClasses },
    [
      !_vm.isOnline
        ? _c("div", { staticClass: "offlineOverlay" }, [
            _c("div", { staticClass: "offlineOverlay-info" }, [
              _vm._v("\n\t\t\tWachten op connectie...\n\t\t")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("Content"),
      _vm._v(" "),
      _c("Notifications")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
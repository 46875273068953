// imports
import Model from '$dependencies/Model';
import Config from '../../Config';
import { formatDateForUi } from '$utils/dateFormat';
import isToday from 'date-fns/is_today';

const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	id: { type: String, default: '' },
	title: { type: String, default: '' },
	coords: {type: Array, default: []},
    description: { type: String, default: '' },
	type: {type: String, default: ''},
	location:  {type: String, default: ''},
	projectId:  {type: String, default: ''},
	focus: {type: Boolean, default: false},
	measurementCount: {type: Number, default: 0},
	measurementDate: { type: String, default: '' },
};

// class definition
export default class MeasuringStripModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	post(data) {
		this.setConfig('url', `${Config.getUrl('projects')}/${this.projectId}/measuring-strips`);
		data = prepareDataForPost(this.toObject(true));
		return super.post(data);
	}

	patch(data, options = {}) {
		//set url
		this.setConfig('url', `${Config.getUrl('projects')}/${this.projectId}/measuring-strips/${this.uuid}`);
		// run super patch
		return super.patch(cleanUpPatchFields(data || this.toObject(true), options.fieldsToPatch));
	}	

	delete() {
		//set url
		this.setConfig('url', `${Config.getUrl('projects')}/${this.projectId}/measuring-strips/${this.uuid}`);
		return super.delete();
	}

	response(xhr) {
		if(!xhr.data) {
			xhr.data = {};
		}
		
		// always call for super response => resets the states
		super.response(xhr);
	}

	get lastMeasurementDateFormatted() {
		return this.measurementDate ? formatDateForUi(Number(this.measurementDate) * 1000) : '-';
	}

	get measuredToday() {
		return this.measurementDate ? isToday(new Date(Number(this.measurementDate) * 1000)) : false;
	}
}

function prepareDataForPost(data) {
	data = toMeasuringStrip(data);
	return data;
}

function cleanUpPatchFields(data, fieldsToPatch) {
	if (!fieldsToPatch) return data;
	if (data && fieldsToPatch) {
		for (const field in data) {
			if (!fieldsToPatch.includes(field)) {
				delete data[field];
			}
		}
	}
	return data;
}

function toMeasuringStrip(zone) {
	const strip = {
		title: zone.title,
		description: zone.description,
		type: zone.type,
		location: ''
	};

	const locationCoords = [];
	zone.coords.forEach(point => {
		locationCoords.push(`${point.lng()} ${point.lat()}`);
	})

	switch (zone.shape) {
		case 'polyline':
			strip.location = `${Config.geoJsonGmap[zone.shape]} (${locationCoords.join(', ')})`;
			break;
		default:
			strip.location = `${Config.geoJsonGmap[zone.shape]} ((${locationCoords.join(', ')}))`;
			break;
	};

	return strip;
}
	
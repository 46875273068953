import Config from '$root/Config';

import JsonApiModel from '$dependencies/JsonApiModel';
import MeasurementDamageTypeModel from '$models/measurement/MeasurementDamageTypeModel';

const API_DICTIONARY = {
	field_m_b_damage_remark_content: 'content',
	field_m_b_damage_remark_type: 'damageType',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	title: { type: String, default: () => Math.round(Math.random() * 10000).toString() },
	content: { type: String, default: '' },
	damageType: { type: Object, default: () => new MeasurementDamageTypeModel(), referenceType: 'taxonomy_term--measurement_damage_type' },
};

export default class MeasurementDamageRemarkModel extends JsonApiModel {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.entityType = 'measurement_remark--m_b_damage_remark';
		args.fields = FIELDS;

		// apply default config values
		args.config = Object.assign({ url: Config.getUrl('measurementRemarkType') }, args.config || {});

		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		super(args);
	}

	patch(data) {
		data = data || this.toObject(true);

		if (data.created) {
			delete data.created;
		}

		this.setConfig('url', `${Config.getUrl('measurementRemarkType')}/${this.id}`);

		return super.patch(data);
	}

	delete() {
		this.setConfig('url', `${Config.getUrl('measurementRemarkType')}/${this.id}`);
		return super.delete();
	}

	parse(data) {
		if (!data) {
			return;
		}

		data = super.parse(data);
		data.content = data.content ? data.content.value.trim() : '';

		return data;
	}

	generateNewTitle() {
		this.set({
			id: '',
			title: Math.round(Math.random() * 10000).toString(),
		});
		this.setConfig('url', Config.getUrl('measurementRemarkType'));
	}

	destroy() {}
}

import Content from './components/Content/Content.vue';
import Loader from './components/Loader/Loader.vue';

// mixins
import IsMobileMixin from '$mixins/is-mobile-mixin';

import { getRouteProps } from '$utils/Utils.js';
import Notifications from './components/Notifications/Notifications.vue';

export default {
	components: {
		// Header,
		Content,
		// Footer,
		Loader,
		Notifications
	},

	mixins: [IsMobileMixin],

	data() {
		return {
			pageClasses: 'page',
			pageProps: null,

			// showLoader timer
			loaderTimer: null,

			// store shortcuts
			session: this.$store.state.session,
			user: this.$store.state.session.user,
			community: this.$store.state.session.user.community,

			// isOnline
			isOnline: true,
		};
	},

	mounted() {
		this.refresh();
		this.$store.state.pageLoader = this.$refs.pageLoader;

		// global listeners
		window.addEventListener('touchstart', this.onFirstTouch, false);

		const { documentElement } = document;

		if (!documentElement.classList.contains('touch')) {
			documentElement.classList.add('no-touch');
		}

		window.addEventListener('offline', () => {
			this.isOnline = false;
		});

		window.addEventListener('online', () => {
			this.isOnline = true;
		});

		window.app = this;
	},

	computed: {
		showGlobalLoader: {
			cache: false,
			get() {
				return this.session.loading || this.session.user.loading || this.session.user.community.loading || this.$store.state.checkingAuthentication;
			},
		},
	},

	methods: {
		refresh() {
			const routeProps = getRouteProps(this.$route);

			if (routeProps) {
				const pageClasses = routeProps.page.classList;
				this.pageClasses = pageClasses.length ? `page page--ready page--${pageClasses.join(' page--')}` : 'page';
			} else {
				this.pageClasses = 'page';
			}
		},

		showLoader() {
			this.$refs.pageLoader.open();
		},

		hideLoader() {
			this.$refs.pageLoader.close();
		},

		onFirstTouch() {
			// or set some global variable
			const { documentElement } = document;

			this.$store.commit('screenTouched');
			documentElement.classList.remove('no-touch');
			documentElement.classList.add('touch');
			// we only need to know once that a human touched the screen, so we can stop listening now
			window.removeEventListener('touchstart', this.onFirstTouch, false);
		},
	},

	watch: {
		$route(to, from) {
			// give time to the animation to be halfway before applying css classes
			// so flickers will be avoided
			setTimeout(() => this.refresh(), 500);
		},

		is_mobile() {
			this.$store.state.isMobile = this.is_mobile;
		},

		is_tablet() {
			this.$store.state.isTablet = this.is_tablet;
		},
	},
};

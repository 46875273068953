import _get from 'lodash/get';
import _map from 'lodash/map';

import Config from '$root/Config';
import BinsData from '$models/BinsData';
import MMCollection from './MMCollection';

const filterDictionary = {};

export default class ReportBinsMeasurementsLocationCollection extends MMCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || BinsData;
		args.filterDictionary = filterDictionary;
		super(args);
	}

	parse(response) {
		return super.parse([new this.Model().set(response)]);
	}

	fetch() {
		const communityID = localStorage.getItem('communityID') || window.vueStore.state.session.user.community.id;
		this.setConfig('url', `${Config.getUrl('dataBinsLocation')}/${communityID}`);

		return super.fetch();
	}
}

// filter object definition
export default {
	install(Vue) {
		if (Vue.filter('cigarettes')) {
			console.warn('[filter duplication]: A filter named cigarettes has already been installed.');
		} else {
			Vue.filter('cigarettes', function(value) {
				if (value === 0) return 'Niet gemeten';
				if (value === 1) return 'Geen';
				if (value < 10) return '< 10 stuks';
				if (value > 10) return '> 10 stuks';
			});
		}
	},
};

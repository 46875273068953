// imports
import Config from '$root/Config';
import JsonApiModel from '$dependencies/JsonApiModel';
import { checkDeepProp } from '$utils/MC_Utils';
import { onRenderTriggered } from 'vue';

// private static properties
const API_DICTIONARY = {
	uuid: 'id',
	uri: 'url',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	url: { type: String, default: '' },
	file: { type: Blob, default: null },
	filename: { type: String, default: '' },
	filemime: { type: String, default: '' },
	filesize: { type: Number, default: 0 },
	image_style_uri: { type: String, default: '' },
	original: { type: String, default: '' },
};

// class definition
export default class PhotoModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.entityType = 'file--file';
		args.fields = FIELDS;

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		args.config = {
			url: Config.getUrl('incidentImages'),
		};

		// call super constructor
		super(args);
	}

	// methods
	post(data = {}) {
		// hijack if file property is filled => file upload is requested!
		if (this.file && this.file.size > 0) {
			// backup & set some settings
			const cfgBackupHeaders = Object.assign({}, this._config.headers);
			this.setConfig('headers', {
				Accept: 'application/vnd.api+json',
				'Content-Disposition': `file; filename="${this.filename}.jpg"`,
				'Content-type': 'application/octet-stream',
			});
			const tpostBackup = this._jsonApi.translationTargets.post;
			this._jsonApi.translationTargets.post = false;

			// perform post
			return super.post(this.file).then((r) => {
				// put original values back
				this._config.headers = cfgBackupHeaders;
				this._jsonApi.translationTargets.post = tpostBackup;
				return r;
			});

		} else {
			// call super
			return super.post(data);
		}
	}

	parse(data) {
		// get url
		if (Array.isArray(data.data)) {
			// repsone with all images after file upload => last is considered right
			data.data = data.data.pop();
		}

		if (checkDeepProp(data, 'data.attributes.uri')) {
			data.data.attributes.uri = data.data.attributes.uri.url;
		}

		return super.parse(data);
	}

	destroy() {}

	// utility methods

	// getters & setters
}

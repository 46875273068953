import { TweenMax } from 'gsap';

export default {
	props: {},

	data() {
		return {};
	},

	computed: {},

	methods: {
		beforeEnter(el) {
			TweenMax.set(el, { opacity: 0 });
		},

		enter(el) {
			TweenMax.to(el, 0.25, { opacity: 1 });
		},

		afterEnter(el) {
			TweenMax.set(el, { opacity: 1 });
		},

		beforeLeave(el) {
			TweenMax.set(el, { opacity: 1 });
		},

		leave(el) {
			TweenMax.to(el, 0.25, { opacity: 0 });
		},

		afterLeave(el) {
			TweenMax.set(el, { opacity: 0 });
		},
	},

	watch: {},
};

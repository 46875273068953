// filter object definition
export default {
	install(Vue) {
		if (Vue.filter('cigarettesShort')) {
			console.warn('[filter duplication]: A filter named cigarettesShort has already been installed.');
		} else {
			Vue.filter('cigarettesShort', function(value) {
				if (value === 0) return 'Niet gemeten';
				if (value === 1) return 'Geen';
				if (value < 10) return '< 10';
				if (value > 10) return '> 10';
			});
		}
	},
};

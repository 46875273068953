// imports
import JsonApiCollection from '$dependencies/JsonApiCollection';
import BinMaterialModel from '$models/bin/BinMaterialModel';
import Config from '$root/Config';

// class definition
export default class BinMaterialCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || BinMaterialModel;
		args.config = {
			url: Config.getUrl('binMaterials'),
		};
		args.jsonApi = { order: { weight: 'asc' } };
		// call super constructor
		super(args);
	}
}

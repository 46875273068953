// imports
import Config from '$root/Config';
import JsonApiModel from '$dependencies/JsonApiModel';

import CommunityModel from '$models/CommunityModel';
import CategoryModel from '$models/CategoryModel';
import PostcodeModel from '$models/PostcodeModel';
import TeamModel from '$models/TeamModel';
import UserModel from '$models/UserModel';
import IncidentReportTypeModel from '$models/IncidentReportTypeModel';
import IncidentDeclineReasonModel from '$models/IncidentDeclineReasonModel';

import PhotoCollection from '$collections/PhotoCollection';
// import RemarkCollection from '$collections/RemarkCollection';
import StatusModel from '$models/StatusModel';
import IncidentBinModel from './incidents/IncidentBinModel';

// private static properties
export const API_DICTIONARY = {
	uuid: 'id',
	field_incident_archived: 'archived',
	field_incident_category: 'category',
	field_incident_city: 'city',
	field_incident_community: 'community',
	field_incident_description: 'description',
	field_incident_legal_info: 'legal_info',
	field_incident_images: 'images',
	field_incident_post_code: 'post_code',
	field_incident_post_code_ref: 'post_code_ref',
	field_incident_priority: 'priority',
	field_incident_reporter_email: 'reporter_email',
	field_incident_reporter_name: 'reporter_name',
	field_incident_reporter_phone: 'reporter_phone',
	field_incident_reporter_sub: 'reporter_subscribed',
	field_incident_decline_remark: 'declined_remark',
	field_incident_declined_reason: 'declined_reason',
	field_incident_external_id: 'external_id',
	field_incident_external_url: 'external_url',
	field_incident_status: 'status',
	field_incident_street: 'street',
	field_incident_team: 'team',
	field_incident_volume: 'volume',
	field_incident_volume_real: 'real_volume',
	field_incident_id: 'custom_id',
	field_incident_candidate_status: 'candidate_status',
	field_incident_report_type: 'report_type',
	field_incident_done_date: 'done_date',
	field_incident_location: 'location',
	field_incident_bin: 'bin',
	uid: 'user',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	custom_id: { type: String, default: '' },
	changed: { type: Date, default: () => new Date() },
	created: { type: Date, default: () => new Date() },
	done_date: { type: Date, default: null },
	title: { type: String, default: '' },
	archived: { type: Boolean, default: false },
	category: { type: CategoryModel, default: () => new CategoryModel(), referenceType: 'taxonomy_term--incident_categories' },
	city: { type: String, default: '' },
	community: { type: CommunityModel, default: () => new CommunityModel(), referenceType: 'node--community' },
	description: { type: String, default: '' },
	legal_info: { type: String, default: '' },
	images: { type: Object, default: () => new PhotoCollection(), referenceType: 'file--file' },
	post_code: { type: String, default: '' },
	post_code_ref: { type: Object, default: () => new PostcodeModel(), referenceType: 'node--post_code' },
	report_type: { type: Object, default: () => new IncidentReportTypeModel(), referenceType: 'taxonomy_term--incident_report_type' },
	declined_reason: { type: Object, default: () => new IncidentDeclineReasonModel(), referenceType: 'taxonomy_term--incident_decline_reason' },
	declined_remark: { type: String, default: '' },
	priority: { type: Boolean, default: false },
	reporter_email: { type: String, default: '' },
	reporter_name: { type: String, default: '' },
	reporter_phone: { type: String, default: '' },
	reporter_subscribed: { type: Boolean, default: false },
	external_id: {type: String, default: ''},
	external_url: {type: String, default: ''},
	// 'remarks':               { type: Object, default: () => new RemarkCollection(), referenceType: 'remark--remark' },
	status: { type: Object, default: () => new StatusModel(), referenceType: 'taxonomy_term--incident_status' },
	street: { type: String, default: '' },
	team: { type: Object, default: () => new TeamModel(), referenceType: 'node--team' },
	volume: { type: Number, default: 0 },
	real_volume: { type: Number, default: 0 },
	candidate_status: { type: Number },
	permissions: { type: Object, default: () => {} },
	checked: { type: Boolean, default: false },
	user: { type: Object, default: () => new UserModel(), referenceType: 'user--user' },
	location: { type: Object, default: {lat: null, lon: null} },
	deletePermission: { type: Boolean, default: false },
	bin: { type: Object, default: () => new IncidentBinModel(), referenceType: 'node--bin' },
};

let id = 0;

// class definition
export default class IncidentModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.entityType = 'node--incident';
		args.fields = FIELDS;

		// apply default config values
		args.config = Object.assign(
			{
				url: '/jsonapi/node/incident',
			},
			args.config || {},
		);

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);

		this.instance_id = id++;

		// set extra properties
		this._subFetch = args._subFetch || false;
	}

	// methods
	async setDeletePermission() {
		return fetch(`${Config.getUrl('incidentDeletePermission')}/${this.id}`, {'headers': this._config.headers}).then(r => r.json()).then((permission) => {
			this.deletePermission = permission.allowed;
		});
	}

	fetch(data) {
		// clear sub collections & models
		this.set('category', { reset: true });
		this.set('community', { reset: true });
		this.set('images', { reset: true });
		this.set('post_code_ref', { reset: true });
		this.set('team', { reset: true });
		this.set('user', { reset: true });

		// set their headers again
		if (this.category) this.category.setConfig('headers', this.config.headers);
		if (this.images) this.images.setConfig('headers', this.config.headers);
		if (this.team) this.team.setConfig('headers', this.config.headers);
		if (this.remarks) this.remarks.setConfig('headers', this.config.headers);

		//set deletePermission
		this.setDeletePermission();

		return super.fetch(data);
	}

	post(data) {
		// change location format
		this.location = `POINT (${this.location.lon} ${this.location.lat})`;

		// run super post
		data = cleanUpdataForPayload(data || this.toObject(true), 'post');

		// remove id as well
		delete data.id;

		// reset config url to base url
		this.setConfig('url', Config.getUrl('incident'));

		// post
		return super.post(data);
	}

	patch(data, options = {}) {
		// run super patch
		this.location = `POINT (${this.location.lon} ${this.location.lat})`;
		if(options.fieldsToPatch) {
			options.fieldsToPatch.push('location');
		}
		return super.patch(cleanUpPatchFields(cleanUpdataForPayload(data || this.toObject(true), 'patch'), options.fieldsToPatch));
	}

	setConfig(prop, value) {
		super.setConfig(prop, value);

		// update child headers
		if (prop === 'headers') {
			// update child headers
			if (this.category) this.category.setConfig(prop, value);
			if (this.images) this.images.setConfig(prop, value);
			if (this.team) this.team.setConfig(prop, value);
			if (this.remarks) this.remarks.setConfig(prop, value);
		}

		return this;
	}

	parse(data) {
		data = super.parse(data);

		// catch textareas and normalize them
		if (data) {
			if (data.description && typeof data.description === 'object') data.description = data.description.value;
			if (data.legal_info && typeof data.legal_info === 'object') data.legal_info = data.legal_info.value;
			if (data.declined_remark && typeof data.declined_remark === 'object') data.declined_remark = data.declined_remark.value;
		}
		return data;
	}

	destroy() {}

	checkforChangedFields(model) {
		// Loop through cloned model to check for changes
		const changedFields = [];
		for (const key of Object.keys(model._values)) {
			let thisHasChanged = false;

			if(key === 'location') {
				if(model._values.location.lat !== this._values.location.lat) {
					thisHasChanged = true;
				}

				if(model._values.location.lon !== this._values.location.lon) {
					thisHasChanged = true;
				}
			}

			// Check if value is photo
			if (model._values[key] instanceof PhotoCollection) {
				// Check amount of images
				if (model._values.images._models.length !== this._values.images._models.length) {
					thisHasChanged = true;
				} else {
					// Loop through all images
					for (let i = 0; i < model._values.images._models.length; i++) {
						thisHasChanged = model._values.images._models[i].id !== this._values.images._models[i].id;
					}
				}
			}

			// Check if value is object & compare ID's in object
			else if (typeof model._values[key] === 'object' && !Array.isArray(model._values[key])) {
				thisHasChanged = model._values[key].id !== this._values[key].id;
			} else if (Array.isArray(model._values[key])) {
				if (model._values[key].length !== this._values[key].length) {
					thisHasChanged = true;
				} else {
					for (let i = 0; i < model._values[key].length; i++) {
						thisHasChanged = model._values[key][i] !== this._values[key][i];
					}
				}
			}

			// Compare value if number or string
			else {
				thisHasChanged = model._values[key] !== this._values[key];
			}

			if (thisHasChanged) changedFields.push(key);
		}
		return changedFields;
	}

	// utility methods
	clearAddress() {
		this.street = this.city = this.post_code = '';
		this.location = {};
		this.post_code_ref = null;
	}


	// getters & setters
	get coordinates() {
		return { latitude: this.location.lat, longitude: this.location.lon };
	}

	get gmapCoordinates() {
		return { lat: this.location.lat, lng: this.location.lon };
	}

	// extra getters
	get fullAddress() {
		let full = '';

		if (this.street) full += this.street;
		if (this.street && (this.post_code || this.city)) full += ', ';
		if (this.post_code) full += this.post_code;
		if (this.city) full += ` ${this.city}`;

		return full;
	}

	get latitude() {
		return this.location.lat;
	}

	get longitude() {
		return this.location.lon;
	}

	set latitude(val) {
		return(this.location.lat = val);
	}

	set longitude(val) {
		return(this.location.lon = val);
	}
}

function cleanUpPatchFields(data, fieldsToPatch) {
	if (!fieldsToPatch) return data;
	if (data && fieldsToPatch) {
		for (const field in data) {
			if (!fieldsToPatch.includes(field)) {
				delete data[field];
			}
		}
	}
	return data;
}

function cleanUpdataForPayload(data, type) {
	// clean up some properties before posting
	if (data.changed !== undefined) delete data.changed;
	if (data.checked !== undefined) delete data.checked;
	if (data.created !== undefined) delete data.created;
	if (data.permissions !== undefined) delete data.permissions;
	if (data.deletePermission !== undefined) delete data.deletePermission;
	if (data.external_id !== undefined) delete data.external_id;
	if (data.external_url !== undefined) delete data.external_url;
	if (data.done_date !== undefined) delete data.done_date;
	if (data.user) delete data.user;
	// remove report type
	if (type == 'post' && !data.report_type.id.length) delete data.report_type;
	if ((type === 'post' || type === 'patch') && !data.declined_reason.id.length) delete data.declined_reason;
	// if (type == 'post' && data.images && !data.images.length) delete data.images;
	// if (type == 'post' && data.remarks && !data.remarks.length) delete data.remarks;
	if (data.team && !data.team.id) delete data.team;

	return data;
}

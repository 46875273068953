var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Overlay",
    {
      ref: "overlay",
      staticClass: "overlay--edit",
      attrs: { root: true, title: _vm.title },
      on: { afterhide: _vm.afterhide }
    },
    [
      _c("template", { slot: "body" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass: "overlay-footerContent",
            class: { "overlay-footerContent--hidden": !_vm.applyIsVisible }
          },
          [
            _c("div", { staticClass: "footer-actions-group" }, [
              _c(
                "button",
                {
                  staticClass: "button button--light button--carnation",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm._onCloseClick.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("\n\t\t\t\t\tAnnuleren\n\t\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button button--filled overlay-applyButton",
                  attrs: { type: "submit", name: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm._onApplyClick.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.submitName) + "\n\t\t\t\t")]
              )
            ])
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
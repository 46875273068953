var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Overlay", {
    ref: "overlay",
    staticClass: "overlay--gdprTerms",
    attrs: { title: "Nieuwe gebruiksvoorwaarden", isShown: true },
    on: { beforehide: _vm.onBeforeHide, denyTerms: _vm.onDenyTerms },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function() {
            return [
              _vm._t("body", function() {
                return [
                  _c(
                    "div",
                    { staticClass: "body-wrapper" },
                    [
                      _c("p", [
                        _vm._v(
                          "De gebruiksvoorwaarden van “Mijn Mooie Straat” beschrijven de regels rond o.a. het gebruiksrecht, intellectueel eigendomsrecht en databeheer van dit digitaal platform. Lees deze gebruiksvoorwaarden zorgvuldig door, aangezien deze van toepassing zijn op jouw gebruik."
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "intro" }, [
                        _vm._v(
                          "Je dient de gebruiksvoorwaarden volledig te lezen alvorens je ze kan aanvaarden of weigeren."
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "inner-scroll",
                          on: { scroll: _vm.onScroll }
                        },
                        [
                          _c("Loader", {
                            ref: "termsLoader",
                            attrs: { nested: true, isShown: _vm.isLoading }
                          }),
                          _vm._v(" "),
                          _c("div", {
                            ref: "scrollText",
                            staticClass: "scroll-text",
                            domProps: { innerHTML: _vm._s(_vm.termsOfUse.text) }
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "button button--light button--shakespeare button--hasIconBefore button--hasDownloadIcon",
                              attrs: {
                                href: _vm.termsOfUse.pdf,
                                target: "_blank"
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "color--shakespeare" },
                                [_vm._v("Download als .pdf")]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "FormulateForm",
                        { ref: "formGdprTerms" },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              type: "checkbox",
                              label: "Ik aanvaard de gebruiksvoorwaarden."
                            },
                            model: {
                              value: _vm.approved,
                              callback: function($$v) {
                                _vm.approved = $$v
                              },
                              expression: "approved"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "overlay-footerContent" }, [
                _c("div", { staticClass: "footer-actions-group" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "button button--light button--carnation button--hasIconBefore button--hasCrossIcon",
                      on: { click: _vm.onDenyTerms }
                    },
                    [_vm._v("\n\t\t\t\t\t\tWeigeren\n\t\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button button button--filled button--big",
                      attrs: { disabled: !_vm.approved },
                      on: { click: _vm.updateGdprTerms }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.buttonText) +
                          "\n\t\t\t\t\t"
                      )
                    ]
                  )
                ])
              ])
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Config from '$root/Config';
import JsonApiModel from '$dependencies/JsonApiModel';

import BinHistoryChangesModel from '$models/bin/BinHistoryChangesModel';

const API_DICTIONARY = {
	uuid: 'id',
	field_bin_history_changes: 'changes',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	title: { type: String, default: '' },
	changes: { type: Object, default: () => new BinHistoryChangesModel(), referenceType: 'bin_history--bin_changes' },
};

export default class BinHistoryModel extends JsonApiModel {
	constructor(args = {}) {
		args = { ...args };
		args.entityType = 'bin_history--bin_history';
		args.fields = FIELDS;

		args.config = { url: Config.getUrl('binHistory') };

		args.jsonApi = { ...(args.jsonApi || {}), ...{ dictionary: API_DICTIONARY } };

		super(args);
	}

	fetch(data) {
		this.include.push('changes');

		// clear sub collections & models
		this.set('changes', { reset: true });

		if (this.changes) this.changes.setConfig('headers', this.config.headers);

		return super.fetch(data);
	}

	setConfig(prop, value) {
		super.setConfig(prop, value);

		// update child headers
		if (prop === 'headers') {
			if (this.changes) this.changes.setConfig(prop, value);
		}

		return this;
	}

	get urlKey() {
		return this.title.toLowerCase().replace(' ', '-');
	}
}

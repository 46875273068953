var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { attrs: { for: _vm.context.id } },
    [
      _vm._v("\n  " + _vm._s(_vm.context.label) + "\n  "),
      _vm.tooltip
        ? _c("InfoIcon", {
            staticClass: "form-label-info-icon",
            attrs: { tooltip: _vm.tooltipObject }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
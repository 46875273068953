// imports
import Config from '$root/Config';
import Model from '$dependencies/Model';
import { formatDateForUi, minutesToHours } from '$utils/dateFormat';
import ActivityModel from './ActivityModel';

const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	created: { type: Number, default: '' },
	field_activity_distance: {type: Number, default: 0},
	distance: {type: Number, default: 0},
	start_address_lat: {type: Number, default: 0},
	start_address_lng: {type: Number, default: 0},
	startLocation: { type: Object, default: {lat: null, lon: null}},
	distance: { type: Number, default: 0 },
	startLocation: { type: Object, default: { lat: 0, lon: 0 } },
	parentModel: {type: ActivityModel, default: () => new ActivityModel()}
};

// class definition
export default class ActivityMarkerModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	get id() {
		return this.uuid;
	}

	get startDate() {
		return formatDateForUi(this.created);
	}

	get coordinates() {
		return {
			lat: this.startLocation.lat,
			lng: this.startLocation.lon
		}
	}
}

import _isObject from 'lodash/isObject';
import _get from 'lodash/get';

import Config from '$root/Config';
import Model from '$dependencies/Model';

export const FIELDS = {
	id: { type: String, default: '', identifier: true },
	title: { type: String, default: '' },
	uuid: { type: String, default: '' },

	field_measurement_addition_bags: { type: Number, default: 0 },
	field_measurement_bin: { type: Object, default: () => {} },
	owner: { type: Object, default: () => {} },
	field_measurement_date: { type: String, default: '' },
	field_measurement_fill_degree: { type: Number, default: 0 },
	field_measurement_id: { type: String, default: '- - - -', isComputed: true, isEditable: false },
	field_measurement_illegal_dump: { type: Number, default: 0 },
	field_measurement_remark: { type: String, default: '' },
	field_measurement_damage: {type: String, default: ''},
	field_measurement_cigarettes: { type: Number, default: 0 },
	field_measurement_addition: { type: String, default: '' },
	created: { type: String, default: '' },
};

let id = 0;

export default class MeasurementModel extends Model {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// apply default config values
		args.config = Object.assign(
			{
				url: '/jsonapi/node/measurement',
			},
			args.config || {},
		);

		// call super constructor
		super(args);

		this.instance_id = id++;

		// set extra properties
		this._subFetch = args._subFetch || false;
	}

	// methods
	fetch(data) {
		// clear sub collections & models
		this.set('community', { reset: true });
		this.set('field_measurement_bin', { reset: true });
		this.set('damage_options', { reset: true });
		this.set('user', { reset: true });

		// set their headers again
		// if (this.damage_options) this.damage_options.setConfig('headers', this.config.headers);
		return super.fetch(data);
	}

	post(data) {
		// run super post
		data = cleanUpdataForPayload(data || this.toObject(true), 'post');

		// remove id as well
		delete data.id;

		// reset config url to base url
		this.setConfig('url', Config.getUrl('measurements'));

		// post
		return super.post(data);
	}

	patch(data, options = {}) {
		return super.patch(cleanUpPatchFields(cleanUpdataForPayload(data || this.toObject(true), 'patch'), options.fieldsToPatch));
	}

	setConfig(prop, value) {
		super.setConfig(prop, value);

		// update child headers
		if (prop === 'headers') {
			// update child headers
			if (this.damage_options) this.damage_options.setConfig(prop, value);
		}

		return this;
	}

	parse(data) {
		data = super.parse(data);

		// catch textareas and normalize them
		const remark = _get(data, 'remark');
		if (_isObject(remark)) {
			data.remark = remark.value;
		}

		return data;
	}

	checkforChangedFields(model) {
		// Loop through cloned model to check for changes
		const changedFields = [];
		for (const key of Object.keys(model._values)) {
			let thisHasChanged = false;

			// Check if value is object & compare ID's in object
			if (typeof model._values[key] === 'object' && !Array.isArray(model._values[key])) {
				if (key !== 'date') {
					thisHasChanged = model._values[key].id !== this._values[key].id;
				} else {
					thisHasChanged = model._values[key] !== this._values[key];
				}
			} else if (Array.isArray(model._values[key])) {
				if (model._values[key].length !== this._values[key].length) {
					thisHasChanged = true;
				} else {
					for (let i = 0; i < model._values[key].length; i++) {
						thisHasChanged = model._values[key][i] !== this._values[key][i];
					}
				}
			}

			// Compare value if number or string
			else {
				thisHasChanged = model._values[key] !== this._values[key];
			}

			if (thisHasChanged) changedFields.push(key);
		}
		return changedFields;
	}

	destroy() {}

	// extra getters
	get computedTitle() {
		let title = '';

		if (this.bin.custom_id) title += this.bin.custom_id;

		return title;
	}
}

function cleanUpPatchFields(data, fieldsToPatch) {
	if (!fieldsToPatch) return data;
	if (data && fieldsToPatch) {
		for (const field in data) {
			if (!fieldsToPatch.includes(field)) {
				delete data[field];
			}
		}
	}
	return data;
}

function cleanUpdataForPayload(data, type) {
	// clean up some properties before posting
	if (data.changed !== undefined) delete data.changed;
	if (data.checked !== undefined) delete data.checked;
	if (data.created !== undefined) delete data.created;
	if (data.permissions !== undefined) delete data.permissions;
	if (data.user) delete data.user;

	if (type === 'post' && !data.damage_remark.id) delete data.damage_remark;return data;
}

export function dictionaryTranslate(value, dictionary = null) {
	if (dictionary) {
		if (Array.isArray(value)) {
			const newValues = [];
			for (const item of value) {
				newValues.push(dictionary[item] || item);
			}
			return newValues;
		}

		return dictionary[value] || value;
	}

	return value;
}

export function reverseDictionary(dictionary) {
	const reversed = {};
	for (const key of Object.keys(dictionary)) {
		reversed[dictionary[key]] = key;
	}
	return reversed;
}

export function createDictionary(...objects) {
	return Object.assign({}, ...objects);
}

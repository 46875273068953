import OverlayChildMixin from '$mixins/OverlayChildMixin';
import FormItem from '$components/FormItem/FormItem.vue';
import { set } from 'lodash';

export default {
	props: {
		hasCheckbox: {
			type: Boolean,
			default: true
		},
		buttonText: {
			type: String,
			default: 'Akkoord'
		}
	},

	components: {
		FormItem
	},

	mixins: [OverlayChildMixin],

	mounted() {},

	data() {
		return {
			
		};
	},

	computed: {
		approved: {
			get() {
				return this.$store.state.volunteers.gdprApproved;
			},
			set(val) {
				this.$store.commit('volunteers/updateGdpr', val);
			}
		}
	},

	methods: {
		onClose(e) {
			this.hide();
			this.$emit('approveClose');
		},

		onAfterHide() {
			this.$emit('afterhide');
		}
	},

	watch: {},
};

// imports
import Sync from '$dependencies/Sync';
import Collection from '$dependencies/Collection';
import { MC_EVENTS } from '$dependencies/MC_Base';
import { deepCloneObj } from '$utils/MC_Utils';

// private static properties
const FIELDS = {
	id: { type: String, default: '', identifier: true },
};

const PRIMITIVES = [Boolean, Number, String, Symbol];

// private static properties

// class definition
export default class Model extends Sync {
	// constructor
	constructor(args = {}) {
		super(args);

		// prepare properties
		this._values = {};
		this._locked = false;
		this.identifier = args.identifier || 'id'; // make sure to add appropriate getters & setters if identifier is different!
		this.fields = args.fields || FIELDS;
		this.strict = typeof args.strict === 'boolean' ? args.strict : true;
		this._boundMCListener = onMCEvent.bind(this);
		this._modified = new Date(0);
		this._updated = new Date(0);

		// prepare fields if given (use this.clear for this to ensure default values)
		if (this.fields) {
			// add fields silently
			this.silent(true);

			// set field with default value (might be a function)
			this.set(Object.keys(this.fields), {
				reset: true,
			});

			// generate getters & setters
			generateGS(this);

			// make it loud again
			this.silent(false);
		}

		// set values if any given
		if (args.values) this.set(args.values);

		// if no id is set, provide one
		if (this.get(this.identifier) === undefined) this.set(this.identifier, Date.now().toString(36));
	}

	// methods
	set(model, options = {}) {
		if (this._locked) {
			console.warn('[ Model ] - Tried to set a value but model was locked, set locked to false before setting a value');
			return this;
		}

		// Prep the options
		options = Object.assign(
			{
				add: true,
				replace: false,
				merge: true,
				reset: false,
				clear: false,
			},
			options,
		);

		// if (options.replace) debugger;

		// handle differently if model is an array or a collection
		if (model instanceof Collection) model = model.toArray(false); // make shallow array of models
		if (model instanceof Model) model = model.toObject(false); // shallow dump

		if (Array.isArray(model)) {
			// loop over models and add them separately using the same set function
			this.silent(true);
			for (let i = 0, len = model.length; i < len; i++) {
				this.set(model[i], options);
			}
			this.silent(false);
		} else if (typeof model === 'object') {
			// loop over keys and add them one by one silently to dispatch change just once!
			for (const key of Object.keys(model)) {
				// field def => skip if no field definition! force strict
				const fieldDef = this.fields[key];
				if (fieldDef === undefined) continue;

				// process the value
				const value = model[key];
				const originalValue = this.get(key);

				// do simple actions first
				if (options.clear) this._values[key] = null;
				if (options.reset) this._values[key] = getDefaultFieldValue(fieldDef);

				// if value is undefined/null => prevent further processing and assign undefined as object
				if (value === undefined || value === null) {
					this._values[key] = undefined;
					continue;
				}

				// replace present value if value is not MC instance
				if (!isMCInstance(originalValue) || options.replace) {
					if (PRIMITIVES.indexOf(fieldDef.type) > -1) {
						// mutate as primitive
						this._values[key] = fieldDef.type(value);
					} else {
						// add listeners if new value is MC instance
						if (isMCInstance(this._values[key])) {
							this._values[key].set(value);
							// addMCToModel(value, key, this);
						} else {
							// mutate as instance
							this._values[key] = value instanceof fieldDef.type ? value : new fieldDef.type(value);
						}
					}
				} else if (options.merge && !options.replace) {
					if (typeof value === 'string') {
						// consider it as an id
						originalValue.set(
							{
								id: value,
							},
							options,
						);
					} else {
						originalValue.set(value, options); // as object
					}
				}
			}
		} else if (typeof model === 'string') {
			// if string is passed => onlyn options reset and clear will be checked
			if (options.clear) this._values[model] = null;
			if (options.reset) resetField(this, model, this.fields, options);
		}

		// update changed time
		this._modified = new Date();

		// emit event and return self
		this.emit('change', {
			values: model,
		});

		return this;
	}

	get(prop) {
		return this._values[prop];
	}

	has(prop, operator = 'and') {
		// make sure prop is array for ease of use
		if (!Array.isArray(prop)) prop = [prop];

		// interprete operator
		if (operator === '==' || operator === '===') operator = 'and';
		if (operator === '||') operator = 'or';

		// loop over values and check against undefined
		for (const p in this._values) {
			switch (operator) {
				case 'and':
					// return false on first missing property
					if (this._values[p] === undefined) return false;
					break;

				case 'or':
					// return true on first available property
					if (this._values[p] !== undefined) return true;
					break;
			}
		}
	}

	fetch() {
		this.setConfig('data', {});
		return super.fetch();
	}

	post(data = null) {
		// prepare data but remove identifier
		if (!data) data = this.toObject(true);
		if (data[this.identifier]) delete data[this.identifier];
		this.setConfig('data', data);
		return super.post();
	}

	patch(data = null) {
		// prepare data with identifier
		if (!data) data = this.toObject(true);
		this.setConfig('data', data);
		return super.patch();
	}

	delete(data = null) {
		console.warn('[ Model ] Delete method not implemented yet.');
		return super.delete();
	}

	response(xhr) {
		// return;
		// make sure model is unlocked during response
		const wasLocked = this.locked;
		this.locked = false;

		// autoset values, xhr data has passed all parsing methods, data should be formatted correctly
		this.set(xhr.data);

		// renew updat date
		this._updated = new Date();

		// always call for super response => resets the states
		super.response(xhr);

		// back to original lock state
		this.locked = wasLocked;
	}

	error(error) {
		// check if error happened in sub instances
		// and isn't a straight ajax error at sync level
		if (!error.status && error.currentTarget && error.currentTarget !== error.data.scope) {
			error.data.scope.error(
				{
					type: 'error',
					originalEvent: error,
					error: error.data.error,
				},
				true,
			);
		} else {
			super.error(error);
		}
	}

	destroy() {
		for (const key of Object.keys(prop)) {
			this._values[key] = null;
		}

		this._values = null;
	}

	// utility methods
	isEmpty() {
		return !!Object.keys(this._values).length;
	}

	toObject(deep = true) {
		const values = Object.assign({}, this._values);

		if (deep) {
			for (const key of Object.keys(values)) {
				switch (true) {
					case values[key] instanceof Model:
						values[key] = values[key].toObject(true);
						break;
					case values[key] instanceof Collection:
						values[key] = values[key].toArray(true);
						break;
				}
			}
		}

		return values;
	}

	toJson() {
		return JSON.stringify(this.toObject(true));
	}

	clone(deep = true, config = false) {
		const model = new this.constructor({ values: deep ? this.toObject(true) : Object.assign({}, this._values) });
		if (config) model._config = deepCloneObj(this._config);
		return model;
	}

	clear(hard = false) {
		// check lock first
		if (this._locked) {
			console.warn('[ Collection ] - Tried to set a value but model was locked, set locked to false before setting a value');
			return this;
		}

		if (!hard) {
			// loop over all fields
			// if current value is a MC instance => clear it instead of replacing it with default!
			// to avoid breaking event listeners outside this instance
			for (const key of Object.keys(this.fields)) {
				const value = this._values[key];
				if (isMCInstance(value)) {
					value.clear();
				} else {
					this._values[key] = getDefaultFieldValue(this.fields[key]);
				}
			}
		} else {
			// do hard clear => quicker but breaks with everything as MC instances will be replaced instead of cleared
			this._values = Object.assign({}, this._fields);
		}

		// update changed time
		this._modified = new Date();

		this.emit('cleared');

		return this;
	}

	properties() {
		return Object.keys(this._values);
	}

	compare(model, exact = false) {
		if (exact) {
			return model === this;
		}
		if (model instanceof this.constructor) model = model.toObject();
		// loop over all
		for (const key of Object.keys(model)) {
			if (this._values[key] !== model[key]) return false;
		}
		return this;
	}

	destroy() {
		super.destroy();
	}

	getState() {
		return {
			locked: this._locked,
			modified: this._modified,
			updated: this._updated,
			strict: this._strict,
			identifier: this._identifier,
		};
	}

	// getters & setters
	get id() {
		return this.get('id');
	}

	set id(value) {
		this.set({ id: value });
	}

	get locked() {
		return this._locked;
	}

	set locked(value) {
		if (this._locked !== value) {
			this._locked = value;
			this.emit(value ? 'locked' : 'unlocked');
		}
	}

	get synced() {
		return this._modified.getTime() === this._updated.getTime();
	}
}

// extract default value from fieldDef => run default value if function
function getDefaultFieldValue(fieldDef) {
	return typeof fieldDef.default === 'function' ? fieldDef.default() : fieldDef.default;
}

// check if given value is an MC instance
export function isMCInstance(instance) {
	return instance instanceof Model || instance instanceof Collection;
}

// reset field using field definitions
function resetField(model, key, fields, options) {
	const originalValue = model._values[key];

	if (isMCInstance(originalValue)) {
		// remove all listeners
		originalValue.off(MC_EVENTS, model._boundMCListener);
	}

	// get empty default
	const newValue = getDefaultFieldValue(fields[key]);

	// replace field with new default, directly (to avoid checks of set)
	model._values[key] = newValue;

	// add listeners if mc instance
	if (isMCInstance(newValue)) newValue.on(MC_EVENTS, model._boundMCListener);
}

function addMCToModel(instance, key, model) {
	// add to values
	model._values[key] = instance;

	// add listeners
	instance.on(MC_EVENTS, model._boundMCListener);
}

function onMCEvent(e) {
	if (e.type === 'error') {
		this.emit('error', {
			type: 'error',
			originalEvent: e,
			error: e.data.error,
		});
	} else if (e.type !== 'response') {
		this.emit(e.type, {
			type: e.type,
			originalEvent: e,
		});
	}
}

function generateGS(model) {
	const { fields } = model;

	for (const key of Object.keys(fields)) {
		const field = fields[key];

		// get object description
		const propDesc = Object.getOwnPropertyDescriptor(model, key);

		// create object definition
		let objectDef;

		if (field.getter !== false && field.setter !== false && !propDesc) {
			// both
			objectDef = {
				configurable: true,
				get() {
					return model.get(key);
				},
				set(value) {
					const v = {};
					v[key] = value;
					model.set(v);
				},
			};
		} else if (field.getter !== false && (!propDesc || !propDesc.get)) {
			// getter
			objectDef = {
				configurable: true,
				get() {
					return model.get(key);
				},
			};
		} else if (field.setter !== false && (!propDesc || !propDesc.set)) {
			// getter
			objectDef = {
				configurable: true,
				set(value) {
					const v = {};
					v[key] = value;
					model.set(v);
				},
			};
		}

		// apply definition
		if (objectDef) Object.defineProperty(model, key, objectDef);
	}
}

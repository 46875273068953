// imports
import Model from '$dependencies/Model';
import JsonApiCollection from '$dependencies/JsonApiCollection';
import GarbageBinModel from '$models/GarbageBinModel';

// class definition
export default class GarbageBinCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.entityType = 'node--bin';
		args.Model = args.Model || GarbageBinModel;

		// call super constructor
		super(args);
	}

	bulkSet(model) {
		// convert to object to avoid any mistakes
		if (model instanceof Model) model = model.toObject(true);

		// loop over all & apply values
		this.each((_, item) => {
			item.set(model);
		});

		return this;
	}
}

// imports
import Model from '$dependencies/Model';
import Collection from '$dependencies/Collection';
import { isTypes } from '$utils/TypeChecks';
import { isMCInstance } from '$dependencies/Model';
import {
	convertFiltersToUrlParams,
	generateFieldsets,
	getTranslatedFilters,
	invertDictionary,
	translateToJsonApi,
	translateFromJsonApi,
} from '$dependencies/MC_JsonApi_Base';

// private static properties
// dictionary needs to be exported so JsonApiCollections can translate fields for filtering/sorting
export const API_DICTIONARY = {
	// [jsonApi field name]: [Model field name]
	// jsonApi field name = field name used in communication protocol (response & payloads)
	// model field name = field used throughout the app (for convenience)
};

// class definition
export default class JsonApiModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to allow modifications
		args = Object.assign({}, args);
		super(args);

		// set properties
		this.entityType = args.entityType;

		// jsonApi settings
		const jsonApi = Object.assign({}, args.jsonApi || {});

		const tTypes = isTypes(['object', 'boolean'], jsonApi.translationTargets, 'or') ? jsonApi.translationTargets : true;

		this._jsonApi = {
			dictionary: jsonApi.dictionary || {},
			links: {},
			filters: {},
			filterGroups: {},
			translationTargets: {
				fetch: tTypes.fetch || tTypes,
				post: tTypes.post || tTypes,
				patch: tTypes.patch || tTypes,
				delete: tTypes.delete || tTypes,
			},
		};

		// jsonapi include
		this.include = args.include || [];

		// set config
		this.setConfig('headers', {
			'Content-Type': 'application/vnd.api+json',
		});
	}

	// methods
	set(values, options = {}) {
		// listensn to translate parameter
		// if true => values parameter is expected to be jsonapi!
		// run through parser?
		if (options.translate) {
			values = this.processJsonApiResponse(values, this);
		}

		return super.set(values, options);
	}

	parse(data) {
		// translate response
		let response = data;
		if (!response) return;

		// check translation
		if (this._jsonApi.translationTargets.fetch) {
			response = processJsonApiResponse(response, this);
		}

		return super.parse(response);
	}

	fetch(data = null) {
		// prep filters
		const translatedFilters = getTranslatedFilters(this);
		const filterUrlParams = convertFiltersToUrlParams(translatedFilters, this._jsonApi.filterGroups);

		// prep fieldset
		const fieldset = generateFieldsets(this);

		this.setConfig('params', filterUrlParams).setConfig('params', fieldset);

		// call super && clean up filter params afterwards
		return super.fetch(data).finally(() => {
			this.unsetConfig('params', filterUrlParams).unsetConfig('params', fieldset);
		});
	}

	post(data) {
		// ensure data is not empty
		data = data || this.toObject(true);

		// check if translation is required
		if (this._jsonApi.translationTargets.post) {
			data = convertToJsonApiPayload.call(this, 'post', data);
		}

		// call super
		return super.post(data);
	}

	patch(data) {
		// ensure data is not empty
		data = data || this.toObject(true);

		// remove data if id is not specified
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				if (typeof data[key] === 'object') {
					if (data[key].hasOwnProperty('id')) {
						if (data[key].id.length < 1) delete data[key];
					}
				}
			}
		}

		// check if translation is required
		if (this._jsonApi.translationTargets.patch) {
			data = convertToJsonApiPayload.call(this, 'patch', data);
		}

		// call super
		return super.patch(data);
	}

	delete() {
		return super.delete();
	}

	ajax(data) {
		// check include property and add them as included recources to request
		if (this.include.length) {
			// translate include array
			this.translatedFields = [];

			for (const field of this.include) {
				this.translatedFields.push(translateToJsonApi(field, this._jsonApi.dictionary));
			}

			this.setConfig('params', {
				include: this.translatedFields.join(','),
			});
		}

		return super.ajax(data).finally(() => {
			this.unsetConfig('params', 'include');
		});
	}

	setConfig(prop, value) {
		// remove dirty revision parameter
		if (prop === 'url') value = value.split('?')[0];
		return super.setConfig(prop, value);
	}

	toPayload(type, data) {
		// ensure data is not empty
		data = data || this.toObject(true);

		// check if translation is required
		if (this._jsonApi.translationTargets[type]) {
			data = convertToJsonApiPayload.call(this, type, data);
		}

		return data;
	}

	setCallFilter(filter, doReplace = false) {
		if (doReplace) {
			this._jsonApi.filters = filter;
		} else {
			Object.assign(this._jsonApi.filters, filter);
		}

		return this;
	}

	removeCallFilter(key) {
		// delete key if it has value
		if (this._jsonApi.filters[key]) {
			delete this._jsonApi.filters[key];
		}

		return this;
	}

	destroy() {}

	// utility methods
}
function convertToJsonApiPayload(type, payload) {
	const literal = payload;
	const dictionary = invertDictionary(this._jsonApi.dictionary);

	// get literal from model & prepare some more variables
	const attributes = {};
	const relationships = {};

	// loop over keys to make up the json api structure
	for (const key of Object.keys(payload)) {
		// extract value first & look for field definition parameters
		const value = payload[key];
		const fieldDef = this.fields[key];
		const jaKey = dictionary[key] || key;

		// skip field if field.def is computed (meaning: field value was computed by backend and thus doens'nt exists for post/patch)
		if (fieldDef.isComputed && !fieldDef.isEditable) continue;

		// check for field definition parameter isReference
		if (fieldDef) {
			// determine to which part of the payload the value needs to be added
			if (fieldDef.referenceType) {
				// only add if relationship model has id
				if (value) relationships[jaKey] = convertValueToRelationship.call(this, value, fieldDef);
			} else if (key !== this.identifier) {
				// add attribute only if not identifier
				attributes[jaKey] = convertValueToAttribute.call(this, value, fieldDef);
			}
		} else if (!fieldDef && this.strict) {
			console.warn('[ JSON API MODEL ] - Mode is strict => field definition is required! Field is ignored for now.');
			continue;
		}
	}

	// render payload object
	payload = {
		data: {
			attributes,
			relationships,
		},
	};

	// add some more properties if present
	if (this.entityType) payload.data.type = this.entityType;
	if (type !== 'post') payload.data.id = this[this.identifier];

	return payload;
}

export function convertValueToAttribute(value, fieldDef) {
	if (value instanceof Date || typeof value === 'date') {
		value = Math.round(value.getTime() / 1000);
	}

	return value;
}

export function convertValueToRelationship(value, fieldDef) {
	// add relationship
	let data;

	// Make up data of relationship accordingly
	if (Array.isArray(value)) {
		data = [];

		for (const item of value) {
			data.push({
				type: fieldDef.referenceType,
				id: item.id,
			});
		}
	} else {
		data = {
			type: fieldDef.referenceType,
			id: value.id,
		};
	}

	return {
		data,
	};
}

export function processJsonApiResponse(response, model) {
	// block parsing if response is null
	// return empty object to signify empty results
	if (!response.data) return {};

	// // extract api data
	// let apiData = response.data;

	// prep fields & dictionary shortcuts
	const { fields } = model;
	const { dictionary } = model._jsonApi;

	// merge attributes and relationships into data & extract the exclude array
	const data = Object.assign({}, response.data.attributes || {}, response.data.relationships || {});
	const included = response.included || null;

	// loop over props to perform the necessary translations
	const newData = {};
	for (const key of Object.keys(data)) {
		// translate the property & extract the value using the old property
		const newKey = translateFromJsonApi(key, dictionary);
		let value = data[key];

		// get field definition, if none found => skip to next
		const fieldDef = fields[newKey];
		if (!fieldDef) continue;

		// if (newKey === 'images') debugger;

		// extra processing is required if field is reference
		if (dataValueIsReference(value)) {
			// process the references => make sure data is array for easy processing
			let usedToBeSingle = false;
			if (!Array.isArray(value.data)) {
				value.data = value.data ? [value.data] : [];
				usedToBeSingle = true;
			}

			for (let i = 0, len = value.data.length; i < len; i++) {
				let record = value.data[i];
				record = normalizeReference(fieldDef, record, dictionary);
				if (included) record = mergeIncludedValues(record, included);
				value.data[i] = record;
			}

			// if usedToBeSingle => consider it to be model data
			if (usedToBeSingle) value.data = value.data[0];

			// process reference links
			value = processReferenceLinks(newKey, value, model);
		}

		newData[newKey] = value;
	}

	// add id field to new data => used to be attributes.uuid but was removed
	if (response.data.id) newData[model.identifier] = response.data.id;

	return newData;
}

export function processReferenceLinks(prop, apiValue, model) {
	if (apiValue.links && apiValue.links.related) {
		// get mc instance from prop, if any
		const instance = model.get(prop);
		if (isMCInstance(instance)) {
			// prep shortcuts for clean code
			const apiLinks = model._jsonApi.links;
			const relatedLink = apiValue.links.related.href;

			// if instance is collection => prep jsonapi links as array
			if (instance instanceof Collection && apiLinks[prop] === undefined) apiLinks[prop] = [];

			// add value link to api links
			Array.isArray(apiLinks[prop]) ? apiLinks[prop].push(relatedLink) : (apiLinks[prop] = relatedLink);

			// set config of instance
			instance.setConfig('url', relatedLink);

			// return parsed value
			return instance.parse(apiValue);
		}
	}

	return apiValue;
}

function dataValueIsReference(value) {
	return value && typeof value === 'object' && Object.keys(value).length === 2 && value.links !== undefined && value.data !== undefined;
}

function mergeIncludedValues(record, included) {
	const includedItem = lookUpDataInIncludedById(record.id, included);

	// merge both object if more data was found
	if (includedItem) {
		return includedItem;
	}
	return record;
}

function lookUpDataInIncludedById(id, included) {
	for (const item of included) {
		if (item.id === id) return item;
	}
	return false;
}

function normalizeReference(fieldDef, data, dictionary) {
	// mutate reference data by moving identifier to attribute property of data
	// for easy parsing of data in sub models of relationships
	// extract identifier from default data of field definition
	// translate it back to jsonapi to use the correct attribute property name for translation later
	// only if identifier isn't present in the attributes property
	data = Object.assign({}, data);
	const jsonApiIdentifier = translateToJsonApi(fieldDef.default().identifier, dictionary);
	if (!data.attributes) data.attributes = {};
	if (!data.attributes[jsonApiIdentifier]) data.attributes[jsonApiIdentifier] = data.id;
	return data;
}

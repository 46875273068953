// imports
import EditOverlay from '$components/EditOverlay/EditOverlay.vue';
import OverlayChildMixin from '$mixins/OverlayChildMixin';

// class
export default {
	props: ['start-value'],

	components: {
		EditOverlay,
	},

	mixins: [],

	data() {
		return {};
	},

	mounted() {},

	computed: {
		...OverlayChildMixin.computed,
	},

	beforeDestroy() {},

	methods: {
		...OverlayChildMixin.methods,

		showApply() {
			this.$refs.overlay.showApply();
		},

		hideApply() {
			this.$refs.overlay.hideApply();
		},

		_onApplyClick(e) {
			// debugger;
		},
	},
};

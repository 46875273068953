// imports
import _get from 'lodash/get';
import _map from 'lodash/map';

import Config from '$root/Config';
import BinMarkerModel from '$models/bin/BinMarkerModel';
import GarbageBinModel from '../models/GarbageBinModel';
import MMCollection from './MMCollection';

const filterDictionary = {
	material: 'field_bin_material',
	location_type: 'field_bin_location_type',
	custom_id: 'field_bin_g_id',
	latitude: 'field_bin_latitude',
	longitude: 'field_bin_longitude',
	last_measurement: 'field_bin_last_measurement_date',
	first_measurement: 'field_bin_first_measurement_date',

	bin_active: 'field_bin_status',
	bin_lid_size: 'field_bin_lid_size',
	color: 'field_bin_color',
	bin_material: 'field_bin_material',
	bin_post_code: 'field_bin_post_code',
	bin_surface: 'field_bin_surface',
	bin_volume: 'field_bin_volume',
	bin_damage: 'field_bin_damage',
	bin_emptying_frequency: 'field_bin_emptying_frequency',
	remark: 'field_bin_remark',

	cigarettes: 'field_measurement_cigarettes',
	addition: 'field_measurement_addition.id',
	fill_degree: 'field_measurement_fill_degree',
	illegal_dump: 'field_measurement_illegal_dump',
};

// class definition
export default class BinMarkerSmallCollection extends MMCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || BinMarkerModel;
		args.parentModel = new GarbageBinModel();
		args.filterDictionary = filterDictionary;
		// call super constructor
		super(args);
	}

	parse(data) {
		const markers = _get(data, 'features');
		data = _map(markers, (item) => {
			const binType = _get(item, 'properties.field_bin_type');
			const gId = _get(item, 'properties.field_bin_g_id');
			const coordinates = {
				lat: parseFloat(_get(item, 'geometry.coordinates[1]')),
				lng: parseFloat(_get(item, 'geometry.coordinates[0]')),
			};

			const preparedItem = {
				id: _get(item, 'properties.uuid'),
				coordinates,
				parentModel: {},
				binType,
				gId,
				..._get(item, 'properties'),
			};
			// create model
			const newModel = new this.Model().set(preparedItem);
			return newModel;
		});

		return super.parse(data);
	}

	fetch() {
		// add community id to url
		this.setConfig('url', `${Config.getUrl('binMarkersSmall')}/${window.vueStore.state.session.user.community.id}`);
		// super fetch
		return super.fetch();
	}
}

// imports
import { formatDate } from '$utils/Utils.js';

// filter object definition
export default {
	install(Vue) {
		if (Vue.filter('date')) {
			console.warn('[filter duplication]: A filter named date has already been installed.');
		} else {
			Vue.filter('date', function(value, format) {
				return formatDate(value, format);
			});
		}
	},
};

import Config from '$root/Config';
import JsonApiModel from '$dependencies/JsonApiModel';

const API_DICTIONARY = {
	uuid: 'id',
	field_bin_change_after: 'after',
	field_bin_change_before: 'before',
	field_bin_change_field_name: 'field_name',
	field_bin_change_is_create: 'is_create',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	title: { type: String, default: '' },
	after: { type: String, default: '' },
	before: { type: String, default: '' },
	field_name: { type: String, default: '' },
	is_create: { type: Boolean, default: false },
};

export default class BinHistoryChangesModel extends JsonApiModel {
	constructor(args = {}) {
		args = { ...args };
		args.entityType = 'bin_history--bin_changes';
		args.fields = FIELDS;

		args.config = { url: Config.getUrl('binChanges') };

		args.jsonApi = { ...(args.jsonApi || {}), ...{ dictionary: API_DICTIONARY } };

		super(args);
	}

	get urlKey() {
		return this.title.toLowerCase().replace(' ', '-');
	}
}

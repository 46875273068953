// default css classes
const DEF = {
	cssClasses: {
		shown: 'overlay--shown',
		beforeShow: 'overlay--beforeShow',
		hidden: 'overlay--hidden',
		beforeHide: 'overlay--beforeHide',
	},
};

export default {
	props: {
		state: {
			type: String,
			default: 'hidden',
		},

		tag: {
			type: String,
			default: 'div',
		},

		title: {
			type: String,
			default: 'Overlay Title',
		},

		showHeader: {
			type: Boolean,
			default: true,
		},

		nested: {
			type: Boolean,
			default: false,
		},

		noBg: {
			type: Boolean,
			default: false,
		},
	},

	components: {},

	mixins: [],

	data() {
		return {
			cssClasses: {
				shown: '',
				beforeShow: '',
				hidden: '',
				beforeHide: '',
			},

			inDom: false,
			visible: false,
		};
	},

	mounted() {
		// fill up properties with default values
		for (const key of Object.keys(DEF.cssClasses)) {
			if (!this.cssClasses[key] || this.cssClasses[key].length === 0) this.cssClasses[key] = DEF.cssClasses[key];
		}
	},

	computed: {
		stateCssClass() {
			if (this.inDom && !this.visible) return this.cssClasses.beforeShow;
			if (this.inDom && this.visible) return this.cssClasses.shown;
			if (!this.inDom && !this.visible) return this.cssClasses.hidden;
			return '';
		},

		// slot checks
		hasHeaderSlot() {
			return !!this.$slots.header;
		},
		hasAdditionalInputsSlot() {
			return !!this.$slots.additionalInputs;
		},
		hasBodySlot() {
			return !!this.$slots.body || this.$slots.default;
		},
		hasFooterSlot() {
			return !!this.$slots.footer;
		},
	},

	beforeDestroy() {},

	methods: {
		show(hard = false) {
			if (!this.visible) {
				this.inDom = true;
				this.$emit('beforeShow');
				this.$nextTick(() => {
					(this.visible = true), this.$emit('shown');
				});
				this.$emit('afterShow');
			}
		},

		hide(hard = false) {
			if (this.visible) {
				this.visible = false;
				this.$emit('beforehide');
				setTimeout(() => {
					(this.inDom = false), this.$emit('hidden');
				}, 500);
				this.$emit('afterhide');
			}
		},

		toggle(hard = false) {
			!this.visible ? this.show(hard) : this.hide(hard);
		},

		// private methods
		_onCloseClick(e) {
			if (e && e.preventDefault) e.preventDefault();
			this.hide();
		},
	},
};

// scroll block by class (make sure it exists in css)
function lockScroll() {
	document.body.classthisList.add('scrollBlock--byOverlay');
}
function unlock() {
	document.body.classList.remove('scrollBlock--byOverlay');
}

// imports
import _forEach from 'lodash/forEach';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import Config from '$root/Config';

import IncidentModel from '$models/IncidentModel';
import CategoryCollection from '$collections/CategoryCollection';
import IncidentCollection from '$collections/IncidentCollection';
import IncidentCandidateCollection from '$collections/IncidentCandidateCollection';
import PermissionCollection from '$collections/PermissionCollection';
import PostcodeCollection from '$collections/PostcodeCollection';
import StatusCollection from '$collections/StatusCollection';
import TeamCollection from '$collections/TeamCollection';
import UsersCollection from '$collections/UsersCollection';
import IncidentMarkerCollection from '$collections/IncidentMarkerCollection';
import IncidentReportTypeCollection from '$collections/IncidentReportTypeCollection';
import IncidentDeclineReasonCollection from '$collections/IncidentDeclineReasonCollection';
import ReportIncidentsCollection from '$collections/ReportIncidentsCollection';
import ReportIncidentsDetailCollection from '$collections/ReportIncidentsDetailCollection';
import ReportIncidentsStartCollection from '$collections/ReportIncidentsStartCollection';
import TaxonomyCollection from '../../collections/TaxonomyCollection';

export default {
	namespaced: true,
	state() {
		return {
			permissions: new PermissionCollection({
				config: { url: Config.getUrl('permissions') },
			}),

			list: new IncidentCollection({
				config: { url: Config.getUrl('incidents') },
			}),

			markers: new IncidentMarkerCollection({
				config: { url: Config.getUrl('incidentMarkers') },
			}),

			candidateList: new IncidentCandidateCollection({
				config: { url: Config.getUrl('incidentCandidates') },
			}),

			current: new IncidentModel({
				config: { url: Config.getUrl('incidents') },
			}),

			selected: new IncidentModel({
				config: { url: Config.getUrl('incidents') },
			}),

			statuses: new StatusCollection({
				config: { url: Config.getUrl('statuses') },
			}),

			categories: new CategoryCollection({
				config: { url: Config.getUrl('categories') },
			}),

			postcodes: new PostcodeCollection(),
			teams: new TeamCollection(),
			users: new UsersCollection(),

			reportTypes: new IncidentReportTypeCollection({
				config: { url: Config.getUrl('incidentReportTypes') },
			}),

			declineReasons: new IncidentDeclineReasonCollection(),

			reportIncidentsCategories: new ReportIncidentsCollection({
				config: { url: Config.getUrl('reportIncidentsCategories') },
			}),

			reportIncidentsDetail: new ReportIncidentsDetailCollection({
				config: { url: Config.getUrl('reportIncidentsDetail') },
			}),

			reportIncidentsStart: new ReportIncidentsStartCollection({
				config: { url: Config.getUrl('reportIncidentsStart') },
			}),

			//new custom endpoint / taxonomies
			c_incidentCategories: new TaxonomyCollection({
				type: 'c_incidentCategories'
			}),

			c_incidentStatuses: new TaxonomyCollection({
				type: 'c_incidentStatuses'
			}),

			c_incidentReportTypes: new TaxonomyCollection({
				type: 'c_incidentReportTypes'
			}),

			c_incidentTeams: new TaxonomyCollection({
				type: 'c_incidentTeams'
			}),

			c_incidentDeclineReasons: new TaxonomyCollection({
				type: 'c_incidentDeclineReasons'
			}),

			c_postalCodes: new TaxonomyCollection({
				type: 'c_postalCodes'
			}),
		};
	},

	mutations: {
		UPDATE_AUTH(state, payload) {
			const authorizationToken = { Authorization: payload.jwt_authorization };
			const stateValuesToUpdateJWT = _pick(state, [
				'list',
				'markers',
				'candidateList',
				'selected',
				'current',
				'categories',
				'permissions',
				'postcodes',
				'statuses',
				'teams',
				'users',
				'reportTypes',
				'c_incidentCategories',
				'c_incidentStatuses',
				'c_incidentReportTypes',
				'c_incidentTeams',
				'c_incidentDeclineReasons',
				'c_postalCodes'
			]);

			// update jwt
			_forEach(stateValuesToUpdateJWT, (item) => {
				item.setConfig('headers', authorizationToken);
			});

			// update permission url (unconventional but hey... so is it's endpoint)
			state.permissions.setConfig('url', `${Config.getUrl('permissions')}/${payload.user.id}?fields[user--user]=field_user_incident_permissions`);

			// apply paths to collections for comunity based data
			state.postcodes.setConfig(
				'url',
				payload.user.community.postcodes
					.getConfig('url')
					.split('?')
					.shift(),
			);
			state.teams.setConfig(
				'url',
				payload.user.community.teams
					.getConfig('url')
					.split('?')
					.shift(),
			);
		},

		ON_LOGOUT(state) {
			const omittedState = _omit(state, ['declineReasons']);

			_forEach(omittedState, (item) => {
				item.unsetConfig('headers').clear();
			});
		},
	},
};

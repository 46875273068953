// imports
import Model from '$dependencies/Model';
import { formatDateForUi } from '$utils/dateFormat';
import PhotoCollection from '$collections/PhotoCollection';
import Config from '../../Config';
import format from 'date-fns/format'
import TaxonomyModel from '$models/TaxonomyModel';
import TaxonomyCollection from '../../collections/TaxonomyCollection';
import MeasuringStripModel from './MeasuringStripModel';

const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	title: { type: String, default: '' },
	startDate: { type: String, default: '' },
	community: { type: String, default: ''},
	description: { type: String, default: ''},
	hasVolunteers: {type: Boolean, default: false},
	isEventual: {type: Boolean, default: false},
	involvedParties: {type: Array, default: () => []},
	otherInvolvedParty: { type: String, default: '' },
	location: { type: Object, default: {lat: null, lon: null} },
	measuringStrips: {type: Array, default: () => []},
	projectZones: {type: Array, default: () => []},
	projectPath: {type: Array, default: () => []},
	// countingMethod: {type: TaxonomyModel, default: () => new TaxonomyModel()},
	locationType: {type: TaxonomyModel, default: () => new TaxonomyModel()},
	// fractions: {type: Array, default: () => []},
	images: { type: Object, default: () => new PhotoCollection(), referenceType: 'file--file' },
	cleaningMethods: {type: Array, default: () => []},
	cleaning: {type: Array, default: () => []},
	cleaningFrequencies: {type: Object, default: {}},
	measureDesigns: {type: Object, default: {}},
	measurementCount: {type: Number, default: 0},
	measurementDate: { type: String, default: '' },

	measuringDesigns: {type: Object, default: () => {}},
	
	status: {type: Object, default: {}},
	//closing
	impact: {type: TaxonomyModel, default: () => new TaxonomyModel()},
	closeDate: {type: String, default: () => new Date()},
	publicationConsent: {type: Boolean, default: true},
	deletePermission: { type: Boolean, default: false },
};

// class definition
export default class ProjectModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	fetch(data) {
		this.setConfig('url', `${Config.getUrl('project')}/${this.uuid}`);
		this.setDeletePermission()
		return super.fetch(data);
	}

	post(data) {
		this.setConfig('url', Config.getUrl('projects'));
		data = prepareDataForPost(this.toObject(true));
		return super.post(data);
	}

	duplicate(uuid) {
		this.setConfig('url', `${Config.getUrl('projects')}/${uuid}/duplicate`);
		const data = prepareDataForDuplication(this.toObject(true));
		return super.post(data);
	}

	close() {
		this.setConfig('url', `${Config.getUrl('project')}/${this.uuid}/close`);
		const data = prepareDataForClosingProject(this.toObject(true));
		return super.post(data);
	}

	open() {
		this.setConfig('url', `${Config.getUrl('project')}/${this.uuid}/reopen`);
		return super.post({});
	}

	parse(data) {
		if(!data) return {};

		if (data.data.location) {
			data.data['projectPath'] = geoToCoords(data.data.location);
		}

		if (data.data.images) {
			data.data.images.map(image => {
				return Object.assign(image, {id: image.uuid});
			});
		}

		if (data.data['cleaningFrequencies']) {
			data.data['cleaningFrequencies'] = {};
			data.data.cleaningMethods.forEach(cm => {
				data.data.cleaningFrequencies[cm.method.uuid] = cm.frequency.uuid;
			});
		}

		if (data.data.measuringStrips) {
			data.data.measuringStrips = data.data.measuringStrips.map(ms => {
				const gmapType = ms.location.indexOf('LINESTRING') === 0 ? 'polyline' : 'polygon';
				ms = Object.assign(ms, {coords: geoToCoords(ms.location), type: gmapType, id: ms.uuid, projectId: data.data.uuid});
				return new MeasuringStripModel().set(ms);
			});
		}

		return super.parse(data.data);
	}

	patch(data, options = {}) {
		// run super patch
		this.setConfig('url', `${Config.getUrl('project')}/${this.uuid}`);
		return super.patch(prepareDataForPatch(cleanUpPatchFields(data || this.toObject(true), options.fieldsToPatch)));
	}

	async setDeletePermission() {
		return fetch(`${Config.getUrl('projectDeletePermission')}/${this.id}`, {'headers': this._config.headers}).then(r => r.json()).then(permission => {
			this.deletePermission = permission.allowed
		})
	}

	// getters & setters
	get active() {
		return this.status.machineName !== 'closed';
	}

	get id() {
		return this.uuid;
	}

	get formattedDate() {
		return formatDateForUi(this.startDate * 1000);
	}

	get modelDate() {
		return new Date(this.startDate * 1000);
	}

	set modelDate(val) {
		this.startDate = new Date(val).getTime() / 1000;
	}
	
	get formattedCloseDate() {
		return formatDateForUi(this.closeDate * 1000);
	}

	get fractionList() {
		const fractions = [];
		this.measuringDesigns.forEach(md => {
			Object.values(md.children).map(mdc => {
				fractions.push(mdc.title)
			})
		})
		return fractions;
	}

	get partiesList() {
		const parties = [];
		this.involvedParties.forEach(f => {
			parties.push(f.title);
		});
		return parties;
	}

	get coordinates() {
		return { latitude: this.location.lat, longitude: this.location.lon };
	}

	get gmapCoordinates() {
		return { lat: this.location.lat, lng: this.location.lon };
	}

	get latitude() {
		return this.location.lat;
	}

	get longitude() {
		return this.location.lon;
	}

	set latitude(val) {
		return(this.location.lat = val);
	}

	set longitude(val) {
		return(this.location.lon = val);
	}

	get isMeasured() {
		return this.measurementCount > 0;
	}

	get formattedLastMeasurementDate() {
		return this.measurementDate ? formatDateForUi(this.measurementDate * 1000) : '-';
	}

	get isClosed() {
		return this.status.machineName === 'closed';
	}
}

function prepareDataForClosingProject(data) {
	const closingData = {};
	closingData['impact'] = data.impact.uuid;
	closingData['publicationConsent'] = data.publicationConsent;
	closingData['closeDate'] = parseInt(new Date(data.closeDate).getTime() / 1000).toFixed(0);
	return closingData;
}

function prepareDataForDuplication(data) {
	for (const field in data) {
		if (field !== 'title') {
			delete data[field];
		}
	}
	return data;
}

function prepareDataForPost(data) {
	//delete closing project props
	delete data.impact;
	delete data.closeDate;
	delete data.publicationConsent;

	//set project lcoation path
	const locationCoords = [];
	if(data.projectPath) {
		data.projectPath.forEach(point => {
			locationCoords.push(`${point.lng()} ${point.lat()}`);
		})
		data.location = `POLYGON ((${locationCoords.join(', ')}))`;
		delete data.projectPath;
	}

	//set measuringStrips
	if(data.projectZones) {
		data.projectZones.forEach(zone => {
			data.measuringStrips.push(toMeasuringStrip(zone));
		})
		delete data.projectZones;
	}

	//location type
	if(data.locationType) {
		data.locationType = data.locationType.uuid;
	}

	if(data.countingMethod) {
		data.countingMethod = data.countingMethod.uuid;
	}

	//set cleaning
	if(data.cleaningFrequencies) {
		for (const key in data.cleaningFrequencies) {
			data.cleaning.push({'method': key, 'frequency': data.cleaningFrequencies[key]});
		}
		delete data.cleaningFrequencies;
		delete data.cleaningMethods;
	}

	//remove images
	if(data.images) {
		delete data.images;
	}
	
	if(data.startDate) {
		data.startDate = parseInt(new Date(data.startDate).getTime() / 1000).toFixed(0);
	}

		// measuringDesigns
		if(data.measuringDesigns) {
			data.measuringDesigns = Object.assign([], Object.values(data.measuringDesigns))
		}

	return data;
}

function prepareDataForPatch(data) {
	//parties
	if(data.involvedParties) {
		data.involvedParties = data.involvedParties.map(ip => {
			return ip.uuid;
		});
	}

	if(data.fractions) {
		data.fractions = data.fractions.map(f => {
			return f.uuid;
		});
	}

	if(data.countingMethod) {
		data.countingMethod = data.countingMethod.uuid;
	}

	//set cleaning
	if(data.cleaningFrequencies) {
		data.cleaning = [];
		for (const key in data.cleaningFrequencies) {
			data.cleaning.push({'method': key, 'frequency': data.cleaningFrequencies[key]});
		}
		delete data.cleaningFrequencies;
		delete data.cleaningMethods;
	}

	//set measuring designs
	if(data.measuringDesigns) {
		const measures = []

		data.measuringDesigns.forEach(md => {
			measures.push({
				'fraction': md.fraction.uuid,
				'countingMethod': md.countingMethod.uuid,
				'countingUnit': md.countingUnit.uuid,
				'children': Object.values(md.children).map( c => {
					return c.uuid
				})
			})
		})
		data.measuringDesigns = measures
	}

	//location type
	if(data.locationType) {
		data.locationType = data.locationType.uuid;
	}

	//set project lcoation path
	const locationCoords = [];
	if(data.projectPath) {
		data.projectPath.forEach(point => {
			locationCoords.push(`${point.lng()} ${point.lat()}`);
		});
		data.location = `POLYGON ((${locationCoords.join(', ')}))`;
		delete data.projectPath;
	}

	//set measuringStrips
	if(data.projectZones) {
		data.projectZones.forEach(zone => {
			data.measuringStrips.push(toMeasuringStrip(zone));
		})
		delete data.projectZones;
	}

	return data;
}

function cleanUpPatchFields(data, fieldsToPatch) {
	if (!fieldsToPatch) return data;
	if (data && fieldsToPatch) {
		for (const field in data) {
			if (!fieldsToPatch.includes(field)) {
				delete data[field];
			}
		}
	}
	return data;
}

function toMeasuringStrip(zone) {
	const strip = {
		title: zone.title,
		description: zone.description,
		type: zone.type,
		location: ''
	};

	const locationCoords = [];
	zone.path.forEach(point => {
		locationCoords.push(`${point.lng()} ${point.lat()}`);
	})

	switch (zone.shape) {
		case 'polyline':
			strip.location = `${Config.geoJsonGmap[zone.shape]} (${locationCoords.join(', ')})`;
			break;
		default:
			strip.location = `${Config.geoJsonGmap[zone.shape]} ((${locationCoords.join(', ')}))`;
			break;
	};

	return strip;
}

function geoToCoords(geo) {
	const type = geo.indexOf('LINESTRING') === 0 ? 'polyline' : 'polygon';

	const regExp = /\((.*)\)/;
	const matches = regExp.exec(geo);

	let coords = [];

	switch (type) {
		case 'polygon':
			coords = regExp.exec(`${matches[1]}`)[1].split(', ')
			break;
		case 'polyline':
			coords = matches[1].split(', ')
			break;
		default:
			coords = regExp.exec(`${matches[1]}`)[1].split(', ')
			break;
	}

	coords = coords.map(coord => {
		return {
			lng: Number(coord.split(' ')[0]),
			lat: Number(coord.split(' ')[1]),
		}
	});

	return coords;
}

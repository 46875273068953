import _delay from 'lodash/delay';

import vSelect from "vue-select";

export default {
	props: {
		context: {
			type: Object,
			required: true
		},
	},

	components: {
		"v-select": vSelect,
	},

	mounted() {

	},

	data() {
		return {
			selectedOption: null
		};
	},

	computed: {

	},

	methods: {
		calculateSelectPostion(dropdownList, component, {width}) {
            const pos = component.$el.getBoundingClientRect();
            const right = window.innerWidth - (pos.x + pos.width);
            const top = pos.y + pos.height + window.scrollY + 10;

            dropdownList.style.top = `${top}px`;
            dropdownList.style.left = `auto`;
            dropdownList.style.right = `${right}px`;
            dropdownList.style.width = width;
        }
	},

	watch: {

	}
};

// imports
import Config from '$root/Config';
import WindowResizeMixin from '$mixins/window-resize-mixin';

// private statics
const logKey = 'Is Mobile Mixin';

export default {
	mixins: [WindowResizeMixin],

	created() {},

	mounted() {
		this.$on('windowresize', this._is_mobile_checkIfMobile);
	},

	data() {
		this._is_mobile_checkIfMobile();

		return {
			is_mobile: false,
			is_tablet: false,
			is_mobileAt: Config.isMobileAt,
			is_tabletAt: Config.isTabletAt,
		};
	},

	beforeDestroy() {
		this.$off('windowresize', this.$blm.remove('_is_mobile_windowresize'));
	},

	methods: {
		_is_mobile_checkIfMobile() {
			this.is_mobile = window.innerWidth <= this.is_mobileAt;
			this.is_tablet = !!(window.innerWidth <= this.is_tabletAt && window.innerWidth >= this.is_mobileAt);
		},
	},
};

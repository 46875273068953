// imports
import Model from '$dependencies/Model';
import Config from '../../Config';

const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	title: { type: String, default: '' },
	state: {type: Object, default: {}},
	description: { type: String, default: '' },
	summary: { type: String, default: '' },
	locationTypes: {type: Array, default: []},
	fractions: {type: Array, default: []},
	cleaningMethods: {type: Array, default: []},
	pillars: {type: Array, default: []},
	tags: {type: Array, default: []},
	effort: { type: Object, default: {}},
	isPopular: { type: Boolean, default: false},
	hasInfluenceOnExperience: { type: Boolean, default: false},
	links: { type: Array, default: []},
	pillars: { type: Array, default: []}, 
	matchingScore : { type: Number, default: 0},
	matchingTags: {type: Array, defauly: []}
};

// class definition
export default class MeasureModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	fetch(data) {
		this.setConfig('url', `${Config.getUrl('measures')}/${this.uuid}`);

		return super.fetch(data);
	}

	parse(data) {
		if(data.data.effort) {
			data.data.effort.weight = data.data.effort.weight+=1;
		}
		return super.parse(data.data);
	}

	// getters & setters
	get active() {
		return this.uuid ? true : false;
	}

	get id() {
		return this.uuid;
	}
}

export default {
    namespaced: true,

    state() {
        return {
            selectedComparison: null,
        }
    },

    mutations: {
        SET_COMPARISON(state, payload) {
            state.selectedComparison = payload
        },
        RESET_COMPARISON(state) {
            state.selectedComparison = null
        },
        REMOVE_FROM_COMPARISON(state, payload) {
            state.selectedComparison.splice(payload, 1)
        }
    }
}
// imports
import { TimelineMax } from 'gsap';
import Config from '$root/Config';

// private static

// class definition
export default {
	props: {
		animate: {
			type: Boolean,
			default: false,
		},
	},

	mounted() {
		if (this.animate && window.innerWidth > Config.isTabletAt) {
			this.drawSidePath();
		}
	},

	methods: {
		drawSidePath() {
			const path = document.querySelector('#Path-2');
			const roadElements = document.querySelectorAll('.roadElement');
			const yellowPointers = document.querySelectorAll('.yellowPointer');
			const redPointers = document.querySelectorAll('.redPointer');
			const greenPointers = document.querySelectorAll('.greenPointer');
			const bluePointers = document.querySelectorAll('.bluePointer');
			const topPointers = document.querySelectorAll('.topPointer');

			const tl = new TimelineMax();

			const pathLength = path.getTotalLength();
			const scaleFrom = { scale: 0.7, opacity: 0, transformOrigin: '50% 50%' };
			const scaleTo = { scale: 1, opacity: 1, transformOrigin: '50% 50%' };

			tl.set(roadElements, { opacity: 0 });

			TweenMax.set(path, { strokeDasharray: pathLength });
			TweenMax.fromTo(path, 3.2, { strokeDashoffset: pathLength }, { strokeDashoffset: 0 });

			tl.staggerFromTo(yellowPointers, 0.4, scaleFrom, scaleTo, 0.25)
				.staggerFromTo(redPointers, 0.4, scaleFrom, scaleTo, 0.25)
				.staggerFromTo(greenPointers, 0.4, scaleFrom, scaleTo, 0.25)
				.staggerFromTo(bluePointers, 0.4, scaleFrom, scaleTo, 0.25)
				.staggerFromTo(topPointers, 0.4, scaleFrom, scaleTo, 0.25);
		},
	},
};

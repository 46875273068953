const IS = {
	boolean: isBoolean,
	number: isNumber,
	string: isString,
	function: isFunction,
	primitive: isPrimitive,
	array: isArray,
	object: isObject,
};

export function isBoolean(arg) {
	return typeof arg === 'boolean';
}

export function isNumber(arg) {
	return typeof arg === 'number';
}

export function isString(arg) {
	return typeof arg === 'string';
}

export function isFunction(arg) {
	return typeof arg === 'function';
}

export function isPrimitive(arg) {
	return arg === null || (typeof arg !== 'object' && typeof arg !== 'function');
}

export function isArray(arg) {
	return Array.isArray(arg);
}

export function isObject(arg) {
	return typeof arg === 'Object' && !isArray(arg);
}

export function isTypes(types, arg, operation = 'and') {
	// ensure types is array
	if (!Array.isArray(types)) types = [types];

	// run through all types it needs to check
	for (const type of types) {
		const isType = IS[type](arg);

		if (!isType && operation === 'and') return false;
		if (isType && operation === 'or') return true;
	}

	// when operation === 'and' && all types match or operation === 'or' && none match
	return operation !== 'or';
}

// imports
import Overlay from '$components/Overlay/Overlay.vue';
import RootInjectorMixin from '$mixins/RootInjectorMixin';

// class
export default {
	props: {
		root: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		Overlay,
	},

	mixins: [RootInjectorMixin],

	data() {
		return {};
	},

	mounted() {
		if (this.root) {
			this.inject_in_root(this.$refs.overlay.$refs.overlay);
		}
	},

	computed: {
		isMobile() {
			return this.$store.state.isMobile;
		},
	},

	beforeDestroy() {},

	methods: {
		show(hard = false) {
			this.$refs.overlay.show(hard);
		},

		hide(hard = false) {
			this.$refs.overlay.hide(hard);
		},

		toggle(hard = false) {
			this.$refs.overlay.toggle(hard);
		},

		_onCloseClick(e) {
			this.$refs.overlay._onCloseClick(e);
		},
	},
};

// imports
import _isString from 'lodash/isString';
import _isBoolean from 'lodash/isBoolean';
import _isObject from 'lodash/isObject';
import _merge from 'lodash/merge';
import _includes from 'lodash/includes';
import _values from 'lodash/values';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isNaN from 'lodash/isNaN';

import Vue from 'vue';
import Config from '$root/Config';
import AdvancedSelect from '$components/AdvancedSelect/AdvancedSelect.vue';
import InfoBox from '$components/InfoBox/InfoBox.vue';
import InfoIcon from '$components/InfoIcon/InfoIcon.vue';

// private static
const logKey = '';
const textInputTypes = ['text', 'search', 'password', 'email', 'tel', 'date-time', 'date-time-local', 'time', 'url'];

const checkOrRadioTypes = ['radio', 'checkbox', 'switch', 'toggle'];

const fileTypes = ['file', 'dropzone'];

const contentInputTypes = textInputTypes.concat(['textarea']);

const errorMessages = {
	empty: 'Dit veld is vereist.',
	emptyFile: 'Dit bestand is vereist.',
	number: 'De ingevulde waarde moet een getal zijn.',
	email: 'Het opgegeven e-mailadres is niet geldig.',
	phone: 'Het opgegeven telefoonnummer is niet geldig.',
	file: 'Ongeldig bestandstype.',
	notEqualTo: ' komen niet overeen',
	notEqualToValue: 'Inhoud komt niet overeen met ',
};

const errorVariants = {
	TEXT: 'text',
	BOX: 'box',
};

const FORCE = {
	text: ['address'],
	file: ['dropzone'],
	checkbox: ['switch', 'toggle'],
	select: ['selectAsButton'],
};

const titlePositionValues = ['right', 'left'];

// class definition
export default {
	props: {
		id: {
			type: String,
			default: () => `field_${Math.random().toString(32)}`,
		},

		name: {
			type: String,
			default: '',
		},

		typeModifier: {
			type: String,
			default: '',
		},

		label: {
			validator: (value) => {
				return _isString(value) || _isBoolean(value);
			},
			default: null,
		},

		showLabel: {
			type: Boolean,
			default: false,
		},

		placeholder: {
			type: String,
			default: '',
		},

		type: {
			type: String,
			default: '',
		},

		required: {
			type: Boolean,
			default: false,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		min: {
			default: null,
		},

		max: {
			default: null,
		},

		disableOnEdges: {
			type: Boolean,
			default: false,
		},

		step: {
			type: Number,
			default: 1,
		},

		modifiers: {
			type: Array,
			default: () => [],
		},

		autocomplete: {
			type: String,
			default: 'off',
		},

		trueValue: {
			default: true,
			validator: () => true,
		},

		falseValue: {
			default: false,
			validator: () => true,
		},

		trueLabel: {
			type: String,
			default: '',
			validator: () => true,
		},

		falseLabel: {
			type: String,
			default: '',
			validator: () => true,
		},

		validators: {
			type: Array,
			default: null,
		},

		options: {
			validator: () => true,
			default: null,
		},

		current: {
			validator: () => true,
			default: null,
		},

		customError: {
			type: Boolean,
			default: false,
		},

		customErrorMessage: {
			type: String,
			default: null,
		},

		errorVariant: {
			type: String,
			default: errorVariants.TEXT,
			validator: (value) => {
				return _includes(_values(errorVariants), value);
			},
		},

		description: {
			type: String,
			default: null,
		},

		infoIcon: {
			validator: (value) => {
				return _isString(value) || _isObject(value);
			},
			default: '',
		},

		infoBoxText: {
			type: String,
			default: null,
		},

		equalTo: {
			default: null,
		},

		process: {
			type: Function,
			default: null,
		},

		dropZoneLabel: {
			type: String,
			default: '',
		},

		allowedFileExtentions: {
			type: Array,
			default: () => [],
		},

		// hack for prefilled file fields!
		prevalidated: {
			type: Boolean,
			default: false,
		},

		startDate: {
			type: String,
		},

		titlePosition: {
			type: String,
			default: 'left',
			validator: (value) => _includes(titlePositionValues, value),
		},

		validAVGNestedFields: {
			type: Boolean,
			default: false,
		},

		dropdownWidth: {
			type: Number,
			default: null,
		},

		injectInRoot: {
			type: Boolean,
			default: true,
		},

		hideOnScroll: {
			type: Boolean,
			default: false,
		},

		boolSelect: {
			type: Boolean,
			default: false,
		},

		// v-model binding
		value: {},
	},

	components: {
		AdvancedSelect,
		InfoBox,
		InfoIcon,
	},

	data() {
		return {
			hasFocus: false,
			hasAutoFill: false,
			isNotValid: false,
			errorMessage: null,
			currentValue: this.value,
			isValid: true,
			currentDate: this.value ? this.value : '',
			dateFormat: 'x',
			datePickerOpened: false,
			inputFired: false, // checkbox safari hack
			errorVariants,
		};
	},

	mounted() {
		if (this.isContentInput) {
			this.$refs.field.addEventListener('focusin', this.$blm.add('field.onFocusIn', this, onFocusIn));
			this.$refs.field.addEventListener('focusout', this.$blm.add('field.onFocusIn', this, onFocusOut));
			this.$refs.field.addEventListener('keyup', this.$blm.add('field.onFocusIn', this, onKeyUp));
			this.$refs.field.addEventListener('change', this.$blm.add('field.onFocusIn', this, onKeyUp));
			this.$refs.field.addEventListener('animationstart', this.$blm.add('field.animationStart', this, onAutoFill));

			// force hasContnet check
			this.hasContent;

			// force tick
			this.$nextTick(() => this.$forceUpdate());
		}

		this.$emit('mounted');
	},

	computed: {
		hasContent: {
			cache: false,
			get() {
				return Object.keys(this.$refs).length ? !!this.$refs.field.value : false;
			},
		},
		isTextInput() {
			return textInputTypes.indexOf(this.fieldType) > -1;
		},
		isFileInput() {
			return fileTypes.indexOf(this.fieldType) > -1;
		},
		isContentInput() {
			return contentInputTypes.indexOf(this.fieldType) > -1;
		},
		isCheckOrRadio() {
			return checkOrRadioTypes.indexOf(this.fieldType) > -1;
		},
		isDateNoOverlayInput() {
			return this.fieldType === 'dateNoOverlay'
		},
		isNumberInput() {
			return this.fieldType === 'number';
		},
		isEditableNumberInput() {
			return this.fieldType === 'editableNumber';
		},
		isSelect() {
			return this.fieldType === 'select';
		},
		isTextArea() {
			return this.fieldType === 'textarea';
		},
		isSwitch() {
			return this.type === 'switch';
		},
		isAVSelect() {
			return this.fieldType === 'advancedSelect';
		},
		isToggle() {
			return this.type === 'toggle';
		},
		fieldId() {
			return this.id || this.name || '';
		},
		fieldName() {
			return this.name || this.id || '';
		},
		fieldValue() {
			return this.value || (this.itemType == 'checkbox' ? false : '');
		},
		indeterminateValue() {
			return _isNaN(this.value);
		},
		itemType() {
			return this.isTextInput ? 'text' : this.fieldType;
		},
		fieldType() {
			return typeForce(this.type);
		},
		infoIconTooltip() {
			const customConfig = _isString(this.infoIcon) ? { content: this.infoIcon } : this.infoIcon;

			return _merge(
				{
					content: '',
					placement: 'right',
					classes: ['light'],
				},
				customConfig,
			);
		},
	},
	methods: {
		onInput($e) {
			this.inputFired = true;

			// different treatment if check or radio
			let value;
			switch (this.type) {
				case 'checkbox':
					value = $e.target.checked;
					break;
				case 'switch':
					value = $e.target.checked;
					break;
				case 'toggle':
					value = $e.target.checked;
					break;
				case 'radio':
					value = $e.target.value;
					break;
				case 'advancedSelect':
					value = $e;
					break;
				case 'editableNumber':
					value = this.getEditableNumber($e.target.value);
					break;
				default:
					value = $e.target.value;
			}

			this.$emit('input', value);
			this.currentValue = value;
		},

		getEditableNumber(value) {
			let currentValue = this.value;

			if (/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(value)) {
				currentValue = value;
			}

			this.$refs.field.value = currentValue;
			return currentValue;
		},

		onKeyEnter(event) {
			this.$emit('onKeyEnter', event);
		},

		onKeyDown(event) {
			this.$emit('onKeyDown', event);
		},

		onKeyUp(event) {
			this.$emit('onKeyUp', event);
		},

		onFocus(event) {
			this.$emit('onFocus', event);
		},

		onBlur(event) {
			this.$emit('onBlur', event);
		},
		onCheckChange($e) {
			// safari hack... handles check state changes differently...
			if (this.isCheckOrRadio && !this.inputFired) {
				this.currentValue = $e.currentTarget.checked;
				this.$emit('input', this.currentValue);
			}
			this.validate();
		},
		isAVGSelectValid() {
			let hasOptions = false;

			if (this.currentValue) {
				if (_isArray(this.currentValue.options) && this.currentValue.options.length) {
					hasOptions = true;
				} else if (_get(this, 'currentValue._options.values.options.length', 0)) {
					hasOptions = true;
				}
			}

			return this.required && this.isAVSelect && (!this.currentValue || hasOptions);
		},
		validate() {
			// clear error message
			this.errorMessage = null;

			// set valid immediately if prevalidated
			if (this.prevalidated) {
				this.isValid = true;
				return true;
			}

			if (this.validAVGNestedFields && this.isAVGSelectValid()) {
				this.errorMessage = errorMessages.empty;
			}

			// if required, alwasy check for a value
			if (this.required && !this.currentValue && this.fieldType !== 'file') {
				this.errorMessage = errorMessages.empty;
			}

			if (this.required && this.fieldType === 'file' && !this.$refs.field.files.length) {
				this.errorMessage = errorMessages.emptyFile;
			}

			if (
				typeof this.currentValue === 'string' &&
				this.currentValue.length &&
				this.type === 'email' &&
				!this.errorMessage &&
				!Config.regex.email.test(this.currentValue)
			) {
				this.errorMessage = errorMessages.email;
			}

			if (this.type === 'tel' && this.currentValue.length && !this.errorMessage && !Config.regex.phone.test(this.currentValue)) {
				this.errorMessage = errorMessages.phone;
			}

			if (isNaN(this.currentValue) && this.type === 'number' && !this.errorMessage && !isNaN(this.currentValue)) {
				this.errorMessage = errorMessages.number;
			}

			if (this.customError) {
				this.errorMessage = this.customError;
			}

			if (this.equalTo !== null && !this.errorMessage) {
				if (this.equalTo instanceof Vue) {
					if (this.equalTo.constructor === this.constructor) {
						if (this.equalTo.currentValue !== this.currentValue) {
							this.errorMessage = `${this.equalTo.label}${errorMessages.notEqualTo}`;
						}
					}
				} else if (this.equalTo !== this.currentValue) {
					this.errorMessage = errorMessages.notEqualToValue + this.equalTo;
				}
			}

			// return values
			this.isValid = !this.errorMessage;
			return this.isValid;
		},
		fixDateForAllBrowsers(dateString) {
			return dateString.replace(/-/g, '/');
		},
		// formatDate (val) {
		// 	let date = false;
		// 	date = parseInt(format(val, this.dateFormat))
		// 	return date;
		// },
		onDropzoneDrop(e) {
			this.$emit('input', processFiles.call(this, e.detail.files));
		},
		onDropzoneClick() {
			this.$refs.field.click();
		},
		onFileUploadFieldChange(e) {
			if (
				this.allowedFileExtentions &&
				this.allowedFileExtentions.length &&
				this.allowedFileExtentions.indexOf(e.currentTarget.files[0].name.split('.').pop()) === -1
			) {
				this.isValid = false;
				this.errorMessage = errorMessages.file.replace('{{ types }}', this.allowedFileExtentions.join(', '));
			} else {
				this.errorMessage = null;
				this.isValid = true;
				this.$emit('input', processFiles.call(this, e.currentTarget.files));
			}
		},
		setDropZoneBg(url) {
			if (this.type === 'dropzone') {
				this.$refs.dropzone.style.backgroundImage = `url(${url})`;
			}
		},
		onDatePickerSelected(value) {
			this.currentValue = value;

			if (value) this.datePickerOpened = true;

			if (this.datePickerOpened && this.validate()) {
				this.$emit('input', value);
			} else {
				this.currentValue = null;
			}
		},
		onNumberPlus() {
			const plusOffset = this.currentValue % Number(this.step);
			const plus = Number(this.step) - plusOffset;

			const newValue = Number(this.currentValue || this.min || this.value || 0) + plus;

			if (newValue <= (this.max || Infinity)) {
				this.$refs.field.value = newValue;
				this.onInput({
					target: this.$refs.field,
				});
			}
		},
		onNumberMin() {
			const minusOffset = this.currentValue % Number(this.step);
			const minus = minusOffset > 0 ? minusOffset : Number(this.step);

			const newValue = Number(this.currentValue || this.min || this.value || 0) - minus;

			if (newValue >= (this.min || 0)) {
				this.$refs.field.value = newValue;
				this.onInput({
					target: this.$refs.field,
				});
			}
		},
	},

	beforeDestroy() {
		// remove listeners
		if (this.isContentInput) {
			this.$refs.field.removeEventListener('focusin', this.$blm.remove('field.onFocusIn'));
			this.$refs.field.removeEventListener('focusout', this.$blm.remove('field.onFocusIn'));
			this.$refs.field.removeEventListener('keyup', this.$blm.remove('field.onFocusIn'));
			this.$refs.field.removeEventListener('change', this.$blm.remove('field.onFocusIn'));
			this.$refs.field.removeEventListener('animationstart', this.$blm.remove('field.animationStart'));
		}
	},

	watch: {
		value() {
			// necessary for when value changes externally
			if (this.value !== this.currentValue) {
				this.currentValue = this.value;
			}
		},
		isValid() {
			this.$emit('onValidateChange');
		},
	},
};

// handlers
function onFocusIn() {
	this.hasFocus = true;
}

function onFocusOut() {
	this.hasFocus = false;

	if (this.type !== 'date' || this.datePickerOpened) this.validate();
}

function onKeyUp() {
	// this.$forceUpdate()
}

function onAutoFill() {
	this.hasAutoFill = true;
}

// utils
function typeForce(type) {
	for (const t in FORCE) {
		if (FORCE[t].indexOf(type) > -1) return t;
	}
	return type;
}

function processFiles(files) {
	if (files.length > 1) console.warn('Only 1 file can be processed, new logic is required. First file of list will be used.');

	if (files.length > 0) {
		let file = files[0];

		// for (let file of files) {
		// do validation

		// dispatch event per file
		if (this.process) file = this.process(file);
		// }

		return file;
	}

	return null;
}

// imports
import Model from '$dependencies/Model';
import JsonApiCollection from '$dependencies/JsonApiCollection';

import IncidentModel from '$models/IncidentModel';

// private static properties

// class definition
export default class IncidentCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to allow modifications
		args = Object.assign({}, args);
		args.Model = args.Model || IncidentModel;

		super(args);
		this.showAll = false;
	}

	// methods
	set(model) {
		// super set
		super.set(model);

		if (model instanceof this.Model) {
			model.setConfig('headers', this._config.headers);
		}
	}

	// Sync header config with children
	setConfig(prop, value) {
		super.setConfig(prop, value);

		// update child headers
		if (prop === 'headers' && this._models) {
			for (const model of this._models) {
				model.setConfig('headers', value);
			}
		}
		return this;
	}

	unsetConfig(prop, value) {
		super.unsetConfig(prop, value);

		// update child headers
		if (prop === 'headers' && this._models) {
			for (const model of this._models) {
				model.unsetConfig('headers', value);
			}
		}
		return this;
	}

	resetConfig() {
		super.resetConfig();

		// update child headers
		if (this._models) {
			for (const model of this._models) {
				model.resetConfig('headers');
			}
		}
		return this;
	}

	bulkSet(model) {
		// convert to object to avoid any mistakes
		if (model instanceof Model) model = model.toObject(true);

		// loop over all & apply values
		this.each((_, item) => {
			item.set(model);
		});

		return this;
	}

	destroy() {}

	// utility methods

	// getters & setters
	get checkedOnly() {
		return this.where((model) => model.checked);
	}

	get hasChecked() {
		return this.findWhere((model) => model.checked);
	}

	get numChecked() {
		const { checkedOnly } = this;
		return checkedOnly ? checkedOnly.lenght : 0;
	}

	get allChecked() {
		if (this.length) {
			return this.length === (this.checkedOnly ? this.checkedOnly.length : 0);
		}
		return false;
	}

	set allChecked(value) {
		this.each((_, model) => (model.checked = value));
	}
}

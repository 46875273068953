import Header from '$components/Header/Header.vue';
import Footer from '$components/Footer/Footer.vue';
import GlobalMessageBox from '$components/GlobalMessageBox/GlobalMessageBox.vue';
import BrowserNotice from '$components/BrowserNotice/BrowserNotice.vue';
import TopBar from '../TopBar/TopBar.vue';
import windowResizeMixin from '$mixins/window-resize-mixin';
import GdprTermsOverlay from '$components/GdprTermsOverlay/GdprTermsOverlay.vue';
import GdprTermsDeniedOverlay from '$components/GdprTermsDeniedOverlay/GdprTermsDeniedOverlay.vue';


export default {
	props: {
		headerCfg: {
			type: Object,
			required: true,
		},

		needsAuthentication: {
			type: Boolean,
			required: false,
			default: false,
		},

		hasHeader: {
			type: Boolean,
			default: true,
		},

		hasFooter: {
			type: Boolean,
			default: true,
		},

		largeFooter: {
			type: Boolean,
			default: true,
		},

		overflowContent: {
			type: Boolean,
			default: false,
		},

		logoModifiers: {
			type: Array,
			default: () => ['mooieStraat', 'landscape', 'coloredBg'],
		},

		showBrowserNotice: {
			type: Boolean,
			default: true
		}
	},

	components: {
		Header,
		Footer,
		GlobalMessageBox,
		TopBar,
		BrowserNotice,
		GdprTermsOverlay,
		GdprTermsDeniedOverlay,
	},

	mixins: [windowResizeMixin],

	mounted() {
		//show browser notice if not chrome and not shown before
		if(this.showBrowserNotice && !this.$browserDetect.isChrome && this.$store.state.showBrowserNotice && !this.$store.state.browserNoticeShown) {
			this.$refs.browserNotice.show();
		}

		if (this.needsAuthentication) {
			// perform authentication check
			this.$store
				.dispatch('session/checkAuthentication')
				.then((authenticating) => {
					// if not authenticating => no session to check => force login
					if (!authenticating && !this.$store.state.session.authenticated) {
						this.$store.state.targetPageAfterLogin = document.location.href.replace(document.location.origin, '');
						forceLogin.call(this, 'U moet eerst aanmelden om toegang te krijgen tot deze pagina.');
					}
				})
				.catch(() => {
					this.$store.state.targetPageAfterLogin = document.location.href.replace(document.location.origin, '');
					forceLogin.call(this, 'Er liep iets mis met uw sessie, probeer nog eens in te loggen.');
				});
		}

		window.setTimeout(() => {
			this.$forceUpdate();
		}, 1000);

		// force scrolltop
		window.scrollTo(0, 0);
	},

	data() {
		return {
			session: this.$store.state.session,
			headerKey: 0,
		};
	},

	computed: {
		heightWrapperStyle: {
			cache: false,
			get() {
				let height = window.innerHeight;

				if (this.$refs.header) {
					height -= this.$refs.header.$el.clientHeight;
				}

				if (this.$refs.footer) {
					height -= this.$refs.footer.$el.clientHeight;
				}

				return {
					display: 'flex',
					minHeight: `${height}px`,
				};
			},
		},

		gdprApproved: {
			get() {
				return this.$store.state.session.gdprUser.acceptProTermsOfUse;
			}
		},

		authenticated() {
			return this.session.authenticated;
		},
	},
	
	methods: {
		onWindowResize() {
			this.$forceUpdate();
		},

		updateHeader() {
			this.headerKey++;
		},

		showDenyTerms() {
			this.$refs.denyOverlay.show();
		},

		onShowTerms() {
			this.$refs.termsOverlay.show();
		},

		approveGdpr() {
			this.session.gdprUser.acceptProTermsOfUse = !this.session.gdprUser.acceptProTermsOfUse
			this.$store.dispatch('session/approveGdprTerms');
		},

		onDenyTerms() {
			this.$store.dispatch('session/logOut');
			window.location = '/';
		}

	},
};

function forceLogin(message) {
	this.$store.targetPathAfterLogin = this.$router.getLastEntry();
	this.$store.state.errorForLogin = message;
	this.$router.push('/');
}

import _map from 'lodash/map';

// plugin definition
const GlobalMessagePlugin = {
	install(Vue, options) {
		Vue.mixin({
			beforeDestroy() {
				const [message, showLoader, sticky] = _map(
					['message', 'showLoader', 'sticky'],
					(key) => this.$store.getters[`globalMessage/${key}`]
				);

				if (message && !showLoader && !sticky) {
					this.$store.commit('globalMessage/CLEAR');
				}
			},
		});
	},
};

export default GlobalMessagePlugin;

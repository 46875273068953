const vAutoGrow = {
	directive: {
		inserted(el) {
			updateContentHeight(el);
		},

		bind(el) {
			el.addEventListener('keyup', onKeyUp);
			updateContentHeight(el);
		},

		componentUpdated(el) {
			updateContentHeight(el);
		},

		unbind(el) {
			el.removeEventListener('keyup', onKeyUp);
		},
	},

	plugin: {
		install(Vue) {
			Vue.directive('auto-grow', vAutoGrow.directive);
		},
	},
};

function onKeyUp(e) {
	updateContentHeight(e.currentTarget);
}

function updateContentHeight(el) {
	if (el.scrollHeight) {
		el.scrollTop = 0;
		el.style.height = '0px';
		el.style.height = `${el.scrollHeight > 100 ? el.scrollHeight : 100}px`;
	}
}

export default vAutoGrow;

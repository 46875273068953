import Model from '$dependencies/Model';

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	coordinates: { type: Object, default: () => {} },
	name: { type: String, default: '' },
	statusId: { type: Number, default: -1 },
	postCode: { type: String, default: '' },
	parentModel: { type: Object, default: () => {} },
};

export default class IncidentMarkerModel extends Model {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.fields = FIELDS;
		super(args);
	}

	destroy() {}
}

import Model from '$dependencies/Model';

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	name: { type: String, default: '' },
	coordinates: { type: Object, default: () => {} },
	measurements: { type: Object, default: () => [] },
	in_analyse: { type: Boolean, default: false },
	parentModel: { type: Object, default: () => {} },
	field_bin_measurement_empty_c: { type: Number, default: 0 },
	field_bin_measurement_half_c: { type: Number, default: 0 },
	field_bin_measurement_full_c: { type: Number, default: 0 },
	field_bin_measurment_overfill_c: { type: Number, default: 0 },
	field_bin_measurement_dump_c: { type: Number, default: 0 },
	field_bin_measurement_addition_c: { type: Number, default: 0 },
	field_bin_required_action: { type: String, default: 0 },
	title: { type: String, default: '' },
	uuid: { type: String, default: '' },
	active: { type: Boolean, default: '' },
	created: { type: String, default: '' },
	field_bin_color: { type: String, default: '' },
	field_bin_material: { type: String, default: '' },
	field_bin_id: { type: String, default: '' },
	field_bin_g_id: { type: String, default: '' },
	field_bin_status: { type: String, default: '' },
	field_bin_post_code: { type: String, default: '' },
	field_bin_volume: { type: String, default: '' },
	field_bin_city: { type: String, default: '' },
	field_bin_street: { type: String, default: '' },
	field_bin_remark: { type: String, default: '' },
	field_bin_last_measurement: { type: String, default: '' },
	field_bin_location: { type: String, default: '' },
	field_bin_damage: { type: String, default: '' },
	field_bin_type: { type: String, default: '' },
	field_bin_type_uuid: {type: String, default: ''},
	field_bin_type_parent_name: {type: String, default: ''},
	field_bin_type_parent_uuid: {type: String, default: ''},
	field_bin_measurement_count: { type: String, default: '' },
	field_bin_surface: { type: String, default: '' },
	field_bin_emptying_frequency: { type: String, default: '' },
	field_bin_lid_size: { type: String, default: '' },
	field_bin_qr: { type: String, default: '' },
	field_bin_remarks: { type: Object, default: () => { } },
	field_bin_incident_count: { type: Number, default: 0 },
	field_bin_last_incident_date: { type: String, default: '' },
};

export default class BinAnalyseModel extends Model {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.fields = FIELDS;
		super(args);
	}

	destroy() {}

	get fullAddress() {
		let full = '';

		if (this.field_bin_street) full += this.field_bin_street;
		if (this.field_bin_street && (this.field_bin_post_code || this.city)) full += ', ';
		if (this.field_bin_post_code) full += this.field_bin_post_code;
		if (this.field_bin_city) full += ` ${this.field_bin_city}`;

		return full;
	}
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box-filter" }, [
    _c(
      "div",
      { staticClass: "box-filter__options" },
      _vm._l(_vm.context.options, function(option) {
        return _c(
          "div",
          {
            key: option.value,
            class:
              "button button--option " +
              (_vm.selected.indexOf(option.value) > -1 ? "button--active" : ""),
            on: {
              click: function($event) {
                return _vm.onOptionClick(option.value)
              }
            }
          },
          [
            _vm.colors
              ? _c(
                  "span",
                  {
                    class:
                      "dataSet-color dataSet-color--" +
                      option.label.toLowerCase()
                  },
                  [_vm._v(_vm._s(option.label))]
                )
              : _vm.icons
              ? _c("div", { staticClass: "box-filter__option-wrapper" }, [
                  _c("img", {
                    staticClass: "box-filter__option-image",
                    attrs: {
                      src: "/images/icons/" + _vm.icons[option.machineName]
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "box-filter__image-label" }, [
                    _vm._v(_vm._s(option.label))
                  ])
                ])
              : _c("span", [_vm._v(_vm._s(option.machineName))])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
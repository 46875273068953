var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BasePage", {
    staticClass: "basePage--login",
    attrs: { "header-cfg": _vm.headerCfg, hasFooter: false, hasHeader: false },
    scopedSlots: _vm._u([
      {
        key: "withoutWrapper",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "mainContent mainContent--login login-wrapper" },
              [
                _c("LoginSideImage", { attrs: { animate: true } }),
                _vm._v(" "),
                _c("div", { staticClass: "login-form-wrapper" }, [
                  _c(
                    "h1",
                    { staticClass: "mainHeader-siteName" },
                    [
                      _c(
                        "Logo",
                        {
                          staticClass:
                            "mainHeader-siteNameLink login-form__logo",
                          attrs: {
                            modifiers: ["mooieStraat", "landscape", "whiteBg"],
                            href: "/",
                            title: "Mijn Mooie Straat"
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\tMijn Mooie Straat\n\t\t\t\t\t")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      staticClass: "loginForm",
                      attrs: { action: "", method: "POST" }
                    },
                    [
                      _c("h2", { staticClass: "loginForm-title" }, [
                        _vm._v(
                          "Het alles-in-één platform voor lokale besturen in de strijd tegen zwerfvuil & sluikstort"
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "loginForm-instruction" }, [
                        _vm._v(
                          "Vul je e-mailadres of gebruikersnaam en wachtwoord in om aan te melden."
                        )
                      ]),
                      _vm._v(" "),
                      _c("FormItem", {
                        attrs: {
                          id: "txtUsername",
                          type: "text",
                          label: "E-mailadres of Gebruikersnaam",
                          errorVariant: "box",
                          modifiers: ["fullWidth", "noGutters"]
                        },
                        model: {
                          value: _vm.username,
                          callback: function($$v) {
                            _vm.username = $$v
                          },
                          expression: "username"
                        }
                      }),
                      _vm._v(" "),
                      _c("FormItem", {
                        attrs: {
                          id: "txtPassword",
                          type: "password",
                          label: "Wachtwoord",
                          errorVariant: "box",
                          modifiers: ["fullWidth", "noGutters"]
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _vm._v(" "),
                      _c("FormItem", {
                        attrs: {
                          type: "checkbox",
                          id: "check-all",
                          name: "check-all",
                          label: "Ik wil aangemeld blijven.",
                          modifiers: ["noGutters"],
                          value: _vm.rememberLoggedIn
                        },
                        on: { input: _vm.onCheckbox }
                      }),
                      _vm._v(" "),
                      _c("MessageBox", {
                        ref: "loginErrorMsg",
                        staticClass: "loginForm-errorMessage",
                        attrs: { type: "error", message: _vm.errorMessage }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-actions" }, [
                        !_vm.submitted
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "button button--big button--filled login-submit",
                                attrs: { type: "submit", name: "btnLogin" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onLoginClick.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v("Aanmelden")]
                            )
                          : _c(
                              "button",
                              {
                                staticClass:
                                  "button button--filled button--big button--loading login-submit",
                                attrs: { type: "button", name: "btnLogin" }
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\tAanmelden\n\t\t\t\t\t\t\t"
                                ),
                                _c("span", { staticClass: "loader" })
                              ]
                            )
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "loginForm-serviceLink",
                          attrs: {
                            to: "/password/reset",
                            title: "Wachtwoord vergeten?"
                          }
                        },
                        [_vm._v("Wachtwoord vergeten?")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "login-footer" }, [
                    _c("p", { staticClass: "login-footer__app" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t© Mijn Mooie Straat " +
                          _vm._s(new Date().getFullYear()) +
                          "\n\t\t\t\t\t\t"
                      ),
                      _c("i", { staticClass: "version" }, [
                        _vm._v(_vm._s(_vm.appVersion))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "footerLink footerLink--disclaimer mainFooter-link login-footer__link",
                        attrs: {
                          href:
                            "https://mijnmooiestraat.be/algemene-voorwaarden.php"
                        }
                      },
                      [_vm._v("Disclaimer")]
                    ),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "login-footer__logo",
                      attrs: {
                        alt: "logo",
                        src: _vm.getImage("logo-patch-white-new.svg", "logos")
                      }
                    })
                  ])
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// imports
import JsonApiCollection from '$dependencies/JsonApiCollection';

import PostcodeModel from '$models/PostcodeModel';

// private static properties

// class definition
export default class PostcodeCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || PostcodeModel;
		args.entityType = 'node--post_code';

		// call super constructor
		super(args);
	}

	// methods
	getByCode(code) {
		return this.findWhere((item) => item.code == code);
	}

	destroy() {}

	// utility methods

	// getters & setters
	get codes() {
		return this.listProperty('code');
	}
}

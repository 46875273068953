// imports
import getAssetPath from '$utils/getAssetPath';
// component
export default {

    props: {
        name: {
            type:    String,
            default: '',
        },
        path: {
            type: String,
            default: ''
        },
        modifiers: {
            type: Array,
            default: () => []
        },
        loading: {
            default: false
        }
    },

    components: {

    },

    mixins: [

    ],

    mounted () {

    },

    data () {
        return {

        };
    },

    computed: {

    },

    methods: {
		getAssetPath,
    },

    watch: {

    },
};

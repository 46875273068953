// imports
import _get from 'lodash/get';
import Config from '$root/Config';
import MeasuringPeriodModel from '../../models/bin/MeasuringPeriodModel';
import MinskyCollection from '../MinskyCollection';

// class definition
export default class MeasuringPeriodCollection extends MinskyCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || MeasuringPeriodModel;
		
		// call super constructor
		super(args);

		this.projectId = null;

	}

	fetch(preventClear) {
		this.setConfig('url', `${Config.getUrl('measuringPeriods')}`);
		return super.fetch(preventClear);
	}

	parse(data) {
		data.data.map(item => {
			const newModel = new this.Model().parse({data: item});
			return newModel;
		});

		return super.parse(data);
	}
}

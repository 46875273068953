import PageTransition from '../../transitions/Page/Page.vue';

export default {
	props: {},

	mounted() {},

	data() {
		return {};
	},

	components: {
		PageTransition,
	},

	computed: {},

	methods: {
		showTopLoader() {
			this.$refs.topLoader.open();
		},

		hideTopLoader() {
			this.$refs.topLoader.close();
		},

		onAuthRequired() {
			this.$router.push('/');
		},
	},

	watch: {},
};

import _omit from 'lodash/omit';
import _map from 'lodash/map';

import JsonApiCollection from '$dependencies/JsonApiCollection';
import UserModel from '$models/UserModel';
import Config from '$root/Config';

export default class UsersCollection extends JsonApiCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || UserModel;

		super(args);

		this.currentUrl = Config.getUrl('users');
	}

	parse(data) {
		return super.parse({
			data: _map(data.data.users, (user) => ({
				id: user.id,
				attributes: _omit(user, 'id'),
			})),
		});
	}

	fetch() {
		const communityID = localStorage.getItem('communityID') || window.vueStore.state.session.user.community.id;
		this.setConfig('url', `${this.currentUrl}/${communityID}`);

		return super.fetch();
	}
}

import Config from '$root/Config';
// filter object definition
export default {
	install(Vue) {
		if (Vue.filter('volume')) {
			console.warn('[filter duplication]: A filter named volume has already been installed.');
		} else {
			Vue.filter('volume', function(value = 0, maxValue) {
				const volume = Math.ceil(Config.factors.literToGarbageBags * value);
				return maxValue && volume > maxValue ? `${maxValue}+` : volume;
			});
		}
	},
};

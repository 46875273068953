import Config from '$root/Config';
import { generateID } from '$utils/Utils';

const LibMGR = {
	libs: {},

	add(libraries) {
		Object.assign(this.libs, libraries);
	},

	remove(key) {
		this.libs[key] = null;
	},

	libIsLoaded(key) {
		return this.libs[key].loaded === true;
	},

	load(key) {
		// get lib info
		const lib = this.libs[key];
		const parameters = [];

		// ignore if loaded
		if (lib.loaded)
			return new Promise((resolve) => {
				resolve();
			});

		// make it wait if loading
		if (lib.loading)
			return new Promise((resolve, reject) => {
				lib.promise.then(() => resolve());
			});

		lib.loading = true;

		// generate url
		if (lib.parameters) {
			for (const p in lib.parameters) {
				parameters.push(toQueryPiece(p, lib.parameters[p]));
			}
		}

		// pass callback if required
		let callbackId = null;
		if (lib.passCallback) {
			callbackId = `libMgrCallback_${generateID(1, '_')}`;

			// prep jsonp callback
			parameters.push(toQueryPiece(lib.callbackParam, callbackId));
		}

		// generate url and tag
		const url = `${lib.url}?${parameters.join('&')}`;
		const tag = document.createElement('script');

		tag.setAttribute('data-libmgr-key', key);
		tag.src = url;

		// add callback if not passed to url
		lib.promise = new Promise((resolve, reject) => {
			if (callbackId) {
				window[callbackId] = () => {
					resolve();
				};
			} else {
				tag.addEventListener('load', (e) => {
					lib.loaded = true;
					lib.loading = false;
					if (!lib.passCallback && lib.callback) lib.callback(lib);
					resolve();
				});
			}
		});

		// append to dom to trigger load
		// load dependencies first if needed
		if (lib.dependencies) {
			loadDependencies(lib.dependencies).then(() => {
				document.body.appendChild(tag);
			});
		} else {
			document.body.appendChild(tag);
		}

		return lib.promise;
	},
};

function toQueryPiece(prop, value) {
	return `${prop}=${encodeURI(value)}`;
}

function prepJsonPCallback(callback) {
	const callbackId = `libMgrCallback_${generateID(1, '_')}`;
	window[callbackId] = () => callback();
	return callbackId;
}

function loadDependencies(dependencies) {
	return new Promise((resolve, reject) => {
		if (dependencies.length) {
			LibMGR.load(dependencies.shift()).then(() => {
				loadDependencies(dependencies).then(() => resolve());
			});
		} else {
			resolve();
		}
	});
}

LibMGR.add(Config.libs);

window.mgr = LibMGR;
export default LibMGR;

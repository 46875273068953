// imports
import Config from '$root/Config';

import JsonApiModel from '$dependencies/JsonApiModel';
import UserModel from '$models/UserModel';
import IncidentModel from '$models/IncidentModel';

// private static properties
const API_DICTIONARY = {
	uuid: 'id',
	uid: 'user',
	field_remark_content: 'content',
	field_remark_public: 'public',
	field_remark_incident: 'incident',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	title: { type: String, default: () => Math.round(Math.random() * 10000).toString() },
	created: { type: Date, default: () => new Date() },
	content: { type: String, default: '' },
	public: { type: Boolean, default: false },
	user: { type: Object, default: () => new UserModel(), referenceType: 'user--user' },
	incident: { type: Object, default: () => new IncidentModel(), referenceType: 'node--incident' },
};

// class definition
export default class RemarkModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.entityType = 'remark--remark';
		args.fields = FIELDS;

		// set url
		args.url = Config.getUrl('remark');

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);
	}

	// methods
	post(data) {
		data = data || this.toObject(true);
		if (data.created != undefined) delete data.created;
		return super.post(data);
	}

	patch(data) {
		data = data || this.toObject(true);

		if (data.created) delete data.created;

		this.setConfig('url', `${Config.getUrl('remark')}/${this.id}`);
		return super.patch(data);
	}

	delete() {
		this.setConfig('url', `${Config.getUrl('remark')}/${this.id}`);
		return super.delete();
	}

	parse(data) {
		if (!data) return;
		// parse description field
		data = super.parse(data);

		// flatten description field
		data.content = data.content ? data.content.value.trim() : '';

		return data;
	}

	destroy() {}

	// utility methods

	// getters & setters
}

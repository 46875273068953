// imports
import _forEach from 'lodash/forEach';
import _pick from 'lodash/pick';
import Config from '$root/Config';

import TaxonomyCollection from '$collections/TaxonomyCollection';
import MeasureCollection from '../../collections/monitoring/MeasureCollection';
import BinPointCollection from '../../collections/monitoring/BinPointCollection';
import MeasuringStripCollection from '../../collections/monitoring/MeasuringStripCollection';
import ProjectCollection from '../../collections/monitoring/ProjectCollection';
import ProjectMapCollection from '../../collections/monitoring/ProjectMapCollection';
import ProjectExperienceScanModel from '../../models/monitoring/ProjectExperienceScanModel';

export default {
	namespaced: true,
	state() {
		return {
			projects: new ProjectCollection({
				config: { url: Config.getUrl('projects') },
			}),
			monitorParties: new TaxonomyCollection({
				type: 'monitorParties'
			}),
			countingMethods: new TaxonomyCollection({
				type: 'countingMethods'
			}),
			cleaningMethods: new TaxonomyCollection({
				type:  'cleaningMethods'
			}),
			cleaningFrequencies: new TaxonomyCollection({
				type: 'cleaningFrequencies'
			}),
			monitorFractions: new TaxonomyCollection({
				type: 'monitorFractions'
			}),
			stripTypes: new TaxonomyCollection({
				type: 'stripTypes'
			}),
			monitorLocationTypes: new TaxonomyCollection({
				type: 'monitorLocationTypes'
			}),
			projectStatuses: new TaxonomyCollection({
				type: 'projectStatuses'
			}),
			projectMeasurementTypes: new TaxonomyCollection({
				type: 'projectMeasurementTypes'
			}),
			measurePillars: new TaxonomyCollection({
				type: 'measurePillars'
			}),
			measureEfforts: new TaxonomyCollection({
				type: 'measureEfforts'
			}),
			projectImpacts: new TaxonomyCollection({
				type: 'projectImpacts'
			}),
			countingUnits: new TaxonomyCollection({
				type: 'countingUnits'
			}),
			measures: new MeasureCollection(),
			binPoints: new BinPointCollection(),
			measuringStrips: new MeasuringStripCollection(),
			mapProjects: new ProjectMapCollection(),
			experienceScanDraft: new ProjectExperienceScanModel(),
		};
	},

	mutations: {
		UPDATE_AUTH(state, payload) {
			const authorizationToken = { Authorization: payload.jwt_authorization };
			const stateValuesToUpdateJWT = _pick(state, [
				'monitorParties',
				'monitorLocationTypes',
				'countingMethods',
				'monitorFractions',
				'stripTypes',
				'cleaningFrequencies',
				'cleaningMethods',
				'projectStatuses',
				'projectMeasurementTypes'
			]);

			// update jwt
			_forEach(state, (item) => {
				item.setConfig('headers', authorizationToken);
			});
		},

		ON_LOGOUT(state) {
			_forEach(state, (item) => {
				item.unsetConfig('headers').clear();
			});
		},

		updateExperienceScanDraft(state, payload) {
			state.experienceScanDraft = payload
		},
	},
};

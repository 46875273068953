import BasePage from '$components/BasePage/BasePage.vue';
import FormItem from '$components/FormItem/FormItem.vue';
import MessageBox from '$components/MessageBox/MessageBox.vue';
import Logo from '$components/Logo/Logo.vue';
import LoginSideImage from '$components/LoginSideImage/LoginSideImage.vue';

import getAssetPath from '$utils/getAssetPath';
import Config from '../../Config';

export default {
	components: {
		BasePage,
		FormItem,
		MessageBox,
		Logo,
		LoginSideImage,
	},

	data() {
		const appVersion = Config.version;

		return {
			headerCfg: {
				showLogo: false,
				showHelp: true,
				showHamburger: false,
			},
			rememberLoggedIn: false,
			username: '',
			password: '',
			errorMessage: '',
			submitted: false,
			appVersion: appVersion ? `v${appVersion}` : '',

			// store shortcuts
			session: this.$store.state.session,
		};
	},

	mounted() {
		this.session.model.on('response', this.$blm.add('login.onResponse', this, onResponse));
		this.session.model.on('error', this.$blm.add('login.onError', this, onError));
		this.$store.commit('HIDE_PAGE_LOADER');
	},

	methods: {
		onLoginClick() {
			this.$store.commit('SHOW_PAGE_LOADER');
			this.submitted = true;
			this.$store.dispatch('session/logIn', {
				user: this.username,
				password: this.password,
				rememberLoggedIn: this.rememberLoggedIn,
				clientId: Config.app_client_id,
				clientSecret: Config.app_client_secret,
			});
		},

		getImage(value, modifier) {
			return getAssetPath(value, modifier);
		},

		onCheckbox(value) {
			this.rememberLoggedIn = value;
		},
	},

	beforeDestroy() {
		this.session.model.off('response', this.$blm.remove('login.onResponse', this, onResponse));
		this.session.model.off('error', this.$blm.remove('login.onError', this, onError));
	},
};

function onResponse() {
	this.$router.push(this.$store.state.targetPageAfterLogin);
}

function onError(e) {
	switch (e.data.error.status) {
		case 403: {
			if (this.submitted) {
				this.$store.commit('HIDE_PAGE_LOADER');
				this.errorMessage = 'Gebruikersnaam of wachtwoord onjuist.';
			}
			break;
		}

		default: {
			this.$store.commit('HIDE_PAGE_LOADER');
			this.errorMessage = 'Er liep iets mis, probeer later nog eens';
		}
	}

	// reset submitted flag
	this.submitted = false;
}

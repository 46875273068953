// imports
import _get from 'lodash/get';

import MeasureModel from '$models/monitoring/MeasureModel';
import MMCollection from '../MMCollection';
import Config from '$root/Config';

// class definition
export default class MeasureCollection extends MMCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || MeasureModel;
		args.config = {
			url: Config.getUrl('measures'),
		};
		
		// call super constructor
		super(args);

		this.projectId = null;
	}

	fetch() {
		if(this.projectId) {
			this.setConfig('url', `${Config.getUrl('projects')}/${this.projectId}/measure-candidates`);
			return super.fetch();
		} else {
			console.warn('Make sure this collection has a projectId');
		}
	}

	parse(data) {
		const total = _get(data, 'count_total');
		const countPerPage = _get(data, 'count_per_page');

		const pagination = {
			total,
			countPerPage,
			totalPages: Math.ceil(total / countPerPage),
			currentPage: _get(data, 'page'),
		};
		
		data.data.map(item => {
			if(item.effort) {
				item.effort.weight = item.effort.weight+1;
			}
			return item;
		})

		return super.parse(data.data, pagination);
	}
}

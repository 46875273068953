// New version of overlay trying to come up with a cleaner implementation & vuex usage
// Uses a base mixin => All variants should use their own mixin extending from OverlayMixin
// No InterfaceMixins anymore.

// imports
import OverlayMixin from '$mixins/OverlayMixin';
import RootInjectorMixin from '$mixins/RootInjectorMixin';

// class definition
export default {
	props: {
		title: String,
		root: Boolean,
		isShown: {
			type: Boolean,
			default: false,
		},
		overlayClass: {
			type: String,
			default: '',
		},
		fullScreen: {
			type: Boolean,
			default: false,
		},
		onCloseFromBg: {
			type: Function,
			default: (callback) => callback(),
		},
	},

	mixins: [OverlayMixin, RootInjectorMixin],

	mounted() {
		if (this.root) {
			this.inject_in_root(this.$refs.overlay);
		}
	},

	beforeDestroy() {
		this.remove_from_root(this.$refs.overlay);
	},

	computed: {
		isMobile() {
			return this.$store.state.isMobile;
		},
	},

	methods: {
		onEscUp() {
			this.hide();
		},
		_onCloseClick() {
			this.onCloseFromBg(this.hide);
		},
	},

	watch: {
		isShown: {
			handler() {
				if (this.isShown) {
					this.show();
				} else {
					this.hide();
				}
			},
			immediate: true,
		},
	},
};

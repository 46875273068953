// imports
import Sync from '$dependencies/Sync';
import Model from '$dependencies/Model';
import { MODEL_EVENTS, COLLECTION_EVENTS } from '$dependencies/MC_Base';
import { deepCloneObj } from '$utils/MC_Utils';

// class definition
export default class Collection extends Sync {
	// constructor
	constructor(args = {}) {
		super(args);

		// set properties
		this._Model = args.Model || Model;
		this._models = [];
		this.identifier = args.identifier || 'id';
		this._boundModelListener = onModelEvent.bind(this);
		this._locked = args._locked || false;
		this._modified = new Date(0);
		this._updated = new Date(0);
		// this.syncChildConfigs = args.syncChildConfigs || false;

		// parse args
		if (args.models) this.set(args.models);

		if (args.data) {
			console.warn('[ Collection ] - Data property of constructor args parameter is deprecated. Use models instead.');
			this.set(args.models);
		}
	}

	// methods
	set(model) {
		// check lock first
		if (this._locked) {
			console.warn('[ Collection ] - Tried to set a value but model was locked, set locked to false before setting a value');
			return this;
		}

		// check for model id (expects it to have a getter if it is a Model instance, else just a prop if its a model definition)
		const id = model[this.identifier];
		if (id) {
			// grab the original model inside the collection
			const originalModel = this.get(id);

			// replace model if found (always replace)
			if (originalModel) {
				originalModel.set(model);
			} else {
				addModelToCollection(model, this);
			}
		}
		// check if model is an array of models
		else if (Array.isArray(model)) {
			// add each silently (to prevent multiple add/replace events)
			this.silent(true);

			// add each model individually
			for (const m of model) {
				this.set(m);
			}

			// make it loud again
			this.silent(false);
		} else if (model instanceof Collection) {
			model.each((index, m) => {
				this.set(m);
			});
		}
		// if model is a Model instance or a Model definition (object literal)
		else {
			addModelToCollection(model, this);
		}

		// update changed time
		this._modified = new Date();

		// emit event
		this.emit('add,change');
	}

	get(id) {
		// check if id is array of ids/objects
		if (Array.isArray(id)) {
			const results = [];
			for (item of id) {
				const model = this.get(item);
				if (model) results.push(model);
			}
			return results;
		}
		// check if id is an object (used as filter to find first occurence)
		if (typeof id === 'object') {
			return this.findWhere(id);
		}
		// assume id is a single datatype that can be used as identifier

		const q = {};
		q[this.identifier] = id;
		return this.findWhere(q);
	}

	at(index) {
		return this._models[index];
	}

	has(model) {
		return !!this.get(model[this.identifier]);
	}

	remove(model) {
		// check lock first
		if (this._locked) {
			console.warn('[ Collection ] - Tried to set a value but model was locked, set locked to false before setting a value');
			return this;
		}

		// remove immediatelly if parameter is instance of model
		if (model instanceof this.Model) {
			// first try by reference, if not found => do a id lookup
			const index = this._models.indexOf(model);
			if (index > -1) {
				this._models.splice(index, 1);
			} else {
				model = this.remove(model[this.identifier]);
			}
		}
		// remove multiple models at once
		else if (Array.isArray(model)) {
			// Remove each item silently to prevent multiple remove events
			this.silent(true);

			// loop over items and remove them individually
			for (const item of model) {
				this.remove(item);
			}

			// make it loud again
			this.silent(false);
		} else {
			// lookup model (get can handle Models, object definitions and pure identifiers, no need for remove to perform checks)
			const modelOfCollection = this.get(model);

			// if found => remove
			if (modelOfCollection) {
				this.remove(modelOfCollection);

				// remove eventlisteners
				modelOfCollection.off(MODEL_EVENTS, this._boundModelListener);
			}
		}

		// update changed time
		this._modified = new Date();

		// and dispatch the remove event
		this.emit('remove,change');

		// return self for chaining
		return this;
	}

	createModel(obj, clone = false) {
		if (obj instanceof this.Model) {
			if (clone) return obj.clone();
			return obj;
		}

		return new this.Model({
			values: obj,
		});
	}

	clear(hard = false) {
		// check lock first
		if (this._locked) {
			console.warn('[ Collection ] - Tried to set a value but model was locked, set locked to false before setting a value');
			return this;
		}

		if (!hard) {
			// silence
			this.silent(true);

			// each model has to be removed one by one to ensure clean removal
			this.remove(this._models.slice(0));

			// make it loud again
			this.silent(false);
		} else {
			// do hard clear => quicker but breaks with everything as MC instances will be replaced instead of cleared
			this._models = [];
		}

		// update changed time
		this._modified = new Date();

		// emit event
		this.emit('cleared');

		return this;
	}

	at(index) {
		return this._models[index];
	}

	destroy() {
		this.clear();
		this._models = null;
		this.identifier = null;
		this._Model = null;
		this._boundModelListener = null;
	}

	fetchIf(condition, data = null) {
		switch (typeof condition) {
			case 'function':
				if (condition(this)) return this.fetch(data);
				break;

			case 'boolean':
				if (condition) return this.fetch(data);
				break;
				// loop over params
				let doFetch = true;
				for (const c of Object.keys(condition)) {
					if (this[c] !== condition[c]) {
						doFetch = false;
						break;
					}
				}
				if (doFetch) return this.fetch(data);
			default:
		}

		return new Promise((resolve) => resolve());
	}
	
	response(xhr, preventClear) {
		if (!preventClear) {
			this.clear();
		}

		// autoset values, xhr data has passed all parsing methods, data should be formatted correctly
		this.set(xhr.data);

		// renew updat date
		this._updated = new Date();

		// always call for super response => resets the states
		super.response(xhr);
	}

	error(error) {
		// check if error happened in sub instances
		// and isn't a straight ajax error at sync level
		if (!error.status && error.currentTarget && error.currentTarget !== error.data.scope) {
			error.data.scope.error(
				{
					type: 'error',
					originalEvent: error,
					error: error.data.error,
				},
				true,
			);
		} else {
			super.error(error);
		}
	}

	// utility methods
	where(query) {
		// allow query parameter to be a function
		let result;
		if (typeof query === 'function') {
			result = this._models.filter(query);
		} else {
			result = this._models.filter((model) => {
				return model.compare(query);
			});
		}

		// return results in new Collection or return null if none found
		return new this.constructor({
			Model: this.Model,
			models: result,
		});
	}

	findWhere(query) {
		// allow query parameter to be a function
		if (typeof query === 'function') {
			return this._models.find(query);
		}
		return this._models.find((model) => {
			return model.compare(query);
		});
	}

	clone(deep = false, config = false) {
		const collection = new this.constructor({ models: deep ? this.toArray(true) : this.models });
		if (config) collection._config = deepCloneObj(this.config);
		return collection;
	}

	toArray(deep = false) {
		if (!deep) {
			// return clone of models array
			return this._models.slice(0);
		}
		// loop over all items to ensure they return their 'object'-self
		const arr = [];
		for (const model of this._models) {
			arr.push(model.toObject(true));
		}
		return arr;
	}

	toJson() {
		return JSON.stringify(this.toArray(true));
	}

	each(callback, reverse = false) {
		if (!reverse) {
			for (let i = 0, len = this.length; i < len; i++) {
				callback.call(this, i, this.at(i), this);
			}
		} else {
			for (let i = this.length; i >= 0; i--) {
				callback.call(this, i, this.at(i), this);
			}
		}
		return this;
	}

	indexOf(model) {
		return this._models.indexOf(model);
	}

	first() {
		return this.models[0];
	}

	last() {
		return this.models[this.length - 1];
	}

	listProperty(prop, skipDuplicates = true) {
		const list = [];
		for (const model of this._models) {
			// extract value
			const value = model.get(prop);

			// skip if duplicates are undesired
			if (skipDuplicates && list.indexOf(value) > -1) continue;

			// push value
			list.push(model.get(prop));
		}
		return list;
	}

	toDictionary(prop, map = null, propMap = null) {
		const dictionary = {};
		for (const model of this._models) {
			const modelProp = model.get(prop);
			dictionary[propMap ? propMap(modelProp) : modelProp] = map ? map(model) : model;
		}
		return dictionary;
	}

	limit(start, end = Infinity) {
		let i = 0;
		const lastIndex = start + end;
		const c = new this.constructor();
		while (i < lastIndex && i < this.length) {
			c.set(this.at(i).clone());
			i++;
		}
		const test = deepCloneObj;
		c._config = deepCloneObj(this._config);
		return c;
	}

	// Array-like methods
	map(method, context) {
		return new this.constructor({
			models: this._models.map(method, context),
		});
	}

	sort(method) {
		// clone the collection
		const models = this._models.slice(0);
		return new this.constructor({
			models: models.sort(method),
		});
	}

	// setConfig(prop, value, syncChildConfigs = null) {
	// 	// super set
	// 	super.setConfig(prop, value)

	// 	// pass to children
	// 	if ( ((this.syncChildConfigs && syncChildConfigs === null) || syncChildConfigs === true) && this._models) {
	// 		for ( model of this._models ) {
	// 			if (model instanceof Model || model instanceof Collection) model.setConfig(prop, value, syncChildConfigs);
	// 		}
	// 	}
	// }

	// unsetConfig (prop, value = null, syncChildConfigs = null) {
	// 	// super set
	// 	super.unsetConfig(prop, value)

	// 	// pass to children
	// 	if ( ((this.syncChildConfigs && syncChildConfigs === null) || syncChildConfigs === true) && this._models) {
	// 		for ( model of this._models ) {
	// 			if (model instanceof Model || model instanceof Collection) model.unsetConfig(prop, value, syncChildConfigs);
	// 		}
	// 	}
	// }

	// resetConfig (syncChildConfigs = null) {
	// 	// super set
	// 	super.resetConfig()

	// 	// pass to children
	// 	if ( ((this.syncChildConfigs && syncChildConfigs === null) || syncChildConfigs === true) && this._models) {
	// 		for ( model of this._models ) {
	// 			if (model instanceof Model || model instanceof Collection) model.resetConfig();
	// 		}
	// 	}
	// }

	getState() {
		return {
			locked: this._locked,
			modified: this._modified,
			updated: this._updated,
			identifier: this._identifier,
		};
	}

	// getters & setters
	get Model() {
		return this._Model;
	}

	set Model(value) {
		this._Model = value;
		this.identifier = value.identifier;
	}

	get models() {
		return this._models;
	}

	get length() {
		return this._models.length;
	}

	get locked() {
		return this._locked;
	}

	set locked(value) {
		if (this._locked !== value) {
			this._locked = value;
			this.emit(value ? 'locked' : 'unlocked');
		}
	}

	get ids() {
		return this.listProperty(this.identifier);
	}

	get dictionaryById() {
		return this.toDictionary(this.identifier);
	}

	get synced() {
		const modDate = this._modified.getTime();
		const uDate = this._updated.getTime();
		return modDate > 0 && uDate > 0 && modDate === uDate;
	}
}

// private listener
function addModelToCollection(model, collection) {
	// ensure model is a Model instance
	if (!(model instanceof collection.Model)) model = collection.createModel(model);

	// add necessary event listeners
	if (!model.hasListener(MODEL_EVENTS, collection._boundModelListener)) {
		model.on(MODEL_EVENTS, collection._boundModelListener);
	}

	// add model to list
	collection._models.push(model);
}

function onModelEvent(e) {
	if (e.type === 'error') {
		this.emit('error', {
			type: 'error',
			originalEvent: e,
			error: e.data.error,
		});
	} else {
		this.emit(e.type, {
			type: e.type,
			originalEvent: e,
		});
	}
}

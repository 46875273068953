// imports
import _forEach from 'lodash/forEach';
import _pick from 'lodash/pick';
import Config from '$root/Config';

import ProjectExperienceScanModel from '../../models/monitoring/ProjectExperienceScanModel';

export default {
	namespaced: true,
	state() {
		return {
			experienceScanDraft: null,
			publishingIssue: false,
		};
	},

	mutations: {
        setExperienceScanDraft(state) {
            state.experienceScanDraft = new ProjectExperienceScanModel()
        },

		updateExperienceScanDraft(state, payload) {
			state.experienceScanDraft = payload
		},

        resetExperienceScanDraft(state) {
            state.experienceScanDraft = null
        },

		setPublishingIssue(state, payload) {
			state.publishingIssue = payload
		}
	},
};

import OverlayChildMixin from '$mixins/OverlayChildMixin';
import ProjectExperienceScanModel from '../../models/monitoring/ProjectExperienceScanModel';

const dataByType = {
	detail: {
		title: 'Wijzingen niet opgeslagen',
		body: 'Je wijzigingen werden nog niet opgeslagen weet je zeker dat je de pagina wil verlaten?',
		cancelButton: 'Annuleren',
		confirmButton: 'Pagina verlaten',
	},
	creation: {
		title: 'Formulier sluiten',
		body: 'Ben je zeker dat je dit scherm wil sluiten? De ingevoerde gegevens worden niet opgeslagen en gaan dus definitief verloren.',
		cancelButton: 'Verder gaan',
		confirmButton: 'Sluiten',
	},
	experienceScanCreation: {
		title: 'Belevingsscan sluiten',
		body: 'Weet u zeker dat u wilt afsluiten? Let op: alle gegevens gaan voorgoed verloren.',
		cancelButton: 'Verder gaan',
		confirmButton: 'Sluiten',
	},
	measurementCreation: {
		title: 'Meting afsluiten',
		body: 'Ben je zeker dat je de meting wenst af te sluiten? Alle informatie gaat hierbij verloren en dit kan niet ongedaan gemaakt worden.',
		cancelButton: 'Annuleren',
		confirmButton: 'Verwijderen',
	},
	volunteerPostWizard: {
		title: 'Post sluiten',
		body: 'Weet u zeker dat u wilt afsluiten? Let op: alle gegevens gaan voorgoed verloren.',
		cancelButton: 'Verder gaan',
		confirmButton: 'Sluiten',
	},
};

export default {
	mixins: [OverlayChildMixin],

	props: {
		type: {
			type: String,
			default: 'detail',
		},
	},

	mounted() {},

	data() {
		return {};
	},

	computed: {
		title() {
			return dataByType[this.type].title;
		},
		body() {
			return dataByType[this.type].body;
		},
		cancelButton() {
			return dataByType[this.type].cancelButton;
		},
		confirmButton() {
			return dataByType[this.type].confirmButton;
		},
	},

	methods: {
		onClose() {
			this.hide();
		},

		onConfirm() {
			if (this.type == 'experienceScanCreation') {
				var data = this.$store.state.experienceScan.experienceScanDraft
				var cancelScan = new ProjectExperienceScanModel()
				cancelScan.setConfig('headers', { Authorization: this.$store.getters['session/jwt_authorization'] });
				cancelScan.delete(data.uuid).then(() => {
                    this.$store.commit('experienceScan/resetExperienceScanDraft')
				})
			}
			this.$emit('confirm');
			this.onClose();
		},
	},

	watch: {},
};

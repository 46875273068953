const nl2br = {
	install(Vue) {
		if (Vue.filter('nl2br')) {
			console.warn('[filter duplication]: A filter named nl2br has already been installed.');
		} else {
			Vue.filter('nl2br', function(text, reg = /(\n|\r)/g) {
				return text && text !== null ? text.split(reg).join('<br/>') : text;
			});
		}
	},
};

export default nl2br;

import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';

import Config from '$root/Config';

export default {
	props: {
		tooltip: {
			validator(value) {
				return _isString(value) || _isObject(value);
			},
			default: '',
		},
		iconColor: {
			type: String,
			default: 'shakespeare',
		},
	},

	data() {
		return {
			isHovered: false,
			isOpen: false,
		};
	},

	computed: {
		tooltipConfig() {
			return {
				...Config.tooltip.defaultInfoIconConfig,
				...this.tooltip,
				show: this.isOpen,
			};
		},
	},

	methods: {
		onHover(value) {
			this.isHovered = value;
		},

		onClick() {
			this.isOpen = !this.isOpen;
		},
	},
};

// imports
import Model from '$dependencies/Model';
// private static properties
export const FIELDS = {
    uuid: { type: String, default: '', identifier: true },
    title: { type: String, default: '' },
    description: { type: String, default: '' },
    parent: { type: String, default: '' },
    machineName: { type: String, default: '' },
    legalInfoStepRequired: { type: Boolean, default: false },
    volumeStepRequired: { type: Boolean, default: false },
    color: { type: String, default: '' },
    //extra fields for select
    label: { type: String, default: '' },
    name: { type: String, default: '' },
    value: { type: String, default: '' },
    id: { type: String, default: '' },
    postalCode: {type: Number, default: 0}
};

// class definition
export default class TaxonomyModel extends Model {
    // constructor
    constructor(args = {}) {
        // clone args to do some modifications
        args = Object.assign({}, args);
        args.fields = FIELDS;
        // call super constructor
        super(args);
    }

    // methods
    destroy() {}
}

import _isFunction from 'lodash/isFunction';
import _upperFirst from 'lodash/upperFirst';

import OverlayChildMixin from '$mixins/OverlayChildMixin';

const CUSTOM_COPY = {
	'project': {
		body: 'Wens je dit project te verwijderen? De gekoppelde meetstroken en metingen worden bijgevolg ook verwijderd. Deze actie kan niet meer ongedaan gemaakt worden.',
		title: 'Project verwijderen',
	},
	'meting': {
		body: 'Ben je zeker dat je deze meting wil verwijderen? Deze actie kan niet meer ongedaan gemaakt worden.',
		title: 'Meting verwijderen',
	}
}

export default {
	props: {
		itemType: {
			type: String,
			required: true,
		},
	},

	mixins: [OverlayChildMixin],

	mounted() {},

	data() {
		return {
			isLoading: false,
		};
	},

	computed: {
		titleItemType() {
			return _upperFirst(this.itemType);
		},

		customText: {
			get() {
				return CUSTOM_COPY[this.itemType] ? CUSTOM_COPY[this.itemType] : false;
			}
		}
	},

	methods: {
		onClose() {
			this.toggleLoading()
			this.hide();
		},

		onDelete(e) {
			this.toggleLoading()
			
			if (e && _isFunction(e.preventDefault)) {
				e.preventDefault();
			}

			this.$emit('apply');
			// this.onClose();
		},
		toggleLoading() {
			this.isLoading = !this.isLoading
		}
	},

	watch: {},
};

// imports
import Config from '$root/Config';
import Model from '$dependencies/Model';
import { formatDateForUi, minutesToHours } from '$utils/dateFormat';

const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	user: { type: Object, default: () => {} },
	email: { type: String, default: '' },
	startAddress: { type: String, default: '' },
	totalVolume: { type: String, default: '' },
	totalVolumeRest: { type: String, default: '' },
	totalVolumePmd: { type: String, default: '' },
	bags: { type: Number, default: 0 },
	created: { type: Number, default: 0 },
	duration: {type: Number, default: 0},
	distance: {type: Number, default: 0},
	totalIncidents: {type: Number, default: 0},
	cleanliness: {type: String, default: ''},
	events: { type: Array, default: () => [] }
};

// class definition
export default class ActivityOverviewModel extends Model {
	
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	fetch(data) {
		this.setConfig('url', `${Config.getUrl('activity')}/${this.uuid}`);
		return super.fetch(data);
	}

	get id() {
		return this.uuid;
	}

	get startDate() {
		return formatDateForUi(this.created * 1000);
	}

	get startTimeFormatted() {
		return formatDateForUi(parseFloat(this.startTime) * 1000);
	}

	get endTimeFormatted() {
		return formatDateForUi(parseFloat(this.endTime) * 1000);
	}

	get activeSince() {
		return formatDateForUi(this.created * 1000);
	}

	get durationFormatted() {
		return minutesToHours(this.field_activity_duration);
	}

	get totalBags () {
		let bags = 0;
		this.collectedTrash.forEach(trash => {
			bags += trash.count;
		});
		return bags;
	}

	get route() {
		return this.routes.map(point => {
			return {
				lat: parseFloat(point.lat),
				lng: parseFloat(point.lon)
			}
		})
	}

	get coordinates() {
		return {
			lat: parseFloat(this.lat),
			lng: parseFloat(this.lon)
		}
	}
}

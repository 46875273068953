import vSelect from "vue-select";

export default {
	props: {
		context: {
			type: Object,
			required: true
		},
		labelFormatter: {
			type: Function,
			default: (option) => option.label
		}
	},

	components: {
		"v-select": vSelect,
	},

	mounted() {
		
	},

	data() {
		return {
		};
	},

	computed: {

	},

	methods: {
		onClose: function () {
			this.context.blurHandler();
		},
	},
};

import Config from '$root/Config';
import store from '$stores/MainStore';

// imports
import EventDispatcher from '$dependencies/EventDispatcher';

// private static properties
const axios = require('axios');

let authTokenRequest;

function refreshToken() {
	const token = JSON.parse(localStorage.getItem(Config.storageKeys.refreshToken));

	const params = new URLSearchParams();
	params.append('grant_type', 'refresh_token');
	params.append('refresh_token', token.refresh_token);
	params.append('client_id', Config.app_client_id);
	params.append('client_secret', Config.app_client_secret);

	return axios.post(`${Config.baseUrl}oauth/token`, params);
}

function getAuthToken() {
	if (!authTokenRequest) {
		authTokenRequest = refreshToken();
		authTokenRequest.then((response) => {
			resetAuthTokenRequest();
			store.dispatch('session/updateToken', response.data.access_token);
			store.dispatch('session/updateRefreshToken', response.data.refresh_token);
			store.dispatch('session/loadUser');
		});
	}

	return authTokenRequest;
}

function resetAuthTokenRequest() {
	authTokenRequest = null;
}

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response.status !== 401) {
			return Promise.reject(error);
		}

		if (error.response.config.url.includes('oauth/token')) {
			store.dispatch('session/logOut');
			if (window.location.pathname !== '/') {
				window.location.href = '/';
			}

			return Promise.reject(error);
		}

		return getAuthToken().then((response) => {
			error.response.config.headers.Authorization = `Bearer ${response.data.access_token}`;
			return axios(error.response.config);
		});
	},
);

// class definition
export default class Request extends EventDispatcher {
	constructor(method, config) {
		super(config);

		// set properties
		this._config = Object.assign({}, config || {});

		// set method in config
		this._config.method = method;
	}

	run() {
		return axios
			.request(this._config)
			.then((response) => {
				this.emit('success', response);
				return response;
			})
			.catch((error) => {
				this.emit('error', error);
				return error.response;
			});
	}

	destroy() {
		this._config = {};
	}

	// getters & setters
	get data() {
		return this._config.data;
	}

	set data(value) {
		Object.assign(this._config.data, value || {});
	}

	get url() {
		return this._config.url;
	}

	set url(value) {
		this._config.url = value;
	}

	get headers() {
		return this._config.headers;
	}

	set headers(value) {
		Object.assign(this._config.headers, value || {});
	}

	get params() {
		return this._config.params;
	}

	set params(value) {
		Object.assign(this._config.params, value || {});
	}
}

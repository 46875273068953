import JsonApiCollection from '$dependencies/JsonApiCollection';
import BinRemarkStatusModel from '$models/bin/BinRemarkStatusModel';
import Config from '$root/Config';

export default class BinRemarkStatusCollection extends JsonApiCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || BinRemarkStatusModel;
		args.config = {
			url: Config.getUrl('binRemarkStatuses'),
		};
		args.jsonApi = { order: { weight: 'asc' } };
		super(args);
	}
}

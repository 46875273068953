// filter object definition
export default {
	install(Vue) {
		if (Vue.filter('fillDegree')) {
			console.warn('[filter duplication]: A filter named fillDegree has already been installed.');
		} else {
			Vue.filter('fillDegree', function(value) {
				if (value <= 10) return 'Leeg';
				if (value > 10 && value <= 40) return 'Licht gevuld';
				if (value > 40 && value <= 60) return 'Halfvol';
				if (value > 60 && value <= 90) return 'Bijna vol';
				if (value > 90 && value <= 100) return 'Vol';
				if (value > 100 && value <= 130) return 'Overvol';
				if (value > 130) return 'Niet gemeten';
			});
		}
	},
};

import Treeselect from '@riophae/vue-treeselect';

export default {
	props: {
		context: {
			type: Object,
			required: true
		},
	},

	components: {
		Treeselect
	},

	mounted() {

	},

	data() {
		return {
			hideTreeSelectPlaceholderOnSearch: false
		};
	},

	computed: {
		selectedOptions: {
			cache: false,
			get() {
				return this.childOptions.filter(option => {
					return this.context.model.indexOf(option.uuid) > -1;
				});
			}
		},
		childOptions: {
			get() {
				const children = [];
				this.context.options.forEach(option => {
					if(option.children) {
						children.push(...option.children);
					}
				})
				return children;
			}
		}
	},

	methods: {
		onOptionClick(option) {
			this.context.model.splice(this.context.model.indexOf(option.uuid), 1);
		},
		toggleShowPlaceholder(e) {
			if(e.length) {
				this.hideTreeSelectPlaceholderOnSearch = true;
			} else {
				this.hideTreeSelectPlaceholderOnSearch = false;
			}
		},
	},

	watch: {

	}
};

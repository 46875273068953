export default {
	props: {},

	data() {
		return {
			_injected_elements: [],
		};
	},

	mounted() {},

	computed: {},

	beforeDestroy() {
		this.remove_from_root();
	},

	methods: {
		inject_in_root(el) {
			this.$root.$el.appendChild(el);
			this.$data._injected_elements.push(el);
		},

		remove_from_root(el) {
			const index = this.$data._injected_elements.indexOf(el);
			if (index > -1) {
				try {
					this.$root.$el.removeChild(el);
					this.$data._injected_elements.splice(index, 1);
				} catch (error) {
					console.error(error)
				} finally {
					return
				}
				// this.$root.$el.removeChild(el);
				// this.$data._injected_elements.splice(index, 1);
			}
		},
	},
};

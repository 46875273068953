/* eslint-disable */

// Callback scope force to self when called
// silent parameter added

var EventDispatcher = (function () {
	'use strict'

	// private static properties
	var version = '1.7.0.x',
	 OldEventDispatcher = EventDispatcher // for noConflict

	// constructor
	function EventDispatcher (args) {
		// prepare properties
		args = args || {}
		this._listeners = {}
		this._silent = args.silent || false;

		// check if listeners are passed
		if (args.on) 	processConstructorListeners.call(this, args.on)
		if (args.once) 	processConstructorListeners.call(this, args.once)
	}

	// methods
	EventDispatcher.prototype.on = function (event, data, callback) {
		addEvent(this._listeners, event, data, callback, false)
		return this
	}

	EventDispatcher.prototype.once = function (event, data, callback) {
		addEvent(this._listeners, event, data, callback, true)
		return this
	}

	function addEvent (listeners, event, data, callback, once) {
		// map arguments correctly
		if (typeof data === 'function') {
			callback = data
			data = {}
		}

		// prepare values
		var events = 		processEventParameter(event),
			callbacks = 	processCallbackParameter(callback),
			once = 			!!(once),

			// add to listeners
		 event, listenerObject
		for (var ci = 0, clen = callbacks.length; ci < clen; ci++) {
			for (var ei = 0, elen = events.length; ei < elen; ei++) {
				event = events[ei]

				// add listener info to array of listeners
				if (!listeners[event]) listeners[event] = []
				listeners[event].push({
					data: 		  data,
					callback: 	callbacks[ci],
					once: 		  !!once
				})

				if (!callbacks[ci]) debugger
			}
		}
	}

	EventDispatcher.prototype.off = function (event, callback) {
		// dot notation shortcut
		var listeners = this._listeners

		// support listener only parameter
		if (typeof event === 'function' && !callback) {
			callback = event
			event = null
		}

		// process parameters
		var events = 	processEventParameter(event),
			callbacks = processCallbackParameter(callback)

		// choose between certain events (loop) or all events (no loop)
		if (events) {
			// loop through passed events
			for (var ei = events.length - 1; ei >= 0; ei--) {
				// check for existance of listener array
				removeCallbacksFromList(listeners[events[ei]], callbacks)
			}
		}
		else {
			// remove given callbacks from all events
			if (callbacks) {
				for (var event in listeners) {
					removeCallbacksFromList(listeners[event])
				}
			}
			// set length of all event arrays to 0 to erase all references
			for (var event in listeners) {
				destroyListenerArray(listeners[event])
			}
		}

		return this
	}

	function removeCallbacksFromList (listenersArr, callbacks) {
		// skip everything if listenerArr has no listeners
		if (listenersArr && listenersArr.length) {
			// choose between certain callbacks or all callbacks
			if (callbacks) {
				// loop through all known listeners
				var ci,
					removedAListener = false
				for (var li = listenersArr.length - 1; li >= 0; li--) {
					// loop through all given callbacks
					for (ci = callbacks.length - 1; ci >= 0; ci--) {
						// remove if callback instances are the same
						if (listenersArr[li].callback === callbacks[ci]) {
							destroyListenerObject(listenersArr.splice(li, 1))
							removedAListener = true
							break
						}
					}

					if (removedAListener) break
				}
			}
			else {
				// set length of all event arrays to 0 to erase all references
				destroyListenerArray(listenersArr)
			}
		}
	}

	EventDispatcher.prototype.hasListener = function (event, callback) {
		// set listeners dot notation shortcut
		var listeners = this._listeners,

			// process parameters
		 events = 	processEventParameter(event),
			callbacks = processCallbackParameter(callback)

		// choose between any event or some events
		if (events) {
			// choose between given callbacks or any callback
			if (callbacks) {
				var listenerArr
				// loop through passed events
				for (var ei = events.length - 1; ei >= 0; ei--) {
					listenerArr = listeners[events[ei]]

					if (listenerArr) {
						// loop through passed callbacks
						for (var ci = callbacks.length - 1; ci >= 0; ci--) {
							// loop through every listener
							for (var li = listenerArr.length - 1; li >= 0; li--) {
								if (listenerArr[li].callback === callbacks[ci]) return true
							}
						}
					}
				}
			}
			else {
				var listenerArr

				// loop through passed events
				for (var ei = events.length - 1; ei >= 0; ei--) {
					listenerArr = listeners[events[ei]]
					if (Array.isArray(listenerArr) && listenerArr.length) return true
				}
			}
		}
		else {
			// choose between given callbacks or any callback
			if (callbacks) {
				var listenerArr

				// loop through known every listener
				for (var event in listeners) {
					listenerArr = listeners[event]

					if (listenerArr) {
						// loop through every passed callback
						for (var ci = callbacks.length - 1; ci >= 0; ci--) {
							// loop through every listener
							for (var li = listenerArr.length - 1; li >= 0; li--) {
								if (listenerArr[li].callback === callbacks[ci]) return true
							}
						}
					}
				}
			}
			else {
				for (var event in listeners) {
					return true
				}
			}
		}

		return false
	}

	EventDispatcher.prototype.getListeners = function (event, parameters) {
		// st listeners dot notation shortcut
		var listeners = this._listeners,

			// process event object
		 events = processEventParameter(event)

		// process parameters object
		// fallback for pre 1.4.0 versions
		if (parameters === true) {
			// noClone => inverse to use as clone parameter
			parameters = { clone: !parameters }
			console.warn('[EventDispatcher] - getListeners\'s noClone parameter is depracated, read docs for more info about this method.')
		}

		// parameters default values
		parameters = parameters || {}
		parameters.clone = parameters.clone || true

		// only run deep loops when events are passed
		if (events) {
			// choose between 1 event (noloop), certain events (loop) or all events (noloop)
			var nrEvents = events.length
			if (nrEvents === 1) {
				// reuse old event var to keep event instance
				event = events[0]

				if (listeners[event]) {
					// choose between clone or no clone
					if (parameters.clone) {
						return listeners[event].slice(0)
					}
					else {
						return listeners[event]
					}
				}
				else {
					// choose between clone or no clone
					if (parameters.clone) {
						return []
					}
					else {
						listeners[event] = []
						return listeners[event]
					}
				}
			}
			else if (nrEvents > 1) {
				var result = {},
					clone = parameters.clone

				// loop through all and reuse getListeners
				for (var i = events.length - 1; i >= 0; i--) {
					// reuse old event var to keep event instance
					event = events[i]

					// add event listeners to result object
					result[event] = this.getListeners(event, parameters)
				}

				return result
			}
			else {
				// choose between clone or no clone
				if (parameters.clone) {
					return Object.assign({}, listeners)
				}
				else {
					return listeners[event]
				}
			}
		}
		else {
			if (parameters.clone) {
				// deep clone
				var listenerArr,
					returnObject = {},
					returnListenerArr

				for (var event in listeners) {
					returnObject[event] = listeners[event].slice(0)
				}

				return returnObject
			}
			else {
				return listeners
			}
		}
	}

	EventDispatcher.prototype.hasEvents = function (callback) {
		// dot notation shortcut
		var listeners = this._listeners,

			// process parameters
		 callbacks = processCallbackParameter(callback)

		// check events of certain given callback or all callbacks
		if (callbacks) {
			var listenerArr

			// loop through passed callbacks
			for (var ci = callbacks.length - 1; ci >= 0; ci--) {
				// loop through listener collections
				for (event in listeners) {
					listenerArr = listeners[event]

					// loop through listeners
					for (var li = listenerArr.length - 1; li >= 0; li--) {
						if (listenerArr[li].callback === callbacks[ci]) return true
					}
				}
			}
		}
		else {
			for (var p in listeners) {
				if (listeners[p].length) return true
			}
		}

		return false
	}

	EventDispatcher.prototype.getEvents = function (callback) {
		// dot notation shortcut
		var listeners = this._listeners,
			events = 	[],
			listenerArr,

			// process callback parameter
		 callbacks = processCallbackParameter(callback)

		// loop through all connected listeners to make up an array of known events
		if (callback) {
			// loop through all passed callbacks
			for (var ci = callbacks.length - 1; ci >= 0; ci--) {
				// loop through all known event listener collections
				for (var e in listeners) {
					listenerArr = listeners[e]
					for (var li = listenerArr.length - 1; li >= 0; li--) {
						if (listenerArr[li].callback === callbacks[ci]) {
							events.push(e)
							break
						}
					}
				}
			}
		}
		else {
			for (var e in listeners) {
				events.push(e)
			}
		}

		return events
	}

	EventDispatcher.prototype.dispatch = function (event, passedData, process) {
		// stop immediately if is silent
		if (this._silent) return this;

		// split events (remove spaces first)
		var events = processEventParameter(event),

			// loop through passed events and fire them in passed order
		 event, callbacks, originalData, data, listener, isAllDefaultPrevented,
			listeners = this._listeners,
			EventClass = EventDispatcher.Event,
			passedData = passedData || {}

		for (var ei = 0, len = events.length; ei < len; ei++) {
			event = events[ei]

			// get callback(s) for current event & loop through them
			callbacks = listeners[event]

			if (callbacks) {
				for (var ci = 0; ci < callbacks.length; ci++) {
					// get listener and data
					listener = callbacks[ci]
					originalData = listener.data

					// add original data without overwriting new data properties
					data = Object.assign({}, originalData, passedData)

					// create event object instance
					var eventObject = new EventClass({
						type: 			      event,
						currentTarget: 	this,
						data: 			      data
					})

					// provide possibility to run code right before dispatch (handy for childs)
					if (process) {
						if (!process(eventObject, listener)) continue
					}

					// run callback & pass event object
					listener.callback.call(this, eventObject)

					// remove if has to run only once
					if (listener.once) {
						this.off(event, listener.callback)
						ci--
					}

					// check default prevention
					isAllDefaultPrevented = eventObject.isAllDefaultPrevented
					if (eventObject.isDefaultPrevented) 		break	// fullstop
					if (!eventObject.isAutoDestroyPrevented) 	eventObject.destroy()
					if (isAllDefaultPrevented) 					return	// stop current event, move on to next
				}
			}
		}

		return this
	}

	EventDispatcher.prototype.destroy = function () {
		// destroy listeners object properties
		this.off()

		// destroy propertie references
		this._listeners = null

		// destroy method references
		this.on = this.once = this.off = this.hasListener = this.getListeners = this.dispatch = this.destroy = null
	}

	EventDispatcher.prototype.toString = function () {
		return '[EventDispatcher]'
	}

	EventDispatcher.prototype.silent = function (value) {
		if (value === undefined) {
			return this._silent;
		}
		else {
			this._silent = !!value;
		}
	}

	// alias methods
	EventDispatcher.prototype.addEventListener = 		EventDispatcher.prototype.on
	EventDispatcher.prototype.removeEventListener = 	EventDispatcher.prototype.off
	EventDispatcher.prototype.getEventListeners = 		EventDispatcher.prototype.getListeners
	EventDispatcher.prototype.dispatchEvent = 			EventDispatcher.prototype.dispatch
	EventDispatcher.prototype.emit = 					EventDispatcher.prototype.dispatch
	EventDispatcher.prototype.trigger = 				EventDispatcher.prototype.dispatch
	EventDispatcher.prototype.willTrigger =				EventDispatcher.prototype.getListeners

	// Utils & Factories
	function processConstructorListeners (collection) {
		for (var e in collection) {
			processListenerObject.call(this, e, collection[e])
		}
	}

	function processListenerObject (event, listeners) {
		if (Array.isArray(listeners)) {
			for (var i = 0; i < listeners.length; i++) {
				processListenerObject.call(this, event, listeners[i])
			}
		}
		else {
			switch (typeof listeners) {
				case 'function':
					addEvent(this._listeners, event, {}, listeners)
					break

				case 'object':
					addEvent(this._listeners, event, listeners.data, listeners.callback)
					break
			}
		}
	}

	function processEventParameter (event) {
		if (event) {
			if (event.indexOf(',') > -1) {
				return event.replace(/ /g, '').split(',')
			}
			else if (Array.isArray(event)) {
				return event
			}
			else {
				return [event]
			}
		}

		return event
	}

	function processCallbackParameter (callback) {
		if (Array.isArray(callback)) {
			return callback
		}
		else if (typeof callback === 'function') {
			return [callback]
		}

		return callback
	}

	function destroyListenerArray (arr) {
		for (var i = arr.length - 1; i >= 0; i--) {
			destroyListenerObject(arr[i])
		}

		arr.length = 0
	}

	function destroyListenerObject (obj) {
		obj.data = obj.callback = obj.once = null
	}

	// static methods
	EventDispatcher.getVersion = function () {
		return version
	}

	EventDispatcher.noConflict = function () {
		var Current = EventDispatcher
		EventDispatcher = OldEventDispatcher
		return Current
	}

	// return
	return EventDispatcher
})()

/** ** - separator - ****/

EventDispatcher.Event = (function () {
	'use strict'

	// private static properties
	var id = 0

	// constructor
	function ED_Event (args) {
		var args = args || {}

		// set properties
		this.eventId = 					id++
		this.type = 					args.type
		this.currentTarget =			args.currentTarget || null
		this.data = 					args.data || {}
		this.timeStamp = 				Date.now()

		this.isDefaultPrevented = 		false
		this.isAllDefaultPrevented = 	false
		this.isAutoDestroyPrevented = 	false
	}

	// methods
	ED_Event.prototype.preventDefault = function () {
		this.isDefaultPrevented = true
	}

	ED_Event.prototype.preventAllDefault = function () {
		this.isAllDefaultPrevented = true
	}

	ED_Event.prototype.preventAutoDestroy = function () {
		this.isAutoDestroyPrevented = false
	}

	ED_Event.prototype.toString = function () {
		return '[Event: ' + this.eventId + ']'
	}

	ED_Event.prototype.destroy = function () {
		// destroy property references
		this.eventId = this.type = this.currentTarget = this.data = this.timeStamp = this.isDefaultPrevented = this.isAllDefaultPrevented = this.isAutoDestroyPrevented = null

		// destroy method references
		this.preventDefault = this.preventAllDefault = this.preventAutoDestroy = this.toString = this.destroy = null
	}

	// return
	return ED_Event
})()

export default EventDispatcher

export default {
	props: {
		context: {
			type: Object,
			required: true
		},
	},

	components: {

	},

	mounted() {
	
	},

	data() {
		return {
			yes: false,
			no: false,
		};
	},

	computed: {
		vModel: {
			cache: false,
			get() {
				return this.context.model;
			}
		}
	},

	methods: {
		onYesInput(val) {
			if(val) {
				this.context.rootEmit("input", 'true');
				this.context.model = 'true';
				this.no = false;
			} else if(!val && !this.no) {
				this.context.rootEmit("input", '');
				this.context.model = '';
			}
		},
		onNoInput(val) {
			if(val) {
				this.context.rootEmit("input", 'false');
				this.context.model = 'false';
				this.yes = false;
			} else if(!val && !this.yes) {
				this.context.rootEmit("input", '');
				this.context.model = '';
			}
		},
	},

	watch: {
		vModel(val) {
			if(!val.length)	{
				this.yes = false;
				this.no = false;
			}
		}
	}
};

// imports
import _get from 'lodash/get';

import VolunteerOverviewModel from '$models/volunteers/VolunteerOverviewModel';
import Config from '$root/Config';
import MMCollectionMinsky from '../MMCollectionMinsky';

// class definition
export default class ActiveVolunteersCollection extends MMCollectionMinsky {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || VolunteerOverviewModel;
		args.config = {
			url: Config.getUrl('volunteers'),
		};
		args.filterConversions = [
			{
				key: 'created',
				action: 'timestampArrayToSeconds'
			},
			{
				key: 'last7',
				action: 'timestampArrayToSeconds'
			},
			{
				key: 'last14',
				action: 'timestampArrayToSeconds'
			},
			{
				key: 'last30',
				action: 'timestampArrayToSeconds'
			},
			{
				key: 'last6m',
				action: 'timestampArrayToSeconds'
			},
			{
				key: 'lastyear',
				action: 'timestampArrayToSeconds'
			}
		];
		
		// call super constructor
		super(args);
	}

	fetch() {
		const communityID = localStorage.getItem('communityID') || window.vueStore.state.session.user.community.id;
		this.setConfig('url', `${Config.getUrl('volunteers')}/${communityID}`);

		return super.fetch();
	}

	parse(data) {
		const total = _get(data, 'count_total');
		const countPerPage = _get(data, 'count_per_page');

		const pagination = {
			total,
			countPerPage,
			totalPages: Math.ceil(total / countPerPage),
			currentPage: data.page+=1,
		};

		if(!data.features) {
			data.features = [];
		}

		return super.parse(data.features, pagination);
	}
}

import Model from '$dependencies/Model';

const FIELDS = {
	data: { type: Object, default: () => ({}) },
	count: { type: Number, default: 0 },
	field_incident_post_code: { type: String, default: '' },
	field_incident_status: { type: String, default: '' },
	field_incident_volume: { type: String, default: '' },
	field_incident_team: { type: String, default: '' },
	field_incident_bin_hasremarks: { type: String, default: '' },
	field_incident_category: { type: String, default: '' },
};

export default class ReportIncidentsDetailCollectionModel extends Model {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.fields = FIELDS;
		super(args);
	}

	destroy() {}
}

import _isFunction from 'lodash/isFunction';

import OverlayChildMixin from '$mixins/OverlayChildMixin';
import Model from '$dependencies/Model';

export default {
	props: {
		title: String,
		startValue: {
			required: false,
		},
		submitName: {
			type: String,
			default: 'Toepassen',
		},
	},

	mixins: [OverlayChildMixin],

	data() {
		return {
			applyIsVisible: false,
		};
	},

	computed: {
		valueChanged: {
			cache: false,
			get() {
				if (this.startValue !== this.currentValue) {
					// do model checks
					if (this.startValue instanceof Model || this.currentVlue instanceof Model) {
						return this.startValue.id === this.currentValue.id;
					}
					return false;
				}
				return false;
			},
		},
	},

	methods: {
		afterhide() {
			this.$emit('afterhide');
		},

		showApply() {
			this.applyIsVisible = true;
		},

		hideApply() {
			this.applyIsVisible = false;
		},

		_onApplyClick(e) {
			if (e && _isFunction(e.preventDefault)) {
				e.preventDefault();
			}

			this.hide();
			this.hideApply();
			this.$emit('apply');
		},
		_onCloseClick() {
			this.$emit('close')
		}
	},
};

function setSuccessMessage(type, messages) {
	this.$notify({
		group: 'mms-notifications',
		type: 'success',
		text: messages[type],
	})
}

function setErrorMessage(type, errors) {
	this.$notify({
		group: 'mms-notifications',
		type: 'error',
		text: errors[type],
	})
}

export { setSuccessMessage, setErrorMessage };

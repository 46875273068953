import VueRouter from 'vue-router';
import _some from 'lodash/some';

import Config from '$root/Config';
import Routes from '$router/Routes.js';
import store from '$stores/MainStore';

import {urlQueryToObject} from '../utils/urlUtils.js';

export default class MainRouter extends VueRouter {
	constructor() {
		super({
			routes: Routes.routes,
			mode: 'history',
		});

		// prepare history object
		this._entries = [];

		// add listeners
		this.beforeEach((to, _, next) => {
			const { meta } = Config.routes;
			Object.assign(meta, to.meta);
			document.title = meta.title;

			//check if auth token is in query params
			const params = urlQueryToObject();
			if(params['_bearer-token'] && !store.state.session.model.jwt_token) {
				store.dispatch('session/updateToken', params['_bearer-token']);
			}

			if (_some(to.matched, ({ meta }) => meta.hideForAuth)) {
				if (store.state.session.model.jwt_token) {
					next({ path: '/home' });
				} else {
					next();
				}
			} else {
				next();
			}
		});

		this.afterEach((to, from) => {
			if (!to.meta.excludeFromHistory) {
				if (from.name === to.name) {
					this._entries.pop(); // to immitate replace
				}

				this._entries.push(to);
			}

			if (this._entries.length > Config.history.max) this._entries.shift();
		});
	}

	getPreviousEntry() {
		// -2 => current entry is last one added to the array
		return this._entries[this._entries.length - 2];
	}

	getLastEntry() {
		// -2 => current entry is last one added to the array
		return this._entries[this._entries.length - 1];
	}

	goBack(backUrl = null) {
		if (!backUrl) backUrl = Config.routes.home;

		if (this._entries.length > 1) {
			// remove last entry
			this._entries.pop();

			// get & remove last entry
			backUrl = this._entries.pop().fullPath;
		} else {
			// empty entries to clean history when it is considered empty
			// one may be present = current page (before page change)
			this._entries.length = 0;
		}

		this.push(backUrl);
	}
}

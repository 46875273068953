export default {
    namespaced: true,

    state() {
        return {
            selectedComparison: null,
            parameters: null,
        }
    },

    mutations: {
        SET_COMPARISON(state, payload) {
            state.selectedComparison = payload
        },
        RESET_COMPARISON(state) {
            state.selectedComparison = null
        },
        SET_PARAMETERS(state, payload) {
            state.parameters = payload
        },
        RESET_PARAMETERS(state) {
            state.parameters = null
        },
    }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    directives: [
      {
        name: "tooltip",
        rawName: "v-tooltip",
        value: _vm.tooltipConfig,
        expression: "tooltipConfig"
      }
    ],
    staticClass: "icon icon--help",
    class: _vm.isHovered ? "icon--dark" : "icon--" + _vm.iconColor,
    on: {
      click: function($event) {
        $event.preventDefault()
        return _vm.onClick.apply(null, arguments)
      },
      mouseenter: function($event) {
        return _vm.onHover(true)
      },
      mouseleave: function($event) {
        return _vm.onHover(false)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
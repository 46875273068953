import Config from '$root/Config';

import JsonApiCollection from '$dependencies/JsonApiCollection';

import IncidentDeclineReasonModel from '$models/IncidentDeclineReasonModel';

export default class IncidentDeclineReasonCollection extends JsonApiCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || IncidentDeclineReasonModel;
		args.entityType = 'taxonomy_term--incident_decline_reason';

		args.config = Object.assign(
			{
				url: Config.getUrl('incidentDeclineReasons'),
			},
			args.config || {},
		);

		super(args);
	}

	destroy() {}
}

export default {
	namespaced: true,

	state() {
		return {
			pages: {},
		};
	},

	getters: {
		getFilters: ({ pages }) => pages,
	},

	mutations: {
		SET_FILTERS(state, { filterName, filters, groups, params, dateParam }) {
			state.pages[filterName] = {
				apiFilter: { ...filters },
				apiFilterGroups: { ...groups },
				apiFilterParams: { ...params },
				dateParam,
			};
		},
		CLEAR_FILTERS(state) {
			state.pages = {};
		},
	},

	actions: {
		setFilters({ commit }, payload) {
			commit('SET_FILTERS', payload);
		},
		clearFilters({ commit }) {
			commit('CLEAR_FILTERS');
		},
	},
};

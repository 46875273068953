import EditDateOverlay from '$components/EditDateOverlay/EditDateOverlay.vue';

import { formatDateForUi } from '$utils/dateFormat';
import getAssetPath from '$utils/getAssetPath';

export default {
	props: {
		context: {
			type: Object,
			required: true
		},
	},

	components: {
		EditDateOverlay,
	},

	mounted() {
		if(this.context.model) {
			this.date = this.context.model;
		}
	},

	data() {
		return {

		};
	},

	computed: {
		uiDate: {
			cache: false,
			get() {
				return formatDateForUi(this.context.model);
			}
		},
		date: {
			cache: false,
			get() {
				return this.context.model ? this.context.model : new Date()
			},
			set(val) {
				this.context.rootEmit("input", val);
				this.context.model = val;
			}
		}
	},

	methods: {
		formatDateForUi,
		getAssetPath,

		onDateApply(date) {
			this.date = date;
		},

		onDateClick() {
			this.$refs.editDateOverlay.show();
		},
	},
};

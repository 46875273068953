// imports
import Model from '../../dependencies/Model';
import Config from '../../Config';
import { formatDateForUi } from '../../utils/dateFormat';


const FIELDS = {
	uuid: { type: String, default: '', identifier: true },
	title: { type: String, default: '' },
	description: { type: String, default: '' },
	startDate: { type: String, default: '' },
	endDate: { type: String, default: '' },
	subject: { type: String, default: '' },
	binGroup: { type: Object, default: () => {} },
	state: { type: Object, default: () => {} },
	created: {type: String, default: '' },
	validityScore: { type: Number },
	deletePermission: { type: Boolean, default: null },
};

// class definition
export default class MeasuringPeriodModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	async setDeletePermission() {
		this.deletePermission = false;

		return fetch(`${Config.getUrl('binMeasuringPeriodDeletePermission')}`, {'headers': this._config.headers}).then(r => r.json()).then((permission) => {
			this.deletePermission = permission.allowed;
		});
	}

	fetch(data) {
		this.setConfig('url', `${Config.getUrl('measuringPeriods')}/${this.uuid}`);
		this.setDeletePermission()
		return super.fetch(data);
	}

	post(data) {
		data = prepareDataForPost(this.toObject(true));
		this.setConfig('url', Config.getUrl('measuringPeriods'))

		return super.post(data)
	}

	patch(data) {
		data = prepareDataForPatch(data)
		this.setConfig('url', `${Config.getUrl('measuringPeriods')}/${this.uuid}`)
		
		return super.patch(data)
	}

	parse(data) {
		if(!data) return {};
        return super.parse(data.data);
    }

	get formattedStartDate() {
		return formatDateForUi(this.startDate * 1000);
	}
	get formattedEndDate() {
		return formatDateForUi(this.endDate * 1000);
	}
	get formattedCreated() {
		return formatDateForUi(this.created * 1000);
	}

	get label() {
		return this.title
	}
}

function prepareDataForPost(data) {
	if (!data.binGroup.uuid) {
		delete data.binGroup
	} else {
		data.binGroup = data.binGroup.uuid
	}

	if (!data.state) {
		delete data.state
	}

	return data
}

function prepareDataForPatch(data) {
	if (data.binGroup === undefined || !data.binGroup.uuid) {
		delete data.binGroup
	} else {
		data.binGroup = data.binGroup.uuid
	}

	if (data.startDate) {
		data.startDate = parseInt(new Date(data.startDate).getTime() / 1000).toFixed(0);
	}

	if (data.endDate) {
		data.endDate = parseInt(new Date(data.endDate).getTime() / 1000).toFixed(0);
	}

	return data
}
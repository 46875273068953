import _get from 'lodash/get';
import Config from '$root/Config';
import MeasurementModel from '$models/measurement/MeasurementModel';
import MMCollection from '$collections/MMCollection';
import _map from "lodash/map";
import { unixToUi } from '$utils/dateFormat';

const filterDictionary = {
	created: 'created',
	measurement_addition_bags: 'field_measurement_addition_bags',
	measurement_bin: 'field_measurement_bin',
	measurement_id: 'field_measurement_id',
	measurement_illegal_dump: 'field_measurement_illegal_dump',
	measurement_addition: 'field_measurement_addition',
	measurement_remark: 'field_measurement_remark',
	title: 'title',
	uuid: 'uuid',
};

export default class MeasurementCollection extends MMCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || MeasurementModel;
		args.filterDictionary = filterDictionary;
		args.filterConversions = [
			{
				key: 'created',
				action: 'timestampArrayToSeconds'
			}
		];
		super(args);

		this.info = {};
		this.currentUrl = Config.getUrl('measurements');
	}

	parse(data) {
		const measurements = _get(data, 'features');
		const total = _get(data, 'count_measurements');
		const countPerPage = _get(data, 'count_per_page');

		const pagination = {
			total,
			countPerPage,
			totalPages: Math.ceil(total / countPerPage),
			currentPage: _get(data, 'page'),
		};

		this.info = {
			countMeasurements: total,
		}

		data = _map(measurements, (item) => {
			const preparedItem = {
				..._get(item, 'properties'),
				id: _get(item, 'properties.uuid'),
				created: unixToUi(parseInt(_get(item, 'properties.created', null))),
			};

			const newModel = new this.Model().set(preparedItem);
			return newModel;
		});

		return super.parse(data, pagination);
	}

	fetch() {
		const communityID = localStorage.getItem('communityID') || window.vueStore.state.session.user.community.id;
		this.setConfig('url', `${this.currentUrl}/${communityID}`);

		return super.fetch();
	}

	setUrl(url) {
		this.currentUrl = url;
	}
}

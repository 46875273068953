var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formulate-address-select" },
    [
      _c(
        "v-select",
        _vm._b(
          {
            ref: "select",
            class:
              "formulate-input-element formulate-input-element--" +
              _vm.context.type,
            attrs: {
              "data-type": _vm.context.type,
              options: _vm.options,
              reduce: function(x) {
                return x.value
              },
              filterable: false,
              label: "label"
            },
            on: { close: _vm.onClose, search: _vm.onSearch },
            scopedSlots: _vm._u([
              {
                key: "selected-option-container",
                fn: function(ref) {
                  var option = ref.option
                  return [
                    _c("span", {
                      staticClass: "vs__selected",
                      domProps: { innerHTML: _vm._s(option.label) }
                    })
                  ]
                }
              },
              {
                key: "option",
                fn: function(ref) {
                  var label = ref.label
                  return [
                    _c(
                      "div",
                      { staticClass: "formulate-option-selector__option" },
                      [
                        _c("span", {
                          staticClass: "option-label",
                          domProps: { innerHTML: _vm._s(label) }
                        })
                      ]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selectedAddress,
              callback: function($$v) {
                _vm.selectedAddress = $$v
              },
              expression: "selectedAddress"
            }
          },
          "v-select",
          _vm.context.attributes,
          false
        )
      ),
      _vm._v(" "),
      _c("input", { ref: "addressfield", staticClass: "noRender" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// imports
import _get from 'lodash/get';
import _map from 'lodash/map';

import Config from '$root/Config';
import BinSmallModel from '$models/bin/BinSmallModel';
import GarbageBinModel from '../models/GarbageBinModel';
import MMCollection from './MMCollection';

export default class GarbageBinSmallCollection extends MMCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || BinSmallModel;
		args.parentModel = new GarbageBinModel();
		// call super constructor
		super(args);
	}

	parse(data) {
		const markers = _get(data, 'features');
		data = _map(markers, (item) => {
			const preparedItem = {
				id: _get(item, 'properties.uuid'),
				title: _get(item, 'properties.title'),
				gId: _get(item, 'properties.field_bin_g_id'),
				parentModel: {},
			};

			// create model
			const newModel = new this.Model().set(preparedItem);
			return newModel;
		});
		return super.parse(data);
	}

	fetch() {
		// add community id to url
		this.setConfig('url', `${Config.getUrl('binsSmall')}/${window.vueStore.state.session.user.community.id}`);
		// super fetch
		return super.fetch();
	}
}

// imports
import JsonApiModel from '$dependencies/JsonApiModel';

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	name: { type: String, default: '' },
};

// class definition
export default class PartnerApiModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.entityType = 'taxonomy_term--api_partner';
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	// methods
	destroy() {}
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Overlay", {
    ref: "overlay",
    staticClass: "overlay--deleteClassic",
    attrs: {
      root: true,
      title: _vm.customText
        ? _vm.customText.title
        : _vm.titleItemType + " verwijderen"
    },
    on: { close: _vm.onClose },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function() {
            return [
              _vm._t("body", function() {
                return [
                  _vm.customText
                    ? _c("span", [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.customText.body) +
                            "\n\t\t\t"
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n\t\t\t\tWeet je zeker dat je deze " +
                            _vm._s(_vm.itemType) +
                            " definitief wil verwijderen?\n\t\t\t\tDit kan nadien niet ongedaan gemaakt worden.\n\t\t\t"
                        )
                      ])
                ]
              })
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "footer-actions-group" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "button button--big button--no-padding button--light button--carnation",
                    on: { click: _vm.onClose }
                  },
                  [_vm._v("\n\t\t\t\tAnnuleren\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button button--big button--filled",
                    class: _vm.isLoading ? "button--loading" : "",
                    on: { click: _vm.onDelete }
                  },
                  [
                    _vm._v("\n\t\t\t\tVerwijderen\n\t\t\t\t"),
                    _vm.isLoading
                      ? _c("span", { staticClass: "loading" })
                      : _vm._e()
                  ]
                )
              ])
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
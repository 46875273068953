// imports
import JsonApiCollection from '$dependencies/JsonApiCollection';

import TeamModel from '$models/TeamModel';

// private static properties

// class definition
export default class TeamCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || TeamModel;
		args.entityType = 'node--team';
		args.jsonApi = { order: { weight: 'asc' } };
		// call super constructor
		super(args);
	}

	// methods
	destroy() {}

	// utility methods

	// getters & setters
	get dictionaryByTitle() {
		return this.toDictionary('title');
	}
}

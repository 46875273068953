// imports
import _get from 'lodash/get';
import _map from 'lodash/map';

import Config from '$root/Config';
import IncidentModel from '$models/IncidentModel';
import IncidentMarkerModel from '$models/IncidentMarkerModel';
import MMCollection from './MMCollection';

const filterDictionary = {
	team: 'field_incident_team',
	archived: 'field_incident_archived',
	status: 'field_incident_status',
	volume: 'field_incident_volume',
	category: 'field_incident_category',
	priority: 'field_incident_priority',
	legal_info: 'field_incident_legal_info',
	report_type: 'field_incident_report_type',
	post_code: 'field_incident_post_code',
};

// class definition
export default class IncidentMarkerCollection extends MMCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || IncidentMarkerModel;
		args.parentModel = new IncidentModel();
		args.dictionary = filterDictionary;
		args.filterDictionary = filterDictionary;
		// call super constructor
		super(args);
	}

	parse(data) {
		const markers = _get(data, 'features');

		data = _map(markers, (item) => {
			const coordinates = {
				lat: parseFloat(_get(item, 'geometry.coordinates[1]')),
				lng: parseFloat(_get(item, 'geometry.coordinates[0]')),
			};

			const preparedItem = {
				..._get(item, 'properties'),
				coordinates,
				id: _get(item, 'properties.uuid'),
				parentModel: {},
			};

			// create model
			const newModel = new this.Model().set(preparedItem);
			newModel.setConfig('headers', this._config.headers);
			// set values for parentModel (ready to fetch)
			return newModel;
		});

		return super.parse(data);
	}

	fetch() {
		// add community id to url
		const communityID = localStorage.getItem('communityID') || window.vueStore.state.session.user.community.id;
		this.setConfig('url', `${Config.getUrl('incidentMarkers')}/${communityID}`);
		// super fetch
		return super.fetch();
	}
}

import Model from '$dependencies/Model';

const FIELDS = {
	data: { type: Object, default: () => ({}) },
};

export default class ReportBinsModel extends Model {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.fields = FIELDS;
		super(args);
	}

	destroy() {}
}

// imports
import _get from 'lodash/get';

import ActivityOverviewModel from '$models/volunteers/ActivityOverviewModel';
import Config from '$root/Config';
import MinskyCollection from '../MinskyCollection';

// class definition
export default class ActivityCollection extends MinskyCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || ActivityOverviewModel;
		args.config = {
			url: Config.getUrl('activities'),
		};
		args.filterConversions = [
			{
				key: 'created',
				action: 'timestampArrayToSeconds'
			}
		];
		// call super constructor
		super(args);
	}

	fetch(preventClear) {
		if (!this.config.params.format) {
			this.setConfig('params', Object.assign(this.config.params, {format: 'list'}))
		}
		this.setConfig('url', `${Config.getUrl('activities')}`);

		return super.fetch(preventClear);
	}

	parse(data) {
		if (!data) {
			return
		}
		return super.parse(data)
	}
}

import Config from '$root/Config';

import JsonApiCollection from '$dependencies/JsonApiCollection';

import IncidentReportTypeModel from '$models/IncidentReportTypeModel';

export default class IncidentReportTypeCollection extends JsonApiCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || IncidentReportTypeModel;
		args.entityType = 'taxonomy_term--incident_report_type';

		args.config = Object.assign(
			{
				url: Config.getUrl('incidentReportTypes'),
			},
			args.config || {},
		);

		super(args);
	}

	destroy() {}
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Overlay", {
    ref: "overlay",
    staticClass: "overlay--gdpr",
    attrs: { title: "Gebruik van persoonsgegevens", root: true },
    on: { close: _vm.onClose, afterhide: _vm.onAfterHide },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function() {
            return [
              _vm._t("body", function() {
                return [
                  _c("p", [
                    _vm._v(
                      "Via de module 'vrijwilligers' hebben jullie als lokaal bestuur toegang tot informatie over de vrijwilligers en burgers die gebruik hebben gemaakt van de mobiele app van Mooimakers om een opruimsessie of melding te registreren binnen jullie werkingsgebied."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Als lokaal bestuur krijgen jullie daarbij ook toegang tot persoonsgegevens zoals naam en e-mailadres van deze gebruikers. De gebruikers worden over deze informatiedeling tussen Mooimakers en het lokaal bestuur ingelicht in de privacyverklaring van Mooimakers."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Het verder gebruik van de voormelde persoonsgegevens door het lokaal bestuur behoort evenwel tot de eigen verantwoordelijkheid van het lokaal bestuur, dat daarbij optreedt als verwerkingsverantwoordelijke onder de Algemene Verordening Gegevensbescherming (AVG). Mooimakers is immers niet betrokken bij de eigen verwerkingen van persoonsgegevens door het lokaal bestuur."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Zo dient het lokaal bestuur, indien zij de voormelde gebruikers wenst aan te schrijven aan de hand van de contactgegevens die beschikbaar zijn in de module ‘vrijwilligers’, te beoordelen hoe zij dit overeenkomstig de AVG wenst te verwezenlijken. In het bijzonder dient het lokaal bestuur na te gaan of zij de betrokkenen al dan niet wenst aan te schrijven zonder hun voorafgaande toestemming. "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.hasCheckbox
                    ? _c("FormItem", {
                        ref: "approvedCheck",
                        attrs: {
                          id: "approved",
                          type: "checkbox",
                          label: "Dit bericht niet meer tonen",
                          modifiers: ["inline", "checkbox--big"]
                        },
                        model: {
                          value: _vm.approved,
                          callback: function($$v) {
                            _vm.approved = $$v
                          },
                          expression: "approved"
                        }
                      })
                    : _vm._e()
                ]
              })
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "footer-actions-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "button button button--filled button--big",
                    on: { click: _vm.onClose }
                  },
                  [_vm._v("\n\t\t\t\t" + _vm._s(_vm.buttonText) + "\n\t\t\t")]
                )
              ])
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
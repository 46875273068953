// imports
import Config from '$root/Config';
import JsonApiModel from '$dependencies/JsonApiModel';

import CommunityModel from '$models/CommunityModel';
import UserModel from '$models/UserModel';

import PhotoCollection from '$collections/PhotoCollection';

import BinTypeModel from '$models/bin/BinTypeModel';
import BinLocationTypeModel from '$models/bin/BinLocationTypeModel';
import BinSurfaceModel from '$models/bin/BinSurfaceModel';
import BinMaterialModel from '$models/bin/BinMaterialModel';
import BinColorModel from '$models/bin/BinColorModel';
import BinHistoryModel from '$models/bin/BinHistoryModel';
import BinEmptyingFrequencyModel from '$models/bin/BinEmptyingFrequencyModel';
import BinRequiredActionModel from '$models/bin/BinRequiredActionModel';
import MeasurementDamageModel from '$models/measurement/MeasurementDamageModel';
import MeasurementDamageRemarkModel from '$models/measurement/MeasurementDamageRemarkModel';
import RemarkCollection from '$collections/RemarkCollection';

// private static properties
export const API_DICTIONARY = {
	uuid: 'id',
	field_bin_community: 'community',
	field_bin_geolocation: 'geolocation',
	field_bin_remark: 'remark',
	field_bin_remarks: 'remarks',
	field_bin_images: 'images',
	field_bin_type: 'bin_type',
	field_bin_location_type: 'location_type',
	field_bin_surface: 'surface',
	field_bin_volume: 'volume',
	field_bin_lid_size: 'lid_size',
	field_bin_material: 'material',
	field_bin_color: 'color',
	field_bin_emptying_frequency: 'emptying_frequency',
	field_bin_emptying_days: 'emptying_days',
	field_bin_emptying_freq_remark: 'emptying_frequency_exception',
	field_bin_required_action: 'required_action',
	field_bin_post_code: 'post_code',
	field_bin_city: 'city',
	field_bin_street: 'street',
	field_bin_g_id: 'custom_id',
	field_bin_id: 'assigned_id',
	field_bin_damage: 'bin_damage',
	field_bin_damage_remark: 'bin_damage_remark',
	'field_bin_damage_remark.field_m_b_damage_remark_type': 'bin_damage_remark.type',
	field_bin_measurement_count: 'measurement_count',
	field_bin_last_measurement_date: 'last_measurement',
	field_bin_first_measurement_date: 'first_measurement',
	field_bin_measurement_empty_c: 'measurement_empty_count',
	field_bin_measurement_half_c: 'measurement_half_count',
	field_bin_measurement_full_c: 'measurement_full_count',
	field_bin_measurment_overfill_c: 'measurement_overfill_count',
	field_bin_measurement_dump_c: 'measurement_dump_count',
	field_bin_measurement_addition_c: 'measurement_addition_count',
	field_bin_ashtray: 'bin_ashtray',
	uid: 'user',
	field_bin_status: 'active',
	field_bin_history: 'history',
	field_bin_qr: 'qr',
	field_bin_qr_blob: 'qr_blob',
	field_bin_incident_count: 'incident_count',
	field_bin_last_incident_date: 'last_incident',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	active: { type: Boolean, default: true },
	title: { type: String, default: '' },
	city: { type: String, default: '' },
	post_code: { type: String, default: '' },
	street: { type: String, default: '' },
	remarks: { type: Object, default: () => new RemarkCollection(), referenceType: 'remark--remark' },
	volume: { type: Number, default: 0 },
	qr: { type: String, default: '' },
	lid_size: { type: Number, default: 0 },
	remark: { type: String, default: '' },
	emptying_frequency_exception: { type: String, default: '' },
	changed: { type: Date, default: () => new Date() },
	created: { type: Date, default: () => new Date() },
	community: { type: CommunityModel, default: () => new CommunityModel(), referenceType: 'node--community' },
	bin_type: { type: BinTypeModel, default: () => new BinTypeModel(), referenceType: 'taxonomy_term--bin_types' },
	bin_damage: { type: MeasurementDamageModel, default: () => new MeasurementDamageModel(), referenceType: 'taxonomy_term--measurement_damage_amounts' },
	location_type: { type: BinLocationTypeModel, default: () => new BinLocationTypeModel(), referenceType: 'taxonomy_term--bin_location_types' },
	qr_blob: {type: String, default: ''},
	bin_damage_remark: {
		type: MeasurementDamageRemarkModel,
		default: () => new MeasurementDamageRemarkModel(),
		referenceType: 'measurement_remark--m_b_damage_remark',
	},
	required_action: {
		type: BinRequiredActionModel,
		default: () => new BinRequiredActionModel(),
		referenceType: 'taxonomy_term--bin_action',
		isComputed: true,
		isEditable: false,
	},
	surface: { type: BinSurfaceModel, default: () => new BinSurfaceModel(), referenceType: 'taxonomy_term--bin_surfaces' },
	material: { type: BinMaterialModel, default: () => new BinMaterialModel(), referenceType: 'taxonomy_term--bin_materials' },
	emptying_frequency: {
		type: BinEmptyingFrequencyModel,
		default: () => new BinEmptyingFrequencyModel(),
		referenceType: 'taxonomy_term--bin_emptying_frequencies',
	},
	emptying_days: { type: Array, default: () => [] },
	color: { type: BinColorModel, default: () => new BinColorModel(), referenceType: 'taxonomy_term--bin_colors' },
	images: { type: Object, default: () => new PhotoCollection(), referenceType: 'file--file' },
	permissions: { type: Object, default: () => {} },
	checked: { type: Boolean, default: false },
	custom_id: { type: String, default: '', isComputed: true, isEditable: true },
	assigned_id: { type: String, default: '' },
	measurement_count: { type: Number, default: 0 },
	last_measurement: { type: Date, default: null },
	first_measurement: { type: Date, default: null },
	incident_count: { type: Number, default: 0 },
	last_incident: { type: Date, default: null },
	measurement_empty_count: { type: Number, default: 0 },
	measurement_half_count: { type: Number, default: 0 },
	measurement_full_count: { type: Number, default: 0 },
	measurement_overfill_count: { type: Number, default: 0 },
	measurement_dump_count: { type: Number, default: 0 },
	measurement_addition_count: { type: Number, default: 0 },
	user: { type: Object, default: () => new UserModel(), referenceType: 'user--user' },
	history: { type: Object, default: () => new BinHistoryModel(), referenceType: 'bin_history--bin_history' },
	bin_ashtray: { type: Boolean, default: null },
	deletePermission: { type: Boolean, default: null },
	geolocation: { type: Object, default: {lat: null, lon: null} },
};

let id = 0;

// class definition
export default class GarbageBinModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.entityType = 'node--bin';
		args.fields = FIELDS;

		// apply default config values
		args.config = Object.assign(
			{
				url: '/jsonapi/node/bin',
			},
			args.config || {},
		);

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);

		this.instance_id = id++;

		// set extra properties
		this._subFetch = args._subFetch || false;
	}

	// methods
	async setDeletePermission(data) {
		this.deletePermission = false;

		return fetch(`${Config.getUrl('binDeletePermission')}/${this.id}`, {'headers': this._config.headers}).then(r => r.json()).then((permission) => {
			this.deletePermission = permission.allowed;
		});
	}

	fetch(data) {
		// clear sub collections & models
		this.set('community', { reset: true });
		this.set('images', { reset: true });
		this.set('bin_type', { reset: true });
		this.set('location_type', { reset: true });
		this.set('required_action', { reset: true });
		this.set('surface', { reset: true });
		this.set('material', { reset: true });
		this.set('color', { reset: true });
		this.set('emptying_frequency', { reset: true });
		this.set('user', { reset: true });
		this.set('history', { reset: true });
		this.set('remarks', { reset: true });

		// set their headers again
		if (this.bin_type) this.bin_type.setConfig('headers', this.config.headers);
		if (this.location_type) this.location_type.setConfig('headers', this.config.headers);
		if (this.required_action) this.required_action.setConfig('headers', this.config.headers);
		if (this.surface) this.surface.setConfig('headers', this.config.headers);
		if (this.material) this.material.setConfig('headers', this.config.headers);
		if (this.color) this.color.setConfig('headers', this.config.headers);
		if (this.emptying_frequency) this.emptying_frequency.setConfig('headers', this.config.headers);
		if (this.images) this.images.setConfig('headers', this.config.headers);
		if (this.history) this.history.setConfig('headers', this.config.headers);
		if (this.remarks) this.remarks.setConfig('headers', this.config.headers);

		return super.fetch(data);
	}

	post(data) {
		this.geolocation = `POINT (${this.geolocation.lon} ${this.geolocation.lat})`;

		// run super post
		data = cleanUpdataForPayload(data || this.toObject(true), 'post');

		// remove id as well
		delete data.id;

		// reset config url to base url
		this.setConfig('url', Config.getUrl('bin'));

		// post
		return super.post(data);
	}

	patch(data, options = {}) {
		// run super patch
		if(this.geolocation.lon && this.geolocation.lat) {
			this.geolocation = `POINT (${this.geolocation.lon} ${this.geolocation.lat})`;
			if(options.fieldsToPatch) {
				options.fieldsToPatch.push('geolocation');
			}
		}
		return super.patch(cleanUpPatchFields(cleanUpdataForPayload(data || this.toObject(true), 'patch'), options.fieldsToPatch));
	}

	setConfig(prop, value) {
		super.setConfig(prop, value);

		// update child headers
		if (prop === 'headers') {
			// update child headers
			if (this.bin_type) this.bin_type.setConfig(prop, value);
			if (this.location_type) this.location_type.setConfig(prop, value);
			if (this.required_action) this.required_action.setConfig(prop, value);
			if (this.surface) this.surface.setConfig(prop, value);
			if (this.material) this.material.setConfig(prop, value);
			if (this.color) this.color.setConfig(prop, value);
			if (this.emptying_frequency) this.emptying_frequency.setConfig(prop, value);
			if (this.images) this.images.setConfig(prop, value);
			if (this.history) this.history.setConfig(prop, value);
			if (this.remarks) this.remarks.setConfig(prop, value);
		}

		return this;
	}

	parse(data) {
		data = super.parse(data);

		// catch textareas and normalize them
		if (data) {
			if (data.remark && typeof data.remark === 'object') data.remark = data.remark.value;
			if (data.emptying_frequency_exception && typeof data.emptying_frequency_exception === 'object')
				data.emptying_frequency_exception = data.emptying_frequency_exception.value;
		}

		return data;
	}

	checkforChangedFields(model) {
		// Loop through cloned model to check for changes
		const changedFields = [];
		for (const key of Object.keys(model._values)) {
			let thisHasChanged = false;
			// Check if value is photo
			if (model._values[key] instanceof PhotoCollection) {
				// Check amount of images
				if (model._values.images._models.length !== this._values.images._models.length) {
					thisHasChanged = true;
				} else {
					// Loop through all images
					for (let i = 0; i < model._values.images._models.length; i++) {
						thisHasChanged = model._values.images._models[i].id !== this._values.images._models[i].id;
					}
				}
			}

			// Check if value is object & compare ID's in object
			else if (typeof model._values[key] === 'object' && !Array.isArray(model._values[key])) {
				thisHasChanged = model._values[key].id !== this._values[key].id;
			} else if (Array.isArray(model._values[key])) {
				if (model._values[key].length !== this._values[key].length) {
					thisHasChanged = true;
				} else {
					for (let i = 0; i < model._values[key].length; i++) {
						thisHasChanged = model._values[key][i] !== this._values[key][i];
					}
				}
			}

			// Compare value if number or string
			else {
				thisHasChanged = model._values[key] !== this._values[key];
			}

			if (thisHasChanged) changedFields.push(key);
		}
		return changedFields;
	}

	destroy() {}

	// utility methods
	clearAddress() {
		this.street = this.city = this.post_code = this.latitude = this.longitude = '';
	}

	// getters & setters
	get coordinates() {
		return { latitude: this.geolocation.lat, longitude: this.geolocation.lon };
	}

	get latitude() {
		return this.geolocation.lat;
	}

	get longitude() {
		return this.geolocation.lon;
	}

	set latitude(val) {
		return(this.geolocation.lat = val);
	}

	set longitude(val) {
		return(this.geolocation.lon = val);
	}

	get gmapCoordinates() {
		return { lat: this.geolocation.lat, lng: this.geolocation.lon };
	}

	// extra getters
	get fullAddress() {
		let full = '';

		if (this.street) full += this.street;

		if (full.includes(`${this.post_code} ${this.city}`)) {
			return full
		} else {
			if (this.street && (this.post_code || this.city)) full += ', ';
			if (this.post_code) full += this.post_code;
			if (this.city) full += ` ${this.city}`;
		}

		return full;
	}
}

function cleanUpPatchFields(data, fieldsToPatch) {
	if (!fieldsToPatch) return data;
	if (data && fieldsToPatch) {
		for (const field in data) {
			if (!fieldsToPatch.includes(field)) {
				delete data[field];
			}
		}
	}
	return data;
}

function cleanUpdataForPayload(data, type) {
	// clean up some properties before posting
	if (data.changed !== undefined) delete data.changed;
	if (data.checked !== undefined) delete data.checked;
	if (data.created !== undefined) delete data.created;
	if (data.permissions !== undefined) delete data.permissions;
	if (data.deletePermission !== undefined) delete data.deletePermission;
	if (data.qr !== undefined) delete data.qr;
	if (data.qr_blob !== undefined) delete data.qr_blob;
	if (data.user) delete data.user;
	if (data.history) delete data.history;

	if (type === 'post' && data.bin_damage) delete data.bin_damage;
	if (type === 'post' && data.bin_damage_remark) delete data.bin_damage_remark;

	// if (type === 'post' && data.images && !data.images.length) delete data.images;
	if (type === 'post' && data.remarks && !data.remarks.length) delete data.remarks;

	return data;
}

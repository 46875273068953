// imports
import JsonApiModel from '$dependencies/JsonApiModel';
import Config from '$root/Config';

// private static properties
const API_DICTIONARY = {
	uuid: 'id',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	name: { type: String, default: '' },
	parent: {type: String, default: null }, 
};

// class definition
export default class BinTypeModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;
		args.entityType = 'taxonomy_term--bin_types';
		args.config = {
			url: Config.getUrl('binTypes'),
		};

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);
	}

	parse(data) {
		// hack into data to move relationship "parent" to attribute desired parsing!
		if (data.data.relationships) {
			let id = null;
			if (data.data.relationships.parent) {
				id = data.data.relationships.parent.data[0].id;
				if (id === 'virtual') id = null; // 'virtual' == null!
			}
	
			data.data.attributes.parent = id;
			delete data.data.relationships.parent;
		}
	
			// debugger;
	
			return super.parse(data);
		}

	get urlKey() {
		return this.name.toLowerCase().replace(' ', '-');
	}
}

export default {
	props: {
		modifiers: {
			type: Array,
			default: () => [],
		},

		href: {},

		externalLink: {
			type: String,
			required: false,
		},
	},

	data() {
		return {};
	},

	computed: {
		tag: {
			cache: false,
			get() {
				return this.href ? 'router-link' : 'span';
			},
		},

		modifierString() {
			return this.modifiers.length ? `logo--${this.modifiers.join(' logo--')}` : '';
		},
	},

	methods: {
		onClick() {
			if (this.externalLink) {
				window.location = this.externalLink;
			}
		},
	},

	watch: {
		$el() {
			this.$forceUpdate();
		},
	},
};

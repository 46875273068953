import Config from '$root/Config';
import JsonApiModel from '$dependencies/JsonApiModel';

import CommunityModel from '$models/CommunityModel';
import CategoryModel from '$models/CategoryModel';
import PostcodeModel from '$models/PostcodeModel';
import UserModel from '$models/UserModel';
import IncidentReportTypeModel from '$models/IncidentReportTypeModel';
import StatusModel from '$models/StatusModel';

import PhotoCollection from '$collections/PhotoCollection';

export const API_DICTIONARY = {
	//uuid: 'id',
	field_incident_archived: 'archived',
	field_incident_category: 'category',
	field_incident_city: 'city',
	field_incident_community: 'community',
	field_incident_description: 'description',
	field_incident_legal_info: 'legal_info',
	// 'field_incident_images':             'images',
	field_incident_candidate_image: 'images',
	field_incident_post_code: 'post_code',
	field_incident_post_code_ref: 'post_code_ref',
	field_incident_reporter_email: 'reporter_email',
	field_incident_reporter_name: 'reporter_name',
	field_incident_reporter_phone: 'reporter_phone',
	field_incident_reporter_sub: 'reporter_subscribed',
	field_incident_status: 'status',
	field_incident_street: 'street',
	field_incident_volume: 'volume',
	field_incident_id: 'custom_id',
	field_incident_report_type: 'report_type',
	field_incident_location: 'location',
	uid: 'user',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	custom_id: { type: String, default: '' },
	changed: { type: Date, default: () => new Date() },
	created: { type: Date, default: () => new Date() },
	title: { type: String, default: '' },
	archived: { type: Boolean, default: false },
	category: { type: CategoryModel, default: () => new CategoryModel(), referenceType: 'taxonomy_term--incident_categories' },
	city: { type: String, default: '' },
	community: { type: CommunityModel, default: () => new CommunityModel(), referenceType: 'node--community' },
	description: { type: String, default: '' },
	legal_info: { type: String, default: '' },
	images: { type: Object, default: () => new PhotoCollection(), referenceType: 'file--file' },
	post_code: { type: String, default: '' },
	post_code_ref: { type: Object, default: () => new PostcodeModel(), referenceType: 'node--post_code' },
	report_type: { type: Object, default: () => new IncidentReportTypeModel(), referenceType: 'taxonomy_term--incident_report_type' },
	reporter_email: { type: String, default: '' },
	reporter_name: { type: String, default: '' },
	reporter_phone: { type: String, default: '' },
	reporter_subscribed: { type: Boolean, default: false },
	// status: { type: Object, default: () => null), referenceType: 'taxonomy_term--incident_status' },
	street: { type: String, default: '' },
	volume: { type: Number, default: 0 },
	permissions: { type: Object, default: () => {} },
	user: { type: Object, default: () => new UserModel(), referenceType: 'user--user' },
	location: { type: Object, default: {lat: 0, lon: 0} }
};

let id = 0;

export default class IncidentCandidateModel extends JsonApiModel {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.entityType = 'node--incident_candidate';
		args.fields = FIELDS;

		args.config = Object.assign(
			{
				url: '/jsonapi/node/incident_candidate',
			},
			args.config || {},
		);

		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		super(args);

		// set images field url
		this.images.setConfig('url', Config.getUrl('incidentCandidateImages'));

		this.instance_id = id++;

		this._subFetch = args._subFetch || false;
	}

	fetch(data) {
		this.set('category', { reset: true });
		this.set('community', { reset: true });
		this.set('images', { reset: true });
		this.set('post_code_ref', { reset: true });
		this.set('user', { reset: true });

		if (this.category) this.category.setConfig('headers', this.config.headers);
		if (this.images) this.images.setConfig('headers', this.config.headers);

		return super.fetch(data);
	}

	post(data) {
		// change location format
		this.location = `POINT (${this.location.lon} ${this.location.lat})`;

		data = cleanUpdataForPayload(data || this.toObject(true), 'post');
		delete data.id;
		this.setConfig('url', Config.getUrl('incidentCandidate'));

		return super.post(data);
	}

	patch(data) {
		return super.patch(cleanUpdataForPayload(data || this.toObject(true), 'patch'));
	}

	setConfig(prop, value) {
		super.setConfig(prop, value);

		if (prop === 'headers') {
			if (this.category) this.category.setConfig(prop, value);
			if (this.images) this.images.setConfig(prop, value);
		}

		return this;
	}

	parse(data) {
		data = super.parse(data);

		if (data.description && typeof data.description === 'object') data.description = data.description.value;
		if (data.legal_info && typeof data.legal_info === 'object') data.legal_info = data.legal_info.value;

		return data;
	}

	destroy() {}

	clearAddress() {
		this.street = this.city = this.post_code = this.latitude = this.longitude = '';
		this.post_code_ref = null;
	}

	get coordinates() {
		return { latitude: this.location.lat, longitude: this.location.lon };
	}

	get gmapCoordinates() {
		return { lat: this.location.lat, lng: this.location.lon };
	}

	get latitude() {
		return this.location.lat;
	}

	get longitude() {
		return this.location.lon;
	}

	set latitude(val) {
		return(this.location.lat = val);
	}

	set longitude(val) {
		return(this.location.lon = val);
	}

	get fullAddress() {
		let full = '';

		if (this.street) full += this.street;
		if (this.street && (this.post_code || this.city)) full += ', ';
		if (this.post_code) full += this.post_code;
		if (this.city) full += ` ${this.city}`;

		return full;
	}
}

function cleanUpdataForPayload(data, type) {
	if (data.changed !== undefined) delete data.changed;
	if (data.created !== undefined) delete data.created;
	if (data.permissions !== undefined) delete data.permissions;
	if (data.user) delete data.user;

	return data;
}

import JsonApiModel from '$dependencies/JsonApiModel';

const API_DICTIONARY = {
	uuid: 'id',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	name: { type: String, default: '' },
};

export default class BinRemarkStatusModel extends JsonApiModel {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.entityType = 'taxonomy_term--bin_remark_status';
		args.fields = FIELDS;

		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		super(args);
	}

	get urlKey() {
		return this.name.toLowerCase().replace(' ', '-');
	}
}

// imports
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _keys from 'lodash/keys';
import _head from 'lodash/head';
import _cloneDeep from 'lodash/cloneDeep';

import Collection from '$dependencies/Collection';
import JsonApiModel from '$dependencies/JsonApiModel';

import { convertFiltersToUrlParams, mutate, sortInfoToJsonApi, invertDictionary } from '$dependencies/MC_JsonApi_Base';
import {getTimestampRange} from "../utils/dateFormat";

// class definition (non-jsonapi collection base)
export default class MMCollectionMinsky extends Collection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model;
		// call super constructor
		super(args);

		this.parentModel = args.parentModel || new JsonApiModel();
		this.filterDictionary = args.filterDictionary || {};
		this.filtered = false;
		this.filterConversions = args.filterConversions ||[];
		this.api = {
			filters: {},
			filterGroups: {},
			order: {},
			secondFilters: {},
		};
		this._pager = {
			current: 1,
			total: 0,
			totalPages: 0,
			countPerPage: 50,
		};
	}

	resetFilters() {
		this.api = {
			filters: {},
			filterGroups: {},
			order: {},
			secondFilters: {},
		};

		return this;
	}

	setCallFilter(filter, doReplace = false) {
		// check if filter has group
		if (doReplace) {
			this.api.filters = filter;
		} else {
			Object.assign(this.api.filters, filter);
		}

		return this;
	}

	setFilterGroup(group) {
		Object.assign(this.api.filterGroups, group);
	}

	setSecondFilter(filter) {
		Object.assign(this.api.secondFilters, filter);
	}

	getFiltersByGroup(group) {
		const filters = [];

		for (const key in this.apiFilter) {
			if (this.apiFilter.hasOwnProperty(key)) {
				if (this.apiFilter[key].group === _head(_keys(group))) {
					filters.push(this.apiFilter[key]);
				}
			}
		}

		return filters.length ? filters : undefined;
	}

	removeFilterGroup(key) {
		// delete actual group
		delete this.api.filterGroups[key];
		// delete filters who are memberOf group
		for (const filter in this.api.filters) {
			if (this.api.filters.hasOwnProperty(filter)) {
				if (this.api.filters[filter].group === key) delete this.api.filters[filter];
			}
		}
	}

	resetFilterGroup() {
		this.api.filterGroups = {};
	}

	removeCallFilter(key) {
		delete this.api.filters[key];
		return this;
	}

	setCallOrder(fields, options = {}) {
		if (typeof fields !== 'object') {
			// translate params for old code
			const field = fields;
			const type = options;

			console.warn(
				'[',
				this.constructor.name,
				'] - setCallOrder(field, type) is deprecated, use setCallOrder(order:object) to support multiple ordering rules.',
			);

			this.api.order.by = field;

			// update type (optional)
			if (type) this.api.order.type = type.toLowerCase();

			// issue warning for devs if field is reference but no dot was found in field name
			// make new instance to get field definitions
			const { fields } = new this.Model();
			if (fields[field] && fields[field].referenceType) {
				console.warn(
					'Given field for order is reference field. Dot-notation is required to know which field of the reference is to be used! Order request has been ignored to avoid unexplainable errors.',
				);
				return this;
			}

			// split on dot when present (in case of reference fields)
			const fieldParts = field.split('.');

			// prepare sort value
			let sortValue = (type === 'desc' ? '-' : '') + translateToJsonApi(fieldParts.shift(), this.api.dictionary);

			// add extra field fo reference to order to
			if (fieldParts.length) sortValue += `.${fieldParts.join('.')}`;

			// set url param in config
			this.setConfig('params', { sort: sortValue });

			return this;
		}

		// merge or replace field info
		this.api.order = Object.assign(options.replace === false ? this._jsonApi.order : {}, fields);

		return this;
	}

	resetPageParams() {
		delete this._config.params.page;
	}

	fetch(valuesToMap) {
		// set filters
		const translatedFilters = getTranslatedFilters(this, this.filterDictionary, this.parentModel, valuesToMap);
		const filterUrlParams = convertFiltersToUrlParams(translatedFilters, this.api.filterGroups, this.api.secondFilters);
		const sort = sortInfoToJsonApi(this.api.order, invertDictionary(this.filterDictionary));

		if (sort.length) {
			this.setConfig('params', { sort });
		}

		this.setConfig('params', filterUrlParams);

		// call super && clean up filter params afterwards

		return super.fetch().finally(() => {
			this.unsetConfig('params', filterUrlParams).unsetConfig('params', 'sort');
		});
	}

	parse(data, pagination = {}) {
		if (!_isEmpty(pagination)) {
			this._pager.current = pagination.currentPage;
			this._pager.total = pagination.total;
			this._pager.totalPages = pagination.totalPages;
			this._pager.countPerPage = pagination.countPerPage;
		}

		return super.parse(data);
	}

	nextPage(onSuccess) {
		if (this.hasNextPage()) {
			fetchWithPageUrl(this, 'next').then(onSuccess);
		}

		return this;
	}

	previousPage(onSuccess) {
		if (this.hasPreviousPage()) {
			fetchWithPageUrl(this, 'previous').then(onSuccess);
		}

		return this;
	}

	hasNextPage() {
		return this._pager.current < this._pager.totalPages;
	}

	hasPreviousPage() {
		return this._pager.current > 1;
	}

	get currentPage() {
		return this._pager.current;
	}

	get totalPages() {
		return this._pager.totalPages;
	}

	get total() {
		return this._pager.total;
	}

	get apiFilter() {
		return this.api.filters;
	}

	get apiFilterGroups() {
		return this.api.filterGroups;
	}

	get apiOrder() {
		return this.api.order;
	}
}

// translation utils
function getTranslatedFilters(instance, filterDictionary, parentModel, valuesToMap) {
	let instanceCopy = _cloneDeep(instance);
	const actionsMap = {
		timestampArrayToSeconds: (el) => getTimestampRange(el.value).split(','),
	}

	if (!_isEmpty(valuesToMap)){
		_forEach(valuesToMap, ({ key, action }) => {
			instanceCopy.api.filters[key] = {
				...instance.api.filters[key],
				value: actionsMap[action](instance.api.filters[key])
			}
		})
	}
	
	if(!_isEmpty(instance.filterConversions)) {
		_forEach(instance.filterConversions, ({ key, action }) => {
			if(instance.api.filters[key]) {
				instanceCopy.api.filters[key] = {
					...instance.api.filters[key],
					value: actionsMap[action](instance.api.filters[key])
				}
			}
		})
	}

	// prep new filter object & ensure instance presence
	instance = instance || this;
	const newFilters = {};

	// loop over all properties & translate them using the models instance
	for (const key of _keys(instanceCopy.api.filters)) {
		if (!instanceCopy.api.filters[key].field) {
			continue;
		}
		// translate field key
		const nestedFields = instanceCopy.api.filters[key].field.split('.');
		let field = false;
		let fieldDef = false;

		if (nestedFields.length > 1) {
			fieldDef = (instance.fields || instance.api.fields || instance.parentModel.fields)[nestedFields[0]];
			nestedFields = nestedFields.map((f) => {
				f = translateFilterField(f, filterDictionary);
				return f;
			});
			field = nestedFields.join('.');
		} else {
			field = translateFilterField(instanceCopy.api.filters[key].field, filterDictionary);
			fieldDef = (parentModel.fields || parentModel.api.fields)[instanceCopy.api.filters[key].field]; // depending on instance is Model or Collection
			// if field is reference => add id to key
			if (_get(fieldDef, 'referenceType')) field += '.id';
		}

		// use new key to create translated filter
		newFilters[key] = Object.assign({}, instanceCopy.api.filters[key]);
		newFilters[key].field = field;

		// do value mutatiion if necessary based on field default value => (ex: date field needs to be in seconds for api, js works with ms => needs to be corrected)
		const defaultValue = _isFunction(_get(fieldDef, 'default')) ? fieldDef.default() : _get(fieldDef, 'default');

		switch (true) {
			// Converted Date value is in ms => convert to s for backend
			case defaultValue instanceof Date:
				newFilters[key] = mutate(newFilters[key], (v) => Math.round(v / 1000));
				break;

			default:
		}
	}

	// return the results
	return newFilters;
}

function fetchWithPageUrl(collection = null, direction = 'next') {
	collection = collection || this;

	const currentPage = collection._pager.current;
	const page = direction === 'next' ? currentPage : currentPage - 2;

	collection.setConfig('params', { page });

	return collection.fetch();
}

function translateFilterField(field, dictionary) {
	if (Array.isArray(field)) {
		const result = [];
		for (const f of field) {
			result.push(translateFilterField(f, dictionary));
		}
		return result;
	}
	return dictionary[field] ? dictionary[field] : field;
}

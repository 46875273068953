import _get from 'lodash/get';
import _map from 'lodash/map';

import Config from '$root/Config';
import ReportBinsModel from '$models/ReportBinsModel';
import MMCollection from './MMCollection';

const filterDictionary = {
	// post_code: 'field_incident_post_code',
	// category: 'field_incident_category.id',
	// volume: 'field_incident_volume',
	// status: 'field_incident_status',
	// team: 'field_incident_team',
	// bin_hasremarks: 'field_incident_bin_hasremarks',
};

export default class ReportBinsCollection extends MMCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || ReportBinsModel;
		args.filterDictionary = filterDictionary;
		super(args);
	}

	parse(response) {
		return super.parse([new this.Model().set(response)]);
	}

	fetch() {
		const communityID = localStorage.getItem('communityID') || window.vueStore.state.session.user.community.id;
		this.setConfig('url', `${Config.getUrl('reportBins')}/${communityID}`);

		return super.fetch();
	}
}

import _get from 'lodash/get';
import _delay from 'lodash/delay';
import _isString from 'lodash/isString';
import _has from 'lodash/has';
import _split from 'lodash/split';
import { differenceInCalendarDays } from 'date-fns';

import Config from '$root/Config';
import BinsData from '$models/BinsData';
import MMCollection from './MMCollection';

const filterDictionary = {};

export default class ReportBinsMeasurementsCollection extends MMCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || BinsData;
		args.filterDictionary = filterDictionary;
		super(args);
	}

	parse(response) {
		return super.parse([new this.Model().set(response)]);
	}

	get daysBetween() {
		const rangeDate = _split(this.getConfig('params').range, ',')
			.map((date) => new Date(Number(date) * 1000))
			.reverse();

		return differenceInCalendarDays(...rangeDate);
	}

	fetchSuper() {
		const communityID = localStorage.getItem('communityID') || window.vueStore.state.session.user.community.id;

		this.setConfig('url', `${Config.getUrl('dataBins')}/${communityID}`);
		this.setConfig('params', {
			datesExpanded: 'all',
		});

		// super.fetch can be only called in method.
		return super.fetch().then((response) => { 
			return response 
		});
	}

	async fetch() {
		let correctResult = false;
		do {
			var p = await new Promise((resolve, reject) => {
				const result = this.fetchSuper();
				if(result) {resolve(result)}
			});
			//check if respond is correct data or "We are building your response"
			// if is correct we will return, if not we will wait 500 ms and refetch. 
			if (this.isBuilding) {
				// console.warn('refetching');
				const waitPromise = await new Promise((resolve, reject) => {
					setTimeout(() => {
					  resolve('foo');
					}, 500);
				  });	
			} else {
				correctResult = true;
				return p;
			}
		} while (!correctResult);
			
	}

	get isBuilding() {
		return !_has(this.models, '[0].data') || _isString(_get(this.models, '[0].data'));
	}
}

import _isFunction from 'lodash/isFunction';

import Config from '$root/Config.js';

// Shortcut to props nested deepely inside a route object passed to the watch function of a component
// Caches the given object to avoid reoccuring dot-searches
let lastRouteObj = null;
let lastRouteProps = null;

export function getRouteProps(routeObj, index = 0) {
	if (lastRouteObj === routeObj) {
		return lastRouteObj;
	}

	if (routeObj.matched[index]) {
		lastRouteProps = routeObj.matched[index].props.default;
	} else {
		lastRouteObj = null;
	}

	return _isFunction(lastRouteProps) ? lastRouteProps(routeObj) : lastRouteProps;
}

export function urlPattern(pattern, input) {
	if (Array.isArray(input)) {
		for (let i = 0; i < input.length; i++) {
			if (urlPattern(pattern, input[i])) {
				return true;
			}
		}
		return false;
	}
	if (Array.isArray(pattern)) {
		for (let i = 0; i < pattern.length; i++) {
			if (urlPattern(pattern[i], input)) {
				return true;
			}
		}
		return false;
	}

	const re = new RegExp(pattern.replace(/([.?+^$[\]\\(){}|/-])/g, '\\$1').replace(/\*/g, '.*'));
	return re.test(input);
}

export function generateID(length = 1, delimiter = '_') {
	const id = [];

	for (let i = 0; i < length; i++) {
		id.push(
			Math.random()
				.toString(36)
				.substr(2),
		);
	}

	return id.join(delimiter);
}

export function provideDefaultValues(values, defaultValues) {
	const newValues = {};

	// set default config
	for (const p in defaultValues) {
		switch (typeof values[p]) {
			case 'object':
				newValues[p] = provideDefaultValues(values[p], defaultValues[p]);
				break;

			case 'boolean':
				newValues[p] = values[p];
				break;

			case 'number':
				newValues[p] = values[p];
				break;

			default:
				newValues[p] = values[p] || defaultValues[p];
		}
	}

	return newValues;
}

export function formatDate(value, format = Config.dateFormat) {
	// convert to date object
	const d = new Date(value);

	return !isNaN(d)
		? format
				.replace(/YYYY/, d.getFullYear())
				.replace(/YY/, d.getFullYear() % 100)
				.replace(/MM/, padStart(d.getMonth() + 1, 2, '0'))
				.replace(/M/, d.getMonth() + 1)
				.replace(/DD/, padStart(d.getDate(), 2, '0'))
				.replace(/D/, d.getDate())
				.replace(/HH/, padStart(d.getHours(), 2, '0'))
				.replace(/H/, d.getHours())
				.replace(/hh/, padStart(d.getHours() % 12, 2, '0'))
				.replace(/h/, d.getHours() % 12)
				.replace(/A/, d.getHours() < 12 ? 'AM' : 'PM')
				.replace(/a/, d.getHours() < 12 ? 'am' : 'pm')
				.replace(/mm/, padStart(d.getMinutes(), 2, '0'))
				.replace(/m/, d.getMinutes())
				.replace(/ss/, padStart(d.getSeconds(), 2, '0'))
				.replace(/s/, d.getSeconds())
		: '-';
}

export function padStart(input, length, symbol = ' ') {
	let str = input.toString();

	while (str.length < length) {
		str = symbol + str;
	}

	return str;
}

export function toUTF8Array(str) {
	/*
		The logic of encoding Unicode in UTF-8 is basically:
		- Up to 4 bytes per character can be used. The fewest number of bytes possible is used.
		- Characters up to U+007F are encoded with a single byte.
		- For multibyte sequences, the number of leading 1 bits in the first byte gives the number of bytes for the character. The rest of the bits of the first byte can be used to encode bits of the character.
		- The continuation bytes begin with 10, and the other 6 bits encode bits of the character.

		https://stackoverflow.com/a/18729931/10239606
	*/

	const utf8 = [];
	for (let i = 0; i < str.length; i++) {
		let charcode = str.charCodeAt(i);
		if (charcode < 0x80) utf8.push(charcode);
		else if (charcode < 0x800) {
			utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
		} else if (charcode < 0xd800 || charcode >= 0xe000) {
			utf8.push(0xe0 | (charcode >> 12), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
		}
		// surrogate pair
		else {
			i++;
			// UTF-16 encodes 0x10000-0x10FFFF by
			// subtracting 0x10000 and splitting the
			// 20 bits of 0x0-0xFFFFF into two halves
			charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
			utf8.push(0xf0 | (charcode >> 18), 0x80 | ((charcode >> 12) & 0x3f), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
		}
	}
	return utf8;
}

export function getURLParams(url) {
	// get query string from url (optional) or window
	let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

	// we'll store the parameters here
	const obj = {};

	// if query string exists
	if (queryString) {
		// stuff after # is not part of query string, so get rid of it
		queryString = queryString.split('#')[0];

		// split our query string into its component parts
		const arr = queryString.split('&');

		for (let i = 0; i < arr.length; i++) {
			// separate the keys and the values
			const a = arr[i].split('=');

			// in case params look like: list[]=thing1&list[]=thing2
			var paramNum = undefined;
			const paramName = a[0].replace(/\[\d*\]/, function(v) {
				paramNum = v.slice(1, -1);
				return '';
			});

			// set parameter value (use 'true' if empty)
			const paramValue = typeof a[1] === 'undefined' ? true : a[1];

			// (optional) keep case consistent
			// paramName = paramName.toLowerCase()
			// paramValue = paramValue.toLowerCase()

			// if parameter name already exists
			if (obj[paramName]) {
				// convert value to array (if still string)
				if (typeof obj[paramName] === 'string') {
					obj[paramName] = [obj[paramName]];
				}
				// if no array index number specified...
				if (typeof paramNum === 'undefined') {
					// put the value on the end of the array
					obj[paramName].push(paramValue);
				}
				// if array index number specified...
				else {
					// put the value at that index number
					obj[paramName][paramNum] = paramValue;
				}
			}
			// if param name doesn't exist yet, set it
			else {
				obj[paramName] = paramValue;
			}
		}
	}

	return obj;
}

export function tplToDom(tpl) {
	const temp = document.createElement('div');
	temp.innerHTML = tpl;
	return temp.firstChild;
}

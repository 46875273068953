var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Overlay", {
    ref: "overlay",
    staticClass: "overlay--browser",
    attrs: { title: "Google Chrome", root: true },
    on: { close: _vm.onClose, afterhide: _vm.onAfterHide },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function() {
            return [
              _vm._t("body", function() {
                return [
                  _c("p", [
                    _vm._v("We merken dat je een andere browser gebruikt dan "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.google.com/intl/nl/chrome/?brand=YTUH&gclid=CjwKCAiAs8acBhA1EiwAgRFdw9nUdzYOLNrC0L3sM6Ac7vZ3XX_7Bn3chtmFDnqazKESPK2MVGCapBoCMGkQAvD_BwE&gclsrc=aw.ds"
                        }
                      },
                      [_vm._v("Google Chrome")]
                    ),
                    _vm._v(
                      ". Voor de best mogelijke gebruikservaring raden we steeds het gebruik van "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.google.com/intl/nl/chrome/?brand=YTUH&gclid=CjwKCAiAs8acBhA1EiwAgRFdw9nUdzYOLNrC0L3sM6Ac7vZ3XX_7Bn3chtmFDnqazKESPK2MVGCapBoCMGkQAvD_BwE&gclsrc=aw.ds"
                        }
                      },
                      [_vm._v("Google Chrome")]
                    ),
                    _vm._v(" aan.")
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Je kan je huidige browser verder gebruiken, maar het is mogelijk dat bepaalde functionaliteiten niet beschikbaar zijn of niet werken zoals het hoort."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.google.com/intl/nl/chrome/?brand=YTUH&gclid=CjwKCAiAs8acBhA1EiwAgRFdw9nUdzYOLNrC0L3sM6Ac7vZ3XX_7Bn3chtmFDnqazKESPK2MVGCapBoCMGkQAvD_BwE&gclsrc=aw.ds"
                        }
                      },
                      [_vm._v("Download Google Chrome.")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: "checkbox",
                      label: "Deze melding niet meer tonen."
                    },
                    model: {
                      value: _vm.checked,
                      callback: function($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked"
                    }
                  })
                ]
              })
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "footer-actions-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "button button button--filled button--big",
                    on: { click: _vm.onClose }
                  },
                  [_vm._v("\n\t\t\t\tSluiten\n\t\t\t")]
                )
              ])
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
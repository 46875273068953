// imports
import _get from 'lodash/get';

import Collection from '$dependencies/Collection';
import JsonApiModel from '$dependencies/JsonApiModel';
import { isTypes } from '$utils/TypeChecks';
import { deepCloneObj } from '$utils/MC_Utils';
import {
	convertFiltersToUrlParams,
	extractDictionaryFromModel,
	extractFieldsFromModel,
	generateFieldsets,
	getTranslatedFilters,
	translateToJsonApi,
	sortInfoToJsonApi,
} from '$dependencies/MC_JsonApi_Base';

// private static properties
const MODEL_EVENTS = 'response,change,locked,unlocked,cleared';
const API_FILTER_GROUP = 'incident-group';
const ROUND_TO_SECONDS = true;

// class definition
export default class JsonApiCollection extends Collection {
	// constructor
	constructor(args = {}) {
		// clone args to allow modifications
		args = Object.assign({}, args);
		args.Model = args.Model || JsonApiModel;

		super(args);

		// set properties
		this._pager = {
			first: null,
			next: null,
			previous: null,
			last: null,
			current: 0,
			total: 0,
		};

		// jsonApi settings
		const jsonApi = args.jsonApi || {};
		const tTypes = isTypes(['object', 'boolean'], jsonApi.translationTargets, 'or') ? jsonApi.translationTargets : true;

		this._jsonApi = {
			dictionary: this._Model ? extractDictionaryFromModel(this._Model) : {},
			fields: this._Model ? extractFieldsFromModel(this._Model) : {},
			// filters: {filtername: [{field: '', operator: '>=', memberOf: group, value: ''}]},
			// filterGroups: {stad: {conjunction: 'OR', memberOf: 'province'}},
			filters: {},
			filterGroups: {},
			order: jsonApi.order || {},
			translationTargets: {
				fetch: tTypes.fetch || tTypes,
				post: tTypes.post || tTypes,
				patch: tTypes.patch || tTypes,
				delete: tTypes.delete || tTypes,
			},
		};

		// jsonapi include
		this.include = args.include || [];

		// set config
		this.setConfig('headers', {
			'Content-Type': 'application/vnd.api+json',
		});
	}

	// methods
	fetch(preventClear) {
		// prep filters
		const translatedFilters = getTranslatedFilters(this);
		const filterUrlParams = convertFiltersToUrlParams(translatedFilters, this._jsonApi.filterGroups);

		// prep fieldset
		const fieldset = generateFieldsets(this);

		// prep sort
		const sort = sortInfoToJsonApi(this._jsonApi.order, this._jsonApi.dictionary);

		this.setConfig('params', filterUrlParams).setConfig('params', fieldset);

		if (sort.length) this.setConfig('params', { sort });

		// call super && clean up filter params afterwards
		return super.fetch(preventClear).finally(() => {
			this.unsetConfig('params', filterUrlParams)
				.unsetConfig('params', fieldset)
				.unsetConfig('params', 'sort');
		});
	}

	fetchAll() {
		this.resetPageParams();
		return new Promise((resolve, reject) => {
			this.fetch().then(() => {
				checkForMore();
			});

			const checkForMore = () => {
				if (!this.hasNextPage()) {
					this.emit('allFetched');
					resolve();
				} else {
					this.loadNext().then(() => {
						checkForMore();
					});
				}
			};
		});
	}

	fetchAllIf(condition) {
		switch (typeof condition) {
			case 'function':
				if (condition(this)) return this.fetchAll();
				break;

			case 'boolean':
				if (condition) return this.fetchAll();
				break;
			default:
		}
	}

	post() {
		return new Promise((resolve, reject) => {
			setAllModelsToLoading(this);
			saveModels({ method: 'post', resolve, reject, collection: this });
		});
	}

	patch(data, options) {
		return new Promise((resolve, reject) => {
			setAllModelsToLoading(this);
			saveModels({ params: [data, options], method: 'patch', resolve, reject, collection: this });
		});
	}

	clone(deep = false, config = false) {
		const collection = super.clone(deep, config);
		collection._jsonApi = deepCloneObj(this._jsonApi);

		return collection;
	}

	nextPage(onSuccess) {
		if (this.hasNextPage()) {
			fetchWithPageUrl(this._pager.next, this).then(onSuccess);
		}

		return this;
	}

	loadNext() {
		if (this.hasNextPage()) return fetchWithPageUrl(this._pager.next, this, true);
	}

	hasNextPage() {
		return !!_get(this, '_pager.next', false);
	}

	previousPage(onSuccess) {
		if (this.hasPreviousPage()) {
			fetchWithPageUrl(this._pager.previous, this).then(onSuccess);
		}

		return this;
	}

	hasPreviousPage() {
		return !!_get(this, '_pager.previous', false);
	}

	get currentPage() {
		return this._pager.current;
	}

	get totalPages() {
		return this._pager.total;
	}

	get amountOfAllData() {
		return this._pager.amountOfAllData;
	}

	ajax(data, preventClear) {
		// check include property and add them as included recources to request
		if (this.include.length) {
			// translate include array
			this.translatedFields = [];

			for (const field of this.include) {
				this.translatedFields.push(translateToJsonApi(field, this._jsonApi.dictionary));
			}

			this.setConfig('params', {
				include: this.translatedFields.join(','),
			});
		}

		return super.ajax(data, preventClear).finally(() => {
			this.unsetConfig('params', 'include');
		});
	}

	parse(data) {
		// check if translation is required
		if (this._jsonApi.translationTargets.fetch) {
			// move all
			const newData = [];

			// loop over all data records and parse them with the static parse function of the model this collection will use
			if (this.Model.prototype instanceof JsonApiModel) {
				if (data === null) debugger;

				// extract inludes if any to add them to all the models
				const included = data.included || [];

				for (const record of data.data) {
					// parse to models immediately and use its parser
					const model = new this.Model();

					model.set(
						model.parse({
							data: record,
							included,
						}),
						{ replace: true, merge: false },
					);

					// set model config url if links are present
					if (record.links) {
						// use related over self
						const linkObj = record.links.related || record.links.self;
						if (linkObj) model.setConfig('url', linkObj.href);
					}

					// copy headers
					model.setConfig('headers', this.getConfig('headers'));

					// push
					newData.push(model);
				}
			}

			// check for pager
			if (data.links) {
				const { current, total } = getPaginationAdditionalInfo(data);

				this._pager.first = data.links.first ? data.links.first.href : null;
				this._pager.next = data.links.next ? data.links.next.href : null;
				this._pager.previous = data.links.prev ? data.links.prev.href : null;
				this._pager.last = data.links.last ? data.links.last.href : null;
				this._pager.amountOfAllData = _get(data, 'meta.count', null);
				this._pager.current = current;
				this._pager.total = total;
			}

			// override data
			data = newData;
		}

		return super.parse(data);
	}

	toPayload(type) {
		// turn models to payload
		const payload = [];
		for (const model of this._models) {
			payload.push(model.toPayload(type));
		}
		return payload;
	}

	setCallFilter(filter, doReplace = false) {
		// check if filter has group
		if (doReplace) {
			this._jsonApi.filters = filter;
		} else {
			Object.assign(this._jsonApi.filters, filter);
		}

		return this;
	}

	setFilterGroup(group) {
		Object.assign(this._jsonApi.filterGroups, group);
	}

	removeFilterGroup(key) {
		// delete actual group
		delete this._jsonApi.filterGroups[key];
		// delete filters who are memberOf group
		for (const filter in this._jsonApi.filters) {
			if (this._jsonApi.filters.hasOwnProperty(filter)) {
				if (this._jsonApi.filters[filter].group === key) delete this._jsonApi.filters[filter];
			}
		}
	}

	removeCallFilter(key) {
		delete this._jsonApi.filters[key];
		return this;
	}

	resetPageParams() {
		delete this._config.params['page[limit]'];
		delete this._config.params['page[offset]'];
	}

	setCallOrder(fields, options = {}) {
		if (typeof fields !== 'object') {
			// translate params for old code
			const field = fields;
			const type = options;

			console.warn(
				'[',
				this.constructor.name,
				'] - setCallOrder(field, type) is deprecated, use setCallOrder(order:object) to support multiple ordering rules.',
			);

			this._jsonApi.order.by = field;

			// update type (optional)
			if (type) this._jsonApi.order.type = type.toLowerCase();

			// issue warning for devs if field is reference but no dot was found in field name
			// make new instance to get field definitions
			const { fields } = new this.Model();
			if (fields[field] && fields[field].referenceType) {
				console.warn(
					'Given field for order is reference field. Dot-notation is required to know which field of the reference is to be used! Order request has been ignored to avoid unexplainable errors.',
				);
				return this;
			}

			// split on dot when present (in case of reference fields)
			const fieldParts = field.split('.');

			// prepare sort value
			let sortValue = (type === 'desc' ? '-' : '') + translateToJsonApi(fieldParts.shift(), this._jsonApi.dictionary);

			// add extra field fo reference to order to
			if (fieldParts.length) sortValue += `.${fieldParts.join('.')}`;

			// set url param in config
			this.setConfig('params', {
				sort: sortValue,
			});

			return this;
		}

		// merge or replace field info
		this._jsonApi.order = Object.assign(options.replace === false ? this._jsonApi.order : {}, fields);

		return this;
	}

	removeCallOrder(fields) {
		// fields can be comma separated string or array
		if (typeof fields === 'string') fields = fields.split(',');

		// loop over filter object & remove field informtion accordingly
		for (const f of Object.keys(field)) {
			if (this._jsonApi.order[f]) delete this._jsonApi.order[f];
		}

		return this;
	}

	destroy() {
		// call super destroy
		super.destroy();
	}

	getFiltersByGroup(group) {
		const filters = [];
		for (const key in this.apiFilter) {
			if (this.apiFilter.hasOwnProperty(key)) {
				if (this.apiFilter[key].group === Object.keys(group)[0]) {
					filters.push(this.apiFilter[key]);
				}
			}
		}
		return filters.length ? filters : undefined;
	}

	// getters & setters
	get Model() {
		return this._Model;
	}

	set Model(Value) {
		this._ModelValue = Value;
		this.identifier = Value.identifier;
		this._jsonApi.dictionary = extractDictionaryFromModel(Value);
	}

	get apiFilter() {
		return this._jsonApi.filters;
	}

	get apiFilterGroups() {
		return this._jsonApi.filterGroups;
	}

	get apiOrder() {
		return this._jsonApi.order;
	}
}

function setAllModelsToLoading(collection) {
	for (const model of collection._models) {
		model.loading = true;
	}
}

function saveModels({ params = [], method, resolve, reject, collection, index = 0 }) {
	// get model & add listener
	const model = collection.at(index);

	// if no model found => ready!
	if (model) {
		// add once
		model.once('response', { collection: this, index }, () => {
			saveModels({ params, method, resolve, reject, collection, index: index + 1 });
		});

		// keep original headers && set collection headers
		const orHeaders = model.config.headers;
		model.config.headers = Object.assign({}, collection.config.headers);

		// perform method
		model[method](...params);

		// put original headers back
		model.config.headers = orHeaders;

		// emit progress event
		collection.emit('progress', {
			method,
			progress: index / collection.length,
			index,
			total: collection.length,
		});
	} else {
		// resolve
		resolve();

		// emit ready class
		collection.emit('progress,ready', {
			method,
			progress: index / collection.length,
			index,
			total: collection.length,
		});
	}
}

function fetchWithPageUrl(url, collection = null, preventClear) {
	// ensure collection variable
	collection = collection || this;

	// process url
	const urlInfo = processUrlLinkParams(url);

	// set collection url
	collection.setConfig('url', urlInfo.url);

	// set collection config
	collection.setConfig('params', urlInfo.params);

	// fetch
	return collection.fetch(preventClear);
}

function processUrlLinkParams(url) {
	// split up using ?
	const parts = url.split('?');

	// return processed url in object form
	return {
		url: parts[0],
		params: parts[1] ? processUrlParamString(parts[1]) : {},
	};
}

function processUrlParamString(str = '') {
	const params = {};

	// remove json api's 'include=&' before processing
	str = decodeURI(str.replace('include=&', ''));

	// break up in param pairs
	const paramPairs = str.split('&');
	for (let i = 0; i < paramPairs.length; i++) {
		const paramParts = paramPairs[i].split('=');
		params[paramParts[0]] = paramParts[1];
	}

	return params;
}

function getPaginationAdditionalInfo(data) {
	const urlParams = processUrlParamString(_get(data, 'links.self.href', ''));
	const offset = parseInt(_get(urlParams, 'page[offset]', 0));
	const limit = parseInt(_get(urlParams, 'page[limit]', 50));
	const totalRecords = _get(data, 'meta.count', 0);
	const current = Math.floor(offset / limit) + 1;
	const total = Math.ceil(totalRecords / limit);

	return { current, total };
}

// function getTranslatedFilters (collection) {
// 	// prep new filter object & ensure collection presence
// 	collection = collection || this;
// 	let newFilters = {}

// 	// loop over all properties & translate them using the models collection
// 	for (let key of Object.keys(collection._jsonApi.filters)) {
// 		// translate field key
// 		let newKey = translateToJsonApi(key, collection._jsonApi.dictionary);
// 		const fieldDef = collection._jsonApi.fields[key]

// 		// if field is reference => add id to key
// 		if (fieldDef.referenceType) newKey += '.id';

// 		// use new key to create translated filter
// 		newFilters[newKey] = collection._jsonApi.filters[key];

// 		// do value mutatiion if necessary based on field default value => (ex: date field needs to be in seconds for api, js works with ms => needs to be corrected)
// 		let defaultValue = (typeof fieldDef.default === 'function') ? fieldDef.default() : fieldDef.default;

// 		switch (true) {

// 			// Converted Date value is in ms => convert to s for backend
// 			case defaultValue instanceof Date:
// 				newFilters[newKey] = mutate(newFilters[newKey], v => Math.round(v / 1000));
// 			break;

// 			default:
// 		}
// 	}

// 	// return the results
// 	return newFilters;
// }

// function convertFiltersToUrlParams(filters) {

// 	// prep some variables
// 	let result = {};

// 	// set first parameter
// 	result['filter[incident-group][group][conjunction]'] = 'AND';

// 	// loop over items of filter
// 	for (let key of Object.keys(filters)) {
// 		let filter = filters[key];

// 		// convert shortcut to full filter definition
// 		if (typeof filter === 'string' || typeof filter === 'number' || typeof filter === 'boolean' || Array.isArray(filter)) {
// 			filter = {
// 				operation: 'equal',
// 				value: filter
// 			};
// 		}

// 		// generate base key
// 		let baseKey = `filter[${key}]`;

// 		// add properties (allow 'equal' to be used to define 'IN' operation)
// 		result[baseKey + '[operator]'] = (filter.operation === 'equal') ? 'IN' : filter.operation.toUpperCase();
// 		result[baseKey + '[condition][memberOf]'] = API_FILTER_GROUP;

// 		let values = (!Array.isArray(filter.value)) ? [filter.value] : filter.value;
// 		if (values.length > 1) {
// 			for (let i = 0; i < values.length; i++) {
// 				result[baseKey + '[value][' + i + ']'] = values[i]
// 			}
// 		}
// 		else {
// 			result[baseKey + '[value]'] = values[0]
// 		}
// 	}

// 	return result;
// }

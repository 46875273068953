import _findIndex from 'lodash/findIndex';

// imports
import SessionModel from '../../models/session/SessionModel';
import SessionCheckModel from '../../models/session/SessionCheckModel';
import UserModel from '../../models/UserModel';
import GdprUserModel from '../../models/session/GdprUserModel';
import TermsOfUseModel from '../../models/session/TermsOfUseModel';
import AccessCheckModel from '../../models/session/AccessCheckModel';
import NotificationModel from '../../models/session/NotificationsModel'
import { setSuccessMessage } from '../../utils/setGlobalMessage';

import Config from '../../Config';
import Pusher from 'pusher-js'
import Notifications from '@gmirmand/vue-notification';

import Vue from 'vue'; 

// private statics

// module definition
export default {
	namespaced: true,

	// state
	state() {
		return {
			loading: false,
			authenticated: false,
			checker: new SessionCheckModel(),
			model: new SessionModel({
				autoLoad: true,
			}),
			user: new UserModel({
				include: ['community', 'field_user_community.field_community_api_partners'],
			}),
			gdprUser: new GdprUserModel(),
			rememberLoggedIn: false,
			hideMeasurementIntro: false,
			momoApiAcces: new AccessCheckModel({type: 'momoApi'}),
			exportReportsApiAcces: new AccessCheckModel({type: 'exportReportsApi'}),
			groupAdminAccess: new AccessCheckModel({type: 'groupAdminAccess'}),
			// termsOfUse: new TermsOfUseModel(),
			notifcations: new NotificationModel()
		};
	},

	// getters
	getters: {
		model: ({ model }) => model,
		user: ({ user }) => user,
		community: ({ user: { community } }) => community,
		jwt: ({ model: { jwt_token } }) => jwt_token,
		jwt_authorization: ({ model: { jwt_token } }) => `Bearer ${jwt_token}`,
		tokenAuth: ({ model: { jwt_token } }) => ({ Authorization: `Bearer ${jwt_token}` }),
	},

	// mutations
	mutations: {
		AUTHENTICATED(state) {
			state.authenticated = true;
		},

		LOADING(_, payload) {
			this.loading = payload;
		},

		LOGOUT(state) {
			// clear all models
			localStorage.removeItem('communityID');
			localStorage.removeItem('vuex');
			localStorage.removeItem('projects')
			state.user.clear();
			state.model.logout();
		},

		SET_LOGGED_IN(state, newValue) {
			state.rememberLoggedIn = newValue;
		},
	},

	// actions
	actions: {
		logIn({ commit, dispatch, getters, state, rootState }, payload) {
			const { user, password, rememberLoggedIn, clientId, clientSecret } = payload;

			// set load state
			commit('LOADING', true);
			state.authenticated = false;
			dispatch('setLoggedIn', rememberLoggedIn);

			// log in
			return (
				state.model
					.login(user, password, rememberLoggedIn, clientId, clientSecret)
					.catch(() => {
						commit('LOADING', false);
					})
			);
		},

		setLoggedIn({ commit }, payload) {
			commit('SET_LOGGED_IN', payload);
		},

		updateToken({ state }, payload) {
			state.model.setToken(payload);
		},

		updateRefreshToken({ state }, payload) {
			state.model.setRefreshToken(payload);
		},

		loadUser({ commit, dispatch, state, getters }) {
			// use session uid
			if (state.model.uid) {
				//check if uid is same as the one saved in storage (else clear some saved data)
				if(localStorage.getItem('userID')) {
					if(`${localStorage.getItem('userID')}` !== `${state.model.uid}`) {
						dispatch('onLogout', {}, {root: true});
					}
				}
				//set uid in storage to check if clearing of any storage is neccesary
				localStorage.setItem('userID', state.model.uid);
				// apply jwt to models
				state.user.setConfig('headers', getters.tokenAuth);
				state.checker.setConfig('headers', getters.tokenAuth);
				state.gdprUser.setConfig('headers', getters.tokenAuth);

				return state.user
					.fetchByUid(state.model.uid)
					.then((response) => {
						if (localStorage.getItem('communityID')) {
							localStorage.removeItem('communityID')
							localStorage.removeItem('projects')
							localStorage.removeItem('vuex')
						}

						localStorage.setItem('communityID', response.data.community.id);
						state.user.community.postcodes.setConfig('headers', getters.tokenAuth);

						const promises = [];
						// fetch postal codes
						promises.push(state.user.community.postcodes.fetch());
						// fetch api-partners
						promises.push(state.user.community.incidents_api_partners.fetch());
						// fetch gdpr user
						promises.push(state.gdprUser.fetch());

						return Promise.all(promises).then(() => {
							commit('LOADING', false);
							dispatch('connectPusher');
							commit('AUTHENTICATED');
						})
					})
					.catch(() => {
						dispatch('logOut');
						commit('LOADING', false);
					});
			} else {
				dispatch('logOut');
			}
		},

		logOut({ commit, dispatch }) {
			commit('LOGOUT');
			dispatch('filters/clearFilters', null, { root: true });
			//remove uid from localstorage
			localStorage.removeItem('userID');
		},

		checkAuthentication({ commit, dispatch, state, getters }) {
			// set load state
			commit('LOADING', true);

			// load user if session has jwt info
			const jwt = state.model.jwt_token;
			const hasUser = state.user.id && state.user.uid && state.user.changed;
			const hasCommunity = state.user.community.id && state.user.community.changed;

			// enough information, fetch session check
			if (jwt && hasUser && hasCommunity) {
				state.authenticated = true;
				return Promise.resolve();
			}
			if (jwt) {
				return dispatch('loadUser');
			}
			// needs login for access
			state.authenticated = false;
			return false;
		},

		approveGdprTerms({ state }) {
			state.gdprUser.acceptProTermsOfUse = true;
			state.gdprUser
				.patch()
				.then((data) => console.log(data))
				.catch( (e) => console.log(e) )
		},

		updateHideMeasurementIntro({state}, payload) {
			state.hideMeasurementIntro = payload
		},

		checkMomoApi({state, getters}) {
			state.momoApiAcces.setConfig('headers', getters.tokenAuth);
			state.momoApiAcces.fetch();
		},

		checkExportReportsApi({state, getters}) {
			state.exportReportsApiAcces.setConfig('headers', getters.tokenAuth);
			state.exportReportsApiAcces.fetch();
		},

		checkGroupAdmin({state, getters}, payload) {
			state.groupAdminAccess.setConfig('headers', getters.tokenAuth);
			state.groupAdminAccess.fetch(payload);
		},

		connectPusher({state, commit, dispatch}) {
			const pusher = new Pusher(Config.pusher_authkey, {
				cluster: Config.pusher_cluster,
				channelAuthorization: {
					// develop: 'https://api-develop.mijnmooiestraat.vlaanderen/pusher/auth'
					endpoint: window.location.host.includes('pro-loc') || window.location.host.includes('pro-develop') ? 'https://api-staging.mijnmooiestraat.vlaanderen/pusher/auth' : ( document.location.host.includes('pro-staging') ? 'https://api-staging.mijnmooiestraat.vlaanderen/pusher/auth' : (document.location.host.includes('pro-beta') ? 'https://api-beta.mijnmooiestraat.vlaanderen/pusher/auth' : 'https://api.mijnmooiestraat.vlaanderen/pusher/auth'))
				}
			});
			  
			const channel = pusher.subscribe(`private-notifications-${state.user.uid}`);

			channel.bind('notification', function (data) {
				data.message = data.message.replace("&lt;", "<")
				data.message = data.message.replace("&gt;", ">")
				data.link.url = decodeURI(data.link.url)
				data.link.url = data.link.url.replace('backUrl=/', 'backUrl=%2F')

				if(data.type === 'activity_heatmap') {
					if (data.link.url.includes(window.location.hash) && window.location.hash === '#kaartoverzicht') {
						dispatch('activitiesDM/fetchData', {}, {root: true});
					} else if (`${window.location.origin}${window.location.pathname}${window.location.search}` === data.link.url.replace('#heatmap', '')) {
						dispatch('activitiesDM/fetchData', {}, {root: true});
	
						if(window.location.hash !== '#heatmap') {
							Vue.prototype.$notify({
								group: 'mms-notifications',
								type: 'success',
								text: `${data.title} ${data.message}`,
								data: {
									hasLink: true,
									urlText: 'Naar de heatmap',
									type: 'heatmap'
								}
							})
						}
					} else {
						if(data.link) {
							var url = data.link.url.replace(window.location.origin, '')

							if (url.includes('#kaartoverzicht')) {
								url = url.replace('#kaartoverzicht', '#heatmap')
							}
							
							if (window.location.hash !== '#heatmap') {
								Vue.prototype.$notify({
									group: 'mms-notifications',
									type: 'success',
									text: `${data.title} ${data.message}`,
									data: {
										hasLink: true,
										url: url,
										urlText: 'Naar de heatmap'
									}
								})
							} else {
								dispatch('activitiesDM/fetchData', {}, {root: true})
							}
						} else {
							Vue.prototype.$notify({
								group: 'mms-notifications',
								type: 'success',
								text: `${data.title} ${data.message}`,
								data: {
									type: 'heatmap',
								}
							})
						}
					}
				} else {
					if(data.link) {
						Vue.prototype.$notify('xlsxPusher')
						if (data.severity === 'success') {
							// if (data.link.title.includes('Download')) {
							// 	var downloadEl =  document.createElement('a')
							// 	downloadEl.setAttribute('href', data.link.url)
							// 	downloadEl.setAttribute('download', 'export')
							// 	downloadEl.style.display = 'none'
							// 	document.body.appendChild(downloadEl)
							// 	downloadEl.click()
							// 	document.body.removeChild(downloadEl)
							// }

							Vue.prototype.$notify.close('xlsxPusher')
							Vue.prototype.$notify({
								group: 'mms-notifications',
								type: 'success',
								text: `${data.title} ${data.message}`,
								data: {
									hasLink: true,
									url: data.link.url,
									urlText: data.link.title
								}
							})
							
						} else {
							Vue.prototype.$notify.close('xlsxPusher')
							Vue.prototype.$notify({
								group: 'mms-notifications',
								type: 'error',
								text: `${data.title} ${data.message}`,
								data: {
									hasLink: true,
									url: data.link.url,
									urlText: data.link.title
								}
							})
						}
					} else {
						Vue.prototype.$notify('xlsxPusher')
						if (data.severity === 'success') {
							Vue.prototype.$notify.close('xlsxPusher')
							Vue.prototype.$notify({
								group: 'mms-notifications',
								type: 'success',
								text: `${data.title} ${data.message}`,
							})
						} else {
							Vue.prototype.$notify.close('xlsxPusher')
							Vue.prototype.$notify({
								group: 'mms-notifications',
								type: 'error',
								text: `${data.title} ${data.message}`,
							})
						}
					}
					
				}
			});
		},
	},
};

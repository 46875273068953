import OverlayChildMixin from '$mixins/OverlayChildMixin.js';

export default {
	props: {
		nested: {
			type: Boolean,
			default: false,
		},
		opaque: {
			type: Boolean,
			default: false,
		},
		isShown: {
			type: Boolean,
			default: false,
		},
		noClose: {
			type: Function,
		}
	},

	mixins: [OverlayChildMixin],

	data() {
		return {};
	},
};

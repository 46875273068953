var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Overlay",
    {
      ref: "overlay",
      staticClass: "overlay-editList",
      attrs: { root: true, title: _vm.title },
      on: { close: _vm.onClose }
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "overlay-editList--body" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.body) + "\n\t\t")
        ])
      ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "footer-actions-group footer-actions-group--right" },
          [
            _c(
              "button",
              {
                staticClass:
                  "button button--big button--light button--carnation",
                on: { click: _vm.onClose }
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(_vm.cancelButton) + "\n\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button--big button--filled",
                on: { click: _vm.onConfirm }
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(_vm.confirmButton) + "\n\t\t\t")]
            )
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "infoBox", class: _vm.type }, [
    _c(
      "div",
      { staticClass: "infoBox--content" },
      [
        _vm.withIcon
          ? _c("span", {
              staticClass: "icon infoBox--icon",
              class: _vm.iconClass
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
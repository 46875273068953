// imports
import _get from 'lodash/get';
import _map from 'lodash/map';
import Config from '$root/Config';
import Collection from '$dependencies/Collection';
import TaxonomyModel from '$models/TaxonomyModel';

// private static properties

// class definition
export default class TaxonomyCollection extends Collection {
	// constructor
	constructor(args = {}) {
		// clone args to allow modifications
		args = Object.assign({}, args);
		args.Model = args.Model || TaxonomyModel;

		if(args.type) {
			args.config = Object.assign(args.config ? args.config : {}, {
				url: Config.getUrl(args.type),
			});
		}
		
		// call super constructor
		super(args);
	}

	parse(data) {
		const terms = _get(data, 'data');
		data = _map(terms, (item) => {
			// create model
			item.label = item.title;
			item.name = item.title;
			item.value = item.uuid;
			item.id = item.uuid;
			const newModel = new this.Model().set(item);
			return newModel;
		});
		return super.parse(data);
	}

	toAVSList() {
		// convert this collection to array for AdvancedSelectComponent
		return collectionToAvSelectOptionList(this);
	}
	
	toAVTList() {
		return collectionToAvTreeSelectList(this);
	}

	getCrumb(model, prop = null) {
		// model could also be a model, which is also allowed
		// if model is string => get model by string
		// if model is model => get model by id of that model to make sure model has all the data required, could be a search query
		model = this.get(typeof model === 'string' ? model : model.id);

		// render crumb & return
		return renderCrumb(model, this, prop);
	}

	get treeStructure() {
		const items = this.toArray(true);
		const structure = [];
		//get all parents
		const parents = items.filter(item => {
			return !item.parent;
		});
		//loop each parent to find his children and put them intro the new structure
		parents.forEach(p => {
			const children = items.filter(item => {
				return item.parent === p.uuid;
			})
			const newParent = Object.assign(p, {children: children.length ? children : undefined});
			structure.push(newParent);
		});
		
		return structure;
	}

	get labelDictionary() {
		const dict = {};
		this.toArray(true).forEach(item => {
			dict[item.machineName] = item.label;
		})
		return dict;
	}
	
	get colorDictionary() {
		const dict = {};
		this.toArray(true).forEach(item => {
			dict[item.machineName] = item.color;
		})
		return dict;
	}
}

function collectionToAvSelectOptionList(collection, parentId = undefined) {
	const options = collection
		.where((item) => item.parent === parentId)
		._models.map((model) => ({
			id: model.id,
			title: model.name,
			name: model.name,
			options: collectionToAvSelectOptionList(collection, model.id),
			parentId: parentId,
			parent: parentId,
			model,
		}));
	return options.length ? options : null;
}

function collectionToAvTreeSelectList(collection, parentId = undefined) {
	const options = collection
		.where((item) => item.parent === parentId)
		._models.map((model) => ({
			id: model.id,
			label: model.name,
			children: collectionToAvTreeSelectList(collection, model.id),
			model,
		}));

	if (options.length) {
		return options;
	}
}

function renderCrumb(model, collection, prop) {
	// block if model is not defined
	if (!model) return [];

	// render path by swimming upstream
	let parentModel;
	let id = model.parent;
	const p = [prop ? model.get(prop) : model];

	while ((parentModel = collection.findWhere((item) => item.id == id))) {
		// keep model or prop of model if prop was provided
		p.unshift(prop ? parentModel.get(prop) : parentModel);

		// break out if current parent has no parent
		if (!parentModel.parent) break;

		// replace id before looping again
		id = parentModel.parent.id;
	}

	return p;
}

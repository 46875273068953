var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.inDom || _vm.visible,
          expression: "inDom || visible"
        }
      ],
      ref: "overlay",
      staticClass: "overlay",
      class: [
        _vm.overlayClass,
        _vm.stateCssClass,
        { "overlay--transparentBg": _vm.noBg },
        { "overlay--fullScreen": _vm.fullScreen },
        { "overlay--mobile": _vm.isMobile },
        { "overlay--desktop": !_vm.isMobile }
      ],
      attrs: { title: _vm.title }
    },
    [
      _c(
        _vm.tag,
        {
          directives: [
            {
              name: "scroll-lock",
              rawName: "v-scroll-lock",
              value: _vm.visible,
              expression: "visible"
            }
          ],
          tag: "component",
          staticClass: "overlay-container",
          class: _vm.hasAdditionalInputsSlot && "additionalInputs-width"
        },
        [
          _vm.showHeader
            ? _c("div", { staticClass: "overlay-header" }, [
                _vm.hasHeaderSlot
                  ? _c(
                      "div",
                      { staticClass: "overlay-inner overlay-inner--header" },
                      [_vm._t("header")],
                      2
                    )
                  : _c(
                      "div",
                      { staticClass: "overlay-inner overlay-inner--header" },
                      [
                        _c("h2", { staticClass: "overlay-title" }, [
                          _vm._v(_vm._s(_vm.title))
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "overlay-closeBtn icon icon--shakespeare icon--cross",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm._onCloseClick.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Sluiten")]
                        )
                      ]
                    )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAdditionalInputsSlot
            ? _c("div", [_vm._t("additionalInputs")], 2)
            : _vm._e(),
          _vm._v(" "),
          _vm.hasBodySlot
            ? _c("div", { staticClass: "overlay-body" }, [
                _c(
                  "div",
                  { staticClass: "overlay-inner overlay-inner--body" },
                  [_vm._t("body")],
                  2
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasFooterSlot
            ? _c("div", { staticClass: "overlay-footer" }, [
                _c(
                  "div",
                  { staticClass: "overlay-inner overlay-inner--footer" },
                  [_vm._t("footer")],
                  2
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "overlay-bg",
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm._onCloseClick.apply(null, arguments)
          }
        }
      }),
      _vm._v(" "),
      _vm._t("outsideOverlay")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-item",
      class: [
        _vm.itemType ? "form-item--" + _vm.itemType + _vm.typeModifier : "",
        _vm.type ? "form-item--" + _vm.type + _vm.typeModifier : "",
        _vm.required ? "form-item--required" : "",
        _vm.disabled ? "form-item--disabled" : "",
        _vm.modifiers.length
          ? "form-item--" + _vm.modifiers.join(" form-item--")
          : "",
        !_vm.isValid || _vm.customErrorMessage ? "form-item--error" : "",
        _vm.hasFocus ? "form-item--hasFocus" : "",
        _vm.hasContent ? "form-item--hasContent" : "",
        _vm.hasAutoFill ? "form-item--hasAutoFill" : ""
      ]
    },
    [
      _c(
        "div",
        [
          _vm._t("before"),
          _vm._v(" "),
          (_vm.isContentInput ||
            _vm.isNumberInput ||
            _vm.isEditableNumberInput ||
            _vm.isSelect) &&
          _vm.label
            ? _c(
                "label",
                { staticClass: "form-label", attrs: { for: _vm.fieldName } },
                [_vm._v(_vm._s(_vm.label))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "dropzone"
            ? _c(
                "div",
                {
                  directives: [{ name: "dropzone", rawName: "v-dropzone" }],
                  ref: "dropzone",
                  staticClass: "dropzone",
                  on: {
                    dropzone_drop: _vm.onDropzoneDrop,
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onDropzoneClick.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "dropzone-label" }, [
                    _vm._v(_vm._s(_vm.dropZoneLabel))
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isTextInput
            ? _c("input", {
                ref: "field",
                attrs: {
                  id: _vm.fieldId,
                  name: _vm.fieldName,
                  type: _vm.fieldType,
                  placeholder: _vm.placeholder,
                  required: _vm.required,
                  disabled: _vm.disabled,
                  autocomplete: _vm.autocomplete
                },
                domProps: { value: _vm.value },
                on: {
                  input: function($event) {
                    return _vm.onInput($event)
                  },
                  keydown: [
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onKeyEnter($event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.onKeyDown($event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.onKeyUp($event)
                    }
                  ],
                  focus: _vm.onFocus,
                  blur: _vm.onBlur
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isDateNoOverlayInput
            ? _c("input", {
                ref: "field",
                attrs: {
                  name: _vm.fieldName,
                  type: "text",
                  placeholder: _vm.placeholder
                },
                domProps: { value: _vm.value },
                on: {
                  input: function($event) {
                    return _vm.onInput($event)
                  },
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown"
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.onKeyDown($event)
                  },
                  focus: _vm.onFocus,
                  blur: _vm.onBlur
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isFileInput
            ? _c("input", {
                ref: "field",
                attrs: {
                  id: _vm.fieldId,
                  name: _vm.fieldName,
                  type: _vm.fieldType,
                  placeholder: _vm.placeholder,
                  required: _vm.required,
                  disabled: _vm.disabled,
                  autocomplete: _vm.autocomplete
                },
                on: {
                  change: function($event) {
                    $event.preventDefault()
                    return _vm.onFileUploadFieldChange($event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isCheckOrRadio
            ? _c("input", {
                ref: "field",
                attrs: {
                  id: _vm.fieldId,
                  name: _vm.fieldName,
                  type: _vm.fieldType,
                  placeholder: _vm.placeholder,
                  required: _vm.required,
                  disabled: _vm.disabled,
                  autocomplete: _vm.autocomplete
                },
                domProps: {
                  value: _vm.value,
                  checked: _vm.value,
                  indeterminate: _vm.indeterminateValue
                },
                on: {
                  change: function($event) {
                    return _vm.onCheckChange($event)
                  },
                  input: _vm.onInput
                }
              })
            : _vm.isTextArea
            ? _c("textarea", {
                directives: [{ name: "autogrow", rawName: "v-autogrow" }],
                ref: "field",
                attrs: {
                  id: _vm.fieldId,
                  name: _vm.fieldName,
                  placeholder: _vm.placeholder,
                  required: _vm.required,
                  disabled: _vm.disabled,
                  autocomplete: _vm.autocomplete
                },
                domProps: { value: _vm.value },
                on: {
                  input: function($event) {
                    return _vm.onInput($event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isNumberInput || _vm.isEditableNumberInput
            ? _c(
                "div",
                { staticClass: "formItem--number__wrapper" },
                [
                  !_vm.isEditableNumberInput
                    ? _c("input", {
                        ref: "field",
                        attrs: {
                          id: _vm.fieldId,
                          name: _vm.fieldName,
                          type: _vm.fieldType,
                          placeholder: _vm.placeholder,
                          required: _vm.required,
                          disabled: _vm.disabled,
                          min: _vm.min,
                          max: _vm.max,
                          step: _vm.step,
                          autocomplete: _vm.autocomplete
                        },
                        domProps: { value: _vm.value },
                        on: {
                          input: function($event) {
                            return _vm.onInput($event)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("numberBetween"),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "formControls formControls--number" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "formControls-button formControls-button--min",
                          class: {
                            disabled:
                              _vm.disableOnEdges && _vm.currentValue === _vm.min
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onNumberMin.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "minus-text" }, [
                            _vm._v("-")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "formControls-value" },
                        [
                          !_vm.isEditableNumberInput
                            ? _vm._t("value", function() {
                                return [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.currentValue) +
                                      "\n\t\t\t\t\t"
                                  )
                                ]
                              })
                            : _c("input", {
                                ref: "field",
                                attrs: {
                                  type: "number",
                                  id: _vm.fieldId,
                                  name: _vm.fieldName,
                                  placeholder: _vm.placeholder,
                                  required: _vm.required,
                                  disabled: _vm.disabled,
                                  min: _vm.min,
                                  max: _vm.max,
                                  autocomplete: _vm.autocomplete
                                },
                                domProps: { value: _vm.value },
                                on: {
                                  input: function($event) {
                                    return _vm.onInput($event)
                                  }
                                }
                              })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "formControls-button formControls-button--plus",
                          class: {
                            disabled:
                              _vm.disableOnEdges && _vm.currentValue === _vm.max
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onNumberPlus.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "plus-text" }, [
                            _vm._v("+")
                          ])
                        ]
                      )
                    ]
                  )
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSelect
            ? _c(
                "select",
                {
                  ref: "field",
                  staticStyle: { display: "block" },
                  attrs: {
                    name: _vm.fieldName,
                    required: _vm.required,
                    disabled: _vm.disabled,
                    autocomplete: _vm.autocomplete
                  },
                  on: {
                    input: function($event) {
                      return _vm.onInput($event)
                    }
                  }
                },
                [
                  _c("option", { attrs: { selected: "", disabled: "" } }, [
                    _vm._v(_vm._s(_vm.placeholder))
                  ]),
                  _vm._v(" "),
                  _vm._l(this.options, function(option, index) {
                    return _vm._t(
                      "option",
                      function() {
                        return [
                          _c(
                            "option",
                            {
                              key: index,
                              attrs: { disabled: option.disabled },
                              domProps: {
                                selected:
                                  option.selected ||
                                  option.value === _vm.value ||
                                  option.title === _vm.value ||
                                  option === _vm.value,
                                value:
                                  option.value ||
                                  option.id ||
                                  option.label ||
                                  option.title
                              }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(
                                    option.label ||
                                      option.title ||
                                      option.value ||
                                      option
                                  ) +
                                  "\n\t\t\t\t"
                              )
                            ]
                          )
                        ]
                      },
                      { option: option }
                    )
                  })
                ],
                2
              )
            : _vm.isAVSelect
            ? _c("AdvancedSelect", {
                ref: "field",
                attrs: {
                  options: _vm.options,
                  label: _vm.label,
                  showLabel: _vm.showLabel,
                  boolSelect: _vm.boolSelect,
                  placeholder: _vm.placeholder,
                  value: _vm.value,
                  dropdownWidth: _vm.dropdownWidth,
                  injectInRoot: _vm.injectInRoot,
                  hideOnScroll: _vm.hideOnScroll
                },
                on: {
                  input: function($event) {
                    return _vm.onInput($event.target.currentItem)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function(slotProps) {
                        return [
                          _vm._v(
                            "\n\t\t\t\t" + _vm._s(slotProps.label) + "\n\t\t\t"
                          )
                        ]
                      }
                    },
                    {
                      key: "placeholder",
                      fn: function(slotProps) {
                        return [
                          _vm._t(
                            "selectPlaceholder",
                            function() {
                              return [_vm._v(_vm._s(slotProps.placeholder))]
                            },
                            { option: slotProps.option }
                          )
                        ]
                      }
                    },
                    {
                      key: "header",
                      fn: function(slotProps) {
                        return [
                          _vm._t(
                            "selectHeader",
                            function() {
                              return [_vm._v(_vm._s(slotProps.option))]
                            },
                            { option: slotProps.option }
                          )
                        ]
                      }
                    },
                    {
                      key: "option",
                      fn: function(slotProps) {
                        return [
                          _vm._t(
                            "selectOption",
                            function() {
                              return [_vm._v(_vm._s(slotProps.option))]
                            },
                            { option: slotProps.option }
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isCheckOrRadio && _vm.label && !_vm.isToggle
            ? _c(
                "label",
                { staticClass: "form-label", attrs: { for: _vm.fieldName } },
                [
                  _vm.typeModifier === "-custom"
                    ? _c("span", { staticClass: "form-item--radio__button" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("label", function() {
                    return [
                      _vm._v("\n\t\t\t\t" + _vm._s(_vm.label) + "\n\t\t\t")
                    ]
                  }),
                  _vm._v(" "),
                  _vm.infoIconTooltip.content
                    ? _c("InfoIcon", {
                        staticClass: "form-label-info-icon",
                        attrs: { tooltip: _vm.infoIconTooltip }
                      })
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isToggle
            ? _c(
                "label",
                {
                  staticClass: "form-label",
                  class: {
                    "toggle-label-left": _vm.titlePosition === "right"
                  },
                  attrs: { for: _vm.fieldName }
                },
                [
                  _vm.titlePosition === "left"
                    ? _vm._t("default", function() {
                        return [
                          _vm._v("\n\t\t\t\t" + _vm._s(_vm.label) + "\n\t\t\t")
                        ]
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "toggle",
                    class: {
                      "toggle-left": _vm.titlePosition === "right"
                    }
                  }),
                  _vm._v(" "),
                  _vm.titlePosition === "right"
                    ? _vm._t("default", function() {
                        return [
                          _vm._v("\n\t\t\t\t" + _vm._s(_vm.label) + "\n\t\t\t")
                        ]
                      })
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSwitch ? _c("br") : _vm._e(),
          _vm._v(" "),
          _vm.isSwitch
            ? _c(
                "span",
                {
                  staticClass: "form-switch",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$refs.field.click()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "form-switchOption" }, [
                    _vm._v(_vm._s(_vm.trueLabel))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "form-switchOption" }, [
                    _vm._v(_vm._s(_vm.falseLabel))
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "date"
            ? _c("div", { staticClass: "datePicker" }, [
                _c(
                  "div",
                  { staticClass: "datepicker-container inline-with-input" },
                  [
                    _c("input", {
                      attrs: {
                        id: "datepicker-inline-trigger",
                        type: "text",
                        placeholder: "Select date"
                      },
                      domProps: { value: _vm.value }
                    }),
                    _vm._v(" "),
                    _c("airbnb-style-datepicker", {
                      attrs: {
                        "trigger-element-id": "datepicker-inline-trigger",
                        mode: "single",
                        inline: true,
                        "fullscreen-mobile": false,
                        "date-one": _vm.currentValue,
                        "months-to-show": 1,
                        "show-action-buttons": true
                      },
                      on: {
                        "date-one-changed": function(val) {
                          return (_vm.currentValue = val)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.description && _vm.description.length
        ? _c(
            "div",
            {
              staticClass: "description description--formItem form-description"
            },
            [_vm._v(_vm._s(_vm.description))]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isValid || _vm.customErrorMessage
        ? _c(
            "div",
            {
              staticClass: "form-error",
              class: { "box-error": _vm.errorVariant === _vm.errorVariants.BOX }
            },
            [
              _vm._t("errorMessage", function() {
                return [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.customErrorMessage || _vm.errorMessage
                      )
                    }
                  })
                ]
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.infoBoxText
        ? _c("InfoBox", { attrs: { withIcon: true } }, [
            _vm._v("\n\t\t" + _vm._s(_vm.infoBoxText) + "\n\t")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("after")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Logo from '$components/Logo/Logo.vue';
import Config from '../../Config';

export default {
	props: {
		large: {
			type: Boolean,
			default: true,
		},
	},

	components: {
		Logo,
	},

	data() {
		const appVersion = Config.version;
		return { appVersion: appVersion ? `v${appVersion}` : '' };
	},

	computed: {},

	methods: {},

	watch: {},
};

// imports
import JsonApiModel from '$dependencies/JsonApiModel';

// private static properties
const API_DICTIONARY = {
	uuid: 'id',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	name: { type: String, default: '' },
};

// class definition
export default class IncidentDeclineReasonModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;
		args.entityType = 'taxonomy_term--incident_decline_reason';

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);
	}

	// methods
	destroy() {}

	// utility methods

	// getters & setters
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "sprite-icon",
      class: [
        _vm.name !== "" ? "sprite-icon--" + _vm.name : "",
        _vm.modifiers.length
          ? "sprite-icon--" + _vm.modifiers.join(" sprite-icon--")
          : ""
      ]
    },
    [
      _vm.loading
        ? _c(
            "svg",
            {
              staticClass: "icon-svg icon-svg--loader",
              attrs: {
                id: "L9",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                x: "0px",
                y: "0px",
                viewBox: "0 0 100 100",
                "enable-background": "new 0 0 0 0",
                "xml:space": "preserve"
              }
            },
            [
              _c(
                "path",
                {
                  attrs: {
                    fill: "currentColor",
                    d:
                      "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                  }
                },
                [
                  _c("animateTransform", {
                    attrs: {
                      attributeName: "transform",
                      attributeType: "XML",
                      type: "rotate",
                      dur: "1s",
                      from: "0 50 50",
                      to: "360 50 50",
                      repeatCount: "indefinite"
                    }
                  })
                ],
                1
              )
            ]
          )
        : _c("img", {
            attrs: {
              src: _vm.getAssetPath(
                (_vm.path.length ? _vm.path : _vm.name) + ".svg",
                "icons"
              ),
              alt: _vm.name + " icon"
            }
          })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
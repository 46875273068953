// imports
import JsonApiCollection from '$dependencies/JsonApiCollection';

import StatusModel from '$models/StatusModel';

// private static properties

// class definition
export default class StatusCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = StatusModel;
		args.jsonApi = { order: { weight: 'asc' } };
		// call super constructor
		super(args);
	}

	// methods
	getByTid(tid) {
		return this.findWhere((item) => item.tid === tid);
	}

	destroy() {}

	// utility methods

	// getters & setters
	get dictionaryByTid() {
		return this.toDictionary('tid');
	}

	get dictionaryByName() {
		return this.toDictionary('name');
	}
	// get dictionaryByModifier() { return this.toDictionary('modifier'); }

	// get modifiers () { return this.listProperty('modifier'); }
}

// imports
import Collection from '$dependencies/Collection';
import PermissionModel from '$models/PermissionModel';

// private static properties

// class definition
export default class PermissionCollection extends Collection {
	// constructor
	constructor(args = {}) {
		// clone args to allow modifications
		args = Object.assign({}, args);
		args.Model = args.Model || PermissionModel;

		// call super constructor
		super(args);
	}

	// methods
	set(model, options = {}) {
		// parse string if model is string
		if (typeof model === 'string') {
			const parts = model.split(' '); // permission postcode category => "edit [id] [id]"

			// make easy shortcuts
			const permission = parts[0];
			const postcode = parts[1];
			const category = parts[2];

			// find out if model already exists using that category id
			let instance = this.findWhere((item) => item.category === category && item.postcode == postcode);
			// let instance = false;

			// create new model if instance isn't found yet
			if (!instance) {
				instance = this.createModel({
					id: `${postcode}|${category}`,
					category,
					postcode,
				});

				super.set(instance, options);
			}

			// apply permission to instance (assuming presense in respons = true value)
			instance[permission] = true;
		} else {
			super.set(model, options);
		}
	}

	parse(data) {
		return super.parse(data.data.attributes.field_user_incident_permissions);
	}

	destroy() {}

	// utility methods
	getByCategory(category) {
		// extract category id & filter out all models with given id
		const id = extractId(category, this.Model);
		return this.where((model) => model.category === id);
	}

	getByPostcode(postcode) {
		// extract category id & filter out all models with given id
		const id = extractId(postcode, this.Model);
		return this.where((model) => model.postcode === id);
	}

	getByPermission(read = null, create = null, edit = null) {
		const query = {};

		if (typeof read === 'string') {
			// read is a string => read, create or edit. will allways equal to true
			query[read] = true;
		} else if (typeof read === 'object' && read !== null) {
			// read is a permission object
			if (read.read) query.read = !!read.read;
			if (read.create) query.create = !!read.create;
			if (read.edit) query.edit = !!read.edit;
		} else {
			// parameters
			if (read) query.read = !!read;
			if (create) query.create = !!create;
			if (edit) query.edit = !!edit;
		}

		// filter
		const filtered = this.where((model) => {
			for (const p in query) {
				if (typeof query[p] === 'boolean') {
					if (query[p] !== model.permission[p]) return false;
				}
			}
			return true;
		});

		return !filtered ? new PermissionCollection() : filtered;
	}

	getPermissionOf(category, postcode, permission) {
		// get category list & postcode list of that category list
		let categories;
		let postcodes;
		let result = false;
		categories = this.getByCategory(category);
		if (categories) postcodes = categories.getByPostcode(postcode);

		// assume postcodes result as 1 record => as should be!
		if (postcodes && postcodes.length) {
			if (permission) {
				result = postcodes[permission];
			} else {
				result = postcodes.at(0);
			}
		}

		return result;
	}

	// getters & setters
	get read() {
		return this.length > 0 ? this.at(0).read : false;
	}

	set read(value) {
		return (this.first().read = value);
	}

	get create() {
		return this.length > 0 ? this.first().create : false;
	}

	set create(value) {
		return (this.first().create = value);
	}

	get edit() {
		return this.length > 0 ? this.first().edit : false;
	}

	set edit(value) {
		return (this.first().edit = value);
	}

	// filtered getters (might be expensive)
	get totalRead() {
		return this.getByPermission(true, null, null).length;
	}

	get totalCreate() {
		return this.getByPermission(null, true, null).length;
	}

	get totalEdit() {
		return this.getByPermission(null, null, true).length;
	}

	get canRead() {
		return !!this.totalRead;
	}

	get canCreate() {
		return !!this.totalCreate;
	}

	get canEdit() {
		return !!this.totalEdit;
	}

	get categories() {
		const categories = [];
		for (const mdl in this._values) {
			categories.push(mdl.category);
		}
		return categories;
	}

	get categories() {
		return this.listProperty('category');
	}

	get postcodes() {
		return this.listProperty('postcode');
	}
}

function extractId(model, Model) {
	if (typeof model === 'string') return model;
	if (typeof model === 'object') {
		if (model instanceof Model) return model[model.identifier];
		return model.id;
	}
}

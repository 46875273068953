var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Overlay", {
    ref: "overlay",
    staticClass: "overlay--gdprTermsDenied",
    attrs: { title: "Ik weiger de gebruiksvoorwaarden.", isShown: false },
    on: { beforehide: _vm.onBeforeHide },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function() {
            return [
              _vm._t("body", function() {
                return [
                  _c("p", [
                    _vm._v(
                      "Ben je zeker dat je de gebruiksvoorwaarden wil weigeren? Als je weigert, word je afgemeld en naar het inlogscherm van Mijn Mooie Straat verwezen."
                    )
                  ])
                ]
              })
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "overlay-footerContent" }, [
                _c("div", { staticClass: "footer-actions-group" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "button button--light button--shakespeare button--hasIconBefore button--hasArrowLeftIcon",
                      on: { click: _vm.onBackToTerms }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\tTerug naar de gebruiksvoorwaarden.\n\t\t\t\t"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button button button--filled button--big",
                      on: { click: _vm.onLogoutClick }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(_vm.buttonText) + "\n\t\t\t\t"
                      )
                    ]
                  )
                ])
              ])
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
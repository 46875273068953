import _find from 'lodash/find';

const allListeners = [];
let id = 0;

const vDropZone = {
	directive: {
		bind(el) {
			const elId = id++;
			el.setAttribute('data-dropzone-id', elId);

			const listeners = {
				id: elId,
				onDragEnter: onDragEnter.bind(this),
				onDragLeave: onDragLeave.bind(this),
				onDragOver: onDragOver.bind(this),
				onDrop: onDrop.bind(this),
			};

			allListeners.push(listeners);

			el.addEventListener('dragenter', listeners.onDragEnter);
			el.addEventListener('dragleave', listeners.onDragLeave);
			el.addEventListener('dragover', listeners.onDragOver);
			el.addEventListener('drop', listeners.onDrop);
		},

		unbind(el) {
			const id = Number(el.getAttribute('data-dropzone-id'));
			const listeners = _find(allListeners, { id });

			if (listeners) {
				el.removeEventListener('dragenter', listeners.onDragEnter);
				el.removeEventListener('dragleave', listeners.onDragLeave);
				el.removeEventListener('dragover', listeners.onDragOver);
				el.removeEventListener('drop', listeners.onDrop);

				allListeners.splice(allListeners.indexOf(listeners));
			}
		},
	},

	plugin: {
		install(Vue) {
			Vue.directive('dropzone', vDropZone.directive);
		},
	},
};

function onDragOver(e) {
	e.preventDefault();
}

function onDragEnter(e) {
	e.preventDefault();
	e.currentTarget.classList.add('incidentPhotoUpload-dropzone--fileOver');
}

function onDragLeave(e) {
	e.preventDefault();
	e.currentTarget.classList.remove('incidentPhotoUpload-dropzone--fileOver');
}

function onDrop(e) {
	e.preventDefault();

	// trigger event
	e.currentTarget.dispatchEvent(
		new CustomEvent('dropzone_drop', {
			detail: {
				files: e.dataTransfer.files,
			},
		}),
	);
}

export default vDropZone;

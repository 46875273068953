// imports
import Config from '$root/Config';
import JsonApiCollection from '$dependencies/JsonApiCollection';

import PhotoModel from '$models/PhotoModel';

// private static properties

// class definition
export default class PhotoCollection extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = PhotoModel;
		args.entityType = 'file--file';

		args.config = {
			url: Config.getUrl('incidentImages'),
		};

		// call super constructor
		super(args);
	}

	// methods

	destroy() {}

	// utility methods

	// getters & setters
}

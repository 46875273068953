import JsonApiCollection from '$dependencies/JsonApiCollection';
import MeasurementDamageTypeModel from '$models/measurement/MeasurementDamageTypeModel';
import Config from '$root/Config';

export default class MeasurementDamageTypeCollection extends JsonApiCollection {
	constructor(args = {}) {
		args = Object.assign({}, args);
		args.Model = args.Model || MeasurementDamageTypeModel;
		args.config = {
			url: Config.getUrl('measurementDamageType'),
		};
		args.jsonApi = { order: { weight: 'asc' } };
		
		super(args);
	}
}

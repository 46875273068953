// imports
import Model from '$dependencies/Model';
import Config from '../../Config';

const FIELDS = {
	pdf: { type: String, default: ''  },
	text: { type: String, default: '' }
};

// class definition
export default class TermsOfUseModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);
	}

	fetch(data) {
		this.setConfig('url', `${Config.getUrl('termsOfUse')}`);
		return super.fetch(data);
	}

	parse(data) {
		return super.parse(data.data);
	}

	// getters & setters
	get id() {
		return this.uuid;
	}
}

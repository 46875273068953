// imports
import Config from '$root/Config';
import Model from '$dependencies/Model';

// private static properties
const FIELDS = {
	allowed: { type: Boolean, default: false },
};

// class definition
export default class AccessCheckModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);

        this.type = args.type;
	}

	// methods
	fetch(tokens = {}) {
        if(this.type) {
            this.setConfig('url', replaceTokens(Config.getUrl(this.type), tokens));
            return super.fetch();
        } else {
            console.error('type parameter is required to fetch correct url.');
        }
	}

	parse(data) {
		return super.parse(data);
	}

	destroy() {}

	// utility methods

	// getters & setters
}


function replaceTokens(str, obj) {
    // Regular expression to match tokens like ${token}
    const tokenRegex = /\${(.*?)}/g;

    // Replace tokens in the string with corresponding values from the object
    return str.replace(tokenRegex, (match, token) => {
        // If the token exists in the object, replace it with its value, otherwise keep it as is
        return obj[token] !== undefined ? obj[token] : match;
    });
}

// imports
import _get from 'lodash/get';

import ProjectModel from '$models/monitoring/ProjectModel';
import MinskyCollection from '../MinskyCollection';
import Config from '$root/Config';

// class definition
export default class ProjectCollection extends MinskyCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || ProjectModel;
		args.config = {
			url: Config.getUrl('projects'),
		};
		
		// call super constructor
		super(args);

		this.urlDictionary = {
			title: 'titel'
		}
	}

	fetch(preventClear) {
		this.setConfig('url', `${Config.getUrl('projects')}`);
		return super.fetch(preventClear);
	}

	parse(data) {
		data.data.map(item => {
			if (item.images) {
				item.images = item.images.map(image => {
					return Object.assign(image, {id: image.uuid});
				})
			}

			const newModel = new this.Model().parse({data: item});
			return newModel;
		});

		return super.parse(data);
	}
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("notifications", {
        attrs: {
          position: "bottom left",
          width: "100%",
          duration: -10,
          closeOnClick: true,
          group: "mms-notifications"
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(ref) {
              var item = ref.item
              var close = ref.close
              return [
                !item.data.hasLink
                  ? _c(
                      "div",
                      {
                        staticClass: "notification",
                        class: item.type,
                        on: { click: close }
                      },
                      [
                        _c("div", { staticClass: "notification-icon" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.getAssetPath(
                                item.type + ".svg",
                                "icons"
                              ),
                              alt: ""
                            }
                          })
                        ]),
                        _vm._v(" "),
                        item.title
                          ? _c("p", { staticClass: "notification-title" }, [
                              _vm._v(_vm._s(item.title))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", { staticClass: "notification-message" }, [
                          _vm._v(_vm._s(item.text))
                        ]),
                        _vm._v(" "),
                        item.data.showLoader
                          ? _c("div", { staticClass: "notification-loader" }, [
                              _c("span", { staticClass: "loader" })
                            ])
                          : _c(
                              "div",
                              {
                                staticClass: "notification-close close-icon",
                                on: { click: close }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.getAssetPath(
                                      "cross--light.svg",
                                      "icons"
                                    )
                                  }
                                })
                              ]
                            )
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "notification", class: item.type },
                      [
                        _c("div", { staticClass: "notification-icon" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.getAssetPath(
                                item.type + ".svg",
                                "icons"
                              ),
                              alt: ""
                            }
                          })
                        ]),
                        _vm._v(" "),
                        item.title
                          ? _c("p", { staticClass: "notification-title" }, [
                              _vm._v(_vm._s(item.title))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", { staticClass: "notification-message" }, [
                          _vm._v(_vm._s(item.text))
                        ]),
                        _vm._v(" "),
                        item.data.hasLink && !item.data.url
                          ? _c(
                              "p",
                              {
                                staticClass: "notification-link",
                                on: {
                                  click: function($event) {
                                    return _vm.switchTab(close)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.data.urlText))]
                            )
                          : item.data.type !== "heatmap"
                          ? _c("a", { attrs: { href: item.data.url } }, [
                              _vm._v(_vm._s(item.data.urlText))
                            ])
                          : item.data.hasLink &&
                            item.data.url !== _vm.currentLocation.href
                          ? _c(
                              "router-link",
                              {
                                staticClass: "notification-link",
                                attrs: { to: item.data.url },
                                nativeOn: {
                                  click: function($event) {
                                    return close.apply(null, arguments)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.data.urlText))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.data.showLoader
                          ? _c("div", { staticClass: "notification-loader" }, [
                              _c("span", { staticClass: "loader" })
                            ])
                          : _c(
                              "div",
                              {
                                staticClass: "notification-close close-icon",
                                on: { click: close }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.getAssetPath(
                                      "cross--light.svg",
                                      "icons"
                                    )
                                  }
                                })
                              ]
                            )
                      ],
                      1
                    )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
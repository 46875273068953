// Collection of shared methods over JsonApiModel & JsonApiCollection
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _keys from 'lodash/keys';
import _merge from 'lodash/merge';
import _isEmpty from 'lodash/isEmpty';
import _castArray from 'lodash/castArray';
import _forEach from 'lodash/forEach';

// Constants
const API_FILTER_GROUP = 'minsky-md-group';

const emptyValuesOperations = ['IS NULL', 'IS NOT NULL'];

// Class (util) methods
export function setFilterParams(instance) {
	instance = instance || this;
	instance.setConfig('params', convertFiltersToUrlParams(getTranslatedFilters(instance)));
}

export function unsetFilterParams(instance) {
	instance = instance || this;
	instance.unsetConfig('params', convertFiltersToUrlParams(getTranslatedFilters(instance)));
}

export function setSparseFieldset(instance) {
	instance = instance || this;
	instance.setConfig('params', generateFieldsets(getTranslatedFilters(instance)));
}

// Util methods
// helper methods
export function getDefaultValueOfFieldDef(fieldDef) {
	return typeof fieldDef.default === 'function' ? fieldDef.default() : fieldDef.default;
}

// general translations
export function translateToJsonApi(field, dictionary) {
	if (Array.isArray(field)) {
		const result = [];
		for (const f of field) {
			result.push(translateToJsonApi(f, dictionary));
		}
		return result;
	}

	for (const p in dictionary) {
		if (dictionary[p] === field) return p;
	}
	return field;
}

export function translateFromJsonApi(field, dictionary) {
	if (Array.isArray(field)) {
		const result = [];
		for (const f of field) {
			result.push(translateFromJsonApi(f));
		}
		return result;
	}

	return dictionary[field] || field;
}

export function invertDictionary(dictionary) {
	const inverted = {};

	for (const p in dictionary) {
		inverted[dictionary[p]] = p;
	}

	return inverted;
}

// sparse fieldsets
export function generateFieldsets(instance, fields = null) {
	// grab instance
	instance = instance || this;

	// get fields
	const entityType = instance.entityType || new instance.Model().entityType;
	const { dictionary } = instance._jsonApi;

	// use instance fields if no fields were provided
	fields = fields || (instance.fields || instance._jsonApi.fields); // depending on instance is Model or Collection;

	// prep params
	const params = {};
	const paramProp = `fields[${entityType}]`;
	params[paramProp] = [];

	// loop over fields to create params
	for (const field of _keys(fields)) {
		const fieldDef = fields[field];

		// only include sub-fieldset of references that are included with the request
		if (fieldDef.referenceType && instance.include.indexOf(field) > -1) {
			// get default value & use it to make up it's fieldset
			const defaultValue = getDefaultValueOfFieldDef(fieldDef);
			Object.assign(params, generateFieldsets(defaultValue));
		}

		params[paramProp].push(translateToJsonApi(field, dictionary));
	}

	// join values
	params[paramProp] = params[paramProp].join(',');

	// return params
	return params;
}

// filter processing
export function convertFiltersToUrlParams(filters, groups, secondFilter) {
	// prep some variables
	const result = {};
	const filterKeys = _keys(filters);
	const filterGroupKeys = _keys(groups);

	if (filterGroupKeys.length) {
		// loop groups and set parameters
		for (const key of filterGroupKeys) {
			for (const param in groups[key]) {
				if (groups[key].hasOwnProperty(param)) {
					result[`filter[${key}][group][${param}]`] = groups[key][param];
				}
			}
		}
	}

	if (filterKeys.length) {
		// loop over items of filter
		for (const key of filterKeys) {
			const filter = filters[key];
			const isEmptyValueCheck = _includes(emptyValuesOperations, filter.operation);

			// convert shortcut to full filter definition
			if (!filter.operation) {
				filter.operation = 'IN';
			}

			// generate base key
			const baseKey = filter.secondaryFilter ? `secondaryfilter[${key}]` : `filter[${key}]`;
			const conditionKey = isEmptyValueCheck ? `${baseKey}[condition]` : baseKey;

			// add properties (allow 'equal' to be used to define 'IN' operation)
			result[`${conditionKey}[operator]`] = filter.operation.toUpperCase();

			if (filter.field) {
				result[`${conditionKey}[path]`] = filter.field;
			}

			if (filter.group) {
				result[`${baseKey}[condition][memberOf]`] = filter.group;
			}

			if (filter.node_type) {
				result[`${baseKey}[node_type]`] = filter.node_type;
			}

			const values = _castArray(filter.value);

			if (!isEmptyValueCheck) {
				const conditionValue = filter.filter ? `${baseKey}[${filter.filter}]` : `${baseKey}[value]`;

				if (values.length > 1) {
					_forEach(values, (item, key) => {
						result[`${conditionValue}[${key}]`] = item;
					});
				} else {
					result[conditionValue] = values[0];
				}
			}
		}
	}

	if (!_isEmpty(secondFilter)) {
		_merge(result, secondFilter);
	}

	return result;
}

// translation utils
export function getTranslatedFilters(instance) {
	// prep new filter object & ensure instance presence
	instance = instance || this;
	const newFilters = {};

	// loop over all properties & translate them using the models instance
	for (const key of _keys(instance._jsonApi.filters)) {
		// translate field key
		const nestedFields = _get(instance, ['_jsonApi', 'filters', key, 'field'], '').split('.');
		let field = false;
		let fieldDef = false;

		if (nestedFields.length > 1) {
			fieldDef = (instance.fields || instance._jsonApi.fields)[nestedFields[0]];
			nestedFields = nestedFields.map((f) => (f === 'id' ? f : translateToJsonApi(f, instance._jsonApi.dictionary)));
			field = nestedFields.join('.');
		} else {
			field = translateToJsonApi(instance._jsonApi.filters[key].field, instance._jsonApi.dictionary);
			fieldDef = (instance.fields || instance._jsonApi.fields)[instance._jsonApi.filters[key].field]; // depending on instance is Model or Collection
			// if field is reference => add id to key
			if (fieldDef && fieldDef.referenceType) field += '.id';
		}

		// use new key to create translated filter
		newFilters[key] = Object.assign({}, instance._jsonApi.filters[key]);
		newFilters[key].field = field;

		// do value mutatiion if necessary based on field default value => (ex: date field needs to be in seconds for api, js works with ms => needs to be corrected)
		const defaultValue = fieldDef && typeof fieldDef.default === 'function' ? fieldDef.default() : fieldDef ? fieldDef.default : fieldDef;

		switch (true) {
			// Converted Date value is in ms => convert to s for backend
			case defaultValue instanceof Date:
				newFilters[key] = mutate(newFilters[key], (v) => Math.round(v / 1000));
				break;

			default:
		}
	}
	// return the results
	return newFilters;
}

export function extractDictionaryFromModel(Model) {
	return new Model()._jsonApi.dictionary;
}

export function extractFieldsFromModel(Model) {
	return new Model().fields;
}

export function mutate(value, method) {
	// if filter defintion
	if (typeof value === 'object' && value.operation !== undefined && value.value !== undefined) {
		// clone first to leave original unchanged
		const newValue = Object.assign({}, value);

		// run mutate on value of object (override value instance of new value with new result)
		newValue.value = mutate(value.value, method);

		// return new value
		return newValue;
	}

	// if array of values
	if (Array.isArray(value)) {
		return value.map((v) => mutate(v, method));
	}

	// if single value

	return (value = method(value));
}

// sort utils
export function sortInfoToJsonApi(sort = {}, dictionary = {}) {
	// create array of keys, ordered by weight
	const keys = _keys(sort);
	const result = [];

	// invert dictionary
	dictionary = invertDictionary(dictionary);

	// sort keys using weight of sort properties (use infinity as default value)
	keys.sort((k1, k2) => (sort[k2].weight || Infinity) - (sort[k1].weight || Infinity));

	// loop over keys to make up correct sort string for api
	keys.forEach((k) => {
		// get field info
		let field = sort[k];

		// convert shorthand to compatible def
		if (typeof field === 'string') field = { type: field };

		// generate new key
		const keyParts = k.split('.');
		keyParts[0] = dictionary[keyParts[0]] || keyParts[0];
		const newKey = keyParts.join('.');

		result.push((field.type === 'desc' ? '-' : '') + newKey);
	});

	return result.join(',');
}

export function jsonApiSortToSortInfo(sort = '', dictionary = {}) {
	// break keys appart & put them into an object
	const parts = sort.split(',');
	const result = {};

	// loop over parts
	parts.forEach((part, index) => {
		// detect type & remove type info of part if necessary
		let type = 'asc';
		if (part[0] === '-') {
			type = 'desc';
			part = part.substring(1);
		}

		// apply to result
		result[dictionary[part] || part] = {
			type,
			weight: index,
		};
	});

	return result;
}

// imports
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import Config from '$root/Config';
import Model from '$dependencies/Model';

// private static properties
const FIELDS = {
	id: {
		type: Number,
		default: '',
		identifier: true,
	},

	// login response
	uid: {
		type: Number,
		default: -1,
		setter: false,
	},
	jwt_token: {
		type: String,
		default: '',
		getter: false,
		setter: false,
	},
	refresh_token: {
		type: String,
		default: '',
	},
	expiration: {
		type: Date,
		default: () => new Date(),
		setter: false,
	},
	created: {
		type: Date,
		default: () => new Date(),
		setter: false,
	},
	// user info response
};

// class definition
export default class SessionModel extends Model {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;

		// call super constructor
		super(args);

		// set extra property
		this._authenticated = false;
		this.rememberLoggedIn = false;

		// set headers config
		this.setConfig('headers', {
			'cache-control': 'no-cache',
		});

		// check for autoload => if true => load from local
		if (args.autoLoad) {
			this.readLocal();
		}
	}

	// methods
	login(username, password, rememberLoggedIn, client_id, client_secret) {
		this.rememberLoggedIn = rememberLoggedIn;

		// prep model config for jwt request
		this.prepareConfig();

		const params = new URLSearchParams();
		params.append('grant_type', 'password');
		params.append('client_id', client_id);
		params.append('client_secret', client_secret);
		params.append('username', username);
		params.append('password', password);

		this.setConfig({
			url: Config.getUrl('login'),
			headers: {
				'Content-type': 'application/x-www-form-urlencoded',
			},
			data: params,
		});

		// send post
		return this.post({});
	}

	requestPassword(email) {
		this.prepareConfig();

		this.setConfig({
			url: Config.getUrl('requestPassword'),
			data: {
				mail: email,
			},
			params: {
				_format: 'json',
			},
			withCredentials: true,
		});

		// send post
		this.post({});
	}

	resetPassword(userName, tempPass, newPass) {
		this.prepareConfig();

		this.setConfig({
			url: Config.getUrl('resetPassword'),
			data: {
				name: userName,
				temp_pass: tempPass,
				new_pass: newPass,
			},
			params: {
				_format: 'json',
			},
			withCredentials: true,
		});

		// send post
		this.post({});
	}

	logout() {
		this.clear();
		this.clearLocal();
		this.emit('logout');
	}

	response(xhr) {
		if (xhr.status === 200) {
			if (xhr.data.access_token && xhr.data.refresh_token) {
				this.setToken(xhr.data.access_token);
				this.setRefreshToken(xhr.data.refresh_token);
			} else if (_includes(_get(xhr, 'data.message'), 'instructions')) {
				this.emit('requestPassword.success');
			} else if (_includes(_get(xhr, 'data.message'), 'saved')) {
				this.emit('resetPassword.success');
			} else {
				this.error();
			}
		}
	}

	prepareConfig() {
		this.unsetConfig('headers');
		this.unsetConfig('params');
		this.unsetConfig('withCredentials');
	}

	setToken(token) {
		this.jwt_token = token;
		this.writeLocal();
	}

	setRefreshToken(refreshToken) {
		this.refresh_token = refreshToken;

		localStorage.setItem(
			Config.storageKeys.refreshToken,
			JSON.stringify({
				refresh_token: this.refresh_token,
			}),
		);
	}

	writeLocal() {
		localStorage.setItem(
			Config.storageKeys.session,
			JSON.stringify({
				jwt_token: this.jwt_token,
			}),
		);
		sessionStorage.setItem(
			Config.storageKeys.session,
			JSON.stringify({
				jwt_token: this.jwt_token,
			}),
		);
	}

	// eslint-disable-next-line class-methods-use-this
	clearLocal() {
		const { session, refreshToken } = Config.storageKeys;

		localStorage.removeItem(session);
		sessionStorage.removeItem(session);
		localStorage.removeItem(refreshToken);
	}

	readLocal() {
		const storageOption = this.getStorageOption();

		if (storageOption) {
			const savedSession = storageOption.getItem(Config.storageKeys.session);
			if (savedSession) {
				const parsedSession = JSON.parse(savedSession);

				if (parsedSession) {
					this.jwt_token = parsedSession.jwt_token;
				}
			}
		}
	}

	getStorageOption() {
		const isAutohrizedOrigin = _includes(document.referrer, window.origin) && document.referrer !== window.origin;

		const vuexStorage = localStorage.getItem('vuex');
		if (vuexStorage) {
			const parsedVuex = JSON.parse(vuexStorage);
			this.rememberLoggedIn = parsedVuex.session.rememberLoggedIn;
		}

		const storageOption = this.rememberLoggedIn || isAutohrizedOrigin ? localStorage : sessionStorage;

		return storageOption;
	}

	destroy() {}

	// utility methods

	// getters & setters
	get jwt_token() {
		return this.get('jwt_token');
	}

	set jwt_token(token) {
		if (token !== this.jwt_token) {
			// set token
			this.set({ jwt_token: token });

			// parse & set token
			parseJwtData.call(this, token);
		}
	}
}

function parseJwt(token) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

	return JSON.parse(window.atob(base64));
}

function parseJwtData(token) {
	const data = parseJwt(token);

	this.set({
		uid: data.sub,
		expiration: data.exp,
		created: data.iat,
	});
}

// imports
import Config from '$root/Config';
import JsonApiCollection from '$dependencies/JsonApiCollection';
import Model from '$dependencies/Model';
import MeasurementModelJSON_API from '$models/measurement/MeasurementModelJSON_API';
// private static properties

// class definition
export default class MeasurementCollectionJSON_API extends JsonApiCollection {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.Model = args.Model || MeasurementModelJSON_API;
		args.entityType = 'node--measurement';

		// config
		args.config = { url: Config.getUrl('measurementJSON_API') };

		// call super constructor
		super(args);
	}

	// methods
	destroy() {}

	bulkSet(model) {
		// convert to object to avoid any mistakes
		if (model instanceof Model) model = model.toObject(true);

		// loop over all & apply values
		this.each((_, item) => {
			item.set(model);
		});

		return this;
	}

	bulkDelete() {
		return Promise.all(
			this.toArray().map((item) => {
				return item.delete();
			}),
		);
	}

	// utility methods

	// getters & setters
}

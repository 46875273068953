// imports
import JsonApiModel from '$dependencies/JsonApiModel';
import BinTypeModel from '$models/bin/BinTypeModel';

// private static properties
export const API_DICTIONARY = {
	uuid: 'id',
    field_bin_g_id: 'custom_id',
	field_bin_id: 'assigned_id',
    field_bin_type: 'bin_type',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
    custom_id: { type: String, default: '', isComputed: true, isEditable: true },
	assigned_id: { type: String, default: '' },
    bin_type: { type: BinTypeModel, default: () => new BinTypeModel(), referenceType: 'taxonomy_term--bin_types' },
};

// class definition
export default class IncidentBinModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.entityType = 'node--bin';
		args.fields = FIELDS;

		// apply default config values
		args.config = Object.assign(
			{
				url: '/jsonapi/node/bin',
			},
			args.config || {},
		);

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);
	}
}